<div>
  <div class="form-box-d" style="padding: 0.5rem">
    <form [formGroup]="mergeOutletForm">
      <div class="row">
        <div class="col-md-2">
          <div class="row mt-3">
            <div class="col-md-12" style="font-size: 20px">
              <label
                style="font-weight: bold; font-size: 20px; margin-bottom: 10px"
              >
                Select Merge Type
              </label>
              <br />
              <ejs-radiobutton
                style="font-size: 24px; margin-bottom: 15px"
                cssClass="e-outline"
                id="outletToParent"
                name="outletToParent"
                value="outletToParent"
                label="Outlet To Parent"
                formControlName="mergeType"
                checked
                [value]="'outlet-to-parent'"
                (change)="mergeTypeChanged($event)"
              >
              </ejs-radiobutton>
              <br />
              <ejs-radiobutton
                style="font-size: 24px"
                cssClass="e-outline e-success"
                id="outletToGpo"
                name="outletToGpo"
                formControlName="mergeType"
                value="outletToGpo"
                label="Outlet To GPO"
                [value]="'outlet-to-gpo'"
                (change)="mergeTypeChanged($event)"
              >
              </ejs-radiobutton>
            </div>
          </div>
        </div>

        <div class="col-md-5 with-divider">
          <div class="col-md-12">
            <div class="row mt-4" style="margin-right: 10px">
              <div class="col-md-6">
                <label
                  for="searchOutlet"
                  style="font-weight: bold; margin-bottom: 15px"
                  >Search Outlets</label
                >
                <div class="e-input-group e-outline">
                  <input
                    class="e-input"
                    type="text"
                    placeholder="Search outlet"
                    (keyup)="searchOutlets($event)"
                    formControlName="searchOutlet"
                  />
                  <span class="e-input-group-icon e-input-popup-date">
                    <i class="fa fa-search"></i>
                  </span>
                </div>
                <div
                  *ngIf="
                    mergeOutletForm.get('searchOutlet')?.invalid &&
                    mergeOutletForm.get('searchOutlet')?.touched
                  "
                  class="text-danger"
                >
                  Outlet is required.
                </div>
              </div>
              <div class="col-md-6">
                <label
                  for="mergeWithDropdown"
                  style="font-weight: bold; margin-bottom: 15px"
                >
                  Merge With
                </label>

                <!-- Outlet Parents -->
                <ejs-dropdownlist
                  *ngIf="mergeType == 'outlet-to-parent'"
                  #selectOutletParentDropdown
                  cssClass="e-outline"
                  id="selectOutletParentDropdown"
                  [dataSource]="availableOutletParent"
                  placeholder="Select Parent Outlet"
                  formControlName="mergeWithDropdown"
                  [fields]="outletParentFields"
                  (select)="mergeWithSelected($event)"
                  [allowFiltering]="true"
                  (filtering)="onOutletParentFiltering($event)"
                >
                  <ng-template #itemTemplate let-data>
                    <div class="custom-item" [title]="data.parentOutlet">
                      {{ data.parentOutlet }}
                    </div>
                  </ng-template>
                </ejs-dropdownlist>

                <!-- GPO's -->
                <ejs-dropdownlist
                  *ngIf="mergeType == 'outlet-to-gpo'"
                  #selectGpoDropdown
                  cssClass="e-outline"
                  id="selectGpoDropdown"
                  [dataSource]="availableGpo"
                  placeholder="Select Parent GPO"
                  formControlName="mergeWithDropdown"
                  [fields]="gpoFields"
                  (select)="mergeWithSelected($event)"
                  [allowFiltering]="true"
                  (filtering)="onOutletGpoFiltering($event)"
                >
                  <ng-template #itemTemplate let-data>
                    <div class="custom-item" [title]="data.gpoOutlet">
                      {{ data.gpoOutlet }}
                    </div>
                  </ng-template>
                </ejs-dropdownlist>

                <div
                  *ngIf="
                    mergeOutletForm.get('mergeWithDropdown')?.invalid &&
                    mergeOutletForm.get('mergeWithDropdown')?.touched
                  "
                  class="text-danger"
                >
                  Merge with is required.
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="row mt-4">
              <div class="col-md-4">
                <label
                  for="effectiveFromDate"
                  style="font-weight: bold; margin-bottom: 15px"
                  >Effective date from</label
                >
                <ejs-datepicker
                  id="effectiveFromDate"
                  format="MM-dd-yyyy"
                  formControlName="effectiveFromDate"
                  cssClass="custom-outline e-outline"
                  placeholder="Select FROM Date"
                  [min]="today"
                  (renderDayCell)="onRenderDayCell($event)"
                >
                </ejs-datepicker>
                <div
                  *ngIf="
                    mergeOutletForm.get('effectiveFromDate')?.invalid &&
                    mergeOutletForm.get('effectiveFromDate')?.touched
                  "
                  class="text-danger"
                >
                  Effective FROM date is required.
                </div>
              </div>
              <div class="col-md-4">
                <label
                  for="effectiveToDate"
                  style="font-weight: bold; margin-bottom: 15px"
                  >Effective date to</label
                >
                <ejs-datepicker
                  id="effectiveToDate"
                  format="MM-dd-yyyy"
                  formControlName="effectiveToDate"
                  cssClass="custom-outline e-outline"
                  placeholder="Select TO Date"
                  [min]="minToDate"
                  (renderDayCell)="onRenderDayCell($event)"
                >
                </ejs-datepicker>
                <div
                  *ngIf="
                    mergeOutletForm.get('effectiveToDate')?.invalid &&
                    mergeOutletForm.get('effectiveToDate')?.touched
                  "
                  class="text-danger"
                >
                  Effective TO date is required.
                </div>
              </div>
              <div class="col-md-4 mt-4 text-center">
                <ejs-switch
                  #bswitch
                  (change)="switchChanged($event)"
                  [checked]="setEndDateSwitch"
                ></ejs-switch>
                <br />
                <label> Set End Date to '12-31-9999' </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-5">
          <div class="row align-items-center">
            <div class="col-md-6">
              <label style="font-weight: 500; font-size: 16px; float: left">
                Selected Outlets
              </label>
            </div>
            <div class="col-md-6">
              <div style="font-weight: 500; font-size: 16px; float: right">
                <button
                  type="button"
                  ejs-button
                  class="btn btn-primary save_btn"
                  [disabled]="!isOutletAvailable"
                  (click)="selectAllOutlets()"
                >
                  Select All
                </button>
              </div>
            </div>
            <div class="col-md-12" style="max-height: 200px; overflow: auto">
              <ejs-grid
                #outletGrid
                id="outletGrid"
                class="syncfusion-grid"
                [dataSource]="searchedOutlets"
                [allowPaging]="true"
                [allowSorting]="true"
                [allowFiltering]="true"
                [filterSettings]="{
                  type: 'Excel'
                }"
                height="100"
                [selectionSettings]="selectionOptions"
                (rowSelected)="onOutletRowSelected($event)"
              >
                <e-columns>
                  <e-column type="checkbox" width="50"> </e-column>
                  <e-column
                    field="outletId"
                    headerText="Outlet ID"
                    width="180"
                    textAlign="center"
                    headerTextAlign="center"
                  ></e-column>
                  <e-column
                    field="outletName"
                    headerText="Outlet Name"
                    width="250"
                    textAlign="left"
                    headerTextAlign="center"
                  ></e-column>
                  <e-column
                    field="outletCot"
                    headerText="Outlet COT"
                    width="250"
                    textAlign="left"
                    headerTextAlign="center"
                  ></e-column>
                  <e-column
                    field="outletCotSubtype"
                    headerText="Outlet SubCOT"
                    width="250"
                    textAlign="left"
                    headerTextAlign="center"
                  ></e-column>
                </e-columns>
              </ejs-grid>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="summaryOfOutletChanges.length == 0">
        <div class="row horizantal-divider">
          <div class="col-md-12">
            <label style="font-weight: 500; font-size: 16px">
              Merge List ({{ mergeList.length }})
            </label>
            &nbsp;
            <button
              class="btn btn-danger removeAll_btn"
              (click)="removeAllFromMergeList()"
              *ngIf="mergeList.length > 1"
            >
              Remove All &nbsp; <span><i class="fa fa-trash"></i></span>
            </button>
          </div>
          <div class="col-md-12">
            <ejs-grid
              #mergeListGrid
              id="mergeListGrid"
              class="syncfusion-grid"
              [dataSource]="mergeList"
              [allowPaging]="true"
              [allowSorting]="true"
              [allowFiltering]="true"
              [filterSettings]="{
                type: 'Excel'
              }"
              [height]="gridHeight"
              [pageSettings]="{
                pageSize: pageSize
              }"
            >
              <e-columns>
                <e-column
                  headerText="Remove"
                  width="110"
                  isPrimaryKey="true"
                  textAlign="center"
                  headerTextAlign="center"
                >
                  <ng-template #template let-data>
                    <a
                      (click)="removeOutletById(data.outletId)"
                      style="
                        color: rgb(255, 0, 81);
                        cursor: pointer;
                        font-weight: 600;
                        text-align: center;
                      "
                    >
                      <i class="fa fa-trash fa-lg"></i>
                    </a>
                  </ng-template>
                </e-column>
                <e-column
                  field="gpoId"
                  headerText="GPO ID"
                  width="130"
                  isPrimaryKey="true"
                ></e-column>
                <e-column
                  field="gpoName"
                  headerText="GPO Name"
                  width="150"
                  isPrimaryKey="true"
                ></e-column>
                <e-column
                  field="gpoOutletGroupId"
                  headerText="GPO Outlet Group ID"
                  width="210"
                ></e-column>
                <e-column
                  field="gpoContractId"
                  headerText="GPO Contract ID"
                  width="190"
                ></e-column>
                <e-column
                  field="outletId"
                  headerText="Outlet ID"
                  width="140"
                  isPrimaryKey="true"
                ></e-column>
                <e-column
                  field="outletGpoId"
                  headerText="Outlet GPO ID"
                  width="170"
                ></e-column>
                <e-column
                  field="outletName"
                  headerText="Outlet Name"
                  width="250"
                ></e-column>
                <e-column
                  field="outletType"
                  headerText="Outlet Type"
                  width="160"
                ></e-column>
                <e-column
                  field="outletCot"
                  headerText="Outlet COT"
                  width="160"
                ></e-column>
                <e-column
                  field="outletCotSubtype"
                  headerText="Outlet COT Subtype"
                  width="210"
                ></e-column>
                <e-column
                  field="outletNpi"
                  headerText="Outlet NPI"
                  width="170"
                ></e-column>
                <e-column
                  field="outletHin"
                  headerText="Outlet HIN"
                  width="170"
                ></e-column>
                <e-column
                  field="outletDea"
                  headerText="Outlet DEA"
                  width="170"
                ></e-column>
                <e-column
                  field="outletAddress1"
                  headerText="Outlet Address 1"
                  width="210"
                ></e-column>
                <e-column
                  field="outletAddress2"
                  headerText="Outlet Address 2"
                  width="210"
                ></e-column>
                <e-column
                  field="outletCity"
                  headerText="Outlet City"
                  width="160"
                ></e-column>
                <e-column
                  field="outletState"
                  headerText="Outlet State"
                  width="160"
                ></e-column>
                <e-column
                  field="outletZip"
                  headerText="Outlet ZIP"
                  width="160"
                ></e-column>
                <e-column
                  field="outletCountry"
                  headerText="Outlet Country"
                  width="170"
                ></e-column>
                <e-column
                  field="outletPhone"
                  headerText="Outlet Phone"
                  width="170"
                ></e-column>
                <e-column
                  field="outletPrimaryContact"
                  headerText="Outlet Primary Contact"
                  width="230"
                ></e-column>
                <e-column
                  field="outletPrimaryEmail"
                  headerText="Outlet Primary Email"
                  width="230"
                ></e-column>
                <e-column
                  field="outletGpoStartDate"
                  headerText="Outlet GPO Start Date"
                  width="230"
                  type="date"
                  [format]="formatoptions"
                >
                </e-column>
                <e-column
                  field="outletGpoEndDate"
                  headerText="Outlet GPO End Date"
                  width="230"
                  type="date"
                  [format]="formatoptions"
                >
                </e-column>
                <e-column
                  field="outletRebateEligible"
                  headerText="Outlet Rebate Eligible"
                  width="230"
                ></e-column>
                <e-column
                  field="outletRebateEligibleStartDate"
                  headerText="Outlet Rebate Eligible Start Date"
                  width="280"
                  type="date"
                  [format]="formatoptions"
                >
                </e-column>
                <e-column
                  field="outletRebateEligibleEndDate"
                  headerText="Outlet Rebate Eligible End Date"
                  width="280"
                  type="date"
                  [format]="formatoptions"
                >
                </e-column>
                <e-column
                  field="outletAnalysisStartDate"
                  headerText="Outlet Analysis Start Date"
                  width="280"
                  type="date"
                  [format]="formatoptions"
                >
                </e-column>
                <e-column
                  field="outletAnalysisEndDate"
                  headerText="Outlet Analysis End Date"
                  width="280"
                  type="date"
                  [format]="formatoptions"
                >
                </e-column>
                <e-column
                  field="outletStatus"
                  headerText="Outlet Status"
                  width="170"
                  isPrimaryKey="true"
                ></e-column>
                <e-column
                  field="outletParentId"
                  headerText="Outlet Parent ID"
                  width="180"
                ></e-column>
                <e-column
                  field="outletParentGpoId"
                  headerText="Outlet Parent GPO ID"
                  width="220"
                ></e-column>
                <e-column
                  field="outletParentName"
                  headerText="Outlet Parent Name"
                  width="250"
                ></e-column>
                <e-column
                  field="outletParentType"
                  headerText="Outlet Parent Type"
                  width="210"
                ></e-column>
                <e-column
                  field="outletParentCot"
                  headerText="Outlet Parent COT"
                  width="210"
                ></e-column>
                <e-column
                  field="outletParentCotSubtype"
                  headerText="Outlet Parent COT Subtype"
                  width="250"
                ></e-column>
                <e-column
                  field="outletParentNpi"
                  headerText="Outlet Parent NPI"
                  width="220"
                ></e-column>
                <e-column
                  field="outletParentHin"
                  headerText="Outlet Parent HIN"
                  width="220"
                ></e-column>
                <e-column
                  field="outletParentDea"
                  headerText="Outlet Parent DEA"
                  width="220"
                ></e-column>
                <e-column
                  field="outletParentAddress1"
                  headerText="Outlet Parent Address 1"
                  width="230"
                ></e-column>
                <e-column
                  field="outletParentAddress2"
                  headerText="Outlet Parent Address 2"
                  width="230"
                ></e-column>
                <e-column
                  field="outletParentCity"
                  headerText="Outlet Parent City"
                  width="200"
                ></e-column>
                <e-column
                  field="outletParentState"
                  headerText="Outlet Parent State"
                  width="200"
                ></e-column>
                <e-column
                  field="outletParentZip"
                  headerText="Outlet Parent ZIP"
                  width="200"
                ></e-column>
                <e-column
                  field="outletParentCountry"
                  headerText="Outlet Parent Country"
                  width="220"
                ></e-column>
                <e-column
                  field="outletParentGpoStartDate"
                  headerText="Outlet Parent GPO Start Date"
                  width="260"
                  type="date"
                  [format]="formatoptions"
                >
                </e-column>
                <e-column
                  field="outletParentGpoEndDate"
                  headerText="Outlet Parent GPO End Date"
                  width="260"
                  type="date"
                  [format]="formatoptions"
                >
                </e-column>
                <e-column
                  field="outletParentStartDate"
                  headerText="Outlet Parent Start Date"
                  width="260"
                  type="date"
                  [format]="formatoptions"
                >
                </e-column>
                <e-column
                  field="outletParentEndDate"
                  headerText="Outlet Parent End Date"
                  width="260"
                  type="date"
                  [format]="formatoptions"
                >
                </e-column>
                <e-column
                  field="outletGrandparentId"
                  headerText="Outlet Grandparent ID"
                  width="230"
                ></e-column>
                <e-column
                  field="outletGrandparentGpoId"
                  headerText="Outlet Grandparent GPO ID"
                  width="250"
                ></e-column>
                <e-column
                  field="outletGrandparentName"
                  headerText="Outlet Grandparent Name"
                  width="250"
                ></e-column>
                <e-column
                  field="outletGrandparentType"
                  headerText="Outlet Grandparent Type"
                  width="250"
                ></e-column>
                <e-column
                  field="outletGrandparentCot"
                  headerText="Outlet Grandparent COT"
                  width="250"
                ></e-column>
                <e-column
                  field="outletGrandparentCotSubtype"
                  headerText="Outlet Grandparent COT Subtype"
                  width="290"
                ></e-column>
                <e-column
                  field="outletGrandparentNpi"
                  headerText="Outlet Grandparent NPI"
                  width="250"
                ></e-column>
                <e-column
                  field="outletGrandparentHin"
                  headerText="Outlet Grandparent HIN"
                  width="250"
                ></e-column>
                <e-column
                  field="outletGrandparentDea"
                  headerText="Outlet Grandparent DEA"
                  width="250"
                ></e-column>
                <e-column
                  field="outletGrandparentAddress1"
                  headerText="Outlet Grandparent Address 1"
                  width="270"
                ></e-column>
                <e-column
                  field="outletGrandparentAddress2"
                  headerText="Outlet Grandparent Address 2"
                  width="270"
                ></e-column>
                <e-column
                  field="outletGrandparentCity"
                  headerText="Outlet Grandparent City"
                  width="230"
                ></e-column>
                <e-column
                  field="outletGrandparentState"
                  headerText="Outlet Grandparent State"
                  width="250"
                ></e-column>
                <e-column
                  field="outletGrandparentZip"
                  headerText="Outlet Grandparent ZIP"
                  width="230"
                ></e-column>
                <e-column
                  field="outletGrandparentCountry"
                  headerText="Outlet Grandparent Country"
                  width="280"
                ></e-column>
                <e-column
                  field="outletGrandparentGpoStartDate"
                  headerText="Outlet Grandparent GPO Start Date"
                  width="300"
                  type="date"
                  [format]="formatoptions"
                >
                </e-column>
                <e-column
                  field="outletGrandparentGpoEndDate"
                  headerText="Outlet Grandparent GPO End Date"
                  width="300"
                  type="date"
                  [format]="formatoptions"
                >
                </e-column>
                <e-column
                  field="recordVersion"
                  headerText="Record Version"
                  width="180"
                  textAlign="center"
                  isPrimaryKey="true"
                >
                </e-column>
              </e-columns>
            </ejs-grid>
          </div>
        </div>

        <div class="d-flex justify-content-end mt-1">
          <button
            ejs-button
            style="margin-left: 10px; margin-right: 10px"
            class="btn btn-primary merge_btn"
            (click)="openMergeConfirmationDialog()"
            [disabled]="!isFormValid()"
          >
            Merge
          </button>
          <button
            ejs-button
            class="btn btn-secondary mr-2 cancle_btn"
            style="margin-left: 10px; color: #fff; margin-right: 10px"
            (click)="openMergeCancellationDialog()"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
  <div
    style="
      background-color: #dfeff4;
      padding: 0.5rem;
      margin-top: 0.5rem;
      border: 1px solid #27d2f8;
      border-radius: 4px;
    "
    *ngIf="summaryOfOutletChanges.length > 0"
  >
    <div class="col-md-12">
      <div class="row align-items-center">
        <div class="col-md-5">
          <label style="font-weight: 500; font-size: 16px">
            Summary of changes
          </label>
        </div>

        <div class="col-md-7">
          <div class="d-flex justify-content-end">
            <!-- Icon container for CSV -->
            <div style="margin-right: 1rem">
              <img
                style="height: 2rem; width: auto; cursor: pointer"
                src="../../../../assets/images/export-options/csv.png"
                (click)="cvsGenerator()"
              />
            </div>

            <!-- Icon container for Excel -->
            <div style="margin-right: 1rem">
              <img
                style="height: 2rem; width: auto; cursor: pointer"
                src="../../../../assets/images/export-options/xlsx.png"
                (click)="xlxsGenerator()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-1">
      <ejs-grid
        #summaryGrid
        id="summaryGrid"
        class="syncfusion-grid"
        [dataSource]="summaryOfOutletChanges"
        [allowPaging]="true"
        [allowSorting]="true"
        [allowFiltering]="true"
        [filterSettings]="{
          type: 'Excel'
        }"
        (rowDataBound)="rowDataBound($event)"
        [height]="gridHeight"
        [pageSettings]="{
          pageSize: pageSize
        }"
        [allowExcelExport]="true"
        (excelExportComplete)="mergeExportComplete()"
      >
        <e-columns>
          <!-- Current cells -->
          <e-column
            field="gpoId"
            headerText="GPO Current ID"
            headerTextAlign="center"
            width="130"
          ></e-column>
          <e-column
            field="gpoName"
            headerText="GPO Current Name"
            headerTextAlign="center"
            width="150"
          ></e-column>
          <e-column
            field="outletParentType"
            headerText="Outlet Current Parent Type"
            headerTextAlign="center"
            width="180"
          ></e-column>
          <e-column
            field="outletParentId"
            headerText="Outlet Current Parent ID"
            headerTextAlign="center"
            width="180"
          ></e-column>
          <e-column
            field="outletParentName"
            headerText="Outlet Current Parent Name"
            headerTextAlign="center"
            width="200"
          ></e-column>
          <e-column
            field="outletParentStartDate"
            headerText="Outlet Current Start Date"
            headerTextAlign="center"
            width="180"
            type="date"
            [format]="formatoptions"
          ></e-column>
          <e-column
            field="outletParentEndDate"
            headerText="Outlet Current End Date"
            headerTextAlign="center"
            width="180"
            type="date"
            [format]="formatoptions"
          ></e-column>

          <!-- New cells -->
          <e-column
            field="gpoNewId"
            headerText="GPO New ID"
            headerTextAlign="center"
            width="130"
          ></e-column>
          <e-column
            field="gpoNewName"
            headerText="GPO New Name"
            headerTextAlign="center"
            width="150"
          ></e-column>
          <e-column
            field="outletParentNewType"
            headerText="Outlet New Parent/GPO Type"
            headerTextAlign="center"
            width="180"
          ></e-column>
          <e-column
            field="outletNewParentId"
            headerText="Outlet New Parent ID"
            headerTextAlign="center"
            width="180"
          ></e-column>
          <e-column
            field="outletNewParentName"
            headerText="Outlet New Parent/GPO Name"
            headerTextAlign="center"
            width="200"
          ></e-column>
          <e-column
            field="outletNewParentStartDate"
            headerText="Outlet New Parent/GPO Start Date"
            headerTextAlign="center"
            width="180"
            type="date"
            [format]="formatoptions"
          ></e-column>
          <e-column
            field="outletNewParentEndDate"
            headerText="Outlet New Parent/GPO End Date"
            headerTextAlign="center"
            width="180"
            type="date"
            [format]="formatoptions"
          ></e-column>
        </e-columns>
      </ejs-grid>

      <div class="d-flex justify-content-end mt-1">
        <button
          ejs-button
          style="margin-right: 10px"
          class="btn btn-primary save_btn"
          (click)="openMergeApplyDialog()"
        >
          Apply
        </button>
        <button
          ejs-button
          class="btn btn-secondary cancle_btn"
          style="margin-left: 10px; color: #fff"
          (click)="openMergeClearAllDialog()"
        >
          Clear All
        </button>
      </div>
    </div>
  </div>
</div>
<ejs-toast #toast></ejs-toast>

<!-- Merge confirmation modal -->
<ejs-dialog
  id="mergeConfirmation"
  #mergeConfirmation
  showCloseIcon="true"
  isModal="true"
  width="40%"
  (overlayClick)="onOverlayClick()"
  cssClass="custom-dialog"
  [footerTemplate]="'Footer'"
  [visible]="false"
>
  <ng-template #footerTemplate>
    <button
      id="yes"
      (click)="mergeConfirmationAction(true)"
      class="btn btn-primary save_btn"
      data-ripple="true"
      style="margin-right: 10px; margin-right: 10px"
    >
      Yes
    </button>
    <button
      id="no"
      (click)="mergeConfirmationAction(false)"
      class="btn btn-secondary cancle_btn"
      data-ripple="true"
      style="margin-right: 10px; margin-right: 10px"
    >
      No
    </button>
  </ng-template>
  <ng-template #content>
    <div class="dialogContent">
      <span style="font-size: 14px; font-weight: 400">
        You are about to merge
        <span style="font-weight: 600">{{ mergeList.length }}</span> outlets. Do
        you want to proceed?
      </span>
    </div>
  </ng-template>
  <ng-template #header>
    <div title="Audit Trails" class="e-icon-settings dlg-template">
      <span style="font-size: 20px">Merge Confirmation</span>
    </div>
  </ng-template>
</ejs-dialog>

<!-- Merge cancellation modal -->
<ejs-dialog
  id="mergeCancellation"
  #mergeCancellation
  showCloseIcon="true"
  isModal="true"
  width="40%"
  (overlayClick)="onOverlayClick()"
  cssClass="custom-dialog"
  [footerTemplate]="'Footer'"
  [visible]="false"
>
  <ng-template #footerTemplate>
    <button
      id="yes"
      (click)="mergeCancellationAction(true)"
      class="btn btn-primary save_btn"
      data-ripple="true"
      style="margin-right: 10px; margin-right: 10px"
    >
      Yes
    </button>
    <button
      id="no"
      (click)="mergeCancellationAction(false)"
      class="btn btn-secondary cancle_btn"
      data-ripple="true"
      style="margin-right: 10px; margin-right: 10px"
    >
      No
    </button>
  </ng-template>
  <ng-template #content>
    <div class="dialogContent">
      <span style="font-size: 14px; font-weight: 400">
        Are you sure you want to cancel? All your changes will be lost.
      </span>
    </div>
  </ng-template>
  <ng-template #header>
    <div title="Audit Trails" class="e-icon-settings dlg-template">
      <span style="font-size: 20px">Merge Cancellation</span>
    </div>
  </ng-template>
</ejs-dialog>

<!-- Merge Apply modal -->
<ejs-dialog
  id="mergeApply"
  #mergeApply
  showCloseIcon="true"
  isModal="true"
  width="40%"
  (overlayClick)="onOverlayClick()"
  cssClass="custom-dialog"
  [footerTemplate]="'Footer'"
  [visible]="false"
>
  <ng-template #footerTemplate>
    <button
      id="yes"
      (click)="mergeApplyAction(true)"
      class="btn btn-primary save_btn"
      data-ripple="true"
      style="margin-right: 10px; margin-right: 10px"
    >
      Yes
    </button>
    <button
      id="no"
      (click)="mergeApplyAction(false)"
      class="btn btn-secondary cancle_btn"
      data-ripple="true"
      style="margin-right: 10px; margin-right: 10px"
    >
      No
    </button>
  </ng-template>
  <ng-template #content>
    <div class="dialogContent">
      <span style="font-size: 14px; font-weight: 400">
        This action will update
        <span style="font-weight: 600">{{
          summaryOfOutletChanges.length
        }}</span>
        outlets. Do you want to proceed?
      </span>
    </div>
  </ng-template>
  <ng-template #header>
    <div title="Audit Trails" class="e-icon-settings dlg-template">
      <span style="font-size: 20px"> Merge Apply Confirmation </span>
    </div>
  </ng-template>
</ejs-dialog>

<!-- Merge Clear All modal -->
<ejs-dialog
  id="mergeClearAll"
  #mergeClearAll
  showCloseIcon="true"
  isModal="true"
  width="40%"
  (overlayClick)="onOverlayClick()"
  cssClass="custom-dialog"
  [footerTemplate]="'Footer'"
  [visible]="false"
>
  <ng-template #footerTemplate>
    <button
      id="yes"
      (click)="mergeClearAllAction(true)"
      class="btn btn-primary save_btn"
      data-ripple="true"
      style="margin-right: 10px; margin-right: 10px"
    >
      Yes
    </button>
    <button
      id="no"
      (click)="mergeClearAllAction(false)"
      class="btn btn-secondary cancle_btn"
      data-ripple="true"
      style="margin-right: 10px; margin-right: 10px"
    >
      No
    </button>
  </ng-template>
  <ng-template #content>
    <div class="dialogContent">
      <span style="font-size: 14px; font-weight: 400">
        Are you sure you want to cancel? All your changes will be lost.
      </span>
    </div>
  </ng-template>
  <ng-template #header>
    <div title="Audit Trails" class="e-icon-settings dlg-template">
      <span style="font-size: 20px">Clear All Confirmation</span>
    </div>
  </ng-template>
</ejs-dialog>
