<style>
  .container {
    width: 500px;
    text-align: center;
    line-height: 400px;
    border: 2px solid #fff;
    margin-top: 12%;
    padding: 2em;
    background-color: #2684cc;
    border-radius: 10px;
  }

  h1 {
    color: #fff;
  }
</style>

<div *ngIf="true">
  <div class="container">
    <h1>Coming soon..!</h1>
  </div>
</div>
<div *ngIf="false">
  <div>
    <button
      class="btn btn-secondary save_btn mt-3"
      title="View Summary"
      (click)="viewSummary()"
    >
      <i class="fas fa-list" style="font-size: 13px"></i
      ><span style="margin-left: 10px">View Summary</span>
    </button>
  </div>

  <div class="row ml-2 mt-3 form-box-d">
    <div class="d-flex flex-wrap align-items-center">
      <ejs-dropdownlist
        #gpoDropdown
        cssClass="e-outline"
        class="e-outline"
        id="gpoDropdown"
        width="200"
        height="30"
        placeholder="Select GPO"
        [allowFiltering]="true"
        [dataSource]="availableGpo"
      ></ejs-dropdownlist>
      <ejs-dropdownlist
        #cotDropdown
        cssClass="e-outline"
        class="e-outline"
        id="cotDropdown"
        width="200"
        placeholder="Select COT"
        [allowFiltering]="true"
        style="margin-left: 10px"
        [dataSource]="availableCot"
      ></ejs-dropdownlist>
      <ejs-dropdownlist
        #cotDropdown
        cssClass="e-outline"
        class="e-outline"
        id="typeDropdown"
        width="200"
        placeholder="Select Type"
        [allowFiltering]="true"
        style="margin-left: 10px"
        [dataSource]="availableType"
      ></ejs-dropdownlist>
      <ejs-dropdownlist
        #cotDropdown
        cssClass="e-outline"
        class="e-outline"
        id="stateDropdown"
        width="200"
        placeholder="Select State"
        [allowFiltering]="true"
        style="margin-left: 10px"
        [dataSource]="availableState"
      ></ejs-dropdownlist>

      <!-- <div class="icon-container" style="margin-left: 300px;">
      <i class="fas fa-file-csv icon-size" style="color: #008000;font-size: 20px;"></i>
    </div>

    <div class="icon-container">
      <i class="fas fa-file-pdf icon-size" style="color: #fa1203;font-size: 20px;"></i>
    </div>

    <div class="icon-container">
      <i class="fas fa-file-excel icon-size" style="color: #008000;font-size: 20px;"></i>
    </div>

    <div class="icon-container">
      <i class="fas fa-expand icon-size" style="font-size: 20px;"></i>
    </div>

    <div class="icon-container">
      <i class="fas fa-columns icon-size" style="font-size: 20px;"></i>
    </div>

    <div class="icon-container">
      <i class="fas fa-chart-bar icon-size" style="font-size: 20px;"></i>
    </div> -->
    </div>
  </div>

  <div
    style="
      background-color: #dfeff4;
      padding: 1rem;
      margin-top: 1rem;
      border: 1px solid #27d2f8;
      border-radius: 4px;
    "
  >
    <div class="control-section default-appbar-section">
      <div class="col-md-12">
        <div class="row align-items-center">
          <div class="col-md-5">
            <label style="font-weight: 500; font-size: 16px">GPO Summary</label>
          </div>

          <div class="col-md-7">
            <div class="d-flex justify-content-end">
              <!-- Icon container for CSV -->
              <div style="margin-right: 1rem">
                <img
                  style="height: 2rem; width: auto; cursor: pointer"
                  src="../../../../assets/images/export-options/csv.png"
                />
              </div>
              <!-- Icon container for PDF -->
              <div style="margin-right: 1rem">
                <img
                  style="height: 2rem; width: auto; cursor: pointer"
                  src="../../../../assets/images/export-options/pdf.png"
                />
              </div>
              <!-- Icon container for Excel -->
              <div style="margin-right: 1rem">
                <img
                  style="height: 2rem; width: auto; cursor: pointer"
                  src="../../../../assets/images/export-options/xlsx.png"
                />
              </div>
              <!-- Icon container for Fullscreen -->
              <div>
                <img
                  style="height: 2rem; width: auto; cursor: pointer"
                  src="../../../../assets/images/export-options/fullscreen.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <ejs-grid
          [dataSource]="dataSource"
          [allowPaging]="true"
          [allowSorting]="true"
        >
          <e-columns>
            <e-column headerText="Select">
              <ng-template #template let-data>
                <input type="checkbox" />
              </ng-template>
            </e-column>
            <e-column headerText="Org Chart">
              <ng-template #template let-data>
                <i class="fas fa-sitemap"></i>
              </ng-template>
            </e-column>
            <e-column field="outletId" headerText="Outlet Id"></e-column>
            <e-column field="outletName" headerText="Outlet Name"></e-column>
            <e-column field="outletType" headerText="Outlet Type"></e-column>
            <e-column
              field="outletParentName"
              headerText="Outlet Parent Name"
            ></e-column>
          </e-columns>
        </ejs-grid>
      </div>
    </div>
  </div>
  @if(displaySummary){
  <div>
    <app-gpo-outlet-summary />
  </div>
  }
</div>
