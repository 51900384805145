<div class="mt-1">
  <form [formGroup]="exportForm" (ngSubmit)="onSubmit()" class="form-box-d">
    <div class="row mb-3">
      <div class="col-md-2">
        <label for="snapshotName" class="form-label">Select Snapshot</label>
        <div class="col-md-12">
          <ejs-multiselect
            cssClass="e-outline"
            class="dropdown-spacing e-outline"
            #snapshotMultiSelect
            formControlName="selectedSnapshot"
            [dataSource]="snapshotData"
            [fields]="{ text: 'snapshotName', value: 'id' }"
            [mode]="'CheckBox'"
          ></ejs-multiselect>
        </div>
      </div>

      <div class="col-md-2">
        <label class="form-label">Export Mode</label>
        <div
          class="col-md-12 d-flex justify-content-between"
          style="margin-top: 0.7rem !important"
        >
          <ejs-radiobutton
            label="Download"
            name="exportMode"
            value="download"
            formControlName="exportMode"
          ></ejs-radiobutton>
          <ejs-radiobutton
            label="API"
            name="exportMode"
            value="api"
            formControlName="exportMode"
            cssClass="disabled-radio"
          ></ejs-radiobutton>
          <ejs-radiobutton
            label="S3"
            name="exportMode"
            value="s3"
            formControlName="exportMode"
            cssClass="disabled-radio"
          ></ejs-radiobutton>
        </div>
      </div>
      <div class="col-md-2">
        <label for="delimiterSelection" class="form-label">File format</label>
        <div
          class="col-md-8 d-flex justify-content-between"
          style="margin-top: 0.7rem !important"
        >
          <ejs-radiobutton
            label="CSV"
            name="exportFormat"
            value="csv"
            formControlName="exportFormat"
            [checked]="true"
            (change)="onFileTypeChange('csv')"
          ></ejs-radiobutton>
          <ejs-radiobutton
            label="Delimiters"
            name="exportFormat"
            value="delimiter"
            formControlName="exportFormat"
            (change)="onFileTypeChange('delimiter')"
          ></ejs-radiobutton>
        </div>
        <div
          *ngIf="exportForm.get('exportFormat')?.value === 'delimiter'"
          class="mt-2"
          style="width: 10rem"
        >
          <ejs-dropdownlist
            cssClass="e-outline"
            class="e-outline"
            id="delimiterSelection"
            formControlName="selectedDelimiter"
            [dataSource]="delimiters"
            [fields]="{ text: 'name', value: 'value' }"
            placeholder="Select Delimiter"
          >
          </ejs-dropdownlist>
        </div>
      </div>

      <div class="col-md-2" style="display: flex; align-items: center">
        <button
          type="submit"
          class="btn btn-secondary save_btn"
          [disabled]="exportForm.invalid"
          style="margin-right: 1rem"
        >
          Submit
        </button>
        <button
          type="button"
          class="btn btn-secondary cancle_btn"
          [disabled]="exportForm.invalid"
          (click)="cancel()"
          style="margin-right: 1rem"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-secondary back_btn"
          (click)="goBack()"
        >
          Back
        </button>
      </div>
    </div>
  </form>
</div>
<ejs-dialog
  #confirmationDialog
  [visible]="true"
  cssClass="custom-dialog"
  *ngIf="showConfirmationDialog"
  [position]="position"
  [content]="confirmationDialogContent"
  [showCloseIcon]="true"
  isModal="true"
  header="Confirm Snapshot Export"
>
  <ng-template #confirmationDialogContent>
    <app-snapshot-export-confirmation-dialog
      [data]="exportForm.value"
      (okClick)="onConfirmation($event)"
      (closeClick)="closeDialog($event)"
    ></app-snapshot-export-confirmation-dialog>
  </ng-template>
</ejs-dialog>

<ejs-dialog
  #cancelDialog
  [visible]="true"
  cssClass="custom-dialog"
  *ngIf="showCancelDialog"
  [position]="position"
  [content]="cancelDialogContent"
  [showCloseIcon]="true"
  isModal="true"
  header="Confirm Cancellation"
>
  <ng-template #cancelDialogContent>
    <app-snapshot-export-cancel-dialog
      (okClick)="onCancel($event)"
      (closeClick)="closeDialog($event)"
    ></app-snapshot-export-cancel-dialog>
  </ng-template>
</ejs-dialog>
