<div class="container-fluid dialog-content">
  <div class="row">
    <div class="col-12">
      <p style="font-size: 14px; font-weight: 400">
        You have selected to create a Snapshot - {{ data.snapshotName }} for
        GPO's - {{ data.selectedGPO }} .
      </p>
      <P style="font-size: 14px; font-weight: 400">
        This Snapshot will be created as Point in Time Date -
        <span>{{ data.snapshotDate | date }}</span>
        .
      </P>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <p style="font-size: 14px; font-weight: 400">
        Please confirm. If you hit cancel you will be redirected back to the
        Create screen
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-primary save_btn"
        style="margin-right: 0.5rem"
        (click)="onOkClick()"
      >
        Confirm
      </button>
      <button
        type="button"
        class="btn btn-secondary cancle_btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
    </div>
  </div>
</div>
