<div class="content d-flex justify-content-center align-items-center">
  <div class="" *ngIf="!isUserLoggedIn">
    <div class="card">
      <article class="card-body">
        <img
          src="assets/images/new_fundae_logo.png"
          class="img-fluid"
          alt="Fundae Logo"
        />

        <form class="mt-4" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="form-group mt-3" style="display: inline">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-user mt-2"></i>
                </span>
              </div>
              <input
                type="email"
                formControlName="username"
                class="form-control"
                id="username"
                placeholder="name@example.com"
                name="username"
              />
            </div>
            <div
              *ngIf="loginForm.get('username')?.errors"
              class="text-danger mt-2"
            >
              <div
                *ngIf="loginForm.get('username')?.errors?.['required'] && loginForm.get('username')?.invalid && loginForm.get('username')?.touched"
              >
                Email is required.
              </div>
              <div
                *ngIf="loginForm.get('username')?.errors?.['email'] && loginForm.get('username')?.invalid && loginForm.get('username')?.touched"
              >
                Please enter a valid email.
              </div>
            </div>
          </div>
          <div class="form-group mt-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-lock mt-2"></i>
                </span>
              </div>
              <input
                name="password"
                placeholder="******"
                type="password"
                formControlName="password"
                class="form-control"
                id="password"
              />
            </div>
            <div
              *ngIf="loginForm.get('password')?.errors?.['required'] && loginForm.get('password')?.invalid && loginForm.get('password')?.touched"
              class="text-danger mt-2"
            >
              Password is required.
            </div>
          </div>
          <div class="form-group mt-3 text-center" style="width: 260px">
            <button class="btn btn-primary btn-block">Login</button>
          </div>
        </form>
      </article>
    </div>
  </div>
  <div class="" *ngIf="isUserLoggedIn && isMfa">
    <div class="card">
      <article class="card-body">
        <div class="text-center" *ngIf="QRCode">
          <div class="text-center">
            <img
              src="assets/images/new_fundae_logo.png"
              class="img-fluid"
              style="max-width: 30%"
              alt="Fundae Logo"
            />
          </div>
          <label class="mb-2 mt-2">
            Scan this QR code to register with the Google Authenticator app.
          </label>
          <img
            [src]="QRCode"
            class="img-fluid text-center"
            alt="Google Authy QR"
          />
        </div>
        <div class="text-center" *ngIf="!QRCode">
          <img
            src="assets/images/new_fundae_logo.png"
            class="img-fluid"
            alt="Fundae Logo"
          />
        </div>

        <!-- MFA Form -->
        <form class="mt-3" [formGroup]="mfaForm" (ngSubmit)="verifyOTP()">
          <label class="mb-4 mt-2" *ngIf="!QRCode">
            Please enter the one-time password from the Google Authenticator
            app.</label
          >
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-key mt-2"></i>
              </span>
            </div>
            <input
              type="text"
              formControlName="code"
              class="form-control"
              id="code"
              placeholder="Enter OTP"
              name="code"
            />
          </div>
          <div
            class="mt-2"
            *ngIf="
              mfaForm.get('code')?.errors &&
              (mfaForm.get('code')?.dirty || mfaForm.get('code')?.touched)
            "
          >
            <div
              class="mt-1 text-danger"
              *ngIf="mfaForm.get('code')?.errors?.['required']"
            >
              OTP is required.
            </div>
            <div
              class="mt-1 text-danger"
              *ngIf="
                mfaForm.get('code')?.errors?.['minlength'] ||
                mfaForm.get('code')?.errors?.['maxlength']
              "
            >
              OTP must be 6 digits long.
            </div>
            <div
              class="mt-1 text-danger"
              *ngIf="mfaForm.get('code')?.errors?.['pattern']"
            >
              OTP must contain only numeric digits.
            </div>
          </div>

          <div class="form-group mt-3 text-center" style="width: 260px">
            <button
              type="submit"
              class="btn btn-primary btn-block"
              [disabled]="mfaForm.invalid"
            >
              Verify
            </button>
          </div>
        </form>
      </article>
    </div>
  </div>
</div>

<ejs-toast #toast></ejs-toast>