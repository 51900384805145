<div class="container-fluid dialog-content">
  <div class="row">
    <div class="col-12">
      <p style="font-size: 14px; font-family: 500">
        To execute Business Rule Sequences, you have the flexibility to reorder
        them by dragging and dropping. The business rules will then execute
        sequentially from top to bottom.
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <label class="form-label">Apply To</label>
    </div>
    <form [formGroup]="gpoForm">
      <div class="col-md-12">
        <ejs-multiselect
          #productDropdown
          id="product"
          formControlName="entity"
          cssClass="custom-outline e-outline"
          [allowFiltering]="true"
          [dataSource]="availableGpo"
          placeholder="Select GPO"
          [mode]="mode"
          (filtering)="searchEntity($event.text)"
          [showSelectAll]="true"
        >
        </ejs-multiselect>
        <!-- Display error message if the dropdown is invalid and touched -->
        <div *ngIf="gpoForm.get('entity')?.invalid" class="text-danger">
          Please select at least one option.
        </div>
      </div>
    </form>
  </div>
  <div class="row my-3">
    <div class="col-12">
      <ejs-listbox
        id="customListBox"
        #listBox
        [dataSource]="data"
        [allowDragAndDrop]="true"
        [fields]="setfield"
        (drop)="onDrop($event)"
        cssClass="custom-listbox"
      ></ejs-listbox>
    </div>
  </div>
  <div class="row">
    <div
      class="col-12 d-flex justify-content-end"
      style="margin-bottom: 0.5rem"
    >
      <button
        type="button"
        class="btn btn-primary check_impact_btn"
        (click)="onOkClick()"
        [disabled]="isButtonDisabled"
      >
        Submit and Check Impact
      </button>
      <button
        type="button"
        class="btn btn-secondary cancle_btn"
        style="margin-left: 0.5rem"
        (click)="closeDialog()"
      >
        Cancel
      </button>
    </div>
  </div>
</div>
