<div class="container-fluid mt-3">
  <!-- Row for Switch and Dialog Button -->
  <ejs-grid
    #grid
    [dataSource]="data"
    [allowPaging]="true"
    [allowSorting]="true"
  >
    <e-columns>
      <e-column field="snapshotName" headerText="Snapshot Name">
        <ng-template #template let-data>
          <span title="{{ data.snapshotName }}">{{ data.snapshotName }}</span>
        </ng-template>
      </e-column>
      <e-column headerText="Snapshot Date">
        <ng-template #template let-data>
          {{ formatSnapshotDate(data.snapshotDate) }}
        </ng-template>
      </e-column>
      <e-column field="selectedGPO" headerText="GPO's"></e-column>
    </e-columns>
  </ejs-grid>
  <div class="row">
    <div class="col-12 d-flex justify-content-end align-items-center">
      <button
        type="button"
        class="btn"
        style="background-color: #29b5e1; color: #ffffff"
        (click)="onOkClick()"
      >
        Confirm
      </button>
    </div>
  </div>
</div>
