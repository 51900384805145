import { CommonModule, DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListBox, ListBoxAllModule, ListBoxComponent } from '@syncfusion/ej2-angular-dropdowns';
import { SnapshotDetailsComponent } from '../../layout/gpo/gpo-point-in-time/snapshot-details/snapshot-details.component';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { Router } from '@angular/router';
import { SnapshotDetailsDialogComponent } from '../snapshot-details-dialog/snapshot-details-dialog.component';
import { LocalDatePipe } from '../pipes/local-date.pipe';



@Component({
  selector: 'app-snapshot-confirmation-dialog',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ListBoxAllModule, DialogModule, CommonModule, SnapshotDetailsDialogComponent
  ],
  providers: [DatePipe],
  templateUrl: './snapshot-confirmation-dialog.component.html',
  styleUrl: './snapshot-confirmation-dialog.component.css'
})
export class SnapshotConfirmationDialogComponent {
  constructor(
    private router: Router,private datePipe: DatePipe) {

  }

  @Input() data!: any;

  @Output() okClick = new EventEmitter();

  @Output() closeClick = new EventEmitter();


  onOkClick() {
    // Emit some data on OK click
    this.okClick.emit({ result: this.data });

  }

  closeDialog() {
    // Emit some data on OK click
    this.closeClick.emit({ result: 'Cancel' });
  }




  isButtonDisabled(): boolean {
    // Return true to disable the button when the condition is not satisfied
    return false;
  }
  onDrop(args: any) {
    this.data = args.source.currentData;
  }

  onDragStop() {
    //  const newListOrder = this.listBox.getSortedList();
    // Process the new list order here
  }



}
