<div class="business-rule-conditions">
  <form [formGroup]="businessConditionsForm" (ngSubmit)="onSubmit()">
    <div formArrayName="businessConditions">
      <div
        *ngFor="
          let condition of businessConditions.controls;
          let i = index;
          let firstRule = first;
          let lastRule = last
        "
        [formGroupName]="i"
      >
        <div class="card form-box-e">
          <div class="card-header">
            <strong>Business Rule #{{ i + 1 }}</strong>
          </div>
          <div class="card-body">
            <!-- Business Rule Type -->
            <div class="row mb-3">
              <label class="col-md-2 col-form-label"
                >Select Business Rule Type</label
              >
              <div class="col-md-10">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    formControlName="ruleType"
                    value="DATE"
                  />
                  <label class="form-check-label">Set Date</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    formControlName="ruleType"
                    value="ATTRIBUTE"
                  />
                  <label class="form-check-label">Set Attributes</label>
                </div>
              </div>
            </div>

            <div class="row">
              <!-- Set Date -->
              <div
                class="col-md-3"
                *ngIf="condition.get('ruleType')?.value === 'DATE'"
                style="border-right: 1px dotted grey"
              >
                <!-- Date to updated -->
                <div class="col-md-12 mt-2">
                  <label class="form-label" style="margin-bottom: 5px"
                    >Select Date To be Updated</label
                  >
                  <ejs-dropdownlist
                    [dataSource]="dateColumns"
                    cssClass="e-outline"
                    placeholder="Please select date field"
                    formControlName="column"
                  >
                    <ng-template #itemTemplate let-data>
                      <span [title]="data">{{ data }}</span>
                    </ng-template>
                  </ejs-dropdownlist>
                  <div
                    *ngIf="
                      condition.get('column')?.invalid &&
                      (condition.get('column')?.dirty ||
                        condition.get('column')?.touched)
                    "
                  >
                    <div
                      *ngIf="condition.get('column')?.hasError('required')"
                      class="text-danger"
                    >
                      Date filter value is required
                    </div>
                  </div>
                </div>

                <!-- Date values -->
                <div class="col-md-12 mt-2">
                  <label class="form-label" style="margin-bottom: 5px"
                    >Set Date To</label
                  >
                  <ejs-dropdownlist
                    [dataSource]="setDateValues"
                    cssClass="e-outline"
                    placeholder="Please select date field"
                    formControlName="value"
                  >
                    <ng-template #itemTemplate let-data>
                      <span [title]="data">{{ data }}</span>
                    </ng-template>
                  </ejs-dropdownlist>
                  <div
                    *ngIf="
                      condition.get('value')?.touched &&
                      condition.get('value')?.hasError('required')
                    "
                    class="text-danger"
                  >
                    Date vaule is required
                  </div>
                </div>

                <!-- Appears only when user selected 'Custom Date' -->
                <div
                  class="col-md-12 mt-2"
                  *ngIf="condition.get('value')?.value === 'Custom Date'"
                >
                  <label class="form-label" style="margin-bottom: 5px"
                    >Custom Date</label
                  >
                  <ejs-datepicker
                    id="customDate"
                    format="MM-dd-yyyy"
                    formControlName="customDate"
                    cssClass="custom-outline e-outline form-control"
                    placeholder="Select custom date (MM-DD-YYYY)"
                  >
                  </ejs-datepicker>
                  <div
                    *ngIf="
                      condition.get('customDate')?.touched &&
                      condition.get('customDate')?.hasError('required')
                    "
                    class="text-danger"
                  >
                    Custom date is required. Or, date is invalid!
                  </div>
                </div>
              </div>

              <!-- Set Attribute -->
              <div
                class="col-md-3"
                *ngIf="condition.get('ruleType')?.value === 'ATTRIBUTE'"
                style="border-right: 1px dotted grey"
              >
                <!-- Attribute field -->
                <div class="col-md-12 mt-2">
                  <label
                    [for]="'attribute-' + i"
                    class="form-label"
                    style="margin-bottom: 5px"
                    >Update Attributes</label
                  >
                  <ejs-dropdownlist
                    [dataSource]="attributeColumns"
                    cssClass="e-outline"
                    placeholder="Please select attribute field"
                    formControlName="column"
                    [allowFiltering]="true"
                    filterBarPlaceholder="Search Here..."
                    (change)="onChange($event, i)"
                    (click)="userInteraction = true"
                    (keydown)="userInteraction = true"
                  >
                    <ng-template #itemTemplate let-data>
                      <span [title]="data">{{ data }}</span>
                    </ng-template>
                  </ejs-dropdownlist>
                  <div
                    *ngIf="
                      condition.get('column')?.invalid &&
                      (condition.get('column')?.dirty ||
                        condition.get('column')?.touched)
                    "
                  >
                    <div
                      *ngIf="condition.get('column')?.hasError('required')"
                      class="text-danger"
                    >
                      Attribute field is required
                    </div>
                  </div>
                </div>

                <!-- Atrribute values -->
                <div
                  class="col-md-12 mt-2"
                  *ngIf="
                    condition.get('column')?.value !== 'Outlet Rebate Eligible'
                  "
                >
                  <label class="form-label" style="margin-bottom: 5px"
                    >Set New Attribute Value</label
                  >
                  <ejs-textbox
                    formControlName="value"
                    placeholder="Please enter attribute value"
                    cssClass="e-outline"
                    style="background-color: white"
                  ></ejs-textbox>
                  <div
                    *ngIf="
                      condition.get('value')?.invalid &&
                      (condition.get('value')?.dirty ||
                        condition.get('value')?.touched)
                    "
                  >
                    <div
                      *ngIf="condition.get('value')?.hasError('required')"
                      class="text-danger"
                    >
                      New attribute value is required
                    </div>
                  </div>
                </div>
                <!-- Boolean Flags -->
                <div
                  class="col-md-12 mt-2"
                  *ngIf="
                    condition.get('column')?.value === 'Outlet Rebate Eligible'
                  "
                >
                  <label class="form-label" style="margin-bottom: 5px"
                    >Select Attribute Value</label
                  >
                  <ejs-dropdownlist
                    [dataSource]="booleanDataSource"
                    formControlName="value"
                    placeholder="Please select attribute value"
                    cssClass="e-outline"
                    style="background-color: white"
                  >
                    <ng-template #itemTemplate let-data>
                      <span [title]="data">{{ data }}</span>
                    </ng-template>
                  </ejs-dropdownlist>
                  <div
                    *ngIf="
                      condition.get('value')?.invalid &&
                      (condition.get('value')?.dirty ||
                        condition.get('value')?.touched)
                    "
                  >
                    <div
                      *ngIf="condition.get('value')?.hasError('required')"
                      class="text-danger"
                    >
                      Attribute value is required
                    </div>
                  </div>
                </div>
              </div>

              <!-- Where Condition -->
              <div class="col-md-9 where-conditions">
                <div formArrayName="whereConditions">
                  <div
                    *ngFor="
                      let whereCondition of getWhereConditions(i).controls;
                      let j = index;
                      let first = first;
                      let last = last
                    "
                    [formGroupName]="j"
                  >
                    <div class="row mt-1">
                      <div class="col-md-12">
                        <div class="card">
                          <div class="card-header">
                            <div class="row">
                              <label
                                class="col-md-2 col-form-label"
                                style="height: 2rem"
                                >Condition Type</label
                              >
                              <div
                                class="col-md-10 col-form-label"
                                style="height: 2rem"
                              >
                                <div class="form-check form-check-inline">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    formControlName="conditionType"
                                    value="DATE"
                                  />
                                  <label class="form-check-label">Date</label>
                                </div>
                                <div class="form-check form-check-inline">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    formControlName="conditionType"
                                    value="ATTRIBUTE"
                                  />
                                  <label class="form-check-label"
                                    >Attributes</label
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="card-body">
                            <!-- Date filters -->
                            <div class="row">
                              <div
                                class="col-md-10 date-filters"
                                *ngIf="
                                  whereCondition.get('conditionType')?.value ===
                                  'DATE'
                                "
                              >
                                <div class="row">
                                  <div class="col-md-4">
                                    <label
                                      class="form-label"
                                      style="margin-bottom: 5px"
                                      >When</label
                                    >
                                    <ejs-dropdownlist
                                      [dataSource]="dateBaseColumns"
                                      cssClass="e-outline"
                                      placeholder="Please select date filter"
                                      formControlName="whereColumn"
                                    >
                                      <ng-template #itemTemplate let-data>
                                        <span [title]="data">{{ data }}</span>
                                      </ng-template>
                                    </ejs-dropdownlist>
                                    <div
                                      *ngIf="
                                        whereCondition.get('whereColumn')
                                          ?.touched &&
                                        whereCondition
                                          .get('whereColumn')
                                          ?.hasError('required')
                                      "
                                      class="text-danger"
                                    >
                                      When date is required.
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <label
                                      class="form-label"
                                      style="margin-bottom: 5px"
                                      >Date is</label
                                    >
                                    <ejs-dropdownlist
                                      [dataSource]="dateCustomOptions"
                                      cssClass="e-outline"
                                      placeholder="Please select date value"
                                      formControlName="filterValue"
                                    >
                                      <ng-template #itemTemplate let-data>
                                        <span [title]="data">{{ data }}</span>
                                      </ng-template>
                                    </ejs-dropdownlist>
                                    <div
                                      *ngIf="
                                        whereCondition.get('filterValue')
                                          ?.touched &&
                                        whereCondition
                                          .get('filterValue')
                                          ?.hasError('required')
                                      "
                                      class="text-danger"
                                    >
                                      Date filter value is required.
                                    </div>
                                  </div>

                                  <!-- Appears only when user selected 'Custom Date' -->
                                  <div
                                    class="col-md-4"
                                    *ngIf="
                                      whereCondition.get('filterValue')
                                        ?.value === 'Custom Date'
                                    "
                                  >
                                    <div class="row">
                                      <div class="row mt-1">
                                        <label
                                          class="form-label"
                                          style="margin-bottom: 5px"
                                          >Logical Operator</label
                                        >
                                        <ejs-dropdownlist
                                          [dataSource]="dateOperators"
                                          cssClass="e-outline"
                                          placeholder="Select logical operator"
                                          formControlName="operator"
                                        >
                                          <ng-template #itemTemplate let-data>
                                            <span [title]="data">{{
                                              data
                                            }}</span>
                                          </ng-template>
                                        </ejs-dropdownlist>
                                        <div
                                          *ngIf="
                                            whereCondition.get('operator')
                                              ?.touched &&
                                            whereCondition
                                              .get('operator')
                                              ?.hasError('required')
                                          "
                                          class="text-danger"
                                        >
                                          Date filter operator is required.
                                        </div>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <label
                                        class="form-label"
                                        style="margin-bottom: 5px"
                                        >Custom Date &nbsp;
                                        <span
                                          *ngIf="
                                            whereCondition.get('operator')
                                              ?.value === 'BETWEEN'
                                          "
                                          >1</span
                                        ></label
                                      >
                                      <ejs-datepicker
                                        id="customDate"
                                        format="MM-dd-yyyy"
                                        formControlName="customDate1"
                                        cssClass="custom-outline e-outline form-control"
                                        placeholder="Select custom date (MM-DD-YYYY)"
                                      >
                                      </ejs-datepicker>
                                      <div
                                        *ngIf="
                                          condition.get('customDate')
                                            ?.touched &&
                                          condition
                                            .get('customDate')
                                            ?.hasError('required')
                                        "
                                        class="text-danger"
                                      >
                                        Custom date is required. Or, date is
                                        invalid!
                                      </div>
                                    </div>
                                    <div
                                      class="row"
                                      *ngIf="
                                        whereCondition.get('operator')
                                          ?.value === 'BETWEEN'
                                      "
                                    >
                                      <label
                                        class="form-label"
                                        style="margin-bottom: 5px"
                                        >Custom Date 2
                                      </label>
                                      <ejs-datepicker
                                        id="customDate"
                                        format="MM-dd-yyyy"
                                        formControlName="customDate2"
                                        cssClass="custom-outline e-outline form-control"
                                        placeholder="Select custom date (MM-DD-YYYY)"
                                      >
                                      </ejs-datepicker>
                                      <div
                                        *ngIf="
                                          condition.get('customDate')
                                            ?.touched &&
                                          condition
                                            .get('customDate')
                                            ?.hasError('required')
                                        "
                                        class="text-danger"
                                      >
                                        Custom date is required. Or, date is
                                        invalid!
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <!-- Attribute filters -->
                              <div
                                class="col-md-10 attribute-filters"
                                *ngIf="
                                  whereCondition.get('conditionType')?.value ===
                                  'ATTRIBUTE'
                                "
                              >
                                <div class="row">
                                  <div class="col-md-4">
                                    <label
                                      class="form-label"
                                      style="margin-bottom: 5px"
                                      >When Attribute</label
                                    >
                                    <ejs-dropdownlist
                                      [dataSource]="attributeBaseColumns"
                                      cssClass="e-outline"
                                      placeholder="Select attribute filter"
                                      formControlName="whereColumn"
                                      [allowFiltering]="true"
                                      filterBarPlaceholder="Search Here..."
                                    >
                                      <ng-template #itemTemplate let-data>
                                        <span [title]="data">{{ data }}</span>
                                      </ng-template>
                                    </ejs-dropdownlist>
                                    <div
                                      *ngIf="
                                        whereCondition.get('whereColumn')
                                          ?.touched &&
                                        whereCondition
                                          .get('whereColumn')
                                          ?.hasError('required')
                                      "
                                      class="text-danger"
                                    >
                                      When attribute is required.
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <label
                                      class="form-label"
                                      style="margin-bottom: 5px"
                                      >Operator</label
                                    >
                                    <ejs-dropdownlist
                                      [dataSource]="stringOperators"
                                      cssClass="e-outline"
                                      placeholder="Select logical operator"
                                      formControlName="operator"
                                      (change)="
                                        whereCondition
                                          .get('filterValue')
                                          ?.setValue('')
                                      "
                                    >
                                      <ng-template #itemTemplate let-data>
                                        <span [title]="data">{{ data }}</span>
                                      </ng-template>
                                    </ejs-dropdownlist>
                                    <div
                                      *ngIf="
                                        whereCondition.get('operator')
                                          ?.touched &&
                                        whereCondition
                                          .get('operator')
                                          ?.hasError('required')
                                      "
                                      class="text-danger"
                                    >
                                      Attribute filter operator is required.
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4"
                                    *ngIf="
                                      whereCondition.get('operator')?.value !==
                                      'IN'
                                    "
                                  >
                                    <label
                                      class="form-label"
                                      style="margin-bottom: 5px"
                                      >Value</label
                                    >
                                    <ejs-textbox
                                      formControlName="filterValue"
                                      placeholder="Please enter attribute value"
                                      cssClass="e-outline"
                                      style="background-color: white"
                                    ></ejs-textbox>
                                    <div
                                      *ngIf="
                                        whereCondition.get('filterValue')
                                          ?.invalid &&
                                        (whereCondition.get('filterValue')
                                          ?.dirty ||
                                          whereCondition.get('filterValue')
                                            ?.touched)
                                      "
                                    >
                                      <div
                                        *ngIf="
                                          whereCondition
                                            .get('filterValue')
                                            ?.hasError('required')
                                        "
                                        class="text-danger"
                                      >
                                        Filter value is required
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4"
                                    *ngIf="
                                      whereCondition.get('operator')?.value ===
                                      'IN'
                                    "
                                  >
                                    <label
                                      class="form-label"
                                      style="margin-bottom: 5px"
                                      >Value</label
                                    >
                                    <ejs-textbox
                                      #filterValue
                                      formControlName="filterValue"
                                      placeholder="Please enter attribute value"
                                      cssClass="e-outline"
                                      style="background-color: white"
                                      (keyup)="
                                        isValidFilterValue(
                                          whereCondition.get('filterValue')
                                            ?.value
                                        )
                                      "
                                    ></ejs-textbox>
                                    <div
                                      *ngIf="
                                        whereCondition.get('filterValue')
                                          ?.invalid &&
                                        (whereCondition.get('filterValue')
                                          ?.dirty ||
                                          whereCondition.get('filterValue')
                                            ?.touched)
                                      "
                                    >
                                      <div
                                        *ngIf="
                                          whereCondition
                                            .get('filterValue')
                                            ?.hasError('required')
                                        "
                                        class="text-danger"
                                      >
                                        Filter value is required
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-2">
                                <div
                                  class="col-md-12 col-form-label"
                                  style="
                                    display: flex;
                                    justify-content: center !important;
                                    align-items: center !important;
                                    padding-top: 2.2rem !important;
                                  "
                                >
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="radio"
                                      formControlName="logicalOperator"
                                      value="AND"
                                    />
                                    <label class="form-check-label">AND</label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="radio"
                                      formControlName="logicalOperator"
                                      value="OR"
                                    />
                                    <label class="form-check-label">OR</label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- Action Buttons -->
                            <div
                              class="row where-condition-actions"
                              *ngIf="last"
                            >
                              <div class="col-md-12">
                                <button
                                  type="button"
                                  class="btn btn-primary btn-sm add_rule_btn"
                                  style="padding: 4px 20px; font-size: 14px"
                                  (click)="addWhereCondition(i)"
                                >
                                  <i class="fa fa-plus"></i>
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-danger btn-sm"
                                  (click)="removeWhereCondition(i, j)"
                                  style="
                                    padding: 4px 20px;
                                    font-size: 14px;
                                    margin-left: 10px;
                                  "
                                  [disabled]="first"
                                >
                                  <i class="fa fa-trash"></i>
                                </button>
                              </div>
                            </div>

                            <div
                              class="row"
                              *ngIf="
                                last && i === businessConditions.length - 1
                              "
                            >
                              <div class="col-md-12 business-rule-actions">
                                <div
                                  class="d-grid gap-2 d-md-flex justify-content-md-end"
                                >
                                  <button
                                    type="button"
                                    class="btn btn-primary search_btn"
                                    (click)="addBusinessCondition()"
                                  >
                                    Add More!
                                  </button>
                                  <button
                                    type="submit"
                                    class="btn btn-primary e-primary save_btn"
                                    [disabled]="
                                      businessConditionsForm.valid && formValid
                                        ? false
                                        : true
                                    "
                                  >
                                    Save
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-secondary cancle_btn"
                                    (click)="goBack()"
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-danger"
                                    style="font-size: 14px"
                                    (click)="removeBusinessRule(i)"
                                    [disabled]="
                                      businessConditions.length > 1
                                        ? false
                                        : true
                                    "
                                  >
                                    <i class="fa fa-trash"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<ejs-dialog
  #deletionDialog
  [visible]="true"
  [isModal]="true"
  [header]="'Business Rule Condition Delete'"
  *ngIf="showDeleteDialog"
  cssClass="custom-dialog"
  [content]="deletionDialogContent"
  [showCloseIcon]="true"
  (close)="showDeleteDialog = false"
>
  <ng-template #deletionDialogContent>
    <app-business-rule-condition-deletion-dialog
      [data]="conditionToRemove"
      (okClick)="onDeleteConfirmation($event)"
      (closeClick)="closeDialog($event)"
    ></app-business-rule-condition-deletion-dialog>
  </ng-template>
</ejs-dialog>
<ejs-toast #toast></ejs-toast>
