import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { ButtonAllModule } from '@syncfusion/ej2-angular-buttons';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { TextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-create-view',
  standalone: true,
  imports: [CommonModule,DialogModule,ButtonAllModule,GridAllModule,TextBoxAllModule],
  templateUrl: './create-view.component.html',
  styleUrl: './create-view.component.css'
})
export class CreateViewComponent {

  @ViewChild('createViewDialog')
  createViewDialog!: DialogComponent;
  animationSettings: Object = { effect: 'Zoom', duration: 200, delay: 0 };
  visible: Boolean = true;
  dialogWidth: string = '800px';
  dialogHeight:string = '800px';
  dimensionModalposition: object = { X: "center", Y: "200" }
  header = "Create View Definition Screen";
  showCloseIcon=true;

  dataSource:any;

  ngOnInit()
  {
    this.dataSource = [{
      "viewName":'username',"viewDescription":'Content',"createdOn":'New Tag',"updatedOn":''
    },
    {
      "viewName":'username',"viewDescription":'Content',"createdOn":'New Tag',"updatedOn":''
    }]
  }

  cancel()
  {
    this.createViewDialog.hide();
  }
}
