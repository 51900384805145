import { Component } from '@angular/core';

@Component({
  selector: 'app-gpo-history',
  standalone: true,
  imports: [],
  templateUrl: './gpo-history.component.html',
  styleUrl: './gpo-history.component.css'
})
export class GpoHistoryComponent {

}
