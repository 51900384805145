import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ScrollBarService } from "@syncfusion/ej2-angular-charts";
import { DropDownListModule } from "@syncfusion/ej2-angular-dropdowns";
import {
  CommandModel,
  GridAllModule,
  Column,
  GridComponent,
  EditService,
  PageService,
  SortService,
  ToolbarService,
  SelectionService,
  ActionEventArgs,
  SaveEventArgs,
  IEditCell,
  RowDataBoundEventArgs,
  ExcelExportProperties,
} from "@syncfusion/ej2-angular-grids";
import {
  AppBarModule,
  ToolbarModule,
} from "@syncfusion/ej2-angular-navigations";
import { DialogComponent, DialogModule } from "@syncfusion/ej2-angular-popups";
import { CommonModule, DatePipe, formatDate } from "@angular/common";
import { CreateViewComponent } from "../create-view/create-view.component";
import { OpenViewComponent } from "../open-view/open-view.component";
import { GpoService } from "../../gpo.service";
import { ToastService } from "../../../common/toast.service";
import { EditSettingsModel } from "@syncfusion/ej2-angular-grids";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { DatePickerModule } from "@syncfusion/ej2-angular-calendars";
import { OrgchartComponent } from "../../../common/chart/orgchart/orgchart.component";
import { DataManager, Query } from "@syncfusion/ej2-data";
import { Router } from "@angular/router";
import { SwitchModule } from "@syncfusion/ej2-angular-buttons";

interface RowData {
  [key: string]: any;
}

@Component({
  selector: "app-customgrid",
  standalone: true,
  imports: [
    DropDownListModule,
    ToolbarModule,
    GridAllModule,
    CommonModule,
    DialogModule,
    AppBarModule,
    CreateViewComponent,
    OpenViewComponent,
    ReactiveFormsModule,
    FormsModule,
    DatePickerModule,
    OrgchartComponent,
    SwitchModule,
  ],
  templateUrl: "./customgrid.component.html",
  styleUrl: "./customgrid.component.css",
  providers: [
    ScrollBarService,
    EditService,
    ToolbarService,
    SortService,
    PageService,
    SelectionService,
    DatePipe,
  ],
})
export class CustomgridComponent implements OnInit {
  public data!: any[];
  @ViewChild("gpoGrid") public grid!: GridComponent;
  @ViewChild("AuditTrailGrid") public AuditTrailGrid!: GridComponent;
  @ViewChild("deleteDialog") public deleteDialog!: DialogComponent;
  @ViewChild("updateDialog") public updateDialog!: DialogComponent;
  @ViewChild("auditTrailDialog") auditTrailDialog: DialogComponent | any;
  @ViewChild("chartDialog") public chartDialog!: DialogComponent;

  public shortDateFormat: string;
  selectedRecords!: any[];
  public pendingDeleteAction: ActionEventArgs | null = null; // To store the pending delete action

  public orderIDRules?: Object;
  public customerIDRules?: Object;
  public freightRules?: Object;
  public editparams?: Object;
  public pageSettings?: Object;
  public editSettings!: EditSettingsModel;
  public toolbar!: string[];
  public commands!: CommandModel[];

  public chartSelectedData!: any[];

  @Input("type") type = "grid";
  orgChartData!: any;

  public dropdownFields: Object = { text: "text", value: "value" }; // Define fields for the dropdown
  public currentColumn?: Column;

  public allGpos?: any;
  public allCots?: any;
  public allTypes?: any;
  public allStates?: any;

  public gridFilter?: any = {
    gpo: null,
    cot: null,
    type: null,
    state: null,
  };

  gridForm!: FormGroup;

  public selectedGpo: string = "";
  public auditTrailData: any;
  public attributeChanges: any;

  public formatoptions?: Object;
  public filterOptions?: Object;

  public outletGpoStartDate!: IEditCell;
  public outletGpoEndDate!: IEditCell;
  public outletRebateEligibleStartDate!: IEditCell;
  public outletRebateEligibleEndDate!: IEditCell;
  public outletAnalysisStartDate!: IEditCell;
  public outletAnalysisEndDate!: IEditCell;
  public outletParentGpoStartDate!: IEditCell;
  public outletParentGpoEndDate!: IEditCell;
  public outletParentStartDate!: IEditCell;
  public outletParentEndDate!: IEditCell;
  public outletGrandparentGpoStartDate!: IEditCell;
  public outletGrandparentGpoEndDate!: IEditCell;

  public auditTrailRecord: any;
  public lastUpdatedBy: string = "NA";
  public lastUpdateMode!: string;
  public gridHeight!: string;
  public pageSize!: number;

  public auditTrailGridHeight: string = "170px";

  headerHeight = 405;
  footerHeight = 20;
  paginationHeight = 40;
  rowHeight = 28;
  buffer = 20;

  public dateFileds: string[] = [
    "outletGpoStartDate",
    "outletGpoEndDate",
    "outletRebateEligibleStartDate",
    "outletRebateEligibleEndDate",
    "outletAnalysisStartDate",
    "outletAnalysisEndDate",
    "outletParentGpoStartDate",
    "outletParentGpoEndDate",
    "outletParentStartDate",
    "outletParentEndDate",
    "outletGrandparentGpoStartDate",
    "outletGrandparentGpoEndDate",
    "activeFromDate",
    "activeTillDate",
    "recordCreatedDate",
    "lastUpdateDate",
  ];

  allDataSource: any;
  toggleBarChecked: string = "all";

  constructor(
    private gpoService: GpoService,
    private toastService: ToastService,
    private formBuilder: FormBuilder,
    private router: Router,
    private datePipe: DatePipe
  ) {
    this.shortDateFormat = "MM-dd-yyyy";
    this.formatoptions = { type: "date", format: "MM-dd-yyyy" };
    this.filterOptions = {
      type: "Excel",
    };

    this.outletGpoStartDate = {
      params: {
        format: "MM-dd-yyyy",
      },
    };
    this.outletGpoEndDate = {
      params: {
        format: "MM-dd-yyyy",
      },
    };
    this.outletRebateEligibleStartDate = {
      params: {
        format: "MM-dd-yyyy",
      },
    };
    this.outletRebateEligibleEndDate = {
      params: {
        format: "MM-dd-yyyy",
      },
    };
    this.outletAnalysisStartDate = {
      params: {
        format: "MM-dd-yyyy",
      },
    };
    this.outletAnalysisEndDate = {
      params: {
        format: "MM-dd-yyyy",
      },
    };
    this.outletParentGpoStartDate = {
      params: {
        format: "MM-dd-yyyy",
      },
    };
    this.outletParentGpoEndDate = {
      params: {
        format: "MM-dd-yyyy",
      },
    };
    this.outletParentStartDate = {
      params: {
        format: "MM-dd-yyyy",
      },
    };
    this.outletParentEndDate = {
      params: {
        format: "MM-dd-yyyy",
      },
    };
    this.outletGrandparentGpoStartDate = {
      params: {
        format: "MM-dd-yyyy",
      },
    };
    this.outletGrandparentGpoEndDate = {
      params: {
        format: "MM-dd-yyyy",
      },
    };
  }

  public ngOnInit(): void {
    this.gridForm = this.formBuilder.group({
      gpo: [this.gridFilter.gpo],
      cot: [this.gridFilter.cot],
      type: [this.gridFilter.type],
      state: [this.gridFilter.state],
    });

    this.getAllGpoList();
    this.getFilterData();

    this.editSettings = {
      allowEditing: true,
      allowDeleting: true,
      mode: "Normal",
    };
    this.toolbar = ["Update", "Delete", "Cancel"];

    this.pageSettings = { pageSize: this.pageSize };
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.adjustGridHeight();
  }

  private adjustGridHeight() {
    // Calculate the grid height
    let availableHeight =
      window.innerHeight -
      this.headerHeight -
      this.footerHeight -
      this.paginationHeight -
      this.buffer;
    this.gridHeight = `${availableHeight}px`;

    // Calculate page size based on row height
    this.pageSize = Math.floor(availableHeight / this.rowHeight);
  }

  refreshGrid() {
    this.grid.dataSource = [];
    this.grid.refresh();
    this.grid.dataSource = this.data;
  }
  getFilterData() {
    this.getAllGpos();
    this.getAllCots();
    this.gatAllTypes();
    this.getAllStates();
  }

  onGPOFiltering(event: any) {
    const query = event.text.toLowerCase();
    const gpoListData = this.allGpos.filter((item: any) => 
      item.value.toLowerCase().includes(query)
    );
    event.updateData(gpoListData);
  }

  onCOTFiltering(event: any) {
    const query = event.text.toLowerCase();
    const cotListData = this.allCots.filter((item: any) => 
      item.value.toLowerCase().includes(query)
    );
    event.updateData(cotListData);
  }

  onTypeFiltering(event: any) {
    const query = event.text.toLowerCase();
    const typeListData = this.allTypes.filter((item: any) => 
      item.value.toLowerCase().includes(query)
    );
    event.updateData(typeListData);
  }

  onSwitchChange(event: any) {
    if (event.checked) {
      this.toggleBarChecked = "active";
      this.data = this.allDataSource.filter(
        (record: any) => record.outletStatus.toUpperCase() === "ACTIVE"
      );
    } else {
      this.data = [...this.allDataSource];
      this.toggleBarChecked = "all";
    }
  }

  changeFilterValue($event: any, key: string) {
    if ($event && $event.value && $event.value != "all") {
      this.gridFilter[key] = $event.value;
    } else {
      this.gridFilter[key] = null;
    }
  }

  clearFilters() {
    this.gridFilter.gpo = null;
    this.gridFilter.cot = null;
    this.gridFilter.type = null;
    this.gridFilter.state = null;

    this.gridForm.reset(this.gridFilter);

    this.getAllGpoList();
  }
  gatAllTypes() {
    this.gpoService.gatAllTypes().subscribe(
      (data) => {
        if (data) {
          data.unshift({ key: "all", value: "All" });
          this.allTypes = data;
          // this.toastService.showToast(
          //   "Success!",
          //   "Outlet types fetched successfully",
          //   "success"
          // );
        } else {
          this.toastService.showToast(
            "Error!",
            "No data available for outlet types",
            "error"
          );
        }
      },
      (error) => {
        this.toastService.showToast(
          "Error!",
          "Failed to load outlet types",
          "error"
        );
      }
    );
  }
  getAllStates() {
    this.gpoService.getAllStates().subscribe(
      (data) => {
        if (data) {
          data.unshift({ key: "all", value: "All" });
          this.allStates = data;
          // this.toastService.showToast(
          //   "Success!",
          //   "Outlet states fetched successfully",
          //   "success"
          // );
        } else {
          this.toastService.showToast(
            "Error!",
            "No data available for outlet states",
            "error"
          );
        }
      },
      (error) => {
        this.toastService.showToast(
          "Error!",
          "Failed to load outlet states",
          "error"
        );
      }
    );
  }
  getAllCots() {
    this.gpoService.getAllCots().subscribe(
      (data) => {
        if (data) {
          data.unshift({ key: "all", value: "All" });
          this.allCots = data;
          // this.toastService.showToast(
          //   "Success!",
          //   "Outlet COTs fetched successfully",
          //   "success"
          // );
        } else {
          this.toastService.showToast(
            "Error!",
            "No data available for outlet COTs",
            "error"
          );
        }
      },
      (error) => {
        this.toastService.showToast(
          "Error!",
          "Failed to load outlet COTs",
          "error"
        );
      }
    );
  }
  getAllGpos() {
    this.gpoService.getAllGpos().subscribe(
      (data) => {
        if (data) {
          data.unshift({ key: "all", value: "All" });
          this.allGpos = data;
          // this.toastService.showToast(
          //   "Success!",
          //   "GPOs fetched successfully",
          //   "success"
          // );
        } else {
          this.toastService.showToast(
            "Error!",
            "No data available for GPOs",
            "error"
          );
        }
      },
      (error) => {
        this.toastService.showToast("Error!", "Failed to load GPO's", "error");
      }
    );
  }

  public actionComplete(args: SaveEventArgs): void {
    if (args.requestType === "save") {
      console.log("Edit action completed.", args);
      const updatedRecord = args.data as {
        outletGpoStartDate: Date;
        outletGpoEndDate: Date;
        outletRebateEligibleStartDate: Date;
        outletRebateEligibleEndDate: Date;
        outletAnalysisStartDate: Date;
        outletAnalysisEndDate: Date;
        outletParentGpoStartDate: Date;
        outletParentGpoEndDate: Date;
        outletParentStartDate: Date;
        outletParentEndDate: Date;
        outletGrandparentGpoStartDate: Date;
        outletGrandparentGpoEndDate: Date;
      };

      // Format the dates to string if needed for backend
      const formattedRecord = {
        ...updatedRecord,
        outletGpoStartDate: this.convertDateToString(
          updatedRecord.outletGpoStartDate
        ),
        outletGpoEndDate: this.convertDateToString(
          updatedRecord.outletGpoEndDate
        ),
        outletRebateEligibleStartDate: this.convertDateToString(
          updatedRecord.outletRebateEligibleStartDate
        ),
        outletRebateEligibleEndDate: this.convertDateToString(
          updatedRecord.outletRebateEligibleEndDate
        ),
        outletAnalysisStartDate: this.convertDateToString(
          updatedRecord.outletAnalysisStartDate
        ),
        outletAnalysisEndDate: this.convertDateToString(
          updatedRecord.outletAnalysisEndDate
        ),
        outletParentGpoStartDate: this.convertDateToString(
          updatedRecord.outletParentGpoStartDate
        ),
        outletParentGpoEndDate: this.convertDateToString(
          updatedRecord.outletParentGpoEndDate
        ),
        outletParentStartDate: this.convertDateToString(
          updatedRecord.outletParentStartDate
        ),
        outletParentEndDate: this.convertDateToString(
          updatedRecord.outletParentEndDate
        ),
        outletGrandparentGpoStartDate: this.convertDateToString(
          updatedRecord.outletGrandparentGpoStartDate
        ),
        outletGrandparentGpoEndDate: this.convertDateToString(
          updatedRecord.outletGrandparentGpoEndDate
        ),
      };

      console.log("Formatted Record to Save:", formattedRecord);

      this.selectedRecords = [];
      this.selectedRecords.push(formattedRecord);
      this.updateDialog.show();
    } else if (args.requestType === "delete") {
      console.log("Delete action completed.", args.data);

      this.selectedRecords = [];
      this.selectedRecords.push((args.data as any[])[0]);
      this.deleteDialog.show();
    }
  }

  async cvsGenerator() {
    // const data = await this.getGpoFilteredData("gpo-csv");
    const csvExportProperties: ExcelExportProperties = {
      fileName: 'gpo-export.csv'
    };
    (this.grid as GridComponent).showSpinner();
    (this.grid as GridComponent).csvExport(csvExportProperties);
  }

  async xlxsGenerator() {
    // const data = await this.getGpoFilteredData("gpo-xls");
    const excelExportProperties: ExcelExportProperties = {
      fileName: 'gpo-export.xlsx'
    };
    (this.grid as GridComponent).showSpinner();
    (this.grid as GridComponent).excelExport(excelExportProperties);
  }
  gpoExportComplete(): void {
    (this.grid as GridComponent).hideSpinner();
  }

  async auditXlxsGenerator() {
    // const data = await this.getAuditFilteredData("audit-xls");
    const excelExportProperties: ExcelExportProperties = {
      fileName: 'audit-trail-export.xlsx'
    };
    (this.AuditTrailGrid as GridComponent).showSpinner();
    (this.AuditTrailGrid as GridComponent).excelExport(excelExportProperties);
  }

  async auditCvsGenerator() {
    // const data = await this.getAuditFilteredData("audit-csv");
    const csvExportProperties: ExcelExportProperties = {
      fileName: 'audit-trail-export.csv'
    };
    (this.AuditTrailGrid as GridComponent).showSpinner();
    (this.AuditTrailGrid as GridComponent).csvExport(csvExportProperties);
  }
  auditExportComplete(): void {
    (this.AuditTrailGrid as GridComponent).hideSpinner();
  }

  getAuditFilteredData(flag: any) {
    const query = this.AuditTrailGrid.getDataModule().generateQuery(true);
    const dataManager = new DataManager(this.auditTrailData);
    const result = dataManager.executeQuery(query);

    result
      .then((response: any) => {
        let filteredData = response.result;
        console.log(filteredData);

        if (filteredData.length === 0) {
          filteredData = this.auditTrailData;
        }
        this.downloadFile(filteredData, flag);
      })
      .catch((error: any) => {
        console.error("Error fetching filtered records:", error);
      });
  }

  getGpoFilteredData(flag: any) {
    const query = this.grid.getDataModule().generateQuery(true);
    const dataManager = new DataManager(this.data);
    const result = dataManager.executeQuery(query);

    result
      .then((response: any) => {
        let filteredData = response.result;
        console.log(filteredData);

        if (filteredData.length === 0) {
          filteredData = this.data;
        }
        this.downloadFile(filteredData, flag);
      })
      .catch((error: any) => {
        console.error("Error fetching filtered records:", error);
      });
  }
  downloadFile(data: any, flag: any) {
    this.gpoService.downloadFile(data, flag, "GPO");
  }
  reloadPage(): void {
    // Navigate away only if it's not the current route already
    const currentUrl = this.router.url;
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]); // navigate back to current route
    });
  }

  autoReload() {
    this.router.navigate(["/home/grid"]);
  }

  getAllGpoList() {
    this.gpoService.getAllGpoList(this.gridFilter).subscribe((data) => {
      this.allDataSource = this.convertDates(data);
      this.data = this.convertDates(data);

      if (this.data?.length > 0) {
        this.toastService.showToast(
          "Success",
          "All GPO Data Fetch Successfully",
          "success"
        );
        this.adjustGridHeight();
      } else {
        this.toastService.showToast(
          "Success",
          "All GPO Data Not Found",
          "error"
        );
      }
      this.onSwitchChange({
        checked: this.toggleBarChecked === "active" ? true : false,
      });
      if (this.grid) {
        this.grid.dataSource = this.data;
        this.grid.refresh();
        this.grid.clearFiltering();
      }
    });
  }
  convertDates(data: any) {
    return data.map((item: any) => {
      // Outlet GPO Start Date
      if (
        typeof item.outletGpoStartDate === "string" &&
        (item.outletGpoStartDate != null ||
          item.outletGpoStartDate == undefined)
      ) {
        const newDate = this.convertStringToDate(item.outletGpoStartDate);
        if (newDate) {
          item.outletGpoStartDate = newDate;
        }
      }

      //Outlet GPO End Date
      if (
        typeof item.outletGpoEndDate === "string" &&
        (item.outletGpoEndDate != null || item.outletGpoEndDate == undefined)
      ) {
        const newDate = this.convertStringToDate(item.outletGpoEndDate);
        if (newDate) {
          item.outletGpoEndDate = newDate;
        }
      }

      //outletRebateEligibleStartDate
      if (
        typeof item.outletRebateEligibleStartDate === "string" &&
        (item.outletRebateEligibleStartDate != null ||
          item.outletRebateEligibleStartDate == undefined)
      ) {
        const newDate = this.convertStringToDate(
          item.outletRebateEligibleStartDate
        );
        if (newDate) {
          item.outletRebateEligibleStartDate = newDate;
        }
      }

      //outletRebateEligibleEndDate
      if (
        typeof item.outletRebateEligibleEndDate === "string" &&
        (item.outletRebateEligibleEndDate != null ||
          item.outletRebateEligibleEndDate == undefined)
      ) {
        const newDate = this.convertStringToDate(
          item.outletRebateEligibleEndDate
        );
        if (newDate) {
          item.outletRebateEligibleEndDate = newDate;
        }
      }

      //outletAnalysisStartDate
      if (
        typeof item.outletAnalysisStartDate === "string" &&
        (item.outletAnalysisStartDate != null ||
          item.outletAnalysisStartDate == undefined)
      ) {
        const newDate = this.convertStringToDate(item.outletAnalysisStartDate);
        if (newDate) {
          item.outletAnalysisStartDate = newDate;
        }
      }

      //outletAnalysisEndDate
      if (
        typeof item.outletAnalysisEndDate === "string" &&
        (item.outletAnalysisEndDate != null ||
          item.outletAnalysisEndDate == undefined)
      ) {
        const newDate = this.convertStringToDate(item.outletAnalysisEndDate);
        if (newDate) {
          item.outletAnalysisEndDate = newDate;
        }
      }

      //outletParentGpoStartDate
      if (
        typeof item.outletParentGpoStartDate === "string" &&
        (item.outletParentGpoStartDate != null ||
          item.outletParentGpoStartDate == undefined)
      ) {
        const newDate = this.convertStringToDate(item.outletParentGpoStartDate);
        if (newDate) {
          item.outletParentGpoStartDate = newDate;
        }
      }

      //outletParentGpoEndDate
      if (
        typeof item.outletParentGpoEndDate === "string" &&
        (item.outletParentGpoEndDate != null ||
          item.outletParentGpoEndDate == undefined)
      ) {
        const newDate = this.convertStringToDate(item.outletParentGpoEndDate);
        if (newDate) {
          item.outletParentGpoEndDate = newDate;
        }
      }

      //outletParentStartDate
      if (
        typeof item.outletParentStartDate === "string" &&
        (item.outletParentStartDate != null ||
          item.outletParentStartDate == undefined)
      ) {
        const newDate = this.convertStringToDate(item.outletParentStartDate);
        if (newDate) {
          item.outletParentStartDate = newDate;
        }
      }

      //outletParentEndDate
      if (
        typeof item.outletParentEndDate === "string" &&
        (item.outletParentEndDate != null ||
          item.outletParentEndDate == undefined)
      ) {
        const newDate = this.convertStringToDate(item.outletParentEndDate);
        if (newDate) {
          item.outletParentEndDate = newDate;
        }
      }

      //outletGrandparentGpoStartDate
      if (
        typeof item.outletGrandparentGpoStartDate === "string" &&
        (item.outletGrandparentGpoStartDate != null ||
          item.outletGrandparentGpoStartDate == undefined)
      ) {
        const newDate = this.convertStringToDate(
          item.outletGrandparentGpoStartDate
        );
        if (newDate) {
          item.outletGrandparentGpoStartDate = newDate;
        }
      }

      //outletGrandparentGpoEndDate
      if (
        typeof item.outletGrandparentGpoEndDate === "string" &&
        (item.outletGrandparentGpoEndDate != null ||
          item.outletGrandparentGpoEndDate == undefined)
      ) {
        const newDate = this.convertStringToDate(
          item.outletGrandparentGpoEndDate
        );
        if (newDate) {
          item.outletGrandparentGpoEndDate = newDate;
        }
      }
      return item;
    });
  }
  convertStringToDate(dateString: string): Date | null {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD format
    if (!dateRegex.test(dateString)) {
      console.error("Invalid date format:", dateString);
      return null;
    }
    return new Date(this.formatDateInUTC(dateString));
  }
  convertDateToString(date: Date): string {
    if (date instanceof Date && !isNaN(date.getTime())) {
      return date.toISOString().split("T")[0];
    }
    return "";
  }
  toggleFullscreen(): void {
    console.log("Calling toggle fullscreen function");
    const grid = document.querySelector(".grid-container");
    if (grid) {
      grid.classList.toggle("fullscreen");
      const closeButton = document.getElementById("fullscreen-close-btn");
      if (closeButton) {
        // console.log(grid.classList.contains("fullscreen"));
        closeButton.style.display = grid.classList.contains("fullscreen")
          ? "block"
          : "none";
      }
      this.headerHeight = 115;
      this.adjustGridHeight();
      this.refreshGrid();
    }
  }

  exitFullscreen(): void {
    const grid = document.querySelector(".grid-container");
    if (grid) {
      grid.classList.remove("fullscreen");
      const closeButton = document.getElementById("fullscreen-close-btn");
      if (closeButton) {
        closeButton.style.display = "none";
      }
    }
    this.headerHeight = 405;
    this.adjustGridHeight();
    this.refreshGrid();
  }

  async updateGridGpo(selectedGpo: any[], flag: any) {
    await this.gpoService.updateGridGpo(selectedGpo, flag).subscribe((data) => {
      this.getAllGpoList();
      this.toastService.showToast(
        "Success",
        "GPO Data updated Successfully",
        "success"
      );
    });
  }

  confirmUpdate(isConfirmed: boolean): void {
    // Logic to manually delete the selected record(s) from your data source
    this.updateDialog.hide();

    if (isConfirmed) {
      this.updateGridGpo(this.selectedRecords, "UPDATE");
    }

    // Get All new records and refesh grid
    this.getAllGpos();
  }
  confirmDelete(isConfirmed: boolean): void {
    this.deleteDialog.hide();
    if (isConfirmed) {
      this.updateGridGpo(this.selectedRecords, "DELETE");
    }
    this.getAllGpos();
  }
  async openAuditTrail(
    gpoId: string,
    gpoName: string,
    outletId: string,
    outletName: string,
    selectedRecord: any
  ) {
    this.selectedGpo = `${gpoId} : ${gpoName} : ${outletId} : ${outletName}`;
    await this.gpoService.getAuditTrail(outletId).subscribe((data) => {
      this.auditTrailData = data.data;
      this.attributeChanges = data.attributeChanges;

      this.auditTrailData.forEach((item: any) => {
        this.dateFileds.forEach((field: string) => {
          if (item[field]) {
            item[field] = new Date(this.formatDateInUTC(item[field]));
          }
        });
      });

      this.auditTrailRecord = this.auditTrailData.find(
        (record: any) =>
          record.outletStatus.toUpperCase() === "ACTIVE" ||
          record.outletStatus.toUpperCase() === "DELETED"
      );
      this.setLastUpdatedModeAndUser(this.auditTrailRecord);
      this.auditTrailDialog.show();
    });
  }
  resetFilters() {
    if (this.grid) {
      this.grid.clearFiltering();
    }
  }
  setLastUpdatedModeAndUser(record: any) {
    this.lastUpdatedBy = "File";
    // if (record.lastUpdatedBy) {
    //   this.gpoService.getUsernameById(record.lastUpdatedBy).subscribe(
    //     (data: any) => {
    //       this.lastUpdatedBy = data.message;
    //     },
    //     (error: any) => {
    //       console.error("Error fetching username:", error);
    //     }
    //   );
    // }

    if (record.lastUpdatedBy) this.lastUpdatedBy = record.lastUpdatedBy;

    switch (record.dataType) {
      case "Updates":
        {
          if (
            record.brUpdates &&
            record.brId != "" &&
            Number(record.brId) > 0
          ) {
            this.gpoService.getBusinessRule(record.brId).subscribe(
              (data: any) => {
                let businessRuleName = data.businessRuleName;
                this.lastUpdateMode =
                  "Business Rule (" +
                  Number(record.brId) +
                  " - " +
                  businessRuleName +
                  ")";
              },
              (error: any) => {
                console.error("Error fetching business rule:", error);
              }
            );
          } else if (record.updateType === "Merge") {
            this.lastUpdateMode = "Merge";
          } else if (record.updateType === "New Record") {
            this.lastUpdateMode = "File Upload";
          } else {
            this.lastUpdateMode = "Manual";
          }
        }
        break;
      case "Baseline":
        {
          if (record.updateType === "New Record") {
            this.lastUpdateMode = "Baseline Record";
          }
        }
        break;
      default:
        this.lastUpdateMode = "Baseline Record";
        break;
    }
  }
  onOverlayClick() {
    this.closeAuditTrailModal();
  }
  closeAuditTrailModal() {
    this.auditTrailDialog.hide();
  }
  isFieldChanged(fieldName: string, version: number) {
    if (
      this.attributeChanges &&
      this.attributeChanges.hasOwnProperty(version.toString())
    ) {
      return this.attributeChanges[version.toString()].includes(fieldName);
    }
    return false;
  }
  getColumnNames(): string[] {
    return this.auditTrailData.length > 0
      ? Object.keys(this.auditTrailData[0])
      : [];
  }
  formatHeader(key: string): string {
    return key
      .replace(/([A-Z])/g, " $1")
      .trim()
      .split(" ")
      .map((word) =>
        word.length <= 3 && word.toUpperCase() != "END"
          ? word.toUpperCase()
          : word.charAt(0).toUpperCase() + word.slice(1)
      )
      .join(" ");
  }
  openview() {
    // this.isOpenView = !this.isOpenView;
  }

  saveview() {
    //  this.isSaveView = !this.isSaveView;
  }

  navigateToOrgChart(data: any) {
    this.getOrgChartData(data.outletParentName);
  }

  closedChartDialog(isConfirmed: boolean): void {
    this.orgChartData = null;

    this.getAllGpos();
  }

  getOrgChartData(outletParentName: string) {
    this.gpoService.getOrgChartData(outletParentName).subscribe((data) => {
      this.orgChartData = data;
    });
  }

  rowDataBound(args: RowDataBoundEventArgs): void {
    const fieldName = this.grid.getColumnByField("recordVersion").field;

    if (args.data && args.row) {
      const recordVersion = (args.data as RowData)["recordVersion"];
      if (this.attributeChanges.hasOwnProperty(recordVersion)) {
        this.attributeChanges[recordVersion].forEach((item: any) => {
          const cellIndex = this.getColumnIndex(args.data, item);
          const cell = args.row?.querySelector(
            `[aria-colindex="${cellIndex}"]`
          );
          if (cell) {
            cell.classList.add("highlight-difference");
          }
        });
      } else {
        console.log("No Change");
      }
    }
  }
  private getColumnIndex(data: any, field: string): number {
    return Object.keys(data).indexOf(field) + 4;
  }
  formatDate(dateString: string): string {
    return formatDate(dateString, "MM-dd-yyyy", "en-US");
  }

  formatDateInUTC(date: any): any {
    const utcDate = new Date(date);
    return this.datePipe.transform(utcDate, "MM-dd-yyyy", "UTC");
  }
}
