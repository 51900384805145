import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { DropDownListAllModule } from "@syncfusion/ej2-angular-dropdowns";
import { SnapshotAnalysisModel } from "../../model/gpo-discover/snapshot-analysis-model";
import { GpoDiscoverService } from "../../services/gpo-discover-analysis.service";
import { Snapshot } from "../../model/gpo-discover/snapshot";
import { SnapshotDetail } from "../../model/gpo-discover/snapshot-detail";
import { ToastService } from "../../../Toast/toast.service";

@Component({
  selector: "app-gpo-discover-details",
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropDownListAllModule,
  ],
  templateUrl: "./gpo-discover-details.component.html",
  styleUrl: "./gpo-discover-details.component.css",
})
export class GpoDiscoverDetailsComponent {
  snapshotForm: FormGroup;
  snapshotAnalysisModelList: SnapshotAnalysisModel[] = [];
  snapshots: Snapshot[] = [];

  snapshotAnalysisId = 0;

  updatingForm = false; // Flag to prevent recursive calls

  filteredSnapshots1!: Snapshot[];
  filteredSnapshots2!: Snapshot[];

  isUpdateMode: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private gpoDiscoverAnalysisService: GpoDiscoverService,
    private route: ActivatedRoute,
    private _toastService: ToastService
  ) {
    this.snapshotForm = this.fb.group({
      snapshotAnalysisId: [{ value: "", disabled: true }],
      name: ["", [Validators.required, this.noSpecialCharsValidator]],
      snapshotFirstId: ["", Validators.required],
      snapshotSecondId: ["", Validators.required],
      model: ["", Validators.required],
    });

    this.initializeData().then(() => {
      this.setupFormSubscriptions();
    });
  }

  ngOnInit(): void {
    // Initialize form with default values if needed
    this.snapshotForm.patchValue({
      snapshotAnalysisId: "SA-" + this.snapshotAnalysisId, // Example value, replace with actual data
    });

    this.initializeData().then(() => {
      this.route.paramMap.subscribe((params) => {
        const id = params.get("id");

        if (id) {
          this.snapshotAnalysisId = Number(id);
          this.fetchSnapshotDetailsByid(this.snapshotAnalysisId);
        }

        if (Number(id) > 0) {
          this.isUpdateMode = true;
        } else {
          this.isUpdateMode = false;
        }
      });
    });
  }

  initializeData(): Promise<void> {
    return new Promise((resolve) => {
      Promise.all([
        this.fetchSnapshotAnalysisModel(),
        this.fetchSnapshots(),
      ]).then(() => {
        resolve();
      });
    });
  }

  setupFormSubscriptions(): void {
    this.snapshotForm
      .get("snapshotFirstId")
      ?.valueChanges.subscribe((value) => {
        if (!this.updatingForm) {
          this.filterSnapshots("snapshotFirstId", value);
        }
      });

    this.snapshotForm
      .get("snapshotSecondId")
      ?.valueChanges.subscribe((value) => {
        if (!this.updatingForm) {
          this.filterSnapshots("snapshotSecondId", value);
        }
      });
  }

  setData(data: any): void {
    this.updatingForm = true; // Set flag to true to prevent filtering during initial set
    this.snapshotForm.patchValue({
      snapshotAnalysisId: "SA-" + data.id,
      name: data.name,
      snapshotFirstId: data.snapshotFirstId,
      snapshotSecondId: data.snapshotSecondId,
      model: data.model,
    });

    // Filter the snapshots based on the selected values
    this.filterSnapshots("snapshotFirstId", data.snapshotFirstId);
    this.filterSnapshots("snapshotSecondId", data.snapshotSecondId);

    this.updatingForm = false; // Reset flag after initial set
  }

  onSubmit(): void {
    if (this.snapshotForm.valid) {
      if (this.snapshotAnalysisId > 0) {
        this.gpoDiscoverAnalysisService
          .updateSnapshotDetail(
            this.snapshotForm.value,
            this.snapshotAnalysisId
          )
          .subscribe(
            (response) => {
              this._toastService.showSuccessToast(
                "Shanpshot Analysis Updated Successfully"
              );
              this.router.navigate(["/home/discover"]);
            },
            (error) => {
              console.log("Error submitting form", error);
              this._toastService.showSuccessToast(
                "Error while update Shanpshot Analysis"
              );
            }
          );
      } else {
        this.gpoDiscoverAnalysisService
          .submitSnapshotDetail(this.snapshotForm.value)
          .subscribe(
            (response) => {
              this._toastService.showSuccessToast(
                "Shanpshot Analysis Created Successfully"
              );
              this.router.navigate(["/home/discover"]);

              // Handle successful form submission
            },
            (error) => {
              console.log("Error submitting form", error);
              this._toastService.showSuccessToast(
                "Error while create Shanpshot Analysis"
              );

              // Handle form submission error
            }
          );
      }
    } else {
      this._toastService.showSuccessToast("Form is invalid");
      this.highlightErrors();
    }
  }

  private highlightErrors(): void {
    Object.keys(this.snapshotForm.controls).forEach((field) => {
      const control = this.snapshotForm.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  onBack(): void {
    this.router.navigate(["/home/discover"]);
  }

  resetForm(): void {
    this.snapshotForm.reset();
    // Reset the dropdown lists to their full options
    this.filteredSnapshots1 = this.snapshots;
    this.filteredSnapshots2 = this.snapshots;
    // Reset any preset value if necessary
    this.snapshotForm.patchValue({
      snapshotAnalysisId: "12345", // Example value, replace with actual data
    });
  }

  noSpecialCharsValidator(control: FormControl) {
    const forbidden = /[!@#$%^&*(),.?":{}|<>]/.test(control.value);
    return forbidden ? { hasSpecialChars: true } : null;
  }

  filterSnapshots(changedDropdown: string, selectedSnapshotId: number): void {
    if (changedDropdown === "snapshotFirstId") {
      this.filteredSnapshots2 = this.snapshots.filter(
        (snapshot) => snapshot.id !== selectedSnapshotId
      );
      // Reset the second snapshot selection if the selected snapshot is no longer in the list
      if (
        !this.filteredSnapshots2.some(
          (snapshot) =>
            snapshot.id === this.snapshotForm.get("snapshotSecondId")?.value
        )
      ) {
        this.snapshotForm.get("snapshotSecondId")?.setValue(null);
      }
    } else if (changedDropdown === "snapshotSecondId") {
      this.filteredSnapshots1 = this.snapshots.filter(
        (snapshot) => snapshot.id !== selectedSnapshotId
      );
      // Reset the first snapshot selection if the selected snapshot is no longer in the list
      if (
        !this.filteredSnapshots1.some(
          (snapshot) =>
            snapshot.id === this.snapshotForm.get("snapshotFirstId")?.value
        )
      ) {
        this.snapshotForm.get("snapshotFirstId")?.setValue(null);
      }
    }
  }

  fetchSnapshotDetailsByid(id: number) {
    this.gpoDiscoverAnalysisService.fetchSnapshotDetailsByid(id).subscribe(
      (data: SnapshotDetail) => {
        this.setData(data);
      },
      (error) => {
        console.error("Error fetching snapshot details", error);
      }
    );
  }

  fetchSnapshotAnalysisModel(): Promise<void> {
    return new Promise((resolve) => {
      this.gpoDiscoverAnalysisService.fetchSnapshotAnalysisModel().subscribe(
        (data: SnapshotAnalysisModel[]) => {
          this.snapshotAnalysisModelList = data;
        },
        (error) => {
          console.error("Error fetching snapshots", error);
        }
      );
      setTimeout(() => {
        console.log("Fetched snapshot analysis model");
        resolve();
      }, 1000);
    });
  }

  fetchSnapshots(): Promise<void> {
    return new Promise((resolve) => {
      this.gpoDiscoverAnalysisService.fetchSnapshots().subscribe(
        (data: Snapshot[]) => {
          this.snapshots = data;
          this.filteredSnapshots1 = this.snapshots;
          this.filteredSnapshots2 = this.snapshots;
        },
        (error) => {
          console.error("Error fetching snapshot details", error);
        }
      );
      setTimeout(() => {
        console.log("Fetched snapshots");
        resolve();
      }, 1000);
    });
  }
}
