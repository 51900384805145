<div class="container-fluid dialog-content">
  <div class="row">
    <div class="col-12">
      <p>
        Following is the count of records impacted by the business rules
        executed, please review and confirm. The changes will be made after your
        confirmation.
      </p>
    </div>
  </div>
  <div class="container mt-4">
    <!-- Summary Section -->
    <div class="row mb-3 p-3 border">
      <div class="col-3 border-right">
        <b>Summary:</b>
      </div>
      <div class="col-6 border-right">
        <b>Number of Business Rules -</b>
      </div>
      <div class="col-3">
        <b
          ><a (click)="onTotalRulesClick()" style="color: blue">{{
            data?.totalRules
          }}</a></b
        >
      </div>
    </div>
    <!-- Table Section -->
    <div class="row mt-3">
      <div class="col-12">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Total Records Impacted</th>
              <th scope="col">Date Updates</th>
              <th scope="col">Attribute Updates</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <ng-container
                  *ngIf="data?.totalRecordsUpdated > 0; else noRecordsUpdated"
                >
                  <a
                    (click)="onTotalRecordsUpdatedClick()"
                    style="color: blue; cursor: pointer"
                    >{{ data?.totalRecordsUpdated }}</a
                  >
                </ng-container>
                <ng-template #noRecordsUpdated>{{
                  data?.totalRecordsUpdated
                }}</ng-template>
              </td>
              <td>
                <ng-container
                  *ngIf="data?.totalDateUpdates > 0; else noDateUpdates"
                >
                  <a
                    (click)="onTotalDateUpdatesClick()"
                    style="color: blue; cursor: pointer"
                    >{{ data?.totalDateUpdates }}</a
                  >
                </ng-container>
                <ng-template #noDateUpdates>{{
                  data?.totalDateUpdates
                }}</ng-template>
              </td>
              <td>
                <ng-container
                  *ngIf="
                    data?.totalAttributeUpdates > 0;
                    else noAttributeUpdates
                  "
                >
                  <a
                    (click)="onTotalAttributeUpdatesClick()"
                    style="color: blue; cursor: pointer"
                    >{{ data?.totalAttributeUpdates }}</a
                  >
                </ng-container>
                <ng-template #noAttributeUpdates>{{
                  data?.totalAttributeUpdates
                }}</ng-template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="container mt-4">
    <table class="table table-bordered">
      <thead class="thead-dark">
        <tr>
          <th>Business Rule ID</th>
          <th>Business Rule Name</th>
          <th>Date Updates</th>
          <th>Attribute Updates</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let rule of data?.rules">
          <td>BR-{{ rule.ruleId }}</td>
          <td>{{ rule.ruleName }}</td>
          <td>
            <a
              *ngIf="rule.dateImpactCount > 0; else noDateImpact"
              (click)="onTotalDateUpdatesClick()"
              style="color: blue; cursor: pointer"
            >
              {{ rule.dateImpactCount }}
            </a>
            <ng-template #noDateImpact>{{ rule.dateImpactCount }}</ng-template>
          </td>
          <td>
            <a
              *ngIf="rule.attributeImpactCount > 0; else noAttributeImpact"
              (click)="onTotalAttributeUpdatesClick()"
              style="color: blue; cursor: pointer"
            >
              {{ rule.attributeImpactCount }}
            </a>
            <ng-template #noAttributeImpact>{{
              rule.attributeImpactCount
            }}</ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row">
    <div class="col-12 d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-primary save_btn"
        style="margin-right: 0.5rem"
        (click)="onOkClick()"
      >
        Execute
      </button>
      <button
        type="button"
        class="btn btn-secondary cancle_btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
    </div>
  </div>
</div>
