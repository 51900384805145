<div
  class="mt-2"
  style="
    background-color: #dfeff4;
    padding: 1rem;
    border: 1px solid #27d2f8;
    border-radius: 4px;
  "
>
  <h2 style="font-size: 18px; font-weight: 600; margin-bottom: 1em">
    {{ isUpdateMode ? "Update Snapshot" : "Create New Snapshot" }}
  </h2>
  <hr />
  <form [formGroup]="snapshotForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <!-- Snapshot ID: Disabled Input -->
      <div class="form-group col-md-3" style="margin-bottom: 15px">
        <label for="snapshotId">Snapshot ID</label>
        <input
          type="text"
          class="form-control"
          id="snapshotId"
          formControlName="snapshotId"
          placeholder="Snapshot ID"
          readonly
          style="cursor: not-allowed"
        />
      </div>

      <!-- Snapshot Name: Text Input with Validation -->
      <div class="form-group col-md-3" style="margin-bottom: 15px">
        <label for="snapshotName">Snapshot Name</label>
        <input
          type="text"
          class="form-control"
          id="snapshotName"
          formControlName="snapshotName"
          placeholder="Snapshot Name"
          maxlength="200"
          [ngClass]="{
            'is-invalid':
              (snapshotForm.get('snapshotName')?.invalid &&
                snapshotForm.get('snapshotName')?.touched) ||
              (snapshotForm.get('snapshotName')?.touched &&
                snapshotForm.get('snapshotName')?.hasError('hasSpecialChars'))
          }"
        />
        <div
          *ngIf="
            (snapshotForm.get('snapshotName')?.invalid &&
              snapshotForm.get('snapshotName')?.touched) ||
            (snapshotForm.get('snapshotName')?.touched &&
              snapshotForm.get('snapshotName')?.hasError('hasSpecialChars'))
          "
          class="invalid-feedback"
        >
          <span
            *ngIf="
              !snapshotForm.get('snapshotName')?.hasError('hasSpecialChars')
            "
            >Snapshot name is required.</span
          >
          <span
            *ngIf="
              snapshotForm.get('snapshotName')?.hasError('hasSpecialChars')
            "
          >
            Snapshot name cannot contain special characters</span
          >
        </div>
      </div>

      <!-- GPO MultiSelect Dropdown -->
      <div class="form-group col-md-3" style="margin-bottom: 15px">
        <label for="gpo" class="label-css">Select GPO</label>
        <ejs-multiselect
          #gpoDropdown
          id="gpo"
          formControlName="selectedGPO"
          cssClass="custom-outline e-outline"
          [allowFiltering]="true"
          [dataSource]="gpoDetails"
          [fields]="{ text: 'gpoName', value: 'gpoId' }"
          placeholder="Select GPO"
          [mode]="mode"
        ></ejs-multiselect>
        <div
          *ngIf="snapshotForm.get('selectedGPO')?.touched && snapshotForm.get('selectedGPO')?.errors?.['required']"
          class="text-danger"
        >
          Selecting at least one GPO is required.
        </div>
      </div>

      <!-- Snapshot Date: DatePicker with Validation -->
      <div class="form-group col-md-3" style="margin-bottom: 15px">
        <label for="snapshotDate">Snapshot Date</label>
        <ejs-datepicker
          id="snapshotDate"
          format="yyyy-MM-dd"
          formControlName="snapshotDate"
          cssClass="custom-outline e-outline"
          placeholder="Select Snapshot Date"
        >
        </ejs-datepicker>
        <div
          *ngIf="
            snapshotForm.get('snapshotDate')?.invalid &&
            snapshotForm.get('snapshotDate')?.touched
          "
          class="text-danger"
        >
          Snapshot Date is required.
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Submit Button: Disabled until the form is valid -->
      <div style="display: flex; justify-content: end">
        <button
          type="submit"
          class="btn btn-secondary save_btn"
          style="margin-right: 5px"
          [disabled]="snapshotForm.invalid"
        >
          {{ isUpdateMode ? "Update" : "Save" }}
        </button>
        <button
          *ngIf="isUpdateMode === false"
          type="button"
          class="btn btn-secondary back_btn"
          style="margin-right: 5px"
          [disabled]="snapshotForm.invalid"
          (click)="resetForm()"
        >
          Cancel
        </button>

        <button
          type="button"
          class="btn btn-secondary back_btn"
          (click)="goBack()"
        >
          Back
        </button>
      </div>
    </div>
  </form>
</div>

<ejs-dialog
  #confirmationDialog
  header="Confirmation!"
  [visible]="true"
  cssClass="custom-dialog"
  *ngIf="showConfirmationDialog"
  [position]="position"
  [content]="confirmationDialogContent"
  [showCloseIcon]="true"
  isModal="true"
>
  <ng-template #confirmationDialogContent>
    <app-snapshot-confirmation-dialog
      [data]="snapshotForm.value"
      (okClick)="onConfirmation($event)"
      (closeClick)="closeDialog($event)"
    ></app-snapshot-confirmation-dialog>
  </ng-template>
</ejs-dialog>

<ejs-dialog
  #cancelDialog
  [visible]="true"
  cssClass="custom-dialog"
  *ngIf="showCancelDialog"
  [position]="position"
  [content]="cancelDialogContent"
  [showCloseIcon]="true"
  header="Cancellation"
  isModal="true"
>
  <ng-template #cancelDialogContent>
    <app-snapshot-cancel-dialog
      (okClick)="onCancel($event)"
      (closeClick)="closeDialog($event)"
    ></app-snapshot-cancel-dialog>
  </ng-template>
</ejs-dialog>

<ejs-dialog
  #detailsDialog
  [visible]="true"
  cssClass="custom-dialog"
  *ngIf="showDetailsDialog"
  [position]="position"
  [content]="detailsDialogContent"
  [showCloseIcon]="true"
  header="Snapshot details!"
>
  <ng-template #detailsDialogContent>
    <app-snapshot-details-dialog
      [data]="snapshotForm.value"
      (okClick)="onConfirmationSnapshot($event)"
    ></app-snapshot-details-dialog>
  </ng-template>
</ejs-dialog>
