export enum ColumnType{
    NUMBER,
    STRING
}

export enum ColumnFormat{
    NONE,
    DOLLER,
    PERCENTAGE
}


export interface ColumnFormater {
    columnName: string,
    columnType: ColumnType,
    columnFormat: ColumnFormat;
}