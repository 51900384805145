<div class="row form-box-d">
  <form [formGroup]="gridForm">
    <div class="d-flex flex-wrap" style="margin-top: 15px; width: fit-content">
      <ejs-dropdownlist
        #gpoDropdown
        cssClass="e-outline"
        class="dropdown-spacing e-outline"
        id="gpoDropdown"
        width="200"
        height="30"
        [fields]="{ text: 'value', value: 'key' }"
        placeholder="Select GPO"
        [allowFiltering]="true"
        [dataSource]="allGpos"
        (change)="changeFilterValue($event, 'gpo')"
        formControlName="gpo"
        mode="'CheckBox'"
        (filtering)="onGPOFiltering($event)"
      ></ejs-dropdownlist>
      <ejs-dropdownlist
        #cotDropdown
        cssClass="e-outline"
        class="dropdown-spacing e-outline"
        id="cotDropdown"
        width="200"
        placeholder="Select COT"
        [allowFiltering]="true"
        [dataSource]="allCots"
        [fields]="{ text: 'value', value: 'key' }"
        (change)="changeFilterValue($event, 'cot')"
        formControlName="cot"
        (filtering)="onCOTFiltering($event)"
      ></ejs-dropdownlist>
      <ejs-dropdownlist
        #cotDropdown
        cssClass="e-outline"
        class="dropdown-spacing e-outline"
        id="typeDropdown"
        width="200"
        placeholder="Select Type"
        [allowFiltering]="true"
        [dataSource]="allTypes"
        [fields]="{ text: 'value', value: 'key' }"
        (change)="changeFilterValue($event, 'type')"
        formControlName="type"
        (filtering)="onTypeFiltering($event)"
      ></ejs-dropdownlist>
      <ejs-dropdownlist
        #cotDropdown
        cssClass="e-outline"
        class="dropdown-spacing e-outline"
        id="stateDropdown"
        width="200"
        placeholder="Select State"
        [allowFiltering]="true"
        [dataSource]="allStates"
        [fields]="{ text: 'value', value: 'key' }"
        (change)="changeFilterValue($event, 'state')"
        formControlName="state"
      ></ejs-dropdownlist>
    </div>
  </form>
  <div style="margin-top: 2em; text-align: right; margin: 0px auto 0px auto">
    <button
      ejs-button
      class="btn btn-primary search_btn"
      style="margin-left: 10px; width: auto"
      (click)="getAllGpoList()"
    >
      Search
    </button>
    <button
      class="btn btn-secondary cancle_btn"
      style="margin-left: 10px; width: auto"
      title="Open View"
      (click)="clearFilters()"
    >
      Clear
    </button>

    <!-- <button
      class="btn btn-secondary save_btn"
      style="margin-left: 10px; width: auto"
      title="Save View"
      (click)="saveview()"
    >
      Save View
    </button>

    <button
      class="btn btn-secondary cancle_btn"
      style="margin-left: 10px; width: auto"
      title="Open View"
      (click)="openview()"
    >
      Open View
    </button> -->
  </div>
</div>

<div
  style="
    background-color: #dfeff4;
    padding: 1rem;
    margin-top: 1rem;
    border: 1px solid #27d2f8;
    border-radius: 4px;
  "
>
  <div class="control-section default-appbar-section">
    <div class="col-md-12">
      <div class="row align-items-center">
        <div class="col-md-5">
          <span style="font-weight: 500; font-size: 16px">GPO's</span> &nbsp;
          <ejs-switch
            onLabel="ON"
            offLabel="OFF"
            class="custom-switch"
            id="gpPriceImpact"
            style="margin-left: 1em"
            (change)="onSwitchChange($event)"
          >
          </ejs-switch>
          <span style="margin-left: 10px; margin-top: -3px">
            Show Active GPO members
          </span>
        </div>

        <div class="col-md-7">
          <div class="d-flex justify-content-end">
            <div style="margin-right: 1rem">
              <button
                type="button"
                class="btn btn-secondary cancle_btn"
                (click)="resetFilters()"
              >
                Reset Filters
              </button>
            </div>
            <!-- Icon container for CSV -->
            <div style="margin-right: 1rem">
              <img
                style="height: 2rem; width: auto; cursor: pointer"
                src="../../../../assets/images/export-options/csv.png"
                (click)="cvsGenerator()"
              />
            </div>
            <!-- Icon container for Excel -->
            <div style="margin-right: 1rem">
              <img
                style="height: 2rem; width: auto; cursor: pointer"
                src="../../../../assets/images/export-options/xlsx.png"
                (click)="xlxsGenerator()"
              />
            </div>
            <!-- Icon container for Fullscreen -->
            <div>
              <img
                style="height: 2rem; width: auto; cursor: pointer"
                src="../../../../assets/images/export-options/fullscreen.png"
                (click)="toggleFullscreen()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="grid-container mt-3" style="height: auto">
    <ejs-grid
      #gpoGrid
      id="gpoGrid"
      [dataSource]="data"
      class="syncfusion-grid"
      [allowPaging]="true"
      [pageSettings]="pageSettings"
      [editSettings]="editSettings"
      [filterSettings]="{
        type: 'Excel',
      }"
      [toolbar]="toolbar"
      (actionComplete)="actionComplete($event)"
      [allowFiltering]="true"
      [allowReordering]="true"
      [allowResizing]="true"
      [autoFit]="true"
      [allowSorting]="true"
      [height]="gridHeight"
      [allowExcelExport]="true"
      (excelExportComplete)="gpoExportComplete()"
    >
      <e-columns>
        <e-column
          field="recordVersion"
          headerText="Record Version"
          width="180"
          textAlign="center"
          isPrimaryKey="true"
        >
          <ng-template #template let-data *ngIf="!orgChartData">
            <a
              (click)="
                openAuditTrail(
                  data.gpoId,
                  data.gpoName,
                  data.outletId,
                  data.outletName,
                  data
                )
              "
              style="color: blue; cursor: pointer; font-weight: 600"
            >
              {{ data.recordVersion }}
            </a>
          </ng-template>
          <ng-template
            #template
            let-data
            *ngIf="orgChartData && orgChartData.length > 0"
          >
            {{ data.recordVersion }}
          </ng-template>
        </e-column>
        <e-column
          field="gpoId"
          headerText="GPO ID"
          width="130"
          isPrimaryKey="true"
        ></e-column>
        <e-column
          headerText="ORG"
          width="130"
          *ngIf="type == 'chart'"
          isPrimaryKey="true"
        >
          <ng-template #template let-data>
            <a style="color: blue; cursor: pointer; font-weight: 600">
              <span
                class="e-icons e-medium e-user-defined"
                (click)="navigateToOrgChart(data)"
              ></span>
            </a>
          </ng-template>
        </e-column>
        <e-column
          field="gpoName"
          headerText="GPO Name"
          width="150"
          isPrimaryKey="true"
        ></e-column>
        <e-column
          field="gpoOutletGroupId"
          headerText="GPO Outlet Group ID"
          width="210"
        ></e-column>
        <e-column
          field="gpoContractId"
          headerText="GPO Contract ID"
          width="190"
        ></e-column>
        <e-column
          field="outletId"
          headerText="Outlet ID"
          width="140"
          isPrimaryKey="true"
        ></e-column>
        <e-column
          field="outletGpoId"
          headerText="Outlet GPO ID"
          width="170"
        ></e-column>
        <e-column
          field="outletName"
          headerText="Outlet Name"
          width="250"
        ></e-column>
        <e-column
          field="outletType"
          headerText="Outlet Type"
          width="160"
        ></e-column>
        <e-column
          field="outletCot"
          headerText="Outlet COT"
          width="160"
        ></e-column>
        <e-column
          field="outletCotSubtype"
          headerText="Outlet COT Subtype"
          width="210"
        ></e-column>
        <e-column
          field="outletNpi"
          headerText="Outlet NPI"
          width="170"
        ></e-column>
        <e-column
          field="outletHin"
          headerText="Outlet HIN"
          width="170"
        ></e-column>
        <e-column
          field="outletDea"
          headerText="Outlet DEA"
          width="170"
        ></e-column>
        <e-column
          field="outletAddress1"
          headerText="Outlet Address 1"
          width="210"
        ></e-column>
        <e-column
          field="outletAddress2"
          headerText="Outlet Address 2"
          width="210"
        ></e-column>
        <e-column
          field="outletCity"
          headerText="Outlet City"
          width="160"
        ></e-column>
        <e-column
          field="outletState"
          headerText="Outlet State"
          width="160"
        ></e-column>
        <e-column
          field="outletZip"
          headerText="Outlet ZIP"
          width="160"
        ></e-column>
        <e-column
          field="outletCountry"
          headerText="Outlet Country"
          width="170"
        ></e-column>
        <e-column
          field="outletPhone"
          headerText="Outlet Phone"
          width="170"
        ></e-column>
        <e-column
          field="outletPrimaryContact"
          headerText="Outlet Primary Contact"
          width="230"
        ></e-column>
        <e-column
          field="outletPrimaryEmail"
          headerText="Outlet Primary Email"
          width="230"
        ></e-column>
        <e-column
          field="outletGpoStartDate"
          headerText="Outlet GPO Start Date"
          width="230"
          type="date"
          [format]="formatoptions"
          editType="datepickeredit"
          [edit]="outletGpoStartDate"
        >
        </e-column>
        <e-column
          field="outletGpoEndDate"
          headerText="Outlet GPO End Date"
          width="230"
          type="date"
          [format]="formatoptions"
          editType="datepickeredit"
          [edit]="outletGpoEndDate"
        >
        </e-column>
        <e-column
          field="outletRebateEligible"
          headerText="Outlet Rebate Eligible"
          width="230"
        ></e-column>
        <e-column
          field="outletRebateEligibleStartDate"
          headerText="Outlet Rebate Eligible Start Date"
          width="280"
          type="date"
          [format]="formatoptions"
          editType="datepickeredit"
          [edit]="outletRebateEligibleStartDate"
        >
        </e-column>
        <e-column
          field="outletRebateEligibleEndDate"
          headerText="Outlet Rebate Eligible End Date"
          width="280"
          type="date"
          [format]="formatoptions"
          editType="datepickeredit"
          [edit]="outletRebateEligibleEndDate"
        >
        </e-column>
        <e-column
          field="outletAnalysisStartDate"
          headerText="Outlet Analysis Start Date"
          width="280"
          type="date"
          [format]="formatoptions"
          editType="datepickeredit"
          [edit]="outletAnalysisStartDate"
        >
        </e-column>
        <e-column
          field="outletAnalysisEndDate"
          headerText="Outlet Analysis End Date"
          width="280"
          type="date"
          [format]="formatoptions"
          editType="datepickeredit"
          [edit]="outletAnalysisEndDate"
        >
        </e-column>
        <e-column
          field="outletStatus"
          headerText="Outlet Status"
          width="170"
          isPrimaryKey="true"
        ></e-column>
        <e-column
          field="outletParentId"
          headerText="Outlet Parent ID"
          width="180"
        ></e-column>
        <e-column
          field="outletParentGpoId"
          headerText="Outlet Parent GPO ID"
          width="220"
        ></e-column>
        <e-column
          field="outletParentName"
          headerText="Outlet Parent Name"
          width="250"
        ></e-column>
        <e-column
          field="outletParentType"
          headerText="Outlet Parent Type"
          width="210"
        ></e-column>
        <e-column
          field="outletParentCot"
          headerText="Outlet Parent COT"
          width="210"
        ></e-column>
        <e-column
          field="outletParentCotSubtype"
          headerText="Outlet Parent COT Subtype"
          width="250"
        ></e-column>
        <e-column
          field="outletParentNpi"
          headerText="Outlet Parent NPI"
          width="220"
        ></e-column>
        <e-column
          field="outletParentHin"
          headerText="Outlet Parent HIN"
          width="220"
        ></e-column>
        <e-column
          field="outletParentDea"
          headerText="Outlet Parent DEA"
          width="220"
        ></e-column>
        <e-column
          field="outletParentAddress1"
          headerText="Outlet Parent Address 1"
          width="230"
        ></e-column>
        <e-column
          field="outletParentAddress2"
          headerText="Outlet Parent Address 2"
          width="230"
        ></e-column>
        <e-column
          field="outletParentCity"
          headerText="Outlet Parent City"
          width="200"
        ></e-column>
        <e-column
          field="outletParentState"
          headerText="Outlet Parent State"
          width="200"
        ></e-column>
        <e-column
          field="outletParentZip"
          headerText="Outlet Parent ZIP"
          width="200"
        ></e-column>
        <e-column
          field="outletParentCountry"
          headerText="Outlet Parent Country"
          width="220"
        ></e-column>
        <e-column
          field="outletParentGpoStartDate"
          headerText="Outlet Parent GPO Start Date"
          width="260"
          type="date"
          [format]="formatoptions"
          editType="datepickeredit"
          [edit]="outletParentGpoStartDate"
        >
        </e-column>
        <e-column
          field="outletParentGpoEndDate"
          headerText="Outlet Parent GPO End Date"
          width="260"
          type="date"
          [format]="formatoptions"
          editType="datepickeredit"
          [edit]="outletParentGpoEndDate"
        >
        </e-column>
        <e-column
          field="outletParentStartDate"
          headerText="Outlet Parent Start Date"
          width="260"
          type="date"
          [format]="formatoptions"
          editType="datepickeredit"
          [edit]="outletParentStartDate"
        >
        </e-column>
        <e-column
          field="outletParentEndDate"
          headerText="Outlet Parent End Date"
          width="260"
          type="date"
          [format]="formatoptions"
          editType="datepickeredit"
          [edit]="outletParentEndDate"
        >
        </e-column>
        <e-column
          field="outletGrandparentId"
          headerText="Outlet Grandparent ID"
          width="230"
        ></e-column>
        <e-column
          field="outletGrandparentGpoId"
          headerText="Outlet Grandparent GPO ID"
          width="250"
        ></e-column>
        <e-column
          field="outletGrandparentName"
          headerText="Outlet Grandparent Name"
          width="250"
        ></e-column>
        <e-column
          field="outletGrandparentType"
          headerText="Outlet Grandparent Type"
          width="250"
        ></e-column>
        <e-column
          field="outletGrandparentCot"
          headerText="Outlet Grandparent COT"
          width="250"
        ></e-column>
        <e-column
          field="outletGrandparentCotSubtype"
          headerText="Outlet Grandparent COT Subtype"
          width="290"
        ></e-column>
        <e-column
          field="outletGrandparentNpi"
          headerText="Outlet Grandparent NPI"
          width="250"
        ></e-column>
        <e-column
          field="outletGrandparentHin"
          headerText="Outlet Grandparent HIN"
          width="250"
        ></e-column>
        <e-column
          field="outletGrandparentDea"
          headerText="Outlet Grandparent DEA"
          width="250"
        ></e-column>
        <e-column
          field="outletGrandparentAddress1"
          headerText="Outlet Grandparent Address 1"
          width="270"
        ></e-column>
        <e-column
          field="outletGrandparentAddress2"
          headerText="Outlet Grandparent Address 2"
          width="270"
        ></e-column>
        <e-column
          field="outletGrandparentCity"
          headerText="Outlet Grandparent City"
          width="230"
        ></e-column>
        <e-column
          field="outletGrandparentState"
          headerText="Outlet Grandparent State"
          width="250"
        ></e-column>
        <e-column
          field="outletGrandparentZip"
          headerText="Outlet Grandparent ZIP"
          width="230"
        ></e-column>
        <e-column
          field="outletGrandparentCountry"
          headerText="Outlet Grandparent Country"
          width="280"
        ></e-column>
        <e-column
          field="outletGrandparentGpoStartDate"
          headerText="Outlet Grandparent GPO Start Date"
          width="300"
          type="date"
          [format]="formatoptions"
          editType="datepickeredit"
          [edit]="outletGrandparentGpoStartDate"
        >
        </e-column>
        <e-column
          field="outletGrandparentGpoEndDate"
          headerText="Outlet Grandparent GPO End Date"
          width="300"
          type="date"
          [format]="formatoptions"
          editType="datepickeredit"
          [edit]="outletGrandparentGpoEndDate"
        >
        </e-column>
      </e-columns>
    </ejs-grid>
    <div id="fullscreen-close-btn" style="display: none" class="mt-1">
      <button
        class="btn btn-secondary cancle_btn"
        style="margin-left: 10px; width: auto"
        (click)="exitFullscreen()"
      >
        Close Fullscreen
      </button>
    </div>
  </div>
</div>

<ejs-dialog
  #deleteDialog
  style="background-color: #dfeff4"
  [visible]="false"
  [width]="400"
  header="Confirmation"
  [target]="'.control-section'"
>
  <ng-template #content>
    <div>Are you sure you want to delete?</div>
  </ng-template>
  <ng-template #footerTemplate>
    <!-- Apply Bootstrap 5 button styles -->
    <button ejs-button class="btn btn-danger m-2" (click)="confirmDelete(true)">
      Yes
    </button>
    <button ejs-button class="btn btn-secondary" (click)="confirmDelete(false)">
      No
    </button>
  </ng-template>
</ejs-dialog>

<ejs-dialog
  #updateDialog
  [visible]="false"
  style="background-color: #dfeff4"
  [width]="400"
  header="Confirmation"
  [target]="'.control-section'"
>
  <ng-template #content>
    <div>Are you sure you want to update?</div>
  </ng-template>
  <ng-template #footerTemplate>
    <!-- Apply Bootstrap 5 button styles -->
    <button
      ejs-button
      class="btn btn-primary m-2"
      (click)="confirmUpdate(true)"
    >
      Yes
    </button>
    <button ejs-button class="btn btn-secondary" (click)="confirmUpdate(false)">
      No
    </button>
  </ng-template>
</ejs-dialog>

<div *ngIf="orgChartData && orgChartData.length > 0">
  <ejs-dialog
    #chartDialog
    [visible]="true"
    class="custom-chart-dialog"
    [width]="'70%'"
    [height]="'auto'"
    header="Confirmation"
    [target]="'.control-section'"
  >
    <ng-template #content>
      <div class="md-col-4">
        <app-orgchart [data]="orgChartData"></app-orgchart>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <button
        ejs-button
        class="btn btn-secondary"
        (click)="closedChartDialog(false)"
      >
        Close
      </button>
    </ng-template>
  </ejs-dialog>
</div>

<!-- Audit Trail modal -->
<ejs-dialog
  id="auditTrailDialog"
  #auditTrailDialog
  showCloseIcon="true"
  isModal="true"
  width="95%"
  (overlayClick)="onOverlayClick()"
  cssClass="custom-dialog"
  [footerTemplate]="'Footer'"
  [visible]="false"
>
  <ng-template #footerTemplate>
    <button
      id="close"
      (click)="closeAuditTrailModal()"
      class="btn btn-secondary cancle_btn"
      data-ripple="true"
    >
      close
    </button>
  </ng-template>
  <ng-template #content>
    <div class="dialogContent">
      <div class="col-md-12 audit-summary">
        <div class="row divider">
          <div class="col-md-2 m-b-1">
            <label> Version Number </label> :
            <span>{{ auditTrailRecord?.recordVersion }}</span>
          </div>
          <div class="col-md-2 m-b-1">
            <label>Last updated on </label> :
            <span>{{ formatDate(auditTrailRecord?.lastUpdateDate) }}</span>
          </div>
          <div class="col-md-3 m-b-1">
            <label> Last updated by </label> :
            <span>{{ lastUpdatedBy }}</span>
          </div>
          <div class="col-md-5 m-b-1">
            <label> Update mode </label> :
            <span>{{ lastUpdateMode }}</span>
            <span *ngIf="lastUpdateMode?.toLowerCase() == 'file upload'">
              ( {{ auditTrailRecord.fileName }} )
            </span>
          </div>
        </div>
      </div>
      <div class="control-section default-appbar-section">
        <div
          class="col-md-12 audit-summary"
          style="padding-top: 0px !important"
        >
          <div class="row align-items-center">
            <div class="col-md-5">
              <span style="font-weight: 500; font-size: 16px">History</span>
            </div>

            <div class="col-md-7">
              <div class="d-flex justify-content-end">
                <!-- Icon container for CSV -->
                <div style="margin-right: 1rem">
                  <img
                    style="height: 2rem; width: auto; cursor: pointer"
                    src="../../../../assets/images/export-options/csv.png"
                    (click)="auditCvsGenerator()"
                  />
                </div>
                <!-- Icon container for Excel -->
                <div style="margin-right: 1rem">
                  <img
                    style="height: 2rem; width: auto; cursor: pointer"
                    src="../../../../assets/images/export-options/xlsx.png"
                    (click)="auditXlxsGenerator()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <ejs-grid
          #AuditTrailGrid
          *ngIf="auditTrailData"
          id="AuditTrailGrid"
          class="syncfusion-grid"
          [dataSource]="auditTrailData"
          [pageSettings]="{ pageSize: 5 }"
          [allowPaging]="true"
          [allowFiltering]="true"
          [filterSettings]="{
            type: 'Excel'
          }"
          allowReordering="true"
          (rowDataBound)="rowDataBound($event)"
          (actionComplete)="actionComplete($event)"
          [allowSorting]="true"
          [height]="auditTrailGridHeight"
          [allowExcelExport]="true"
          (excelExportComplete)="auditExportComplete()"
        >
          <e-columns>
            <e-column
              field="recordVersion"
              headerText="Record Version"
              headerTextAlign="center"
              width="180"
              textAlign="center"
            >
            </e-column>
            <e-column
              field="updateType"
              headerText="Update Type"
              headerTextAlign="center"
              width="180"
              textAlign="center"
            >
            </e-column>
            <e-column
              field="updateType"
              headerText="Update Values"
              headerTextAlign="center"
              width="400"
              textAlign="left"
            >
              <ng-template #template let-data>
                <div
                  class="bullet-container"
                  *ngIf="
                    attributeChanges &&
                    data.recordVersion &&
                    attributeChanges[data.recordVersion]
                  "
                >
                  <ul class="bullet-list">
                    <li
                      *ngFor="let item of attributeChanges[data.recordVersion]"
                    >
                      {{ formatHeader(item) }}
                    </li>
                  </ul>
                </div>
                <div *ngIf="!attributeChanges[data.recordVersion]">
                  No changes detected
                </div>
              </ng-template>
            </e-column>
            <e-column
              field="gpoId"
              headerText="GPO ID"
              width="130"
              isPrimaryKey="true"
            ></e-column>
            <!-- <e-column
              headerText="ORG"
              width="130"
              *ngIf="type == 'chart'"
              isPrimaryKey="true"
            >
              <ng-template #template let-data>
                <a style="color: blue; cursor: pointer; font-weight: 600">
                  <span
                    class="e-icons e-medium e-user-defined"
                    (click)="navigateToOrgChart(data)"
                  ></span>
                </a>
              </ng-template>
            </e-column> -->
            <e-column
              field="gpoName"
              headerText="GPO Name"
              width="150"
              isPrimaryKey="true"
            ></e-column>
            <e-column
              field="gpoOutletGroupId"
              headerText="GPO Outlet Group ID"
              width="210"
            ></e-column>
            <e-column
              field="gpoContractId"
              headerText="GPO Contract ID"
              width="190"
            ></e-column>
            <e-column
              field="outletId"
              headerText="Outlet ID"
              width="140"
              isPrimaryKey="true"
            ></e-column>
            <e-column
              field="outletGpoId"
              headerText="Outlet GPO ID"
              width="170"
            ></e-column>
            <e-column
              field="outletName"
              headerText="Outlet Name"
              width="250"
            ></e-column>
            <e-column
              field="outletType"
              headerText="Outlet Type"
              width="160"
            ></e-column>
            <e-column
              field="outletCot"
              headerText="Outlet COT"
              width="160"
            ></e-column>
            <e-column
              field="outletCotSubtype"
              headerText="Outlet COT Subtype"
              width="210"
            ></e-column>
            <e-column
              field="outletNpi"
              headerText="Outlet NPI"
              width="170"
            ></e-column>
            <e-column
              field="outletHin"
              headerText="Outlet HIN"
              width="170"
            ></e-column>
            <e-column
              field="outletDea"
              headerText="Outlet DEA"
              width="170"
            ></e-column>
            <e-column
              field="outletAddress1"
              headerText="Outlet Address 1"
              width="210"
            ></e-column>
            <e-column
              field="outletAddress2"
              headerText="Outlet Address 2"
              width="210"
            ></e-column>
            <e-column
              field="outletCity"
              headerText="Outlet City"
              width="160"
            ></e-column>
            <e-column
              field="outletState"
              headerText="Outlet State"
              width="160"
            ></e-column>
            <e-column
              field="outletZip"
              headerText="Outlet ZIP"
              width="160"
            ></e-column>
            <e-column
              field="outletCountry"
              headerText="Outlet Country"
              width="170"
            ></e-column>
            <e-column
              field="outletPhone"
              headerText="Outlet Phone"
              width="170"
            ></e-column>
            <e-column
              field="outletPrimaryContact"
              headerText="Outlet Primary Contact"
              width="230"
            ></e-column>
            <e-column
              field="outletPrimaryEmail"
              headerText="Outlet Primary Email"
              width="230"
            ></e-column>
            <e-column
              field="outletGpoStartDate"
              headerText="Outlet GPO Start Date"
              width="230"
              type="date"
              [format]="formatoptions"
              editType="datepickeredit"
              [edit]="outletGpoStartDate"
            >
            </e-column>
            <e-column
              field="outletGpoEndDate"
              headerText="Outlet GPO End Date"
              width="230"
              type="date"
              [format]="formatoptions"
              editType="datepickeredit"
              [edit]="outletGpoEndDate"
            >
            </e-column>
            <e-column
              field="outletRebateEligible"
              headerText="Outlet Rebate Eligible"
              width="230"
            ></e-column>
            <e-column
              field="outletRebateEligibleStartDate"
              headerText="Outlet Rebate Eligible Start Date"
              width="280"
              type="date"
              [format]="formatoptions"
              editType="datepickeredit"
              [edit]="outletRebateEligibleStartDate"
            >
            </e-column>
            <e-column
              field="outletRebateEligibleEndDate"
              headerText="Outlet Rebate Eligible End Date"
              width="280"
              type="date"
              [format]="formatoptions"
              editType="datepickeredit"
              [edit]="outletRebateEligibleEndDate"
            >
            </e-column>
            <e-column
              field="outletAnalysisStartDate"
              headerText="Outlet Analysis Start Date"
              width="280"
              type="date"
              [format]="formatoptions"
              editType="datepickeredit"
              [edit]="outletAnalysisStartDate"
            >
            </e-column>
            <e-column
              field="outletAnalysisEndDate"
              headerText="Outlet Analysis End Date"
              width="280"
              type="date"
              [format]="formatoptions"
              editType="datepickeredit"
              [edit]="outletAnalysisEndDate"
            >
            </e-column>
            <e-column
              field="outletStatus"
              headerText="Outlet Status"
              width="170"
              isPrimaryKey="true"
            ></e-column>
            <e-column
              field="outletParentId"
              headerText="Outlet Parent ID"
              width="180"
            ></e-column>
            <e-column
              field="outletParentGpoId"
              headerText="Outlet Parent GPO ID"
              width="220"
            ></e-column>
            <e-column
              field="outletParentName"
              headerText="Outlet Parent Name"
              width="250"
            ></e-column>
            <e-column
              field="outletParentType"
              headerText="Outlet Parent Type"
              width="210"
            ></e-column>
            <e-column
              field="outletParentCot"
              headerText="Outlet Parent COT"
              width="210"
            ></e-column>
            <e-column
              field="outletParentCotSubtype"
              headerText="Outlet Parent COT Subtype"
              width="250"
            ></e-column>
            <e-column
              field="outletParentNpi"
              headerText="Outlet Parent NPI"
              width="220"
            ></e-column>
            <e-column
              field="outletParentHin"
              headerText="Outlet Parent HIN"
              width="220"
            ></e-column>
            <e-column
              field="outletParentDea"
              headerText="Outlet Parent DEA"
              width="220"
            ></e-column>
            <e-column
              field="outletParentAddress1"
              headerText="Outlet Parent Address 1"
              width="230"
            ></e-column>
            <e-column
              field="outletParentAddress2"
              headerText="Outlet Parent Address 2"
              width="230"
            ></e-column>
            <e-column
              field="outletParentCity"
              headerText="Outlet Parent City"
              width="200"
            ></e-column>
            <e-column
              field="outletParentState"
              headerText="Outlet Parent State"
              width="200"
            ></e-column>
            <e-column
              field="outletParentZip"
              headerText="Outlet Parent ZIP"
              width="200"
            ></e-column>
            <e-column
              field="outletParentCountry"
              headerText="Outlet Parent Country"
              width="220"
            ></e-column>
            <e-column
              field="outletParentGpoStartDate"
              headerText="Outlet Parent GPO Start Date"
              width="260"
              type="date"
              [format]="formatoptions"
              editType="datepickeredit"
              [edit]="outletParentGpoStartDate"
            >
            </e-column>
            <e-column
              field="outletParentGpoEndDate"
              headerText="Outlet Parent GPO End Date"
              width="260"
              type="date"
              [format]="formatoptions"
              editType="datepickeredit"
              [edit]="outletParentGpoEndDate"
            >
            </e-column>
            <e-column
              field="outletParentStartDate"
              headerText="Outlet Parent Start Date"
              width="260"
              type="date"
              [format]="formatoptions"
              editType="datepickeredit"
              [edit]="outletParentStartDate"
            >
            </e-column>
            <e-column
              field="outletParentEndDate"
              headerText="Outlet Parent End Date"
              width="260"
              type="date"
              [format]="formatoptions"
              editType="datepickeredit"
              [edit]="outletParentEndDate"
            >
            </e-column>
            <e-column
              field="outletGrandparentId"
              headerText="Outlet Grandparent ID"
              width="230"
            ></e-column>
            <e-column
              field="outletGrandparentGpoId"
              headerText="Outlet Grandparent GPO ID"
              width="250"
            ></e-column>
            <e-column
              field="outletGrandparentName"
              headerText="Outlet Grandparent Name"
              width="250"
            ></e-column>
            <e-column
              field="outletGrandparentType"
              headerText="Outlet Grandparent Type"
              width="250"
            ></e-column>
            <e-column
              field="outletGrandparentCot"
              headerText="Outlet Grandparent COT"
              width="250"
            ></e-column>
            <e-column
              field="outletGrandparentCotSubtype"
              headerText="Outlet Grandparent COT Subtype"
              width="290"
            ></e-column>
            <e-column
              field="outletGrandparentNpi"
              headerText="Outlet Grandparent NPI"
              width="250"
            ></e-column>
            <e-column
              field="outletGrandparentHin"
              headerText="Outlet Grandparent HIN"
              width="250"
            ></e-column>
            <e-column
              field="outletGrandparentDea"
              headerText="Outlet Grandparent DEA"
              width="250"
            ></e-column>
            <e-column
              field="outletGrandparentAddress1"
              headerText="Outlet Grandparent Address 1"
              width="270"
            ></e-column>
            <e-column
              field="outletGrandparentAddress2"
              headerText="Outlet Grandparent Address 2"
              width="270"
            ></e-column>
            <e-column
              field="outletGrandparentCity"
              headerText="Outlet Grandparent City"
              width="230"
            ></e-column>
            <e-column
              field="outletGrandparentState"
              headerText="Outlet Grandparent State"
              width="250"
            ></e-column>
            <e-column
              field="outletGrandparentZip"
              headerText="Outlet Grandparent ZIP"
              width="230"
            ></e-column>
            <e-column
              field="outletGrandparentCountry"
              headerText="Outlet Grandparent Country"
              width="280"
            ></e-column>
            <e-column
              field="outletGrandparentGpoStartDate"
              headerText="Outlet Grandparent GPO Start Date"
              width="300"
              type="date"
              [format]="formatoptions"
              editType="datepickeredit"
              [edit]="outletGrandparentGpoStartDate"
            >
            </e-column>
            <e-column
              field="outletGrandparentGpoEndDate"
              headerText="Outlet Grandparent GPO End Date"
              width="300"
              type="date"
              [format]="formatoptions"
              editType="datepickeredit"
              [edit]="outletGrandparentGpoEndDate"
            >
            </e-column>
            <e-column
              field="lastUpdateDate"
              headerText="Last Update Date"
              width="250"
              type="date"
              [format]="formatoptions"
              editType="datepickeredit"
            >
            </e-column>
            <e-column
              field="lastUpdatedBy"
              headerText="Last Updated By"
              width="250"
            >
            </e-column>
          </e-columns>
        </ejs-grid>
      </div>
    </div>
  </ng-template>
  <ng-template #header>
    <div title="Audit Trails" class="e-icon-settings dlg-template">
      <span style="font-size: 20px">Audit Trail</span>
      <br />
      <span style="font-size: 14px; margin-left: 0.1rem; font-style: italic"
        >({{ selectedGpo }})</span
      >
    </div>
  </ng-template>
</ejs-dialog>
