import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, findIndex } from "rxjs";
import { AnyCatcher } from "rxjs/internal/AnyCatcher";
import { environment } from "../../environments/environment";
import { GPOHistory } from "./gpo/model/gpo-history";
import { BehaviorSubject } from "rxjs";
import saveAs from "file-saver";
import { RuleType } from "./modal/rule-type";

interface RuleToRun {
  ruleId: number[];
  gpoId: any[];
}

@Injectable({
  providedIn: "root",
})
export class GpoService {
  private BASE_URL = environment.apiURL;

  constructor(private http: HttpClient) {}

  getAllGpos(): Observable<any> {
    const apiUrl = this.BASE_URL + "api/gpo/gpo-filters/gpos";
    return this.http.get<any>(apiUrl);
  }
  getAllCots(): Observable<any> {
    const apiUrl = this.BASE_URL + "api/gpo/gpo-filters/cots";
    return this.http.get<any>(apiUrl);
  }
  getAllStates(): Observable<any> {
    const apiUrl = this.BASE_URL + "api/gpo/gpo-filters/states";
    return this.http.get<any>(apiUrl);
  }
  gatAllTypes(): Observable<any> {
    const apiUrl = this.BASE_URL + "api/gpo/gpo-filters/types";
    return this.http.get<any>(apiUrl);
  }

  getAllSimulation(): Observable<any> {
    const apiUrl = this.BASE_URL + "simulations";
    return this.http.get<any>(apiUrl);
  }

  saveEntity(data: any): Observable<any> {
    const url = this.BASE_URL + "simulations"; // Adjust URL
    return this.http.post(url, data);
  }

  editEntity(id: number, data: any): Observable<any> {
    const url = this.BASE_URL + `simulations/${id}`; // Adjust URL
    return this.http.post(url, data);
  }

  submitScenario(data: any): Observable<any> {
    const url = this.BASE_URL + "scenarios"; // Replace with your actual endpoint
    return this.http.post(url, data);
  }

  updateScenario(scenariId: number, data: any): Observable<any> {
    const url = this.BASE_URL + `scenarios/${scenariId}`; // Replace with your actual endpoint
    return this.http.post(url, data);
  }

  updateReferenceata(simulationId: number, data: any): Observable<any> {
    const url = this.BASE_URL + `simulations/reference-data/${simulationId}`;
    return this.http.post(url, data);
  }

  fetchSimulationById(simulationId: number): Observable<any> {
    const url = this.BASE_URL + `simulations/${simulationId}`;
    return this.http.get(url);
  }

  fetchNdcBrand(search: any): Observable<any> {
    const formData = new FormData();
    formData.append("searchText", search);
    const url = this.BASE_URL + `config/ndc-brand`;
    return this.http.post(url, formData);
  }

  fetchEntity(classification: any, search: any): Observable<any> {
    const formData = new FormData();
    formData.append("classification", "GPO");
    formData.append("searchText", search);
    const url = this.BASE_URL + `config/entity`;
    return this.http.post(url, formData);
  }

  fetchChannel(search: any): Observable<any> {
    const formData = new FormData();
    formData.append("searchText", search);
    const url = this.BASE_URL + `config/channel`;
    return this.http.post(url, formData);
  }

  fetchPriceType(): Observable<any> {
    const url = this.BASE_URL + `application-config/price-type`;
    return this.http.get(url);
  }

  fetchScenarioData(simualtionId: any, scenarioId: any): Observable<any> {
    const url =
      this.BASE_URL + `simulations/scenario/data/${simualtionId}/${scenarioId}`;
    return this.http.get(url);
  }

  fetchREfVSFORData(simualtionId: any): Observable<any> {
    const url = this.BASE_URL + `simulations/analysis/${simualtionId}`;
    return this.http.get(url);
  }
  uploadFile(data: FormData): Observable<any> {
    const url = this.BASE_URL + `api/gpo/data-load`;
    return this.http.post(url, data);
  }

  getGpoData(): Observable<any> {
    const apiUrl = this.BASE_URL + `api/gpo/file-history`;
    return this.http.get<any>(apiUrl);
  }

  updateGpo(item: GPOHistory, status: string): Observable<any> {
    const url = this.BASE_URL + `api/gpo/data-load-confirmation/` + status;
    return this.http.post(url, item);
  }

  getAllBusinessRule(): Observable<any> {
    const apiUrl = this.BASE_URL + "api/gpo/business-rules";

    return this.http.get<any>(apiUrl);
  }

  getBusinessRule(id: number): Observable<any> {
    const apiUrl = this.BASE_URL + "api/gpo/business-rule/" + id;
    return this.http.get<any>(apiUrl);
  }
  addBusinessRule(data: any): Observable<any> {
    const url = this.BASE_URL + "api/gpo/business-rule"; // Adjust URL
    return this.http.post(url, data);
  }

  updateBusinessRule(item: any, id: number): Observable<any> {
    const url = this.BASE_URL + `api/gpo/business-rule/update/` + id;
    return this.http.post(url, item);
  }

  addBusinessRuleCondition(data: any): Observable<any> {
    const url = this.BASE_URL + "gpo-rule-condition"; // Adjust URL
    return this.http.post(url, data);
  }

  updateBusinessRuleCondition(item: any, id: number): Observable<any> {
    const url = this.BASE_URL + `api/gpo/business-rule/update/` + id;
    return this.http.post(url, item);
  }

  runBusinessRule(id: number[], gpo: number[]): Observable<any> {
    const url = this.BASE_URL + "api/gpo/business-rule/run";
    const ruleRunRequest: RuleToRun = {
      ruleId: id,
      gpoId: gpo,
    };
    return this.http.post(url, ruleRunRequest);
  }

  fetchConfiguration(): Observable<any> {
    const url = this.BASE_URL + `api/gpo/business-rule/config`;
    return this.http.get(url);
  }

  checkImpact(id: number[], gpo: number[]): Observable<any> {
    const url = this.BASE_URL + "api/gpo/business-rule/impact";
    const ruleRunRequest: RuleToRun = {
      ruleId: id,
      gpoId: gpo,
    };
    return this.http.post(url, ruleRunRequest);
  }

  getGPOImpactByRuleType(
    ruleRunRequest: RuleToRun,
    ruleType: String
  ): Observable<any> {
    const url =
      this.BASE_URL + "api/gpo/business-rule/impact/" + ruleType.toString();
    return this.http.post(url, ruleRunRequest);
  }

  deleteRule(id: number): Observable<any> {
    const url = this.BASE_URL + "api/gpo/business-rule/delete/" + id;
    return this.http.get(url);
  }

  fetchSnapshotDetailsData(id: any): Observable<any> {
    const url = this.BASE_URL + `api/gpo/get-snapshot` + id;
    return this.http.get(url);
  }

  createSnapshotDetailsData(
    snapshotDetails: any,
    snapshot_id: number,
    isUpdateMode: boolean
  ): Observable<any> {
    let url = null;
    if (isUpdateMode && snapshot_id) {
      url = this.BASE_URL + "api/gpo/update-snapshot/" + snapshot_id;
    } else {
      url = this.BASE_URL + "api/gpo/create-snapshot";
    }
    return this.http.post(url, snapshotDetails);
  }

  updateSnapshotDetailsData(id: any, snapshotDetails: any): Observable<any> {
    const url = this.BASE_URL + "api/gpo/update-snapshot" + id;
    return this.http.post(url, snapshotDetails);
  }

  getGpoDetails(): Observable<any> {
    const url = this.BASE_URL + "api/gpo/get-gpos";
    return this.http.get(url);
  }

  getSnapshotData(): Observable<any> {
    const url = this.BASE_URL + "api/gpo/get-snapshots";
    return this.http.get(url);
  }

  deleteSnapshot(data: any): Observable<any> {
    const url = this.BASE_URL + "api/gpo/snapshot/delete";
    return this.http.post(url, data);
  }

  getSnapshotDataByFilters(snapshotSearchFilters: any): Observable<any> {
    const url = this.BASE_URL + "api/gpo/get-snapshots";
    return this.http.post(url, snapshotSearchFilters);
  }

  exportSnapshot(payload: any): Observable<any> {
    const url = this.BASE_URL + "api/gpo/execute-snapshot";
    return this.http.post(url, payload, {
      responseType: "blob",
    });
  }

  getSnapshotById(id: number): Observable<any> {
    const url = this.BASE_URL + `api/gpo/snapshot/${id}`;
    return this.http.get(url);
  }

  private selectedRowsSubject = new BehaviorSubject<any[]>([]);
  selectedRows$ = this.selectedRowsSubject.asObservable();

  setSelectedRows(rows: any[]) {
    this.selectedRowsSubject.next(rows);
  }

  fetchAllGpoEntity(): Observable<any> {
    const url = this.BASE_URL + `api/gpo/business-rule/get-gpos`;
    return this.http.get(url);
  }

  downloadFile(data: any, flag: any, from: any) {
    let filename = "";
    let exportType = "";
    let url = "";

    if (flag === "audit-csv") {
      filename = "gpo-audit.csv";
      exportType = "CSV";
    }

    if (flag === "audit-xls") {
      filename = "gpo-audit.xls";
      exportType = "XLSX";
    }

    if (flag === "gpo-csv") {
      filename = "gpo-member.csv";
      exportType = "CSV";
    }

    if (flag === "gpo-xls") {
      filename = "gpo-member.xls";
      exportType = "XLSX";
    }

    if (flag === "history-csv") {
      filename = "file-history.csv";
      exportType = "CSV";
    }

    if (flag === "history-xls") {
      filename = "file-history.xls";
      exportType = "XLSX";
    }

    if (flag === "summary-csv") {
      filename = "merge-outlet-summary.csv";
      exportType = "CSV";
    }

    if (flag === "summary-xls") {
      filename = "merge-outlet-summary.xls";
      exportType = "XLSX";
    }

    if (flag === "business-rule-csv") {
      filename = "business-rule-summary.csv";
      exportType = "CSV";
    }

    if (flag === "business-rule-xls") {
      filename = "business-rule-summary.xls";
      exportType = "XLSX";
    }

    if (flag === "discover-csv") {
      filename = "snapshot-analysis-summary.csv";
      exportType = "CSV";
    }

    if (flag === "discover-xls") {
      filename = "snapshot-analysis-summary.xls";
      exportType = "XLSX";
    }

    if (from === "GPO") {
      url = this.BASE_URL + `api/export/gpo/${exportType}`;
    } else if (from === "FILE") {
      url = this.BASE_URL + `api/export/file/${exportType}`;
    } else if (from === "MERGE") {
      url = this.BASE_URL + `api/export/merge/${exportType}`;
    } else if (from === "BUSINESS-RULE") {
      url = this.BASE_URL + `api/export/rule/${exportType}`;
    } else if (from === "DISCOVER") {
      url = this.BASE_URL + `api/export/discover/${exportType}`;
    }

    this.http
      .post(url, data, {
        responseType: "blob",
      })
      .subscribe((blob: any) => {
        saveAs(blob, filename);
      });
  }

  getBusinessRuleNamesAndId(): Observable<any> {
    const url = this.BASE_URL + `api/gpo/business-rule/get-rules`;
    return this.http.get(url);
  }
  downloadRuleCsvFile(status: string) {
    const url = this.BASE_URL + `api/gpo/business-rule/download-csv/` + status;
    this.http
      .get(url, {
        responseType: "blob",
      })
      .subscribe((blob: any) => {
        saveAs(blob, "Business-Rules.csv");
      });
  }
  downloadRuleXlxsFile(status: string) {
    const url = this.BASE_URL + `api/gpo/business-rule/download-xlsx/` + status;
    this.http
      .get(url, {
        responseType: "blob",
      })
      .subscribe((blob: any) => {
        saveAs(blob, "Business-Rules.xlsx");
      });
  }

  getAllGpoList(gridFilter: any): Observable<any> {
    const apiUrl = this.BASE_URL + "api/gpo/grid/all-gpo";

    return this.http.post<any>(apiUrl, gridFilter);
  }

  getOrgChartData(outletParentName: string): Observable<any> {
    const apiUrl =
      this.BASE_URL + "api/gpo/gpo-org-chart-data/" + outletParentName;
    return this.http.get<any>(apiUrl);
  }

  updateGridGpo(item: any[], flag: any): Observable<any> {
    const url = this.BASE_URL + `api/gpo/grid/update-gpo-member/${flag}`;
    return this.http.post(url, item);
  }

  getAllRuleListForBtachUpload(id: any): Observable<any> {
    const url = this.BASE_URL + `api/gpo/business-rule/get-applied-rules/` + id;
    return this.http.get(url);
  }

  updateruleExecutionStatus(id: any): Observable<any> {
    const url =
      this.BASE_URL + `api/gpo/business-rule/update-applied-rule-status/` + id;
    return this.http.get(url);
  }
  getAuditTrail(outletId: string): Observable<any> {
    const apiUrl = this.BASE_URL + `audit/gpo/audit-trail/${outletId}`;
    return this.http.get<any>(apiUrl);
  }
  getOutletParents(): Observable<any> {
    const apiUrl = this.BASE_URL + `outlet/merge/distinct-parent-outlet`;
    return this.http.get<any>(apiUrl);
  }
  getGpos(): Observable<any> {
    const apiUrl = this.BASE_URL + `outlet/merge/distinct-gpo`;
    return this.http.get<any>(apiUrl);
  }
  applyMerge(payload: any): Observable<any> {
    const apiUrl = this.BASE_URL + `outlet/merge/apply-merge`;
    return this.http.post<any>(apiUrl, payload);
  }
  getOutletParentDetails(id: any, value: any): Observable<any> {
    const apiUrl =
      this.BASE_URL + `outlet/merge/outlet-parent-details/${id}/${value}`;
    return this.http.get<any>(apiUrl);
  }
  getUsernameById(id: any): Observable<any> {
    const apiURL = this.BASE_URL + `auth/get-username/${id}`;
    return this.http.get<any>(apiURL);
  }
  executeSnapshotAnalysis(id: any): Observable<any> {
    const apiUrl =
      this.BASE_URL + `api/snapshot-analysis/execute-snapshot-analysis/${id}`;
    return this.http.get<any>(apiUrl);
  }
  getDelta1(id: any): Observable<any> {
    const apiUrl = this.BASE_URL + `api/snapshot-analysis/delta-1/${id}`;
    return this.http.get<any>(apiUrl);
  }

  getDelta2(id: any): Observable<any> {
    const apiUrl = this.BASE_URL + `api/snapshot-analysis/delta-2/${id}`;
    return this.http.get<any>(apiUrl);
  }
  getComparison(id: any): Observable<any> {
    const apiUrl = this.BASE_URL + `api/snapshot-analysis/comparison/${id}`;
    return this.http.get<any>(apiUrl);
  }
  getSnapshotAnalysisDetailsById(id: number): Observable<any> {
    const apiUrl = this.BASE_URL + `api/snapshot-analysis/${id}`;
    return this.http.get<any>(apiUrl);
  }
}
