import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { LoadingService } from './layout/loading-Service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private loadingService: LoadingService  // Inject the LoadingService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Start the spinner

    this.loadingService.setLoading(true, req.url);


    if (req.url.endsWith('fundae/auth/login')) {
      // Ensure to stop the spinner for this specific request as it bypasses the general flow
              this.loadingService.setLoading(false, req.url);
;
      return next.handle(req);
    }

    const authToken = localStorage.getItem('token');
    const authReq = authToken ? req.clone({
      headers: req.headers.set('Authorization', `Bearer ${authToken}`)
    }) : req;

    return next.handle(authReq).pipe(
      catchError((error) => {
        // Stop the spinner in case of an error
          this.loadingService.setLoading(false, req.url);


        if (error.status === 401 || error.status === 403 || error.status === 0) {
          this.authService.redirectLogin.next(true);
          console.error('Unauthorized or Forbidden req detected. Redirecting to login...');
        }
        return throwError(() => error);
      }),
      finalize(() => {
        // Stop the spinner once the req is finished
        this.loadingService.setLoading(false, req.url);
;
      })
    );
  }
}
