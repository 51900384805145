<div
  class="container-fluid"
  style="
    padding: 1rem;
    background-color: #dfeff4;
    border: 1px solid #27d2f8;
    border-radius: 4px;
  "
>
  <div class="row">
    <div class="col-md-12">
      <h2
        style="
          display: flex;
          flex-direction: column;
          float: left;
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 1em;
        "
      >
        Snapshot Analysis Execution
      </h2>
      <button
        style="display: flex; flex-direction: column-reverse; float: right"
        type="button"
        class="btn btn-secondary back_btn me-2"
        (click)="onBack()"
      >
        Back
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12"><hr style="margin: 0.5rem 0 !important" /></div>
  </div>
  <div class="row">
    <div class="col-6">
      <form [formGroup]="snapshotForm" class="w-100">
        <div class="row">
          <div class="col-6">
            <label for="snapshotAnalysisId">Snapshot Analysis ID</label>
            <input
              type="text"
              id="snapshotAnalysisId"
              class="form-control"
              formControlName="snapshotAnalysisId"
              readonly
            />
          </div>
          <div class="col-6">
            <label for="snapshotFirstName">Snapshot First Name</label>
            <input
              type="text"
              id="snapshotFirstName"
              class="form-control"
              formControlName="snapshotFirstName"
              readonly
              [value]="snapshot1FileName"
            />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <label for="snapshotAnalysisName">Snapshot Analysis Name</label>
            <input
              type="text"
              id="snapshotAnalysisName"
              class="form-control"
              formControlName="snapshotAnalysisName"
              readonly
            />
          </div>
          <div class="col-6">
            <label for="snapshotSecondName">Snapshot Second Name</label>
            <input
              type="text"
              id="snapshotSecondName"
              class="form-control"
              formControlName="snapshotSecondName"
              readonly
              [value]="snapshot2FileName"
            />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <label for="model">Selected Model</label>
            <input
              type="text"
              id="model"
              class="form-control"
              formControlName="model"
              readonly
            />
          </div>
        </div>
      </form>
    </div>
    <div class="col-6">
      <table
        class="table table-striped table-hover table-bordered custom-table w-100"
      >
        <thead class="thead-dark">
          <tr>
            <th>Metric</th>
            <th>Snapshot First</th>
            <th>Snapshot Second</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Total Number of Records</td>
            <td>
              {{ firstSnapshot ? firstSnapshot.totalNoRecords : "" }}
            </td>
            <td>
              {{ secondSnapshot ? secondSnapshot.totalNoRecords : "" }}
            </td>
          </tr>
          <tr>
            <td>Total Number of GPOs</td>
            <td>{{ firstSnapshot ? firstSnapshot.totalNoGpos : "" }}</td>
            <td>{{ secondSnapshot ? secondSnapshot.totalNoGpos : "" }}</td>
          </tr>
          <tr>
            <td>GPO Names</td>
            <td>{{ firstSnapshot ? firstSnapshot.gpoNames : "" }}</td>
            <td>{{ secondSnapshot ? secondSnapshot.gpoNames : "" }}</td>
          </tr>
          <tr>
            <td>Snapshot Date</td>
            <td>
              {{
                firstSnapshot
                  ? firstSnapshot.snapshotDate
                    ? formatDateInUTC(firstSnapshot.snapshotDate)
                    : ""
                  : ""
              }}
            </td>
            <td>
              {{
                secondSnapshot
                  ? secondSnapshot.snapshotDate
                    ? formatDateInUTC(secondSnapshot.snapshotDate)
                    : ""
                  : ""
              }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div
          class="col-md-12"
          style="display: flex; flex-direction: row-reverse"
        >
          <button
            type="button"
            class="btn btn-secondary save_btn me-2"
            style="justify-content: start"
            (click)="getAnalysisResults()"
            [disabled]="!activateButton"
          >
            Get Analysis
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row mt-3">
    <div class="col-md-12">
      <hr />
    </div>
  </div>
  <div class="form-group row" *ngIf="showAnalysisAccordian">
    <ejs-accordion
      expandMode="Single"
      style="padding: 0rem !important; border: none; background: transparent"
    >
      <e-accordionitems>
        <e-accordionitem expanded="true">
          <ng-template #header>
            <div class="accordion-header">
              <span class="heading">
                Delta between First and Second snapshot
              </span>
              <span class="description">
                (Records are present in First but not in Second snapshot.)
              </span>
            </div>
          </ng-template>
          <ng-template #content>
            <div class="first-grid">
              <app-snapshot-analysis-grid
                [dataSource]="delta1Datasource"
                [allowGrouping]="false"
                [groupSettings]="{}"
                [attributeChanges]="{}"
              >
              </app-snapshot-analysis-grid>
            </div>
          </ng-template>
        </e-accordionitem>
        <e-accordionitem>
          <ng-template #header>
            <div class="accordion-header">
              <span class="heading">
                Delta between Second and First snapshot
              </span>
              <span class="description">
                (Records are present in Second but not in First snapshot.)
              </span>
            </div>
          </ng-template>
          <ng-template #content>
            <div class="second-grid">
              <app-snapshot-analysis-grid
                [dataSource]="delta2Datasource"
                [allowGrouping]="false"
                [groupSettings]="{}"
                [attributeChanges]="{}"
              >
              </app-snapshot-analysis-grid>
            </div>
          </ng-template>
        </e-accordionitem>
        <e-accordionitem>
          <ng-template #header>
            <div class="accordion-header">
              <span class="heading">Comparision Report</span>
              <span class="description">
                (Records are present in both but attributes have different
                values.)
              </span>
            </div>
          </ng-template>
          <ng-template #content>
            <div class="third-grid">
              <app-snapshot-analysis-grid
                [dataSource]="comparisonDatasource"
                [allowGrouping]="true"
                [groupSettings]="{ columns: ['GroupID'] }"
                [attributeChanges]="attributeChanges"
              >
              </app-snapshot-analysis-grid>
            </div>
          </ng-template>
        </e-accordionitem>
      </e-accordionitems>
    </ejs-accordion>
  </div>
</div>
<ejs-toast #toast></ejs-toast>
