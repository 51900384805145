import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GpoService } from '../layout/gpo.service';
import { PredealService } from '../layout/gpo/services/predeal.service';

@Component({
  selector: 'app-oauth2',
  standalone: true,
  imports: [],
  templateUrl: './oauth2.component.html',
  styleUrl: './oauth2.component.css'
})
export class Oauth2Component implements OnInit{

  router = inject(Router);


  constructor(
    private route: ActivatedRoute,
    private gpoService: GpoService,
  ) {

  }

  
  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      const token = params.get('token');
      if (token) {
        localStorage.setItem('token', token);
            this.router.navigate(['/home']);
      } else {
        this.router.navigate(['/login']);

      }
    });
  }
}
