import { CommonModule, DatePipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { Component, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { SwitchModule } from "@syncfusion/ej2-angular-buttons";
import {
  GridModule,
  GridAllModule,
  GridComponent,
  ExcelExportProperties
} from "@syncfusion/ej2-angular-grids";
import { ToolbarModule } from "@syncfusion/ej2-angular-navigations";
import { DialogModule, DialogComponent } from "@syncfusion/ej2-angular-popups";
import { BusinessRuleSequenceDialogComponent } from "../../../common/business-rule-sequence-dialog/business-rule-sequence-dialog.component";
import {
  ToastComponent,
  ToastModule,
} from "@syncfusion/ej2-angular-notifications";
import { BusinessRuleDeletionDialogComponent } from "../../../common/business-rule-deletion-dialog/business-rule-deletion-dialog.component";
import { BusinessRuleConfirmationDialogComponent } from "../../../common/business-rule-confirmation-dialog/business-rule-confirmation-dialog.component";
import { GpoService } from "../../gpo.service";
import { PredealService } from "../services/predeal.service";
import { BusinessRules } from "../model/business-rules";
import { Directive, HostListener } from "@angular/core";
import { GpoRuleDetailsComponent } from "./gpo-rule-details/gpo-rule-details.component";
import { BusinessRuleDetailDialogComponent } from "../../../common/business-rule-detail-dialog/business-rule-detail-dialog.component";
import { BusinessRuleDetailsDialogComponent } from "../../../common/business-rule-details-dialog/business-rule-details-dialog.component";
import { ToastService } from "../../../common/toast.service";
import { DataManager, Query } from "@syncfusion/ej2-data";

@Component({
  selector: "app-gpo-rule",
  standalone: true,
  imports: [
    GridModule,
    DialogModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    CommonModule,
    ToolbarModule,
    GridAllModule,
    ToolbarModule,
    HttpClientModule,
    CommonModule,
    SwitchModule,
    BusinessRuleSequenceDialogComponent,
    BusinessRuleConfirmationDialogComponent,
    BusinessRuleDeletionDialogComponent,
    BusinessRuleDetailDialogComponent,
    BusinessRuleDetailsDialogComponent,
    ToastModule,
  ],
  providers: [DatePipe],
  templateUrl: "./gpo-rule.component.html",
  styleUrl: "./gpo-rule.component.css",
})
export class GpoRuleComponent {
  position = { X: "center", Y: "center" };

  @ViewChild("ejDialog") ejDialog!: DialogComponent;
  @ViewChild("confirmationDialog") confirmationDialog!: DialogComponent;
  @ViewChild("deletionDialog") deletionDialog!: DialogComponent;
  dialogData = { title: "Dialog Title", content: "Dialog Content" };
  confirmationDialogData = {
    title: "Confirmation Dialog Title",
    content: "Confirmation Dialog Content",
  };
  deletionDialogData = {
    title: "Deletion Dialog Title",
    content: "Deletion Dialog Content",
  };

  @ViewChild("toast")
  toastObj!: ToastComponent;

  selectedItems: any[] = [];
  selectedCheckBoxItems: any[] = [];

  selectedSet: Set<any> = new Set();
  selectedData: any[] = [];
  selectedGpo: any[] = [];
  gpoGridData!: any[];
  areItemsSelected: boolean = false;
  public showSequencialDialog: boolean = false;
  public showConfirmationDialog: boolean = false;
  public showDeleteDialog: boolean = false;
  public showGpodetailsDialog: boolean = false;

  runItem: any;
  public toolbar: (
    | string
    | {
        text: string;
        tooltipText: string;
        prefixIcon: string;
        id: string;
        cssClass?: string;
      }
  )[] = [
    {
      text: "Create New Rule",
      tooltipText: "Add",
      prefixIcon: "e-add",
      id: "Add",
      cssClass: "add_new_rule_btn",
    },
    "Search",
  ];

  deleteForm!: FormGroup;

  allDataSource: BusinessRules[] = [];

  impact: any;
  rulesToRun: any[] = [];
  rulesToDelete!: any;
  dataSource: any[] = [];
  toggleBarChecked: string = "all";
  @ViewChild("businessRuleGrid", { static: false })
  public grid!: GridComponent;
  editSettings!: {
    allowEditing: boolean;
    allowAdding: boolean;
    allowDeleting: boolean;
  };
  gridHeight!: string;
  pageSize: number = 5;

  headerHeight = 240;
  footerHeight = 20;
  paginationHeight = 48;
  rowHeight = 48;
  buffer = 20;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private gpoService: GpoService,
    private toastService: ToastService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getDynamicData();

    this.editSettings = {
      allowEditing: true,
      allowAdding: true,
      allowDeleting: true,
    };
  }
  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.adjustGridHeight();
  }

  private adjustGridHeight() {
    // Calculate the grid height
    let availableHeight =
      window.innerHeight -
      this.headerHeight -
      this.footerHeight -
      this.paginationHeight -
      this.buffer;
    this.gridHeight = `${availableHeight}px`;

    // Calculate page size based on row height
    this.pageSize = Math.floor(availableHeight / this.rowHeight);
  }
  formatDate(date: Date) {
    return new DatePipe("en-US").transform(date, "MM-dd-yyyy");
  }

  async onDialogOk(data: any) {
    const result: any[] = data.result;
    const rules: number[] = [];
    result.map((item) => rules.push(item.id));
    console.log("All IDs:", rules);
    this.ejDialog.hide();
    await this.checkImpact(rules);
  }
  receiveDataFromDialog(event: any) {
    const result: any[] = event.result.entity;
    const gpos: any[] = [];
    const updatedList: string[] = result.map((item) => {
      const index = item.indexOf(" - "); // Find the index of ' - ' delimiter
      return item.substring(0, index); // Get the substring from the start to the index of ' - '
    });
    updatedList.map((item) => gpos.push(item));
    this.selectedGpo = gpos;
  }
  openConfirmationDialog() {
    // this.confirmationDialog.show(); // Show the second dialog
    if (this.impact && this.impact.totalRecordsUpdated > 0) {
      this.showConfirmationDialog = true;
    } else {
      this.toastObj.show({
        title: "Warning!",
        content: "Business rule has no impact on any data.",
        cssClass: "e-toast-warning",
        position: { X: "Right", Y: "Top" },
      });
    }
  }
  openDeletionDialog() {
    // this.deletionDialog.show(); // Show the second dialog
    this.showDeleteDialog = true;
  }
  onConfirmation(data: any) {
    console.log("Confirmed ");
    // this.confirmationDialog.hide();
    this.showConfirmationDialog = false;
    this.gpoService
      .runBusinessRule(this.rulesToRun, this.selectedGpo)
      .subscribe(
        (data) => {
          this.toastObj.show({
            title: "Success!",
            content: "Business rule successfully executed.",
            cssClass: "e-toast-success",
            position: { X: "Right", Y: "Top" },
          });
          this.getDynamicData();
          // Refresh the grid
          this.grid.refresh();
        },
        (error) => {
          this.toastObj.show({
            title: "Error!",
            content: "Failed to execute business rule",
            cssClass: "e-toast-danger",
            position: { X: "Right", Y: "Top" },
          });
        }
      );
    this.selectedItems = [];
  }

  onDeleteConfirmation(data: any) {
    console.log("Delete Confirmed");
    //this.deletionDialog.hide();
    this.showDeleteDialog = false;
    const index = this.dataSource.findIndex(
      (item: { id: any }) => item.id === this.rulesToDelete.id
    );
    if (index > -1) {
      // Refresh the grid to reflect the changes
      this.gpoService.deleteRule(this.rulesToDelete.id).subscribe((data) => {
        this.getDynamicData();
        this.dataSource.splice(index, 1);
        this.grid.dataSource = this.dataSource; // Update the grid's dataSource property
        this.grid.refresh();
      });
    }
  }

  openDialog(runSimulationItem: any[]) {
    this.selectedItems = runSimulationItem.map((item) => ({
      ...item,
      DisplayName: `${item.id} - ${item.businessRuleName} - ${item.businessRuleDesc}`,
    }));

    this.showSequencialDialog = true;
    //this.ejDialog.show();
  }

  closeDialog(data: any): void {
    // this.ejDialog.hide(); // This closes the dialog
    this.selectedItems = [];
    this.showSequencialDialog = false;
    // this.confirmationDialog.hide(); // This closes the dialog
    this.showConfirmationDialog = false;
    this.showDeleteDialog = false;

    // this.deletionDialog.hide(); // This closes the dialog
  }

  dialogClosedByUser() {
    const indexToRemove = this.selectedItems.findIndex(
      (item) => item.id === this.runItem.id
    );
    if (indexToRemove !== -1) {
      this.selectedItems = this.selectedItems.filter(
        (_, index) => index !== indexToRemove
      );
    }
    this.showSequencialDialog = false;
  }
  onBeforeOpen(args: any) {
    // setting maxHeight to the Dialog.
  }

  onSwitchChange(event: any) {
    if (event.checked) {
      this.toggleBarChecked = "active";
      this.dataSource = this.allDataSource.filter(
        (record) => record.status === "ACTIVE"
      );
    } else {
      this.dataSource = [...this.allDataSource];
      this.toggleBarChecked = "all";
    }
  }

  created(args: any): void {
    (
      document.getElementById(
        (this.grid as any).element.id + "_searchbar"
      ) as any
    ).addEventListener("keyup", () => {
      (this.grid as any).search(
        ((event as any).target as HTMLInputElement).value
      );
    });
  }

  getDynamicData() {
    this.gpoService.getAllBusinessRule().subscribe((data) => {
      this.allDataSource = data;
      this.dataSource = data;

      //create a login to update date columns with the new Date()

      this.dataSource.forEach((item) => {
        if (item.creationDate)
          item.creationDate = new Date(this.formatDateInUTC(item.creationDate));

        if (item.lastRunDate)
          item.lastRunDate = new Date(this.formatDateInUTC(item.lastRunDate));
        if (item.lastUpdateDate)
          item.lastUpdateDate = new Date(
            this.formatDateInUTC(item.lastUpdateDate)
          );
      });

      this.onSwitchChange({
        checked: this.toggleBarChecked === "active" ? true : false,
      });
      this.adjustGridHeight();
    });
  }

  onToolbarClick(args: any): void {
    const toolbarItemId = args.item.id; // Assuming the toolbar item identifier is available as 'id'
    switch (toolbarItemId) {
      case "Add":
        console.log("Add");
        this.router.navigate(["/home/rule/rule-details"]);
        break;
      default:
        console.error("Unknown toolbar item:", toolbarItemId);
        break;
    }
  }

  editClick(id: number) {
    this.router.navigate(["/home/rule/rule-details/", id]);
  }

  runClick(data: any) {
    const simulationItem: any[] = [];
    // const rules: number[] = [];
    // rules.push(id);
    // this.checkImpact(rules);
    simulationItem.push(data);
    //this.runItem = data;
    this.openDialog(simulationItem);
  }

  async checkImpact(rules: number[]) {
    this.rulesToRun = rules;
    await this.gpoService
      .checkImpact(rules, this.selectedGpo)
      .subscribe((data) => {
        this.impact = data;
        this.openConfirmationDialog();
      });
  }
  onRowSelected(event: any): void {
    // Add the selected item to the selectedItems array
    const selectedItem = event.data;
    if (Array.isArray(selectedItem)) {
      // If event.data is an array
      const activeItems = selectedItem.filter(
        (item: any) => item.status === "ACTIVE"
      );
      activeItems.forEach((item) => {
        if (!this.selectedCheckBoxItems.includes(item)) {
          this.selectedCheckBoxItems.push(item); // Add only new active items to selectedCheckBoxItems list
        }
      });
    } else {
      // If event.data is an object
      if (
        selectedItem.status === "ACTIVE" &&
        !this.selectedCheckBoxItems.includes(selectedItem) &&
        selectedItem.version > 0
      ) {
        this.selectedCheckBoxItems.push(event.data); // Add the object to selectedCheckBoxItems list only if status is Active and it's not already in the list
        this.areItemsSelected = true;
      }
    }
  }

  onRowDeselected(event: any): void {
    // Remove the deselected item from the selectedCheckBoxItems array
    const deselectedItem = event.data;
    if (Array.isArray(deselectedItem)) {
      this.selectedCheckBoxItems = [];
    } else {
      this.selectedCheckBoxItems = this.selectedCheckBoxItems.filter(
        (item) => item.id !== deselectedItem.id
      );
    }
    console.log("Selected items:", this.selectedCheckBoxItems);
    this.areItemsSelected = this.selectedCheckBoxItems.length > 0;
  }

  deleteRow(rowData: any): void {
    const index = this.dataSource.findIndex(
      (item: { id: any }) => item.id === rowData.id
    );
    if (index > -1) {
      this.rulesToDelete = rowData;
      this.openDeletionDialog();
    }
  }

  onGpodetailsDialogOk() {
    console.log("onGpodetailsDialogOk");
    this.showGpodetailsDialog = false;
  }

  onGpodetailsDialogClosed(): void {
    // this.ejDialog.hide(); // This closes the dialog
    console.log("onGpodetailsDialogClosed");

    this.showGpodetailsDialog = false;
  }

  gpoDataGrid(data: any) {
    console.log("1111111111");
    console.log(JSON.stringify(data));
    this.gpoGridData = data;
    this.showGpodetailsDialog = true;
  }
  async cvsGenerator() {
    // await this.getBusinessRuleFilteredData("business-rule-csv");
    const csvExportProperties: ExcelExportProperties = {
      fileName: 'rule-export.csv'
    };
    (this.grid as GridComponent).showSpinner();
    (this.grid as GridComponent).csvExport(csvExportProperties);
  }
  async xlxsGenerator() {
    // await this.getBusinessRuleFilteredData("business-rule-xls");
    const excelExportProperties: ExcelExportProperties = {
      fileName: 'rule-export.xlsx'
    };
    (this.grid as GridComponent).showSpinner();
    (this.grid as GridComponent).excelExport(excelExportProperties);
  }
  ruleExportComplete(): void {
    (this.grid as GridComponent).hideSpinner();
  }
  onRowDoubleClick(args: any): void {
    // args.rowData contains the data for the double-clicked row
    if (args.rowData.status && args.rowData.status === "ACTIVE") {
      this.router.navigate(["/home/rule/rule-details/", args.rowData.id]);
    }
  }

  getBusinessRuleFilteredData(flag: any) {
    const query = this.grid.getDataModule().generateQuery(true);
    const dataManager = new DataManager(this.dataSource);
    const result = dataManager.executeQuery(query);

    result
      .then((response: any) => {
        let filteredData = response.result;
        console.log(filteredData);

        if (filteredData.length === 0) {
          filteredData = this.dataSource;
        }
        this.downloadFile(filteredData, flag);
      })
      .catch((error: any) => {
        console.error("Error fetching filtered records:", error);
      });
  }
  downloadFile(data: any, flag: any) {
    this.gpoService.downloadFile(data, flag, "BUSINESS-RULE");
  }
  formatDateInUTC(date: any): any {
    const utcDate = new Date(date);
    return this.datePipe.transform(utcDate, "MM-dd-yyyy", "UTC");
  }
}
