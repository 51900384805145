<div class="mt-3">
  <!-- Row for Switch and Dialog Button -->
  <div class="row mb-3">
    <div class="col-6 d-flex justify-content-start">
      <ejs-switch
        onLabel="ON"
        offLabel="OFF"
        class="custom-switch"
        id="gpPriceImpact"
        style="margin-left: 1em"
        (change)="onSwitchChange($event)"
      >
      </ejs-switch>
      <span style="margin-left: 10px; margin-top: -3px"
        >Show Executed Snapshot Analysis Only</span
      >
    </div>
    <div class="col-6 d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-primary e-primary simulation_btn"
        (click)="openDialog(selectedCheckBoxItems)"
        [disabled]="!areItemsSelected"
        style="margin-right: 1em"
        [disabled]="selectedCheckBoxItems.length === 0"
      >
        Execute Snapshot Analysis
      </button>
      <!-- Icon container for CSV -->
      <div style="margin-right: 1rem">
        <img
          style="height: 2rem; width: auto; cursor: pointer"
          src="../../../../assets/images/export-options/csv.png"
          (click)="cvsGenerator()"
        />
      </div>
      <!-- Icon container for Excel -->
      <div style="margin-right: 1rem">
        <img
          style="height: 2rem; width: auto; cursor: pointer"
          src="../../../../assets/images/export-options/xlsx.png"
          (click)="xlxsGenerator()"
        />
      </div>
    </div>
  </div>

  <!-- Row for the Grid -->
  <div class="row">
    <div
      class="col-12 grid-container"
      style="
        padding: 10px;
        background-color: #dfeff4;
        border: 1px solid #27d2f8;
        border-radius: 4px;
      "
    >
      <ejs-grid
        #grid
        [dataSource]="dataSource"
        [allowSorting]="true"
        autoFit="true"
        allowSelection="true"
        [toolbar]="toolbar"
        (toolbarClick)="onToolbarClick($event)"
        allowReordering="true"
        allowResizing="true"
        (recordDoubleClick)="onRowDoubleClick($event)"
        (created)="created($event)"
        [selectionSettings]="{
          type: 'Multiple',
          checkboxOnly: true,
          persistSelection: true
        }"
        (rowSelected)="onRowSelected($event)"
        (rowDeselected)="onRowDeselected($event)"
        [allowPaging]="true"
        [pageSettings]="{ pageSize: 15 }"
        class="syncfusion-grid"
        [allowFiltering]="true"
        [filterSettings]="{
          type: 'Excel'
        }"
        [allowSorting]="true"
        [allowExcelExport]="true"
        (excelExportComplete)="discoverExportComplete()"
      >
        <e-columns>
          <e-column
            textAlign="Center"
            headerText="Select"
            type="checkbox"
            [width]="60"
            [allowEditing]="true"
          />
          <e-column
            textAlign="Center"
            headerText="Edit"
            [width]="80"
            [allowEditing]="false"
          >
            <ng-template #template let-data>
              <div
                *ngIf="data.status === 'ACTIVE'"
                class="icon-center-container"
                (click)="editClick(data.id)"
              >
                <span
                  class="e-icons e-edit"
                  style="color: #008000; cursor: pointer; font-size: 15px"
                ></span>
              </div>
            </ng-template>
          </e-column>
          <e-column
            textAlign="Center"
            headerText="Run"
            [width]="80"
            [allowEditing]="false"
          >
            <ng-template #template let-data>
              <div
                *ngIf="data.status === 'ACTIVE'"
                class="icon-center-container"
                (click)="selectedCheckBoxItems.length == 0 && runClick(data.id)"
              >
                <span
                  class="e-icons e-play"
                  style="color: #444444; cursor: pointer; font-size: 15px"
                ></span>
              </div>
            </ng-template>
          </e-column>
          <e-column
            textAlign="Center"
            headerText="Delete"
            [width]="90"
            [allowEditing]="false"
          >
            <ng-template #template let-data>
              <div
                *ngIf="data.status === 'ACTIVE'"
                class="icon-center-container"
                (click)="deleteRow(data)"
              >
                <span
                  class="e-icons e-delete"
                  style="color: red; cursor: pointer; font-size: 15px"
                ></span>
              </div>
            </ng-template>
          </e-column>
          <e-column
            textAlign="Center"
            field="id"
            headerText="Snapshot Analysis ID"
            name="Id"
            [isPrimaryKey]="true"
            [width]="220"
          >
            <ng-template #template let-data>
              {{ "SA-" + data.id }}
            </ng-template>
          </e-column>
          <e-column
            textAlign="Center"
            field="name"
            headerText="Snapshot Analysis Name"
            name="Name"
            [allowEditing]="false"
            [width]="320"
          >
            <ng-template #template let-data>
              <div style="text-align: left">{{ data.name }}</div>
            </ng-template>
          </e-column>
          <e-column
            textAlign="Center"
            field="status"
            headerText="Status"
            name="Status"
            [allowEditing]="false"
            [width]="120"
          ></e-column>
          <e-column
            textAlign="Center"
            field="creationDate"
            headerText="Created On"
            name="Created On"
            [allowEditing]="false"
            [width]="150"
            type="date"
            format="MM-dd-yyyy"
          >
            <ng-template #template let-data>
              <div>{{ data.createdOn }}</div>
            </ng-template>
          </e-column>
          <e-column
            textAlign="Center"
            field="createdBy"
            headerText="Created By"
            name="Created By"
            [allowEditing]="false"
            [width]="200"
          ></e-column>
          <e-column
            textAlign="Center"
            field="lastRunDate"
            headerText="Last Run Date"
            name="Last Run Date"
            [allowEditing]="false"
            [width]="180"
            type="date"
            format="MM-dd-yyyy"
          >
            <ng-template #template let-data>
              <div>{{ data.lastRunDate }}</div>
            </ng-template>
          </e-column>
          <e-column
            textAlign="Center"
            field="lastExecutedStatus"
            headerText="Last Run Status"
            name="Last Run Status"
            [allowEditing]="false"
            [width]="250"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </div>
  </div>
</div>

<ejs-dialog
  #ejDialog
  [visible]="true"
  [header]="'Execute Business Rule Sequences'"
  *ngIf="showSequencialDialog"
  isModal="true"
  [position]="position"
  [content]="dialogContent"
  [showCloseIcon]="true"
  cssClass="custom-dialog"
  [height]="100"
  [width]="500"
  (close)="showSequencialDialog = false"
>
  <ng-template #dialogContent>
    <app-business-rule-sequence-dialog
      [data]="selectedItems"
      (dataEvent)="receiveDataFromDialog($event)"
      (okClick)="onDialogOk($event)"
      (closeClick)="closeDialog($event)"
    ></app-business-rule-sequence-dialog>
  </ng-template>
</ejs-dialog>

<ejs-dialog
  #confirmationDialog
  [visible]="true"
  isModal="true"
  cssClass="custom-dialog"
  *ngIf="showConfirmationDialog"
  [position]="position"
  [content]="confirmationDialogContent"
  [showCloseIcon]="true"
  [height]="'700px'"
  [width]="'500px'"
  [header]="'Business Rules Execution Summary'"
  (close)="showConfirmationDialog = false"
>
  <ng-template #confirmationDialogContent>
    <app-business-rule-confirmation-dialog
      [data]="impact"
      (gpoDataGridEvent)="gpoDataGrid($event)"
      (okClick)="onConfirmation($event)"
      (closeClick)="closeDialog($event)"
    ></app-business-rule-confirmation-dialog>
  </ng-template>
</ejs-dialog>

<ejs-dialog
  #deletionDialog
  [visible]="true"
  isModal="true"
  [header]="'Snapshot - Analysis Delete'"
  *ngIf="showDeleteDialog"
  cssClass="delete-dialog"
  [position]="position"
  [content]="deletionDialogContent"
  [showCloseIcon]="true"
  (close)="showDeleteDialog = false"
>
  <ng-template #deletionDialogContent [width]="1500" [height]="1000">
    <app-business-rule-deletion-dialog
      (okClick)="onDeleteConfirmation($event)"
      (closeClick)="closeDialog($event)"
    ></app-business-rule-deletion-dialog>
  </ng-template>
</ejs-dialog>

<ejs-dialog
  #gpodetailsDialog
  [visible]="true"
  [header]="'Summary :'"
  *ngIf="showGpodetailsDialog"
  isModal="true"
  [content]="GpodetailsDialogContent"
  [showCloseIcon]="true"
  cssClass="custom-dialog"
  [position]="position"
  (close)="showGpodetailsDialog = false"
>
  <ng-template #GpodetailsDialogContent>
    <app-business-rule-detail-dialog
      [data]="gpoGridData"
      (businessRuleDetailOkClick)="onGpodetailsDialogOk()"
      (businessRuleDetailCloseClick)="onGpodetailsDialogClosed()"
    ></app-business-rule-detail-dialog>
  </ng-template>
</ejs-dialog>
<ejs-toast #toast></ejs-toast>
