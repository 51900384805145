import { CommonModule, DatePipe, formatDate } from "@angular/common";
import { Component, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { GridAllModule } from "@syncfusion/ej2-angular-grids";
import { AccordionModule } from "@syncfusion/ej2-angular-navigations";
import { CustomgridComponent } from "../../customgrid/customgrid.component";
import { SnapshotAnalysisGridComponent } from "../snapshot-analysis-grid/snapshot-analysis-grid.component";
import { GpoService } from "../../../gpo.service";
import {
  ToastAllModule,
  ToastComponent,
} from "@syncfusion/ej2-angular-notifications";
import { Data } from "../data";

interface AnalysisDetails {
  snapshotAnalysisName: string;
  snapshotFirstName: string;
  snapshotSecondName: string;
  model: string;
}
interface SnapshotDetails {
  totalNoRecords: string;
  totalNoGpos: string;
  gpoNames: string;
  snapshotDate: string;
}
@Component({
  selector: "app-gpo-discover-analysis",
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    GridAllModule,
    CustomgridComponent,
    SnapshotAnalysisGridComponent,
    ToastAllModule,
  ],
  providers: [DatePipe],
  templateUrl: "./gpo-discover-analysis.component.html",
  styleUrl: "./gpo-discover-analysis.component.css",
})
export class GpoDiscoverAnalysisComponent {
  snapshotForm!: FormGroup;

  @ViewChild("toast")
  toastObj!: ToastComponent;

  private analysisId: any;

  public dateFileds: string[] = [
    "outletGpoStartDate",
    "outletGpoEndDate",
    "outletRebateEligibleStartDate",
    "outletRebateEligibleEndDate",
    "outletAnalysisStartDate",
    "outletAnalysisEndDate",
    "outletParentGpoStartDate",
    "outletParentGpoEndDate",
    "outletParentStartDate",
    "outletParentEndDate",
    "outletGrandparentGpoStartDate",
    "outletGrandparentGpoEndDate",
    "activeFromDate",
    "activeTillDate",
    "recordCreatedDate",
    "lastUpdateDate",
  ];

  public snapshotData!: AnalysisDetails;

  public delta1Datasource!: any[];
  public delta2Datasource!: any[];
  public comparisonDatasource!: any[];
  public firstSnapshot?: SnapshotDetails;
  public secondSnapshot?: SnapshotDetails;
  public attributeChanges: { [key: string]: string[] } = {};
  public activateButton: boolean = true;
  public showAnalysisAccordian: boolean = false;
  public snapshot1FileName!: string;
  public snapshot2FileName!: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private _gpoService: GpoService,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    this.snapshotForm = this.fb.group({
      snapshotAnalysisId: [{ value: "", disabled: true }],
      snapshotAnalysisName: [{ value: "", disabled: true }],
      snapshotFirstName: [{ value: "", disabled: true }],
      snapshotSecondName: [{ value: "", disabled: true }],
      model: [{ value: "", disabled: true }],
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get("id");

      if (id) {
        this.analysisId = id;
        this._gpoService
          .getSnapshotAnalysisDetailsById(Number(id))
          .subscribe((data: any) => {
            if (data) {
              this.snapshotForm.patchValue({
                snapshotFirstName: data.firstDataSetFileName,
                snapshotSecondName: data.secondDataSetFileName,
                model: data.model,
                snapshotAnalysisName: data.name,
                snapshotAnalysisId: data.id,
              });

              this.generateSnapshotDetails(data);
            }
          });
        this.executeSnashotAnalysis(id);
      }
    });
  }

  executeSnashotAnalysis(id: any) {
    this.activateButton = false;
    this._gpoService.executeSnapshotAnalysis(id).subscribe({
      next: (response: any) => {
        this.activateButton = true;
      },
      error: (error: any) => {
        this.toastObj.show({
          title: "Error!",
          content: "Failed to execute snapshot analysis!",
          cssClass: "e-toast-danger",
          position: { X: "Right", Y: "Top" },
        });
      },
    });
  }

  generateSnapshotDetails(data: any) {
    this.getFirstSnapshotDetails(data.snapshotFirstId);
    this.getSecondSnapshotDetails(data.snapshotSecondId);
  }

  async getFirstSnapshotDetails(id: any) {
    await this._gpoService.getSnapshotById(id).subscribe({
      next: (response: any) => {
        let gpoNames = "";
        if (response.gpoList) {
          const jsonArray = JSON.parse(response.gpoList);
          gpoNames = jsonArray.map((item: any) => item.gpoName).join(", ");
        }
        this.snapshot1FileName = response.snapshotName;
        this.firstSnapshot = {
          totalNoRecords: response.noOfRecords,
          totalNoGpos: response.noOfGpos,
          gpoNames: gpoNames,
          snapshotDate: this.formatDateInUTC(
            this.datePipe.transform(response.snapshotDate)
          ),
        };
        console.log("first snapshot", this.firstSnapshot);
      },
      error: (error: any) => {
        this.toastObj.show({
          title: "Error!",
          content: "Failed to get snapshot details!",
          cssClass: "e-toast-danger",
          position: { X: "Right", Y: "Top" },
        });
      },
    });
  }

  async getSecondSnapshotDetails(id: any) {
    await this._gpoService.getSnapshotById(id).subscribe({
      next: (response: any) => {
        let gpoNames = "";
        if (response.gpoList) {
          const jsonArray = JSON.parse(response.gpoList);
          gpoNames = jsonArray.map((item: any) => item.gpoName).join(", ");
        }

        this.snapshot2FileName = response.snapshotName;

        this.secondSnapshot = {
          totalNoRecords: response.noOfRecords,
          totalNoGpos: response.noOfGpos,
          gpoNames: gpoNames,
          snapshotDate: this.formatDateInUTC(
            this.datePipe.transform(response.snapshotDate)
          ),
        };
        console.log("Second snapshot", this.secondSnapshot);
      },
      error: (error: any) => {
        this.toastObj.show({
          title: "Error!",
          content: "Failed to get snapshot details!",
          cssClass: "e-toast-danger",
          position: { X: "Right", Y: "Top" },
        });
      },
    });
  }

  async getAnalysisResults() {
    this.showAnalysisAccordian = true;
    this._gpoService.getDelta1(this.analysisId).subscribe({
      next: (response: any[]) => {
        if (response != null) {
          this.delta1Datasource = response;
          this.delta1Datasource.forEach((item: any) => {
            this.dateFileds.forEach((field: string) => {
              if (item[field]) {
                item[field] = new Date(item[field]);
              }
            });
          });
        }
      },
      error: (error: any) => {
        this.toastObj.show({
          title: "Error!",
          content: "Failed to load delta 1 snapshot analysis!",
          cssClass: "e-toast-danger",
          position: { X: "Right", Y: "Top" },
        });
      },
    });

    this._gpoService.getDelta2(this.analysisId).subscribe({
      next: (response: any[]) => {
        if (response != null) {
          this.delta2Datasource = response;
          this.delta2Datasource.forEach((item: any) => {
            this.dateFileds.forEach((field: string) => {
              if (item[field]) {
                item[field] = new Date(item[field]);
              }
            });
          });
        }
      },
      error: (error: any) => {
        this.toastObj.show({
          title: "Error!",
          content: "Failed to load delta 2 snapshot analysis!",
          cssClass: "e-toast-danger",
          position: { X: "Right", Y: "Top" },
        });
      },
    });

    this._gpoService.getComparison(this.analysisId).subscribe({
      next: (response: any[]) => {
        if (response != null) {
          response.forEach((item: any) => {
            this.dateFileds.forEach((field: string) => {
              if (item[field]) {
                item[field] = new Date(item[field]);
              }
            });
          });
          this.prepareComparisonGridData(response);
        }
      },
      error: (error: any) => {
        this.toastObj.show({
          title: "Error!",
          content: "Failed to load comparison snapshot analysis!",
          cssClass: "e-toast-danger",
          position: { X: "Right", Y: "Top" },
        });
      },
    });
  }
  prepareComparisonGridData(complexData: any) {
    this.comparisonDatasource = [];
    if (complexData) {
      complexData.forEach((item: any, index: any) => {
        /** Maintain grouping */
        const groupID = `Comparison ${index + 1}`;
        this.comparisonDatasource.push({
          RecordType: "Record 1",
          GroupID: groupID,
          ...item.snapshot1,
        });
        this.comparisonDatasource.push({
          RecordType: "Record 2",
          GroupID: groupID,
          ...item.snapshot2,
        });

        /** Collect changed keys */
        this.attributeChanges[groupID] = [];
        Object.keys(item.differences).forEach((key) => {
          if (item.differences[key].changed) {
            this.attributeChanges[groupID].push(key);
          }
        });
      });
    } else {
      console.info("No comparison differences found");
    }
    console.log("Data : ", this.comparisonDatasource, this.attributeChanges);
  }

  onBack(): void {
    this.router.navigate(["/home/discover"]);
  }

  formatSnapshotDate(snapshotDate: string): string {
    return formatDate(snapshotDate, "MM-dd-yyyy", "en-US");
  }

  formatDateInUTC(date: any): any {
    const utcDate = new Date(date);
    return this.datePipe.transform(utcDate, "MM-dd-yyyy", "UTC");
  }

  private getFormattedDate(dateString: string): string {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    // Construct the formatted date string in YYYY-MM-DD format
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }
}
