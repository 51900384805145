import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListBox, ListBoxAllModule, ListBoxComponent } from '@syncfusion/ej2-angular-dropdowns';
import { DialogModule, DialogComponent } from "@syncfusion/ej2-angular-popups";
import {
  GridModule,
  GridAllModule,
  GridComponent,
} from "@syncfusion/ej2-angular-grids";

@Component({
  selector: 'app-business-rule-details-dialog',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ListBoxAllModule, CommonModule,DialogModule,GridAllModule,GridModule
],
  templateUrl: './business-rule-details-dialog.component.html',
  styleUrl: './business-rule-details-dialog.component.css'
})
export class BusinessRuleDetailsDialogComponent {
  
  @ViewChild('listBox') listBox!: ListBox; // Reference to the ListBox component
  @Input() data!: any;
 
 totalRecordsImpacted :number =0;
 totalDateUpdates : number = 0;
 noOfRules :number = 0;
 totalAttributeUpdates :number = 0;
  @Output() okClick = new EventEmitter();

  @Output() closeClick = new EventEmitter();

  onOkClick() {
    // Emit some data on OK click
    this.okClick.emit({ result:  this.data });
  }

  closeDialog() {
    // Emit some data on OK click
    this.closeClick.emit({ result: 'Cancel to process execution' });
  }


   public setfield = { text: "DisplayName" };


  isButtonDisabled(): boolean {
    // Return true to disable the button when the condition is not satisfied
    return this.data.totalRecordsUpdated ===0;
  }
   onDrop(args: any) {
    this.data = args.source.currentData;
  }

  onDragStop() {
  //  const newListOrder = this.listBox.getSortedList();
    // Process the new list order here
  }
  
}
