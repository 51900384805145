import { Injectable } from "@angular/core";
import { Toast, ToastModel } from "@syncfusion/ej2-angular-notifications";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  private toastInstance!: Toast;

  constructor() {}

  initializeToast(toast: Toast): void {
    this.toastInstance = toast;
  }

  showToast(title: string, content: string, type?: string): void {
    if (!this.toastInstance) {
      console.error("Toast Instance is not initialized.");
      return;
    }

    let toastModel: ToastModel = {
      title: title,
      content: content,
      icon: type === "success" ? "e-toast-success" : "e-toast-error",
      cssClass: type === "success" ? "e-toast-success" : "e-toast-error",
      position: { X: "Right", Y: "Top" },
      // Add more customization as per your need
    };

    this.toastInstance.show(toastModel);
  }
}
