import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, CheckBoxAllModule } from '@syncfusion/ej2-angular-buttons';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { GpoOutletSummaryComponent } from '../gpo-outlet-summary/gpo-outlet-summary.component';

@Component({
  selector: 'app-gpo-summary',
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule,FormsModule,DropDownListModule,ButtonModule,GridAllModule,CheckBoxAllModule,GpoOutletSummaryComponent],
  templateUrl: './gpo-summary.component.html',
  styleUrl: './gpo-summary.component.css'
})
export class GpoSummaryComponent {

  dataSource:any;
  availableGpo=["ONMARK", "UNITY", "ION", "VITALSOURCE"];
  availableCot=["MSPL", "ONCL","Group Practice"];
  availableState=["NY", "OH", "VA", "IL", "AL", "CA", "FL", "CT", "MN", "MD", "TN", "GA", "PA", "CO", "TX", "NV", "AZ"];
  availableType=["Ophthalmology", "Rheumatology", "Hematology Oncology", "Internal Medicine", "Pediatrics", "OB/GYN"];
  displaySummary:boolean=false;

  ngOnInit()
  {
    this.dataSource=[{
      "outletId":4110,"outletName":'WizeKeeper',"outletType":'Oncology',"outletParentName":'softGrow'
    }];

  }

  viewSummary(){
    this.displaySummary=!this.displaySummary;
  }

}
