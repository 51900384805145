import { CommonModule } from "@angular/common";
import { Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { ButtonAllModule } from "@syncfusion/ej2-angular-buttons";
import { CarouselAllModule } from "@syncfusion/ej2-angular-navigations";
import {
  DialogAllModule,
  DialogComponent,
} from "@syncfusion/ej2-angular-popups";

@Component({
  selector: "app-gpo-audit-history",
  standalone: true,
  imports: [CommonModule, ButtonAllModule, CarouselAllModule, DialogAllModule],
  templateUrl: "./gpo-audit-history.component.html",
  styleUrl: "./gpo-audit-history.component.css",
  encapsulation: ViewEncapsulation.None,
})
export class GpoAuditHistoryComponent {
  @ViewChild("auditHistoryDialog")
  auditHistoryDialog!: DialogComponent;
  animationSettings: Object = { effect: "Zoom", duration: 200, delay: 0 };
  visible: Boolean = true;
  dialogWidth: string = "1000px";
  dialogHeight: string = "2000px";
  dimensionModalposition: object = { X: "center", Y: "50" };
  header = "Audit History ";
  showCloseIcon = true;

  public size!: number;
  public displayedItems: any;

  ngOnInit(): void {}

  public productItems: Record<string, string | number>[] = [
    { ID: 1, Name: "Cardinal", imageName: "Pattern_7" },
    { ID: 2, Name: "Kingfisher", imageName: "Pattern_8" },
    { ID: 3, Name: "Keel-billed-toucan", imageName: "Pattern_9" },
    { ID: 4, Name: "Yellow-warbler", imageName: "Pattern_6" },
  ];

  public getImage(pattern: string): string {
    return `https://ej2.syncfusion.com/angular/demos/assets/diagram/patternimages/${pattern}.png`;
  }
}
