import { HttpClientModule } from "@angular/common/http";
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { LayoutComponent } from "./layout/layout.component";
import {
  AccordionModule,
  AppBarAllModule,
  MenuModule,
  TabModule,
  ToolbarModule,
} from "@syncfusion/ej2-angular-navigations";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import {
  ButtonAllModule,
  RadioButtonModule,
  CheckBoxAllModule,
  SwitchModule,
} from "@syncfusion/ej2-angular-buttons";
import { DropDownListAllModule } from "@syncfusion/ej2-angular-dropdowns";
import { TextBoxAllModule } from "@syncfusion/ej2-angular-inputs";
import { Toast, ToastModule } from "@syncfusion/ej2-angular-notifications";
import { LoginComponent } from "./login/login.component";
import { ChartModule } from "@syncfusion/ej2-angular-charts";
import { AuthService } from "./auth.service";
import { LoadingService } from "./layout/loading-Service";
import { delay } from "rxjs";
import { ToastService } from "./common/toast.service";

@Component({
  selector: "app-root",
  standalone: true,
  imports: [
    RouterOutlet,
    LayoutComponent,
    ChartModule,
    LoginComponent,
    HttpClientModule,
    ReactiveFormsModule,
    MenuModule,
    TabModule,
    ToolbarModule,
    TextBoxAllModule,
    HttpClientModule,
    ButtonAllModule,
    RadioButtonModule,
    DropDownListAllModule,
    CommonModule,
    FormsModule,
    AccordionModule,
    CheckBoxAllModule,
    SwitchModule,
    AppBarAllModule,
    ToastModule,
    AccordionModule,
  ],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.css",
  providers: [AuthService],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = "gpo-fe";
  loading: boolean = false;

  @ViewChild('toast')
  public toast!: Toast;


  constructor(private _loading: LoadingService, private toastService: ToastService) {}

  ngOnInit() {
    this.listenToLoading();
  }

  ngAfterViewInit(): void {
    this.toastService.initializeToast(this.toast);
  }


  /**
   * Listen to the loadingSub property in the LoadingService class. This drives the
   * display of the loading spinner.
   */
  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }
}
