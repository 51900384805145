<div class="container-fluid dialog-content">
  <div class="row">
    <div class="col-12">
      <p style="font-size: 14px; font-weight: 400">
        You will lose all the configurations. Are you sure? Please confirm. If
        you hit cancel you will be redirected back to the Export screen
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-primary confirm_btn"
        style="margin-right: 0.5rem"
        (click)="onOkClick()"
      >
        Confirm
      </button>
      <button
        type="button"
        class="btn btn-secondary cancle_btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
    </div>
  </div>
</div>
