import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonAllModule } from '@syncfusion/ej2-angular-buttons';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { DialogAllModule, DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-open-view',
  standalone: true,
  imports: [CommonModule,FormsModule,ReactiveFormsModule,GridAllModule,ButtonAllModule,DialogAllModule],
  templateUrl: './open-view.component.html',
  styleUrl: './open-view.component.css'
})
export class OpenViewComponent {
  @ViewChild('openViewDialog')
  openViewDialog!: DialogComponent;
  animationSettings: Object = { effect: 'Zoom', duration: 200, delay: 0 };
  visible: Boolean = true;
  dialogWidth: string = '800px';
  dialogHeight:string = '400px';
  dimensionModalposition: object = { X: "center", Y: "200" }
  header = "Open View Screen";
  showCloseIcon=true;

  dataSource:any;

  ngOnInit()
  {
    this.dataSource = [{
      "viewName":'username',"viewDescription":'Content',"createdOn":'New Tag',"lastUpdatedOn":'31/05/2021'
    },
    {
      "viewName":'username',"viewDescription":'Content',"createdOn":'New Tag',"lastUpdatedOn":'13/09/2020'
    }]
  }

  cancel()
  {
    this.openViewDialog.hide();
  }
}
