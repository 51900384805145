<div
  class="container-fluid"
  style="
    margin: auto;
    background-color: #dfeff4;
    padding: 1rem;
    border: 1px solid #27d2f8;
    border-radius: 4px;
  "
>
  <h2 style="font-size: 18px; font-weight: 600; margin-bottom: 1em">
    {{
      isUpdateMode ? "Update Snapshot Analysis" : "Create New Snapshot Analysis"
    }}
  </h2>
  <hr />
  <form [formGroup]="snapshotForm" (ngSubmit)="onSubmit()">
    <div class="col-md-7">
      <div class="form-group row">
        <label for="snapshotAnalysisId" class="col-md-3 col-form-label"
          >Snapshot Analysis ID</label
        >
        <div class="col-md-9">
          <input
            style="border-color: #27d2f8"
            type="text"
            class="form-control"
            id="snapshotAnalysisId"
            formControlName="snapshotAnalysisId"
            placeholder="Snapshot Analysis ID"
            readonly
          />
        </div>
      </div>

      <div class="form-group row">
        <label for="name" class="col-md-3 col-form-label"
          >Snapshot Analysis Name</label
        >
        <div class="col-md-9">
          <input
            style="border-color: #27d2f8"
            type="text"
            class="form-control"
            id="name"
            formControlName="name"
            placeholder="Snapshot Analysis Name"
            maxlength="200"
            [ngClass]="{
              'is-invalid':
                (snapshotForm.get('name')?.invalid &&
                  snapshotForm.get('name')?.touched) ||
                (snapshotForm.get('name')?.touched &&
                  snapshotForm.get('name')?.hasError('hasSpecialChars'))
            }"
          />
          <div
            *ngIf="
              (snapshotForm.get('name')?.invalid &&
                snapshotForm.get('name')?.touched) ||
              (snapshotForm.get('name')?.touched &&
                snapshotForm.get('name')?.hasError('hasSpecialChars'))
            "
            class="invalid-feedback"
          >
            <span *ngIf="!snapshotForm.get('name')?.hasError('hasSpecialChars')"
              >Snapshot name is required.</span
            >
            <span *ngIf="snapshotForm.get('name')?.hasError('hasSpecialChars')">
              Snapshot name cannot contain special characters</span
            >
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="snapshotFirstId" class="col-md-3 col-form-label"
          >Select First Snapshot</label
        >
        <div class="col-md-9">
          <ejs-dropdownlist
            cssClass="e-outline"
            id="snapshotFirstId"
            formControlName="snapshotFirstId"
            [dataSource]="filteredSnapshots1"
            [fields]="{ text: 'snapshotName', value: 'id' }"
            placeholder="Select First Snapshot"
            [ngClass]="{
              'is-invalid':
                snapshotForm.get('snapshotFirstId')?.invalid &&
                snapshotForm.get('snapshotFirstId')?.touched
            }"
          ></ejs-dropdownlist>
          <div
            *ngIf="
              snapshotForm.get('snapshotFirstId')?.invalid &&
              snapshotForm.get('snapshotFirstId')?.touched
            "
            class="invalid-feedback"
          >
            First Snapshot is required.
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="snapshotSecondId" class="col-md-3 col-form-label"
          >Select Second Snapshot</label
        >
        <div class="col-md-9">
          <ejs-dropdownlist
            cssClass="e-outline"
            id="snapshotSecondId"
            formControlName="snapshotSecondId"
            [dataSource]="filteredSnapshots2"
            [fields]="{ text: 'snapshotName', value: 'id' }"
            placeholder="Select Second Snapshot"
            [ngClass]="{
              'is-invalid':
                snapshotForm.get('snapshotSecondId')?.invalid &&
                snapshotForm.get('snapshotSecondId')?.touched
            }"
          ></ejs-dropdownlist>
          <div
            *ngIf="
              snapshotForm.get('snapshotSecondId')?.invalid &&
              snapshotForm.get('snapshotSecondId')?.touched
            "
            class="invalid-feedback"
          >
            Second Snapshot is required.
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="model" class="col-md-3 col-form-label">Select Model</label>
        <div class="col-md-9">
          <ejs-dropdownlist
            cssClass="e-outline"
            id="model"
            formControlName="model"
            [dataSource]="snapshotAnalysisModelList"
            [fields]="{ text: 'name', value: 'id' }"
            placeholder="Select Model"
            [ngClass]="{
              'is-invalid':
                snapshotForm.get('model')?.invalid &&
                snapshotForm.get('model')?.touched
            }"
          ></ejs-dropdownlist>
          <div
            *ngIf="
              snapshotForm.get('model')?.invalid &&
              snapshotForm.get('model')?.touched
            "
            class="invalid-feedback"
          >
            Model is required.
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12 offset-md-8">
          <button type="submit" class="btn btn-secondary save_btn me-2">
            {{ snapshotAnalysisId > 0 ? "Update" : "Create" }}
          </button>
          <button
            type="button"
            class="btn btn-secondary back_btn me-2"
            (click)="onBack()"
          >
            Back
          </button>
          <button
            type="button"
            class="btn btn-secondary back_btn"
            (click)="resetForm()"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
