import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  input,
} from "@angular/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
// import { GpoServiceService } from '../service/gpo-service.service';
import { EmitType, createElement } from "@syncfusion/ej2-base";
import {
  DropDownListComponent,
  DropDownListModule,
} from "@syncfusion/ej2-angular-dropdowns";
import { CheckBoxAllModule } from "@syncfusion/ej2-angular-buttons";
import {
  CheckBoxSelectionService,
  ListBox,
  ListBoxAllModule,
  ListBoxComponent,
  MultiSelectModule,
} from "@syncfusion/ej2-angular-dropdowns";
import { SubSink } from "subsink";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { gpoData } from "../model/gpo-data";
import {
  GridAllModule,
  PageSettingsModel,
  GridModule,
  GridComponent,
  ExcelExportProperties
} from "@syncfusion/ej2-angular-grids";
import { UploaderModule } from "@syncfusion/ej2-angular-inputs";
import { Comment } from "@angular/compiler";
import { CommonModule, DatePipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import {
  CheckBoxModule,
  RadioButtonModule,
} from "@syncfusion/ej2-angular-buttons";
import { PredealService } from "../services/predeal.service";
import { GpoService } from "../../gpo.service";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { DialogComponent, DialogModule } from "@syncfusion/ej2-angular-popups";
import { GPOHistory } from "../model/gpo-history";
import { interval, startWith, Subscription, switchMap, takeWhile } from "rxjs";
import { BusinessRuleConfirmationDialogComponent } from "../../../common/business-rule-confirmation-dialog/business-rule-confirmation-dialog.component";
import { BusinessRuleOrderDialogComponent } from "../../../common/business-rule-order-dialog/business-rule-order-dialog.component";
import { BusinessRuleDetailDialogComponent } from "../../../common/business-rule-detail-dialog/business-rule-detail-dialog.component";

import {
  ToastComponent,
  ToastModule,
} from "@syncfusion/ej2-angular-notifications";
import { BusinessRuleSequenceDialogComponent } from "../../../common/business-rule-sequence-dialog/business-rule-sequence-dialog.component";
import { DataManager, Query } from "@syncfusion/ej2-data";

interface businessRule {
  businessRuleId: number;
  businessRuleName: string;
}
interface businessRuleInfo {
  businessRuleId: string;
  businessRuleName: string;
}
@Component({
  selector: "app-gpo-data",
  standalone: true,
  imports: [
    GridAllModule,
    HttpClientModule,
    UploaderModule,
    CommonModule,
    RouterModule,
    DropDownListModule,
    CheckBoxModule,
    RadioButtonModule,
    ReactiveFormsModule,
    FormsModule,
    ConfirmDialogComponent,
    DialogModule,
    ToastModule,
    MultiSelectModule,
    CheckBoxAllModule,
    BusinessRuleConfirmationDialogComponent,
    BusinessRuleOrderDialogComponent,
    BusinessRuleDetailDialogComponent,
    BusinessRuleSequenceDialogComponent,
  ],
  templateUrl: "./gpo-data.component.html",
  styleUrls: ["./gpo-data.component.css"],
  providers: [PredealService, GpoService, CheckBoxSelectionService, DatePipe],
})
export class GpoDataComponent implements OnInit {
  @ViewChild("entityDropdown", { static: true })
  entityDropdown!: DropDownListComponent;

  @ViewChild("delimeters", { static: true })
  delimeters!: DropDownListComponent;

  @ViewChild("ruleDropdown", { static: true })
  ruleDropdown!: DropDownListComponent;

  mode = "CheckBox"; // For MultiSelect
  position = { X: "center", Y: "center" };
  @ViewChild("BatchGrid", { static: false })
  public grid!: GridComponent;
  responseData: any[] = [];
  selectedEntity: string | undefined;
  selectedFile: File | null = null;
  selectedDelimiter: string | undefined;
  selectedFileType: string = "txt";
  dropdownEntity: string[] = [];
  availableAllEntities: string[] = [
    "GPO001 - ION",
    "GPO002 - VITALSOURCE",
    "GPO003 - ONMARK",
    "GPO004 - UNITY",
  ];
  public availableEntities: string[] = this.availableAllEntities;
  public availableRules: string[] = [];
  availableAllRules: businessRule[] = [];
  selectedDataState: string = "Baseline";
  private _subsink = new SubSink();
  public dropArea: HTMLElement;
  public dropElement!: HTMLElement;
  rulesToRun: any[] = [];
  impact: any;
  public showConfirmationDialog: boolean = false;
  gpoGridData!: any[];
  public showGpodetailsDialog: boolean = false;
  public showReorderDialog: boolean = false;
  selectedGpo: any[] = [];
  selectedItems: any[] = [];
  selectedGpoId: any[] = [];
  delimiters = [
    // { text: 'Comma (",")', value: ',' },
    { text: 'Pipe ("|")', value: "|" },
    // { text: 'Tab ("\t")', value: '/t' },
    // { text: 'Space ("\s")', value: '/s' }
  ];

  //public pageSettings: PageSettingsModel = { pageSize: 10, pageSizes: true };

  delimiterFields: Object = { text: "text", value: "value" };

  // public path: Object = {
  //   // saveUrl: 'https://services.syncfusion.com/js/production/api/FileUploader/Save',
  //   // removeUrl: 'https://services.syncfusion.com/js/production/api/FileUploader/Remove'
  // };
  public ele?: HTMLElement;

  gpoForm!: FormGroup; // Define the form group

  gpoData: GPOHistory[] = [];

  isStatus: boolean = false;
  isPending: boolean = false;
  headerAdd: string = "";
  message: string = "";

  tempFileHistoryId: any = 0;

  private subscription!: Subscription;

  @ViewChild("ejDialog") ejDialog!: DialogComponent;
  @ViewChild("confirmationDialog") confirmationDialog!: DialogComponent;
  @ViewChild("deletionDialog") deletionDialog!: DialogComponent;

  @ViewChild("confirmDialog")
  confirmDialog!: DialogComponent;
  animationSettings: Object = { effect: "Zoom", duration: 200, delay: 0 };
  visible: Boolean = true;
  dialogWidth: string = "650px";
  dimensionModalposition: object = { X: "center", Y: "300" };
  header = this.headerAdd;

  @Input()
  item!: GPOHistory;

  @ViewChild("toast")
  toastObj!: ToastComponent;

  selectedItem: any;

  defaultFormValues = {
    fileUpload: null,
    entity: null,
    fileType: "txt",
    dataState: "Baseline",
    delimeters: null,
    ruleId: null,
  };

  public historyFilter?: any = {
    gpo: null,
    cot: null,
    type: null,
    state: null,
  };

  showExecutionSectionPopup = false;
  pendingRuleExecutionFiles: any = [];
  // Define the fields mapping
  public fields: Object = { text: "fileName", value: "fileId" };
  selectedFileForExecution: string = "";

  headerHeight = 517;
  footerHeight = 20;
  paginationHeight = 48;
  rowHeight = 48;
  buffer = 20;
  gridHeight!: string;
  pageSize = 10;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private _gpoService: GpoService,
    private datePipe: DatePipe
  ) {
    this.dropArea = createElement("div", { id: "droparea" });
  }

  ngOnInit(): void {
    this.gpoForm = this.formBuilder.group({
      fileUpload: [this.defaultFormValues.fileUpload, Validators.required], // Add validators as needed
      entity: [this.defaultFormValues.entity, Validators.required],
      fileType: [this.defaultFormValues.fileType, Validators.required],
      dataState: [this.defaultFormValues.dataState, Validators.required],
      delimeters: [this.defaultFormValues.delimeters],
      ruleId: [this.defaultFormValues.ruleId],
    });

    this.fetchRules();

    this.ele = document.getElementById("dropArea") as HTMLElement;
    const browseButton = document.getElementById("browse");
    if (browseButton) {
      browseButton.onclick = () => {
        const fileSelectWrap =
          document.getElementsByClassName("e-file-select-wrap")[0];
        if (fileSelectWrap) {
          const fileSelectButton = fileSelectWrap.querySelector("button");
          if (fileSelectButton instanceof HTMLButtonElement) {
            fileSelectButton.click();
          }
        }
        return false;
      };
    }

    this.initializeDataAndPolling();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.adjustGridHeight();
  }

  private adjustGridHeight() {
    // Calculate the grid height
    let availableHeight =
      window.innerHeight -
      this.headerHeight -
      this.footerHeight -
      this.paginationHeight -
      this.buffer;
    this.gridHeight = `${availableHeight}px`;

    // Calculate page size based on row height
    this.pageSize = Math.floor(availableHeight / this.rowHeight);
  }
  customValidation(formGroup: FormGroup) {
    const delimitersControl = formGroup.get("delimeters");

    if (this.selectedFileType !== "csv") {
      delimitersControl?.setValidators(Validators.required);
    } else {
      delimitersControl?.clearValidators();
    }

    delimitersControl?.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSelectionChange(event: any): void {
    this.selectedEntity = event.target.value;
    // You can perform further actions based on the selected value
  }
  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0];
  }

  onDelimiterChange(selectedDelimiter: string): void {
    this.selectedDelimiter = selectedDelimiter;
  }
  handleDrop(event: DragEvent) {
    event.preventDefault();
    const file = event.dataTransfer?.files[0];
    if (file) {
      this.handleFiles(file);
    }
  }
  handleDragOver(event: DragEvent) {
    event.preventDefault();
  }

  handleFileInput(event: any) {
    const files: FileList = event.target.files;
    if (files.length > 0) {
      this.selectedFile = files[0];
      this.gpoForm.get("fileUpload")?.setValue(this.selectedFile);
    }
  }

  handleFiles(file: File) {
    this.selectedFile = file;
  }

  onFileTypeChange(selectedFileType: string): void {
    this.selectedFileType = selectedFileType;
    this.customValidation(this.gpoForm);
  }

  onDataStateChange(selectedDataState: string): void {
    this.selectedDataState = selectedDataState;
  }

  clearForm(): void {
    this.clearSelectedFile();
    this.gpoForm.reset(this.defaultFormValues);
    this.onFileTypeChange(this.defaultFormValues.fileType);
    this.onDataStateChange(this.defaultFormValues.dataState);
  }

  uploadFile(): void {
    if (!this.selectedFile) {
      this.toastObj.show({
        title: "Warnig!",
        content: "No file selected! Please select file first.",
        cssClass: "e-toast-warning",
        position: { X: "Right", Y: "Top" },
      });
      return;
    } else {
      this.customValidation(this.gpoForm);
    }

    const formData = this.gpoForm.value;
    if (this.gpoForm.valid) {
      const formData = new FormData();
      formData.append("file", this.selectedFile);
      formData.append("entity", this.gpoForm.value.entity);
      formData.append("fileType", this.gpoForm.value.fileType);
      formData.append("dataState", this.gpoForm.value.dataState);

      if (this.selectedFileType !== "csv") {
        formData.append("delimiter", this.gpoForm.value.delimeters);
      } else {
        formData.append("delimiter", ",");
      }

      //for applying rule storing data from form
      const ruleIdNameList: any[] = this.gpoForm.value.ruleId;
      const values: string[] = [];
      // Iterate through each string
      if (ruleIdNameList && ruleIdNameList.length > 0) {
        ruleIdNameList.forEach((str) => {
          const parts = str.split("-");
          if (parts.length >= 2) {
            const value = parts[1].trim();
            values.push(value);
          }
        });
      }

      formData.append("appliedRules", values.toString());

      const entity = this.gpoForm.value.entity;
      const index = entity.indexOf(" - "); // Find the index of ' - ' delimiter
      const gpoId: any[] = [];
      gpoId.push(entity.substring(0, index)); // Get the substring from the start to the index of ' - '
      this.selectedGpoId = gpoId;

      this._gpoService.uploadFile(formData).subscribe({
        next: (response: string[] | any[]) => {
          this.responseData = response;
          this.toastObj.show({
            title: "Success!",
            content: "File uploaded successfully",
            cssClass: "e-toast-success",
            position: { X: "Right", Y: "Top" },
          });
          this.initializeDataAndPolling();
        },
        error: (error: any) => {
          this.toastObj.show({
            title: "Error!",
            content: "Failed to upload file!",
            cssClass: "e-toast-danger",
            position: { X: "Right", Y: "Top" },
          });
        },
      });
      this.clearForm();
    }
    // if (this.gpoForm.invalid) {
    //   this.gpoForm.markAllAsTouched();
    //   return;
    // }
  }

  applyRule() {
    this.showReorderDialog = true;
  }
  async checkImpact(rules: number[]) {
    this.rulesToRun = rules;
    await this._gpoService
      .checkImpact(rules, this.selectedGpo)
      .subscribe((data) => {
        this.impact = data;
        this.openConfirmationDialog();
      });
  }

  openConfirmationDialog() {
    // this.confirmationDialog.show(); // Show the second dialog
    if (this.impact && this.impact.totalRecordsUpdated > 0) {
      this.showConfirmationDialog = true;
    } else {
      this.toastObj.show({
        title: "Warning!",
        content: "Business rule has no impact on any data.",
        cssClass: "e-toast-warning",
        position: { X: "Right", Y: "Top" },
      });
      this.processList();
    }
  }

  onEntitySelectionChange(event: { value: string | undefined }) {
    this.selectedEntity = event.value;
  }
  onRuleSelectionChange(event: { value: string | undefined }) {}
  // fetchEntity(seachText: any) {
  //   this._gpoService
  //     .fetchEntity(this.gpoForm.get("entity")?.value, seachText)
  //     .subscribe({
  //       next: (response: string[] | any[]) => {
  //         this.availableEntities = response;
  //       },
  //       error: (error: any) => {
  //         console.log("Error:" + error);
  //       },
  //     });
  // }

  fetchRules() {
    this._gpoService.getBusinessRuleNamesAndId().subscribe({
      next: (response: businessRule[]) => {
        this.availableAllRules = response;
        this.availableRules = this.availableAllRules.map(
          (option) => option.businessRuleId + " - " + option.businessRuleName
        );
        this.adjustGridHeight();
      },
      error: (error: any) => {
        console.log("Error:" + error);
      },
    });
  }

  async searchEntity(searchText: any) {
    if (searchText && searchText.length > 0) {
      this.availableEntities = this.availableAllEntities.filter((option) => {
        // Example filter criteria: Filter options that contain 'someValue' in the string
        return option.toLowerCase().includes(searchText.toLowerCase());
      });
    } else {
      this.availableEntities = this.availableAllEntities;
    }
  }
  async searchRules(searchText: any) {
    if (searchText && searchText.length > 0) {
      this.availableRules = this.availableAllRules.map(
        (option) => option.businessRuleId + " - " + option.businessRuleName
      );

      this.availableRules = this.availableRules.filter((option) => {
        // Example filter criteria: Filter options that contain 'someValue' in the string
        return option.toLowerCase().includes(searchText.toLowerCase());
      });
    } else {
      this.availableRules = this.availableAllRules.map(
        (option) => option.businessRuleId + " - " + option.businessRuleName
      );
    }
  }
  openDialog(item: GPOHistory) {
    this.headerAdd = "Confirmation for " + item.fileName;
    this.message =
      "Do You want to load validated <" +
      item.fileName +
      ">data into database?";
    this.isStatus = true;
    this.selectedItem = item;
  }

  onClick(status: any) {
    if (this.selectedItem) {
      this.isStatus = false;
      this._gpoService
        .updateGpo(this.selectedItem, status)
        .subscribe((res: any) => {
          this.initializeDataAndPolling();
        });
    }
  }

  cancel(status: any) {
    if (this.selectedItem) {
      this.isStatus = false;
      this._gpoService
        .updateGpo(this.selectedItem, status)
        .subscribe((res: any) => {
          this.processList();
        });
    }
  }

  private initializeDataAndPolling(): void {
    if (!this.showExecutionSectionPopup) {
      this.processList();
      this.setupPollingForPendingItems();
    }
  }

  private processList(): void {
    this._gpoService.getGpoData().subscribe({
      next: async (response: any[]) => {
        // Map each item in the response array and format the date asynchronously
        const formattedItems = await Promise.all(
          response.map(async (item: any) => {
            item.processDate = await new Date(
              this.formatDateInUTC(item.processDate)
            );
            return item;
          })
        );

        // Update gpoData with the formatted items
        this.gpoData = formattedItems;

        const validatedItems = this.gpoData.filter(
          (item) => item.status === "VALIDATED"
        );
        validatedItems.forEach((item) => {
          this.openDialog(item);
        });

        const pendingruleExecutionStatusItems = this.gpoData.filter(
          (item) =>
            item.ruleExecutionStatus === false &&
            item.status === "SUCCESS" &&
            (item.appliedRules != "" || item.appliedRules === undefined)
        );

        if (pendingruleExecutionStatusItems.length > 1) {
          this.pendingRuleExecutionFiles = pendingruleExecutionStatusItems;
          this.showExecutionSectionPopup = true;
        } else {
          if (pendingruleExecutionStatusItems[0]) {
            this.getAllRuleListForBtachUpload(
              pendingruleExecutionStatusItems[0].fileId
            );
          }
        }
        // pendingruleExecutionStatusItems.forEach((item) => {
        //   this.getAllRuleListForBtachUpload(item.fileId);
        // });
      },
      error: (error: any) => {
        console.log("Error:" + error);
      },
    });
  }

  private formatDate(dateString: string): string {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    // Construct the formatted date string in YYYY-MM-DD format
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  selectFileForExecution(event: any) {
    this.selectedFileForExecution = event.itemData.fileId;
  }
  onRuleExecutionClick() {
    this.getAllRuleListForBtachUpload(this.selectedFileForExecution);
    this.closeRuleExecutionDialog();
  }
  closeRuleExecutionDialog() {
    this.showExecutionSectionPopup = false;
  }

  private setupPollingForPendingItems(): void {
    this.subscription?.unsubscribe();
    this.subscription = interval(60000)
      .pipe(
        startWith(0),
        switchMap(() => this._gpoService.getGpoData()), // Corrected: Added closing parenthesis here
        takeWhile(
          () =>
            this.gpoData.some(
              (item) => item.status === "PENDING" || item.status === "APPROVED"
            ),
          true
        )
      )
      .subscribe((latestList: GPOHistory[]) => {
        this.gpoData = latestList;
        this.processList();
      });
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const fileList = event.dataTransfer?.files;
    if (fileList && fileList.length > 0) {
      this.selectedFile = fileList[0];
      this.gpoForm.get("fileUpload")?.setValue(this.selectedFile);
    }
  }

  clearSelectedFile(): void {
    this.selectedFile = null;
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  }

  toggleFullscreen(): void {
    console.log("Calling toggle fullscreen function");
    const grid = document.querySelector(".grid-container");
    if (grid) {
      grid.classList.toggle("fullscreen");
      const closeButton = document.getElementById("fullscreen-close-btn");
      if (closeButton) {
        // console.log(grid.classList.contains("fullscreen"));
        closeButton.style.display = grid.classList.contains("fullscreen")
          ? "block"
          : "none";
      }

      this.headerHeight = 60;
      this.adjustGridHeight();
    }
  }

  exitFullscreen(): void {
    const grid = document.querySelector(".grid-container");
    if (grid) {
      grid.classList.remove("fullscreen");
      const closeButton = document.getElementById("fullscreen-close-btn");
      if (closeButton) {
        closeButton.style.display = "none";
      }
      this.headerHeight = 517;
      this.adjustGridHeight();
    }
  }

  async cvsGenerator() {
    // const data = await this.getHistoryFilteredData("history-csv");
    const csvExportProperties: ExcelExportProperties = {
      fileName: 'batch-export.csv'
    };
    (this.grid as GridComponent).showSpinner();
    (this.grid as GridComponent).csvExport(csvExportProperties);
  }

  async xlxsGenerator() {
    // const data = await this.getHistoryFilteredData("history-xls");
    const excelExportProperties: ExcelExportProperties = {
      fileName: 'batch-export.xlsx'
    };
    (this.grid as GridComponent).showSpinner();
    (this.grid as GridComponent).excelExport(excelExportProperties);
  }
  batchExportComplete(): void {
    (this.grid as GridComponent).hideSpinner();
  }

  getHistoryFilteredData(flag: any) {
    const query = this.grid.getDataModule().generateQuery(true);
    const dataManager = new DataManager(this.gpoData);
    const result = dataManager.executeQuery(query);

    result
      .then((response: any) => {
        let filteredData = response.result;
        console.log(filteredData);

        if (filteredData.length === 0) {
          filteredData = this.gpoData;
        }
        this.downloadFile(filteredData, flag);
      })
      .catch((error: any) => {
        console.error("Error fetching filtered records:", error);
      });
  }

  downloadFile(data: any, flag: any) {
    this._gpoService.downloadFile(data, flag, "FILE");
  }

  gpoDataGrid(data: any) {
    this.gpoGridData = data;
    this.showGpodetailsDialog = true;
  }

  onConfirmation(data: any) {
    console.log("Confirmed");
    this.showConfirmationDialog = false;
    this._gpoService
      .runBusinessRule(this.rulesToRun, this.selectedGpo)
      .subscribe(
        (data) => {
          this.selectedGpo = [];
          this.updateruleExecutionStatus(this.tempFileHistoryId);
          this.toastObj.show({
            title: "Success!",
            content: "Business rule successfully executed.",
            cssClass: "e-toast-success",
            position: { X: "Right", Y: "Top" },
          });
          // Refresh the grid
          if (this.grid) this.grid.refresh();
          this.initializeDataAndPolling();
        },
        (error) => {
          this.toastObj.show({
            title: "Error!",
            content: "Failed to execute business rule",
            cssClass: "e-toast-danger",
            position: { X: "Right", Y: "Top" },
          });
        }
      );
    this.selectedItems = [];
  }

  closeDialog(data: any): void {
    // this.ejDialog.hide(); // This closes the dialog
    this.selectedItems = [];
    this.showReorderDialog = false;
    // this.confirmationDialog.hide(); // This closes the dialog
    this.showConfirmationDialog = false;

    // this.deletionDialog.hide(); // This closes the dialog
  }

  onGpodetailsDialogOk() {
    console.log("onGpodetailsDialogOk");
    this.showGpodetailsDialog = false;
  }

  onGpodetailsDialogClosed(): void {
    // this.ejDialog.hide(); // This closes the dialog
    console.log("onGpodetailsDialogClosed");
    this.showGpodetailsDialog = false;
  }

  openReorderDialog(runSimulationItem: any[]) {
    this.selectedItems = runSimulationItem.map((item) => ({
      ...item,
      DisplayName: `${item.id} - ${item.businessRuleName} - ${item.businessRuleDesc}`,
    }));

    console.log(JSON.stringify(this.selectedItems));
    this.showReorderDialog = true;
    //this.ejDialog.show();
  }

  async onDialogOk(data: any) {
    const result: any[] = data.result;
    const rules: number[] = [];
    result.map((item) => rules.push(item.id));
    console.log("All IDs:", rules);
    this.ejDialog.hide();
    this.showReorderDialog = true;

    await this.checkImpact(rules);
  }
  receiveDataFromDialog(event: any) {
    const result: any[] = event.result.entity;
    const gpos: any[] = [];
    const updatedList: string[] = result.map((item) => {
      const index = item.indexOf(" - "); // Find the index of ' - ' delimiter
      return item.substring(0, index); // Get the substring from the start to the index of ' - '
    });
    updatedList.map((item) => gpos.push(item));
    this.selectedGpo = gpos;
  }

  getAllRuleListForBtachUpload(id: any) {
    this.tempFileHistoryId = id;
    this._gpoService.getAllRuleListForBtachUpload(id).subscribe({
      next: (response: any[]) => {
        if (response.length > 0) {
          this.openReorderDialog(response);
          this.toastObj.show({
            title: "Success!",
            content: "business rule fetched successfully",
            cssClass: "e-toast-success",
            position: { X: "Right", Y: "Top" },
          });
        }
      },
      error: (error: any) => {
        this.toastObj.show({
          title: "Error!",
          content: "Failed to fetched business rule!",
          cssClass: "e-toast-danger",
          position: { X: "Right", Y: "Top" },
        });
      },
    });
  }

  updateruleExecutionStatus(id: any) {
    this._gpoService.updateruleExecutionStatus(id).subscribe({
      next: (response) => {
        this.tempFileHistoryId = 0;
        this.toastObj.show({
          title: "Success!",
          content: "business Rule Execution Status updated successfully",
          cssClass: "e-toast-success",
          position: { X: "Right", Y: "Top" },
        });
        this.processList();
      },
      error: (error: any) => {
        this.toastObj.show({
          title: "Error!",
          content: "Failed to update Rule Execution Status!",
          cssClass: "e-toast-danger",
          position: { X: "Right", Y: "Top" },
        });
        this.processList();
      },
    });
  }

  formatDateInUTC(date: any): any {
    const utcDate = new Date(date);
    return this.datePipe.transform(utcDate, "MM-dd-yyyy", "UTC");
  }
}
