<div class="grid-container">
  <ejs-grid
    #grid
    [dataSource]="data"
    [height]="400"
    [width]="'100%'"
    [toolbar]="toolbar"
    [allowResizing]="true"
    [allowSorting]="true"
    [allowExcelExport]="true"
    (toolbarClick)="toolbarClick($event)"
  >
    <e-columns>
      <ng-container *ngFor="let key of getColumnNames()">
        <e-column
          field="{{ key }}"
          headerText="{{ formatHeader(key) }}"
          width="250"
        ></e-column>
      </ng-container>
    </e-columns>
  </ejs-grid>
</div>
