import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListBox, ListBoxAllModule, ListBoxComponent } from '@syncfusion/ej2-angular-dropdowns';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { GpoRuleDetailsComponent } from '../../layout/gpo/gpo-rule/gpo-rule-details/gpo-rule-details.component';
import { BusinessRuleDetailDialogComponent } from '../business-rule-detail-dialog/business-rule-detail-dialog.component';
import { GpoService } from '../../layout/gpo.service';
import { RuleType } from '../../layout/modal/rule-type';
import { ToastComponent } from '@syncfusion/ej2-angular-notifications';



@Component({
  selector: 'app-business-rule-confirmation-dialog',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ListBoxAllModule, CommonModule, DialogModule, BusinessRuleDetailDialogComponent
],
  templateUrl: './business-rule-confirmation-dialog.component.html',
  styleUrl: './business-rule-confirmation-dialog.component.css'
})
export class BusinessRuleConfirmationDialogComponent {
  
  @ViewChild('listBox') listBox!: ListBox; // Reference to the ListBox component

  @ViewChild('ejDialog') gpodetailsDialog!: DialogComponent;
  public showGpodetailsDialog: boolean = false;

  position = { X: "center", Y: "center" };

  toastObj!: ToastComponent;



  @Input() data!: any;

 newListOrder =  [];

 totalRecordsImpacted :number =0;
 totalDateUpdates : number = 0;
 noOfRules :number = 0;
 totalAttributeUpdates :number = 0;

  @Output() okClick = new EventEmitter();

  @Output() closeClick = new EventEmitter();

  @Output() gpoDataGridEvent = new EventEmitter();


  public constructor(private _gpoService: GpoService){

  }


 

  onOkClick() {
    // Emit some data on OK click
    this.okClick.emit({ result:  this.data });
  }

  closeDialog() {
    // Emit some data on OK click
    this.closeClick.emit({ result: 'Cancel to process execution' });
  }


   public setfield = { text: "DisplayName" };


   preprocessData(): void {
    
      this.totalDateUpdates = this.data.totalDateUpdates; // Assigning item['dateImpactCount'] to this.dateImpactCount
      this.totalRecordsImpacted = this.data.totalRecordsUpdated;
      this.totalAttributeUpdates = this.data.totalAttributeUpdates;
      this.noOfRules = this.data.totalRules;
  }
  isButtonDisabled(): boolean {
    // Return true to disable the button when the condition is not satisfied
    return this.data.totalRecordsUpdated ===0;
  }
   onDrop(args: any) {
    this.data = args.source.currentData;
  }

  onDragStop() {
  //  const newListOrder = this.listBox.getSortedList();
    // Process the new list order here
  }

  onTotalRulesClick(): void {
    console.log('Total Rules Clicked');
    this.gpoDataGridEvent.emit( this.data.rulesSelected );

    // Implement your logic here
  }

  onTotalRecordsUpdatedClick(): void {
    this.showGpodetailsDialog = true
    console.log('Total Records Updated Clicked');

    this._gpoService.getGPOImpactByRuleType(this.data.ruleAndGPO, RuleType.ALL).subscribe({
      next: (response: any) => {
        this.gpoDataGridEvent.emit( response );
      },
      error: (error: Error) =>{
        this.toastObj.show({
          title: "Error!",
          content: "Failed to load impacted data!",
          cssClass: "e-toast-danger",
          position: { X: "Right", Y: "Top" },
        });
      }
    }
      
    )



    // Implement your logic here
  }

  onTotalDateUpdatesClick(): void {
    this.showGpodetailsDialog = true
    console.log('Date Updates Clicked');
    this._gpoService.getGPOImpactByRuleType(this.data.ruleAndGPO, RuleType.DATE).subscribe({
      next: (response: any) => {
        this.gpoDataGridEvent.emit( response );
      },
      error: (error: Error) =>{
        this.toastObj.show({
          title: "Error!",
          content: "Failed to load DATE impacted data!",
          cssClass: "e-toast-danger",
          position: { X: "Right", Y: "Top" },
        });
      }
    });

  }

  onTotalAttributeUpdatesClick(): void {
    this.showGpodetailsDialog = true
    console.log('Attribute Updates Clicked');


    this._gpoService.getGPOImpactByRuleType(this.data.ruleAndGPO, RuleType.ATTRIBUTE).subscribe({
      next: (response: any) => {
        this.gpoDataGridEvent.emit( response );
      },
      error: (error: Error) =>{
        this.toastObj.show({
          title: "Error!",
          content: "Failed to load ATTRIBUTE impacted data!",
          cssClass: "e-toast-danger",
          position: { X: "Right", Y: "Top" },
        });
      }
    });

  }

  showDateImpact(gridData: any[]){
    this.showGpodetailsDialog = true
    this.gpoDataGridEvent.emit( gridData)
  }

  showAttributeImpact(gridData: any[]){
    this.showGpodetailsDialog = true
    this.gpoDataGridEvent.emit( gridData)
  }

 
}
