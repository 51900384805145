import { CommonModule, DatePipe } from "@angular/common";
import { Component, HostListener, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {
  ButtonAllModule,
  CheckBoxAllModule,
  RadioButtonModule,
  SwitchModule,
} from "@syncfusion/ej2-angular-buttons";
import {
  CheckBoxSelectionService,
  DropDownListModule,
  FilteringEventArgs,
  MultiSelectComponent,
  MultiSelectModule,
} from "@syncfusion/ej2-angular-dropdowns";
import { GpoService } from "../../gpo.service";
import {
  GridAllModule,
  GridComponent,
  RowDataBoundEventArgs,
  RowSelectEventArgs,
  SelectionSettingsModel,
} from "@syncfusion/ej2-angular-grids";
import {
  DatePickerModule,
  RenderDayCellEventArgs,
} from "@syncfusion/ej2-angular-calendars";
import {
  ToastComponent,
  ToastModule,
} from "@syncfusion/ej2-angular-notifications";
import { DialogComponent, DialogModule } from "@syncfusion/ej2-angular-popups";
import { DataManager, Query } from "@syncfusion/ej2-data";

interface RowData {
  [key: string]: any; // Allow additional properties
}

@Component({
  selector: "app-gpo-merge",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropDownListModule,
    ButtonAllModule,
    RadioButtonModule,
    GridAllModule,
    CheckBoxAllModule,
    MultiSelectModule,
    DatePickerModule,
    FormsModule,
    SwitchModule,
    ToastModule,
    DialogModule,
  ],
  providers: [CheckBoxSelectionService, DatePipe],
  templateUrl: "./gpo-merge.component.html",
  styleUrl: "./gpo-merge.component.css",
})
export class GpoMergeComponent {
  @ViewChild("toast")
  toastObj!: ToastComponent;
  @ViewChild("selectOutletDropdown")
  outletsMultiselect!: MultiSelectComponent;
  @ViewChild("outletGrid")
  outletGrid!: GridComponent;
  @ViewChild("mergeListGrid")
  mergeListGrid!: GridComponent;
  @ViewChild("mergeConfirmation")
  mergeConfirmation!: DialogComponent;
  @ViewChild("mergeCancellation")
  mergeCancellation!: DialogComponent;
  @ViewChild("mergeApply")
  mergeApply!: DialogComponent;
  @ViewChild("mergeClearAll")
  mergeClearAll!: DialogComponent;
  @ViewChild("summaryGrid")
  summaryGrid!: GridComponent;

  public outletFilter?: any = {
    gpo: null,
    cot: null,
    type: null,
    state: null,
  };

  public mergeOutletForm!: FormGroup;
  public dataSource: any;
  public outletDatasource: any;
  public mode?: string;
  public outletFields?: object;
  public outletParentFields?: object;
  public gpoFields?: object;
  public mergeType?: string = "outlet-to-parent";
  public availableOutlet: any;
  public availableGpo: any;
  public availableOutletParent: any;
  public popupHeight: string = "250px";
  public searchedOutlets: any;
  public selectionOptions?: SelectionSettingsModel;
  public mergeList: any = [];
  public selectedRecords: any[] = [];
  public formatoptions?: Object;
  public summaryOfOutletChanges: RowData[] = [];
  public selectedMergeWith: any;
  public setEndDateSwitch: boolean = false;
  public today: Date = new Date();
  public minToDate: any = this.today;
  public isOutletAvailable: boolean = false;
  public dateFileds: string[] = [
    "outletGpoStartDate",
    "outletGpoEndDate",
    "outletRebateEligibleStartDate",
    "outletRebateEligibleEndDate",
    "outletAnalysisStartDate",
    "outletAnalysisEndDate",
    "outletParentGpoStartDate",
    "outletParentGpoEndDate",
    "outletParentStartDate",
    "outletParentEndDate",
    "outletGrandparentGpoStartDate",
    "outletGrandparentGpoEndDate",
    "activeFromDate",
    "activeTillDate",
    "recordCreatedDate",
    "lastUpdateDate",
  ];

  headerHeight = 490;
  footerHeight = 20;
  paginationHeight = 48;
  rowHeight = 48;
  buffer = 20;

  gridHeight!: string;
  pageSize: number = 5;

  constructor(
    private formBuilder: FormBuilder,
    private _gpoService: GpoService,
    private datePipe: DatePipe
  ) {
    this.mode = "CheckBox";
    this.outletFields = { text: "outletName", value: "id" };
    this.gpoFields = { text: "gpoOutlet", value: "id" };
    this.outletParentFields = { text: "parentOutlet", value: "id" };
    this.selectionOptions = { type: "Multiple" };
    this.formatoptions = { type: "date", format: "MM-dd-yyyy" };
  }

  ngOnInit() {
    this.mergeOutletForm = this.formBuilder.group({
      mergeType: new FormControl("", Validators.required),
      searchOutlet: new FormControl("", Validators.required),
      mergeWithDropdown: new FormControl(
        { value: "", disabled: true },
        Validators.required
      ),
      effectiveFromDate: new FormControl(
        { value: "", disabled: false },
        Validators.required
      ),
      effectiveToDate: new FormControl(
        { value: "", disabled: false },
        Validators.required
      ),
    });

    this.mergeOutletForm
      .get("effectiveFromDate")
      ?.valueChanges.subscribe((selectedDate: Date) => {
        this.minToDate = selectedDate;
        this.mergeOutletForm.get("effectiveToDate")?.updateValueAndValidity();
      });

    this.getAllGpos();
    this.adjustGridHeight();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.adjustGridHeight();
  }

  private adjustGridHeight() {
    // Calculate the grid height
    let availableHeight =
      window.innerHeight -
      this.headerHeight -
      this.footerHeight -
      this.paginationHeight -
      this.buffer;
    this.gridHeight = `${availableHeight}px`;

    // Calculate page size based on row height
    this.pageSize = Math.floor(availableHeight / this.rowHeight);
  }

  onOutletParentFiltering(e: FilteringEventArgs): void {
    const query = new Query();
    // Frame the query based on search string with "contains" filter.
    query.where("parentOutlet", "contains", e.text, true);
    // Pass the filter data source, filter query to updateData method.
    e.updateData(this.availableOutletParent, query);
  }

  onOutletGpoFiltering(e: FilteringEventArgs): void {
    const query = new Query();
    // Frame the query based on search string with "contains" filter.
    query.where("gpoOutlet", "contains", e.text, true);
    // Pass the filter data source, filter query to updateData method.
    e.updateData(this.availableGpo, query);
  }

  isFormValid(): boolean {
    if (
      this.mergeList.length > 0 &&
      this.selectedMergeWith &&
      this.mergeType &&
      this.mergeOutletForm.get("effectiveFromDate")?.value &&
      this.mergeOutletForm.get("effectiveToDate")?.value
    ) {
      return true;
    } else {
      return false;
    }
  }
  getAllGpos() {
    this._gpoService.getAllGpoList(this.outletFilter).subscribe(
      (data: any) => {
        //this.dataSource = data;
        this.dataSource = data.filter(
          (record: any) => record.outletStatus.toUpperCase() === "ACTIVE"
        );
        this.dataSource.forEach((item: any) => {
          this.dateFileds.forEach((field: string) => {
            if (item[field]) {
              item[field] = new Date(this.formatDateInUTC(item[field]));
            }
          });
        });

        this.toastObj.show({
          title: "Sucess!",
          content: "GPOs are fetched successfully!",
          cssClass: "e-toast-success",
          position: { X: "Right", Y: "Top" },
        });
        this.getGpos();
        this.getOutletParents();
      },
      (error: any) => {
        this.toastObj.show({
          title: "Error!",
          content: "Failed to load GPOs.",
          cssClass: "e-toast-danger",
          position: { X: "Right", Y: "Top" },
        });
      }
    );
  }
  getGpos() {
    this._gpoService.getGpos().subscribe(
      (data: any) => {
        this.availableGpo = data
          .filter((item: any) => item.key && item.key.trim() !== "")
          .map((item: any) => ({
            gpoOutlet: item.key + " - " + item.value,
            id: item.key,
          }));
      },
      (error: any) => {
        this.toastObj.show({
          title: "Error!",
          content: "Failed to load GPOs.",
          cssClass: "e-toast-danger",
          position: { X: "Right", Y: "Top" },
        });
      }
    );
  }
  getOutletParents() {
    this._gpoService.getOutletParents().subscribe(
      (data: any) => {
        this.availableOutletParent = data
          .filter((item: any) => item.key && item.key.trim() !== "")
          .map((item: any) => ({
            parentOutlet: item.key + " - " + item.value,
            id: item.key,
          }));
      },
      (error: any) => {
        this.toastObj.show({
          title: "Error!",
          content: "Failed to load Outlet Parents.",
          cssClass: "e-toast-danger",
          position: { X: "Right", Y: "Top" },
        });
      }
    );
  }
  onOutletRowSelected(args: RowSelectEventArgs): void {
    const currentSelected = this.outletGrid.getSelectedRecords();
    currentSelected.forEach((record: any) => {
      if (
        !this.mergeList.find((item: any) => item.outletId === record.outletId)
      ) {
        this.mergeList.push(record);
      }
    });
    if (this.mergeListGrid) {
      this.mergeListGrid.dataSource = this.mergeList;
      this.mergeListGrid.refresh();
    }
    this.isOutletSelected();
  }
  searchOutlets(event: KeyboardEvent) {
    let query = (event.target as HTMLInputElement).value.toLowerCase();
    if (query == "") {
      this.searchedOutlets = [];
      this.isOutletAvailable = false;
      return;
    } else {
      this.isOutletAvailable = true;
    }
    this.searchedOutlets = this.dataSource.filter((item: any) => {
      return (
        (item["outletId"] as string).toLowerCase().includes(query) ||
        (item["outletName"] as string).toLowerCase().includes(query) ||
        (item["outletCot"] as string).toLowerCase().includes(query) ||
        (item["outletCotSubtype"] as string).toLowerCase().includes(query)
      );
    });
  }
  switchChanged(event: any) {
    this.setEndDateSwitch = event.checked;
    if (this.setEndDateSwitch) {
      this.mergeOutletForm
        .get("effectiveToDate")
        ?.setValue(this.formatDateInUTC("9999-12-31"));
      this.mergeOutletForm.get("effectiveToDate")?.clearValidators();
      this.mergeOutletForm.get("effectiveToDate")?.updateValueAndValidity();
      this.mergeOutletForm.get("effectiveToDate")?.disable();
    } else {
      this.mergeOutletForm.get("effectiveToDate")?.enable();
      this.mergeOutletForm.get("effectiveToDate")?.setValue("");
    }
  }
  mergeTypeChanged(event: any) {
    this.mergeType = event.value;
    this.switchChanged({ checked: false });
    this.mergeOutletForm.get("mergeWithDropdown")?.reset();

    this.isFormValid();
  }
  removeOutletById(id: any): void {
    this.mergeList = this.mergeList.filter((item: any) => item.outletId !== id);
    if (this.mergeListGrid) {
      this.mergeListGrid.dataSource = this.mergeList;
      this.mergeListGrid.refresh();
    }
    this.removeOutletGridSelection();
    this.isOutletSelected();
  }
  isOutletSelected(): void {
    if (this.mergeList.length > 0) {
      this.mergeOutletForm.get("mergeWithDropdown")?.enable();
    } else {
      this.mergeOutletForm.get("mergeWithDropdown")?.disable();
      this.mergeOutletForm.get("mergeWithDropdown")?.reset();
    }
    this.isFormValid();
  }
  mergeWithSelected(event: any) {
    if (event && event.itemData) {
      this.selectedMergeWith = event.itemData;
    }

    this.mergeOutletForm.get("searchOutlet")?.reset();
    this.searchedOutlets = [];
    this.isOutletAvailable = false;
    if (this.outletGrid) {
      this.outletGrid.refresh();
    }
    this.isFormValid();
  }
  onOverlayClick() {
    this.closeMergeConfirmationModal();
    this.closeMergeCancellationDialog();
    this.closeMergeApplyModal();
    this.closeMergeClearAllModal();
  }
  closeMergeClearAllModal() {
    if (this.mergeConfirmation) {
      this.mergeClearAll.hide();
    }
  }
  closeMergeConfirmationModal() {
    if (this.mergeConfirmation) {
      this.mergeConfirmation.hide();
    }
  }
  closeMergeCancellationDialog() {
    if (this.mergeCancellation) {
      this.mergeCancellation.hide();
    }
  }
  closeMergeApplyModal() {
    if (this.mergeApply) {
      this.mergeApply.hide();
    }
  }
  mergeCancellationAction(data: boolean) {
    if (data) {
      this.closeMergeCancellationDialog();
      this.resetPage();
    } else {
      this.closeMergeCancellationDialog();
    }
  }
  mergeConfirmationAction(data: boolean) {
    if (data) {
      this.closeMergeConfirmationModal();
      if (this.isFormValid()) {
        this.calculateChanges();
      } else {
        this.toastObj.show({
          title: "Error!",
          content:
            "Please select at least one outlet and a Parent/GPO outlet to proceed with the merge.",
          cssClass: "e-toast-danger",
          position: { X: "Right", Y: "Top" },
        });
      }
    } else {
      this.closeMergeConfirmationModal();
    }
  }
  mergeClearAllAction(data: boolean) {
    if (data) {
      this.closeMergeClearAllModal();
      this.resetPage();
    } else {
      this.closeMergeClearAllModal();
    }
  }
  mergeApplyAction(data: boolean) {
    if (data) {
      this.closeMergeApplyModal();
      this.applyMerge();
    } else {
      this.closeMergeApplyModal();
    }
  }
  removeAllFromMergeList() {
    if (this.mergeListGrid) {
      this.mergeListGrid.dataSource = [];
      this.mergeList = [];
      this.mergeListGrid.refresh();
    }
    this.removeOutletGridSelection();
  }
  removeOutletGridSelection() {
    if (this.outletGrid) {
      this.outletGrid.clearSelection();
    }
  }
  openMergeCancellationDialog() {
    this.mergeCancellation.show();
  }
  openMergeConfirmationDialog() {
    this.mergeConfirmation.show();
  }
  openMergeApplyDialog() {
    this.mergeApply.show();
  }
  openMergeClearAllDialog() {
    this.mergeClearAll.show();
  }

  getMergewithValues() {
    return this.mergeType === "outlet-to-parent" &&
      this.selectedMergeWith.parentOutlet
      ? this.selectedMergeWith.parentOutlet.split("-")
      : this.selectedMergeWith.gpoOutlet.split("-");
  }
  calculateChanges() {
    let outletIds = this.mergeList.map((item: any) => item.outletId);
    const [id, value] = this.getMergewithValues();
    const outletParentStartDate =
      this.mergeOutletForm.get("effectiveFromDate")?.value;
    const outletParentEndDate =
      this.mergeOutletForm.get("effectiveToDate")?.value;
    if (this.mergeType === "outlet-to-gpo") {
      const newVersion = this.dataSource
        .filter((item: any) => outletIds.includes(item.outletId))
        .map((item: any) => {
          return {
            gpoNewId: id.trim(),
            gpoNewName: value.trim(),
            outletId: item.outletId,
            outletParentNewType: item.outletParentType,
            outletNewParentId: item.outletParentId,
            outletNewParentName: item.outletParentName,
            outletNewParentStartDate: outletParentStartDate,
            outletNewParentEndDate: outletParentEndDate,
          };
        });

      this.summaryOfOutletChanges = this.mergeList.map((mergeItem: any) => {
        const newItem = newVersion.find(
          (item: any) => item.outletId === mergeItem.outletId
        );
        return {
          gpoId: mergeItem.gpoId,
          gpoName: mergeItem.gpoName,
          outletParentType: mergeItem.outletParentType,
          outletParentId: mergeItem.outletParentId,
          outletParentName: mergeItem.outletParentName,
          outletParentStartDate: mergeItem.outletParentStartDate,
          outletParentEndDate: mergeItem.outletParentEndDate,

          gpoNewId: newItem ? newItem.gpoNewId : null,
          gpoNewName: newItem ? newItem.gpoNewName : null,
          outletParentNewType: newItem ? newItem.outletParentNewType : null,
          outletNewParentId: newItem ? newItem.outletNewParentId : null,
          outletNewParentName: newItem ? newItem.outletNewParentName : null,
          outletNewParentStartDate: newItem
            ? newItem.outletNewParentStartDate
            : null,
          outletNewParentEndDate: newItem
            ? newItem.outletNewParentEndDate
            : null,
        };
      });
    } else {
      this._gpoService
        .getOutletParentDetails(id.trim(), value.trim())
        .subscribe(
          (data: any) => {
            const parent = data;
            this.summaryOfOutletChanges = this.mergeList.map(
              (mergeItem: any) => {
                return {
                  gpoId: mergeItem.gpoId,
                  gpoName: mergeItem.gpoName,
                  outletParentType: mergeItem.outletParentType,
                  outletParentId: mergeItem.outletParentId,
                  outletParentName: mergeItem.outletParentName,
                  outletParentStartDate: mergeItem.outletParentStartDate,
                  outletParentEndDate: mergeItem.outletParentEndDate,

                  gpoNewId: mergeItem.gpoId,
                  gpoNewName: mergeItem.gpoName,
                  outletParentNewType: parent ? parent.outletParentType : null,
                  outletNewParentId: parent ? parent.outletParentId : null,
                  outletNewParentName: parent ? parent.outletParentName : null,
                  outletNewParentStartDate: outletParentStartDate,
                  outletNewParentEndDate: outletParentEndDate,
                };
              }
            );
          },
          (error: any) => {
            this.toastObj.show({
              title: "Error!",
              content: "Failed to load selected Parent outlet details.",
              cssClass: "e-toast-danger",
              position: { X: "Right", Y: "Top" },
            });
          }
        );
      this.headerHeight = 500;
      this.adjustGridHeight();
    }
  }
  rowDataBound(args: RowDataBoundEventArgs): void {
    const columnsToCompare = [
      { current: "gpoId", new: "gpoNewId" },
      { current: "gpoName", new: "gpoNewName" },
      { current: "outletParentType", new: "outletParentNewType" },
      { current: "outletParentId", new: "outletNewParentId" },
      { current: "outletParentName", new: "outletNewParentName" },
      { current: "outletParentStartDate", new: "outletNewParentStartDate" },
      { current: "outletParentEndDate", new: "outletNewParentEndDate" },
    ];
    if (args.data) {
      columnsToCompare.forEach((columnPair) => {
        const currentField = columnPair.current;
        const newField = columnPair.new;

        // Accessing dynamically with correct type assertion
        const currentValue = (args.data as RowData)[currentField];
        const newValue = (args.data as RowData)[newField];

        if (currentValue !== newValue) {
          // Highlight the new cell
          if (args.data && args.row) {
            const newCellIndex = this.getColumnIndex(args.data, newField);
            const newCell = args.row.querySelector(
              `[aria-colindex="${newCellIndex}"]`
            );
            if (newCell) {
              newCell.classList.add("highlight-difference");
            }
          }
        }
      });
    }
  }

  private getColumnIndex(data: any, field: string): number {
    return Object.keys(data).indexOf(field) + 1;
  }
  applyMerge() {
    const [id, value] = this.getMergewithValues();
    const payload = {
      mergeType: this.mergeType,
      mergeWith: {
        key: id.trim(),
        value: value.trim(),
      },
      summaryOfOutletChanges: this.updateDateFormats(),
      effectiveFromDate: this.formatDate(
        this.mergeOutletForm.get("effectiveFromDate")?.value
      ),
      effectiveToDate: this.formatDate(
        this.mergeOutletForm.get("effectiveToDate")?.value
      ),
    };

    console.log("Final Payload", payload);
    this._gpoService.applyMerge(payload).subscribe(
      (data: any) => {
        if (data.success) {
          this.toastObj.show({
            title: "Success!",
            content: "Merge applied successfully!",
            cssClass: "e-toast-success",
            position: { X: "Right", Y: "Top" },
          });
          this.resetPage();
        } else {
          this.toastObj.show({
            title: "Error!",
            content: "Failed to apply merge." + data.message,
            cssClass: "e-toast-danger",
            position: { X: "Right", Y: "Top" },
          });
        }
      },
      (error: any) => {
        this.toastObj.show({
          title: "Error!",
          content: "Failed to apply merge.",
          cssClass: "e-toast-danger",
          position: { X: "Right", Y: "Top" },
        });
      }
    );
  }
  updateDateFormats() {
    this.mergeList.forEach((item: any) => {
      this.dateFileds.forEach((field: string) => {
        if (item[field]) {
          item[field] = this.formatDate(item[field]);
        }
      });
    });
    return this.mergeList;
  }
  resetPage() {
    this.mergeOutletForm.reset();
    this.mergeList = [];
    this.searchedOutlets = [];
    this.summaryOfOutletChanges = [];
    this.selectedMergeWith = "";

    this.switchChanged({ checked: false });

    this.mergeOutletForm.get("mergeWithDropdown")?.setValue("");
    this.mergeOutletForm.get("mergeWithDropdown")?.disable();
    this.mergeOutletForm.get("effectiveToDate")?.enable();
    this.mergeOutletForm.get("mergeType")?.setValue("outlet-to-parent");
    this.mergeType = "outlet-to-parent";

    this.removeOutletGridSelection();
    this.isOutletSelected();
    this.getAllGpos();
    if (this.mergeListGrid) {
      this.mergeListGrid.refresh();
    }
    if (this.outletGrid) {
      this.outletGrid.refresh();
    }
  }
  formatDate(dateString: string): string {
    if (dateString === null || dateString === undefined || dateString === "") {
      return "";
    }
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = date.getUTCDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  onRenderDayCell(args: RenderDayCellEventArgs): void {
    if (args.date?.getDay() === 0 || args.date?.getDay() === 6) {
      args.isDisabled = true;
    }
  }

  async cvsGenerator() {
    // const data = await this.getSummaryFilteredData("summary-csv");
    (this.summaryGrid as GridComponent).showSpinner();
    (this.summaryGrid as GridComponent).csvExport();
  }

  async xlxsGenerator() {
    // const data = await this.getSummaryFilteredData("summary-xls");
    (this.summaryGrid as GridComponent).showSpinner();
    (this.summaryGrid as GridComponent).excelExport();
  }
  mergeExportComplete(): void {
    (this.summaryGrid as GridComponent).hideSpinner();
  }

  selectAllOutlets() {
    const query = this.outletGrid.getDataModule().generateQuery(true);
    const dataManager = new DataManager(this.searchedOutlets);
    const result = dataManager.executeQuery(query);

    result
      .then((response: any) => {
        let filteredData = response.result;
        console.log(filteredData);

        if (filteredData.length === 0) {
          filteredData = this.searchedOutlets;
        }

        filteredData.forEach((item: any) => {
          if (
            !this.mergeList.find(
              (mergeItem: any) => mergeItem.outletId === item.outletId
            )
          ) {
            this.mergeList.push(item);
          }
          this.mergeListGrid.refresh();
        });

        this.isOutletSelected();
      })
      .catch((error: any) => {
        console.error("Error fetching filtered records:", error);
      });
  }

  getSummaryFilteredData(flag: any) {
    const query = this.summaryGrid.getDataModule().generateQuery(true);
    const dataManager = new DataManager(this.summaryOfOutletChanges);
    const result = dataManager.executeQuery(query);

    result
      .then((response: any) => {
        let filteredData = response.result;
        console.log(filteredData);

        if (filteredData.length === 0) {
          filteredData = this.summaryOfOutletChanges;
        }
        this.downloadFile(filteredData, flag);
      })
      .catch((error: any) => {
        console.error("Error fetching filtered records:", error);
      });
  }

  downloadFile(data: any, flag: any) {
    this._gpoService.downloadFile(data, flag, "MERGE");
  }

  formatDateInUTC(date: any): any {
    const utcDate = new Date(date);
    return this.datePipe.transform(utcDate, "MM-dd-yyyy", "UTC");
  }
}
