import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ToastData } from "./toast";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  private _toastMessageSource = new Subject<ToastData>();
  toastMessage$ = this._toastMessageSource.asObservable();

  constructor() {}

  showSuccessToast(message: string) {
    let data = {
      title: "Success !",
      content: message,
      cssClass: "e-toast-success",
    };
    this._toastMessageSource.next(data);
  }

  showInfoToast(message: string) {
    let data = {
      title: "Information !",
      content: message,
      cssClass: "e-toast-info",
    };
    this._toastMessageSource.next(data);
  }

  showWarningToast(message: string) {
    let data = {
      title: "Warning !",
      content: message,
      cssClass: "e-toast-warning",
    };
    this._toastMessageSource.next(data);
  }

  showErrorToast(message: string) {
    let data = {
      title: "Error !",
      content: message,
      cssClass: "e-toast-danger",
    };
    this._toastMessageSource.next(data);
  }
}
