import { CommonModule, DatePipe } from "@angular/common";
import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import {
  ButtonAllModule,
  CheckBoxAllModule,
} from "@syncfusion/ej2-angular-buttons";
import { DatePickerModule } from "@syncfusion/ej2-angular-calendars";
import {
  CheckBoxSelectionService,
  DropDownListAllModule,
  MultiSelectAllModule,
} from "@syncfusion/ej2-angular-dropdowns";
import {
  GridAllModule,
  GridComponent,
  GridModule,
  SelectionSettingsModel,
} from "@syncfusion/ej2-angular-grids";
import { TextBoxAllModule } from "@syncfusion/ej2-angular-inputs";
import { LocalDatePipe } from "../../../common/pipes/local-date.pipe";
import { GPO } from "../model/gpo";
import { SearchSnapshot } from "../model/snapshot-search";
import { GpoService } from "../../gpo.service";
import { formatDate } from "@angular/common";
import {
  DialogModule,
  DialogComponent,
  TooltipModule,
} from "@syncfusion/ej2-angular-popups";
import { SnapshotDetailsComponent } from "./snapshot-details/snapshot-details.component";
import { Router } from "@angular/router";
import { SnapshotDeleteDialogComponent } from "../../../common/snapshot-delete-dialog/snapshot-delete-dialog.component";
import { ToastService } from "../../../common/toast.service";
@Component({
  selector: "app-gpo-point-in-time",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DropDownListAllModule,
    ButtonAllModule,
    TextBoxAllModule,
    DatePickerModule,
    DialogModule,
    CheckBoxAllModule,
    GridAllModule,
    GridModule,
    SnapshotDeleteDialogComponent,
    MultiSelectAllModule,
    TooltipModule,
  ],
  providers: [CheckBoxSelectionService, LocalDatePipe, DatePipe],

  templateUrl: "./gpo-point-in-time.component.html",
  styleUrl: "./gpo-point-in-time.component.css",
})
export class GpoPointInTimeComponent implements OnInit {
  searchForm!: FormGroup;
  snapshots: any[] = [];
  gpoOptions: GPO[] = []; // Example GPOs
  mode = "CheckBox"; // For MultiSelect
  public selectionOptions!: SelectionSettingsModel;
  public selectedRows: any[] = [];
  snapshotToDelete!: any;
  allDataSource: any[] = [];
  @ViewChild("grid", { static: false })
  public grid!: GridComponent;
  snapshotList: any[] = [];
  public pageSizeOptions: number[] = [5, 10, 20]; // Define available page size options
  public selectedPageSize: number = 5; // Default page size
  deleteDialogData = {
    title: "Confirm Snapshot Delete",
    content: "Confirmation Dialog Content",
  };
  public showDeleteDialog: boolean = false;

  @ViewChild("deleteDialog") deleteDialog!: DialogComponent;
  dialogData = { title: "Dialog Title", content: "Dialog Content" };

  @ViewChild("detailsDialog") detailsDialog!: DialogComponent;
  gpoDetails: any[] = [];
  position = { X: "center", Y: "center" };
  public formatoptions?: Object;
  public filterOptions: Object = { type: "Excel" };
  public selectedSnapshot: any;

  headerHeight = 387;
  footerHeight = 20;
  paginationHeight = 48;
  rowHeight = 48;
  buffer = 20;
  gridHeight!: string;
  pageSize = 10;

  constructor(
    private fb: FormBuilder,
    private gpoService: GpoService,
    private localDatePipe: LocalDatePipe,
    private router: Router,
    private toastService: ToastService,
    private datePipe: DatePipe
  ) {
    this.formatoptions = { type: "date", format: "MM-dd-yyyy" };
  }

  public updatePageSize(): void {
    this.grid.pageSettings.pageSize = this.selectedPageSize; // Update pageSize property of the ejs-grid
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.adjustGridHeight();
  }

  private adjustGridHeight() {
    // Calculate the grid height
    let availableHeight =
      window.innerHeight -
      this.headerHeight -
      this.footerHeight -
      this.paginationHeight -
      this.buffer;
    this.gridHeight = `${availableHeight}px`;

    // Calculate page size based on row height
    this.pageSize = Math.floor(availableHeight / this.rowHeight);
  }
  search(): void {
    console.log(JSON.stringify(JSON.stringify(this.convertToServerData())));
    this.gpoService
      .getSnapshotDataByFilters(this.convertToServerData())
      .subscribe({
        next: (response: string | any) => {
          this.snapshotData = response.map((snapshot: any) => {
            const gpoList = JSON.parse(snapshot.gpoList);
            const gpoNames = gpoList.map((gpo: any) => gpo.gpoName);
            return { ...snapshot, gpoList: gpoNames };
          });
          this.snapshotData = this.convertDates();
          console.log("Modified Snapshot Data:", this.snapshotData);
        },
        error: (error: any) => {
          console.log("Error:" + error);
        },
      });
  }
  get selectedGPOs(): any[] {
    if (
      !this.gpoDetails ||
      !Array.isArray(this.gpoDetails) ||
      !this.searchForm.value.selectedGPOs
    ) {
      return [];
    }

    return this.gpoDetails.filter((option) =>
      this.searchForm.value.selectedGPOs.includes(option.gpoId)
    );
  }

  convertToServerData(): any {
    // Example conversion, adjust based on actual requirements
    const convertedData: any = {
      snapshotName:
        this.searchForm.get("snapshotName")?.value == "All"
          ? null
          : this.searchForm.get("snapshotName")?.value,
      gpoList: this.selectedGPOs,
      startDate: this.localDatePipe.transform(
        this.searchForm?.get("startDate")?.value
      ), // Assuming the server expects 'gpoList' instead of 'selectedGPO'
      endDate: this.localDatePipe.transform(
        this.searchForm?.get("endDate")?.value
      ),
      // Perform any other necessary transformations here
    };

    Object.keys(convertedData).forEach((key) => {
      if (
        typeof convertedData[key] === "string" &&
        convertedData[key].trim() === ""
      ) {
        delete convertedData[key];
      }
    });

    return convertedData;
  }

  resetForm(): void {
    this.searchForm.reset();
  }

  availableSnapshot = ["snapshot1", "snapshot2"];
  availableGpo = ["ONMARK", "UNITY", "ION", "VITALSOURCE"];
  dataSource: any;
  snapshotData: any;
  snapshotFilterData: any;

  ngOnInit() {
    // this.search()
    this.selectionOptions = { type: "Multiple", checkboxOnly: true };
    this.fetchSnapshotData();
    this.fetchGpoDetails();

    this.searchForm = this.fb.group({
      snapshotName: "",
      selectedGPOs: [],
      startDate: "",
      endDate: "",
    });

    this.getDynamicData();
  }

  createSnapshotPage() {
    this.router.navigate(["/home/point-in-time/snapshot"]);
  }

  onConfirmation(data: any) {
    if (!this.selectedRows || this.selectedRows.length === 0) {
      console.warn("No snapshots selected for deletion.");
      return;
    }

    const snapshotIds = this.selectedRows.map((row: any) => row.id); // Extract IDs from selected rows
    console.log("Selected snapshot IDs:", snapshotIds);

    // Call the API to delete the snapshots (assuming gpoService.deleteSnapshots accepts an array of IDs)
    this.gpoService.deleteSnapshot(snapshotIds).subscribe(
      (data) => {
        this.toastService.showToast(
          "Success",
          "Snapshot deleted successfully.",
          "success"
        );

        setTimeout(() => {
          this.getDynamicData();
          this.selectedRows = [];
        }, 3000);
      },
      (error) => {
        this.toastService.showToast(
          "Error",
          "Failed to delete snapshot!",
          "error"
        );
      }
    );

    this.showDeleteDialog = false;
  }

  closeDialog(data: any): void {
    this.showDeleteDialog = false;
  }

  deleteSingleSnapshot(snapshot: any) {
    this.selectedRows = [];

    // Add the provided ID to the selected rows
    this.selectedRows.push(snapshot);
    this.showDeleteDialog = true;
  }

  deleteSnapshot(selectedRows: any[]) {
    this.showDeleteDialog = true;
  }

  getDynamicData() {
    this.gpoService.getSnapshotData().subscribe((data) => {
      this.allDataSource = data;
      this.snapshotData = data.map((snapshot: any) => {
        const gpoList = JSON.parse(snapshot.gpoList);
        const gpoNames = gpoList.map((gpo: any) => gpo.gpoName);
        return { ...snapshot, gpoList: gpoNames };
      });
      this.convertDates();
    });
  }

  onRowSelected(event: any): void {
    if (event.isHeaderCheckboxClicked) {
      this.selectedRows = this.snapshotData;
    } else {
      const selectedItem = event.data;
      if (Array.isArray(selectedItem)) {
        this.selectedRows.push(selectedItem);
      } else {
        this.selectedRows.push(selectedItem);
      }
    }
    console.log(this.selectedRows);
  }

  onRowDeselected(event: any) {
    const deselectedItem = event.data;
    if (Array.isArray(deselectedItem)) {
      this.selectedRows = [];
    } else {
      this.selectedRows = this.selectedRows.filter(
        (item) => item.id !== deselectedItem.id
      );
    }
    console.log(this.selectedRows);
  }

  fetchGpoDetails() {
    this.gpoService.getGpoDetails().subscribe({
      next: (response: string | any) => {
        this.gpoDetails = response;
        console.log("Response :", this.gpoDetails);
      },
      error: (error: any) => {
        console.log("Error:" + error);
      },
    });
  }

  fetchSnapshotData() {
    this.gpoService.getSnapshotData().subscribe({
      next: (response: string | any) => {
        response.unshift({
          id: 0,
          snapshotName: "All",
          gpoList: "[]",
          snapshotDate: null,
          createdBy: null,
          userId: null,
          createDate: null,
          lastUpdateDate: null,
          status: "ACTIVE",
          snapshotFileNameTxt: null,
          snapshotFileNameJson: null,
        });
        this.snapshotFilterData = response;
        this.snapshotData = response.map((snapshot: any) => {
          const gpoList = JSON.parse(snapshot.gpoList);
          const gpoNames = gpoList.map((gpo: any) => gpo.gpoName);
          return { ...snapshot, gpoList: gpoNames };
        });

        this.convertDates();
        this.adjustGridHeight();

        console.log("Modified Snapshot Data:", this.snapshotData);
      },
      error: (error: any) => {
        console.log("Error:" + error);
      },
    });
  }

  convertDates() {
    return this.snapshotData.map((item: any) => {
      // Outlet GPO Start Date
      if (item.snapshotDate != null || item.snapshotDate == undefined) {
        const newDate = new Date(this.formatDateInUTC(item.snapshotDate));
        if (newDate) {
          item.snapshotDate = newDate;
        }
      }
      // lastUpdateDate
      if (item.lastUpdateDate != null && item.lastUpdateDate == undefined) {
        const newDate = new Date(this.formatDateInUTC(item.lastUpdateDate));
        if (newDate) {
          item.lastUpdateDate = newDate;
        }
      }
      // createDate
      if (item.createDate != null && item.createDate == undefined) {
        const newDate = new Date(this.formatDateInUTC(item.createDate));
        if (newDate) {
          item.createDate = newDate;
        }
      }
      // createDate
      if (item.lastRunDate != null && item.lastRunDate == undefined) {
        const newDate = new Date(this.formatDateInUTC(item.lastRunDate));
        if (newDate) {
          item.lastRunDate = newDate;
        }
      }

      return item;
    });
  }
  formatDateToYYYYMMDD(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Ensure two digits
    const day = ("0" + date.getDate()).slice(-2); // Ensure two digits
    return `-${month}-${day}-${year}`;
  }

  formatSnapshotDate(snapshotDate: string): string {
    let result = "";
    if (snapshotDate != null) {
      result = formatDate(snapshotDate, "MM-dd-yyyy", "en-US");
    }
    return result;
  }

  exportSnapshotPage() {
    console.log(JSON.stringify(this.selectedRows));
    this.gpoService.setSelectedRows(this.selectedRows);
    this.router.navigate(["/home/point-in-time/snapshot-export"]);
  }

  exportSingleSnapshot(id: any) {
    this.selectedRows = [];
    this.selectedRows.push({ id });
    this.gpoService.setSelectedRows(this.selectedRows);
    this.router.navigate(["/home/point-in-time/snapshot-export"]);
  }

  editClick(id: number) {
    this.router.navigate(["/home/point-in-time/snapshot", id]);
  }
  openSnapshotDetails(snapshot: any) {
    this.detailsDialog.show();
    this.selectedSnapshot = snapshot;
  }
  closeDetailsDialog() {
    this.detailsDialog.hide();
  }
  formatDateInUTC(date: any): any {
    const utcDate = new Date(date);
    return this.datePipe.transform(utcDate, "MM-dd-yyyy", "UTC");
  }
}
