import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule ,FormGroup, FormBuilder,FormControl, Validators} from '@angular/forms';
import { CheckBoxAllModule } from '@syncfusion/ej2-angular-buttons';
import { CheckBoxSelectionService, ListBox, ListBoxAllModule, ListBoxComponent, MultiSelectModule} from '@syncfusion/ej2-angular-dropdowns';
import { GpoService } from '../../layout/gpo.service';

interface availableEntities {
  gpoId: string;
  gpoName: string;
}
@Component({
  selector: 'app-business-rule-sequence-dialog',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ListBoxAllModule, CommonModule,MultiSelectModule, CheckBoxAllModule
],
providers: [CheckBoxSelectionService],

  templateUrl: './business-rule-sequence-dialog.component.html',
  styleUrl: './business-rule-sequence-dialog.component.css'
})
export class BusinessRuleSequenceDialogComponent implements OnInit{
  
  @ViewChild('listBox') listBox!: ListBox; // Reference to the ListBox component

  gpoForm!: FormGroup;
  @Input() data!: any[];
  mode = 'CheckBox'; // For MultiSelect

  newListOrder =  [];
  availableEntities : availableEntities[]=[];
  public availableGpo: string[] = [];
  selectedEntity: string | undefined;
  defaultSelectedValues :any[] =[];
  isButtonDisabled: boolean = true;
  @Output() okClick = new EventEmitter();
  @Output() dataEvent = new EventEmitter<any>();
  @Output() closeClick = new EventEmitter();
  
  ngOnInit(): void {
      this.fetchEntity();
    this.gpoForm = this.formBuilder.group({
      entity: ['',Validators.required] // Initialize form control with an empty value
    });
    const entityControl = this.gpoForm.get('entity');

    if (entityControl) {
      // Subscribe to changes in the form control's value
      entityControl.valueChanges.subscribe(value => {
        // Update the button disabled status based on form control validity
        this.isButtonDisabled = entityControl.invalid;
      });
    }
  }

  constructor(private _gpoService: GpoService,private formBuilder: FormBuilder
    ) {
     
    }
    
  async onOkClick() {
    // Emit some data on OK click
    await this.dataEvent.emit({ result : this.gpoForm.value})
    await this.okClick.emit({ result:  this.data });
    
  }

  closeDialog() {
    // Emit some data on OK click
    this.closeClick.emit({ result: 'Cancel to process execution' });
  }


   public setfield = { text: "DisplayName" };


   preprocessData(): void {
    this.data = this.data.map((item : any) => ({
      ...item,
      DisplayName: `${item['id']} - ${item['businessRuleName']} - ${item['businessRuleDesc'] }`
    }));
  }

   onDrop(args: any) {
    this.data = args.source.currentData;
  }

  onDragStop() {
  //  const newListOrder = this.listBox.getSortedList();
    // Process the new list order here
  }
  onEntitySelectionChange(event: { value: string | undefined; }) {
    this.selectedEntity = event.value;
  }
  async fetchEntity() {
    await this._gpoService.fetchAllGpoEntity().subscribe({
      next: (response: availableEntities[]) => {
        this.availableEntities = response;
        this.availableGpo = this.availableEntities.map(option => option.gpoId +" - "+option.gpoName);
      },
      error: (error: any) => {
        console.log("Error:" + error);
      }
    });
  }
  async searchEntity(searchText:any) {
    if (searchText && searchText.length > 0) {
      this.availableGpo = this.availableEntities.map(option => option.gpoId+" - "+option.gpoName);
      
      // Filter availableGpo array based on certain criteria
      this.availableGpo = this.availableGpo.filter(option => {
        // Example filter criteria: Filter options that contain 'someValue' in the string
        return option.toLowerCase().includes(searchText.toLowerCase());
      });
    } else {
      this.availableGpo = this.availableEntities.map(option => option.gpoId+" - "+option.gpoName);
    }
  }
  onSelectionChange(event: any): void {
    this.selectedEntity = event.target.value;
  }
}
