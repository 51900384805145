<ejs-appbar colorMode="Dark" style="height: 60px; box-shadow: none">
  <div class="row row-header">
    <div class="col-md-4">
      <div class="header-list hover-pointer" style="margin: auto">
        <span class="regular" style="margin: 0 5px"
          ><img
            src="assets/images/fundae_data_pulse_hawk_final_logo.png"
            alt=""
            style="height: 50px"
        /></span>
      </div>
    </div>
    <div class="col-md-4">
      <span style="font-size: 1.4rem; font-weight: 600">
        GPO Member Maintenance
      </span>
    </div>
    <div class="col-md-4">
      <!-- <span
        class="e-icons e-large e-home"
        (click)="navigateToHomePage()"
        style="margin-left: 10px; margin-right: 10px; color: #fd5e53"
        title="Home"
      ></span> -->

      <!-- Uncomment this once admin module is live -->
      <!-- <button
        ejs-button
        *ngIf="isAdmin"
        class="btn btn-primary btn-style"
        [isPrimary]="appBarColor.isPrimary"
        [cssClass]="appBarColor.loginClass"
        title="Admin"
        (click)="navigateToadmin()"
        style="
          font-size: 14px;
          text-transform: capitalize;
          font-weight: 600;
          background: none;
          border: none;
        "
      >
        <span
          class="e-icons e-large e-settings"
          (click)="navigateToadmin()"
        ></span>
      </button> -->
      <button
        ejs-button
        class="btn btn-primary btn-style"
        [isPrimary]="appBarColor.isPrimary"
        [cssClass]="appBarColor.loginClass"
        title="Logout"
        (click)="openLogoutDialog()"
        style="font-size: 14px; text-transform: capitalize; font-weight: 600"
      >
        <i
          class="fa fa-sign-out"
          aria-hidden="true"
          style="margin-right: 10px"
        ></i>
        Log out
      </button>
    </div>
  </div>
</ejs-appbar>

<div>
  <div>
    <ejs-tab
      id="element"
      #element
      (selected)="tabSelected($event)"
      class="headr-dev"
      [selectedItem]="0"
    >
      <e-tabitems>
        <e-tabitem
          [header]="tabs[0]"
          content="#idv2"
          [iconCss]="'custom-icon-grid'"
        ></e-tabitem>
        <e-tabitem
          [header]="tabs[1]"
          content="#idv2"
          [iconCss]="'custom-icon-sitemap'"
        ></e-tabitem>
        <e-tabitem
          [header]="tabs[2]"
          content="#idv2"
          [iconCss]="'custom-icon-clipboard-check'"
        ></e-tabitem>
        <e-tabitem
          [header]="tabs[3]"
          content="#idv2"
          [iconCss]="'custom-icon-file-alt'"
        ></e-tabitem>
        <e-tabitem
          [header]="tabs[4]"
          content="#idv2"
          [iconCss]="'custom-icon-code-branch'"
        ></e-tabitem>
        <e-tabitem
          [header]="tabs[5]"
          content="#idv2"
          [iconCss]="'custom-icon-ruler'"
        ></e-tabitem>
        <e-tabitem
          [header]="tabs[6]"
          content="#idv2"
          [iconCss]="'custom-icon-history'"
        ></e-tabitem>
        <e-tabitem
          [header]="tabs[7]"
          content="#idv2"
          [iconCss]="'custom-icon-search'"
        ></e-tabitem>
        <e-tabitem
          [header]="tabs[8]"
          content="#idv2"
          [iconCss]="'custom-icon-layer-group'"
        ></e-tabitem>
        <e-tabitem
          [header]="tabs[9]"
          content="#idv2"
          [iconCss]="'custom-icon-point-in-time'"
        ></e-tabitem>
      </e-tabitems>
    </ejs-tab>

    <div id="idv2" class="p-2">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<ejs-dialog
  #logoutDailog
  [visible]="false"
  [width]="400"
  [header]="logoutDailogHeader"
  [position]="dialogPosition"
  [content]="logoutDailogContent"
  class="logout"
  isModal="true"
>
  <ng-template #logoutDailogHeader>
    <div class="col-md-12">
      <h4>Log Out</h4>
    </div>
  </ng-template>
  <ng-template #logoutDailogContent>
    <div class="col-md-12">
      <span style="font-size: 14px; font-weight: 400">
        Are you sure, you waant to logout?
      </span>
    </div>
    <div class="col-md-12 d-flex justify-content-end mt-3">
      <button class="btn btn-primary logout_btn" (click)="logout()">Yes</button>
      <button
        class="btn btn-primary cancel_btn"
        style="margin-left: 1rem"
        (click)="closeLogoutModal()"
      >
        Cancel
      </button>
    </div>
  </ng-template>
</ejs-dialog>

<ejs-dialog
  #sessionExpiredDailog
  [visible]="false"
  [width]="400"
  [header]="sessionExpiredDailogHeader"
  [position]="dialogPosition"
  [content]="sessionExpiredDailogContent"
  class="logout"
  isModal="true"
>
  <ng-template #sessionExpiredDailogHeader>
    <div class="col-md-12">
      <h4>Session Expired!</h4>
    </div>
  </ng-template>
  <ng-template #sessionExpiredDailogContent>
    <div class="col-md-12">
      <span style="font-size: 14px; font-weight: 400">
        Your session has expired. To ensure the security of your account, please
        log in again. Click 'OK' to proceed to the login page.
      </span>
    </div>
    <div class="col-md-12 d-flex justify-content-end mt-2">
      <button class="btn btn-primary logout_btn" (click)="logout()">Ok</button>
    </div>
  </ng-template>
</ejs-dialog>
