import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListBox, ListBoxAllModule, ListBoxComponent } from '@syncfusion/ej2-angular-dropdowns';
import { formatDate } from '@angular/common';
import { GridAllModule, GridComponent, GridModule, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';



@Component({
  selector: 'app-snapshot-details-dialog',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule,GridAllModule,
    GridModule, ListBoxAllModule, CommonModule
],
  templateUrl: './snapshot-details-dialog.component.html',
  styleUrl: './snapshot-details-dialog.component.css'
})
export class SnapshotDetailsDialogComponent implements OnChanges{
  
 
  @Input() data!: any;

  @Output() okClick = new EventEmitter();

  @Output() closeClick = new EventEmitter();

  ngOnChanges(){
    console.log('data',this.data);
  }

  onOkClick() {
    // Emit some data on OK click
    this.okClick.emit({ result:  this.data });
    
  }

 
  formatSnapshotDate(snapshotDate: string): string {
    return formatDate(snapshotDate, 'MM/dd/yyyy', 'en-US');
  }


   
  isButtonDisabled(): boolean {
    // Return true to disable the button when the condition is not satisfied
    return false;
  }
   onDrop(args: any) {
    this.data = args.source.currentData;
  }

  onDragStop() {
  //  const newListOrder = this.listBox.getSortedList();
    // Process the new list order here
  }

}
