<ejs-dialog
  #createViewDialog
  [visible]="visible"
  [header]="header"
  [width]="dialogWidth"
  [height]="dialogHeight"
  [animationSettings]="animationSettings"
  isModal="true"
  [position]="dimensionModalposition"
  [showCloseIcon]="true"
>
  <ng-template #content>
    <form>
      <label>View Name</label>
      <ejs-textbox
        placeholder="Enter View Name"
        cssClass="e-outline"
      ></ejs-textbox>
      <br /><br />
      <label>View Description</label>
      <ejs-textbox
        placeholder="Enter View Description"
        cssClass="e-outline"
      ></ejs-textbox>
      <br /><br />
      <div class="button-container">
        <button
          type="submit"
          ejs-button
          class="btn btn-primary"
          style="background-color: blue; color: white"
        >
          Save
        </button>
        <button
          type="cancel"
          ejs-button
          class="btn btn-secondary"
          style="margin-left: 10px"
          (click)="cancel()"
        >
          Cancel
        </button>
      </div>
    </form>

    <div class="mt-5">
      <ejs-grid
        [dataSource]="dataSource"
        [allowPaging]="true"
        [allowSorting]="true"
      >
        <e-columns>
          <e-column headerText="Default">
            <ng-template #template let-data>
              <input type="radio" />
            </ng-template>
          </e-column>
          <e-column field="viewName" headerText="View Name"></e-column>
          <e-column
            field="viewDescription"
            headerText="View Description"
          ></e-column>
          <e-column field="createdOn" headerText="Created On"></e-column>
          <e-column
            field="lastUpdatedOn"
            headerText="Last Updated On"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </div>
  </ng-template>
</ejs-dialog>
