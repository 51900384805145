<ejs-grid
  #analysisGrid
  id="analysisGrid"
  [dataSource]="dataSource"
  class="syncfusion-grid"
  [allowPaging]="true"
  [pageSettings]="pageSettings"
  [filterSettings]="{
  type: 'Excel',
}"
  [allowFiltering]="true"
  [allowResizing]="true"
  [allowSorting]="true"
  [height]="'300px'"
  [allowGrouping]="allowGrouping"
  [groupSettings]="groupSettings"
  (rowDataBound)="rowDataBound($event)"
>
  <e-columns>
    <e-column
      *ngIf="allowGrouping"
      field="GroupID"
      headerText="Group ID"
      width="180"
      textAlign="center"
      isPrimaryKey="true"
    >
    </e-column>
    <e-column
      field="recordVersion"
      headerText="Record Version"
      width="180"
      textAlign="center"
      isPrimaryKey="true"
    >
    </e-column>
    <e-column
      field="gpoId"
      headerText="GPO ID"
      width="130"
      isPrimaryKey="true"
    ></e-column>
    <e-column
      field="gpoName"
      headerText="GPO Name"
      width="150"
      isPrimaryKey="true"
    ></e-column>
    <e-column
      field="gpoOutletGroupId"
      headerText="GPO Outlet Group ID"
      width="210"
    ></e-column>
    <e-column
      field="gpoContractId"
      headerText="GPO Contract ID"
      width="190"
    ></e-column>
    <e-column
      field="outletId"
      headerText="Outlet ID"
      width="140"
      isPrimaryKey="true"
    ></e-column>
    <e-column
      field="outletGpoId"
      headerText="Outlet GPO ID"
      width="170"
    ></e-column>
    <e-column
      field="outletName"
      headerText="Outlet Name"
      width="250"
    ></e-column>
    <e-column
      field="outletType"
      headerText="Outlet Type"
      width="160"
    ></e-column>
    <e-column field="outletCot" headerText="Outlet COT" width="160"></e-column>
    <e-column
      field="outletCotSubtype"
      headerText="Outlet COT Subtype"
      width="210"
    ></e-column>
    <e-column field="outletNpi" headerText="Outlet NPI" width="170"></e-column>
    <e-column field="outletHin" headerText="Outlet HIN" width="170"></e-column>
    <e-column field="outletDea" headerText="Outlet DEA" width="170"></e-column>
    <e-column
      field="outletAddress1"
      headerText="Outlet Address 1"
      width="210"
    ></e-column>
    <e-column
      field="outletAddress2"
      headerText="Outlet Address 2"
      width="210"
    ></e-column>
    <e-column
      field="outletCity"
      headerText="Outlet City"
      width="160"
    ></e-column>
    <e-column
      field="outletState"
      headerText="Outlet State"
      width="160"
    ></e-column>
    <e-column field="outletZip" headerText="Outlet ZIP" width="160"></e-column>
    <e-column
      field="outletCountry"
      headerText="Outlet Country"
      width="170"
    ></e-column>
    <e-column
      field="outletPhone"
      headerText="Outlet Phone"
      width="170"
    ></e-column>
    <e-column
      field="outletPrimaryContact"
      headerText="Outlet Primary Contact"
      width="230"
    ></e-column>
    <e-column
      field="outletPrimaryEmail"
      headerText="Outlet Primary Email"
      width="230"
    ></e-column>
    <e-column
      field="outletGpoStartDate"
      headerText="Outlet GPO Start Date"
      width="230"
      [type]="dateOptions"
      [format]="formatoptions"
    >
    </e-column>
    <e-column
      field="outletGpoEndDate"
      headerText="Outlet GPO End Date"
      width="230"
      [type]="dateOptions"
      [format]="formatoptions"
    >
    </e-column>
    <e-column
      field="outletRebateEligible"
      headerText="Outlet Rebate Eligible"
      width="230"
    ></e-column>
    <e-column
      field="outletRebateEligibleStartDate"
      headerText="Outlet Rebate Eligible Start Date"
      width="280"
      [type]="dateOptions"
      [format]="formatoptions"
    >
    </e-column>
    <e-column
      field="outletRebateEligibleEndDate"
      headerText="Outlet Rebate Eligible End Date"
      width="280"
      [type]="dateOptions"
      [format]="formatoptions"
    >
    </e-column>
    <e-column
      field="outletAnalysisStartDate"
      headerText="Outlet Analysis Start Date"
      width="280"
      [type]="dateOptions"
      [format]="formatoptions"
    >
    </e-column>
    <e-column
      field="outletAnalysisEndDate"
      headerText="Outlet Analysis End Date"
      width="280"
      [type]="dateOptions"
      [format]="formatoptions"
    >
    </e-column>
    <e-column
      field="outletStatus"
      headerText="Outlet Status"
      width="170"
      isPrimaryKey="true"
    ></e-column>
    <e-column
      field="outletParentId"
      headerText="Outlet Parent ID"
      width="180"
    ></e-column>
    <e-column
      field="outletParentGpoId"
      headerText="Outlet Parent GPO ID"
      width="220"
    ></e-column>
    <e-column
      field="outletParentName"
      headerText="Outlet Parent Name"
      width="250"
    ></e-column>
    <e-column
      field="outletParentType"
      headerText="Outlet Parent Type"
      width="210"
    ></e-column>
    <e-column
      field="outletParentCot"
      headerText="Outlet Parent COT"
      width="210"
    ></e-column>
    <e-column
      field="outletParentCotSubtype"
      headerText="Outlet Parent COT Subtype"
      width="250"
    ></e-column>
    <e-column
      field="outletParentNpi"
      headerText="Outlet Parent NPI"
      width="220"
    ></e-column>
    <e-column
      field="outletParentHin"
      headerText="Outlet Parent HIN"
      width="220"
    ></e-column>
    <e-column
      field="outletParentDea"
      headerText="Outlet Parent DEA"
      width="220"
    ></e-column>
    <e-column
      field="outletParentAddress1"
      headerText="Outlet Parent Address 1"
      width="230"
    ></e-column>
    <e-column
      field="outletParentAddress2"
      headerText="Outlet Parent Address 2"
      width="230"
    ></e-column>
    <e-column
      field="outletParentCity"
      headerText="Outlet Parent City"
      width="200"
    ></e-column>
    <e-column
      field="outletParentState"
      headerText="Outlet Parent State"
      width="200"
    ></e-column>
    <e-column
      field="outletParentZip"
      headerText="Outlet Parent ZIP"
      width="200"
    ></e-column>
    <e-column
      field="outletParentCountry"
      headerText="Outlet Parent Country"
      width="220"
    ></e-column>
    <e-column
      field="outletParentGpoStartDate"
      headerText="Outlet Parent GPO Start Date"
      width="260"
      [type]="dateOptions"
      [format]="formatoptions"
    >
    </e-column>
    <e-column
      field="outletParentGpoEndDate"
      headerText="Outlet Parent GPO End Date"
      width="260"
      [type]="dateOptions"
      [format]="formatoptions"
    >
    </e-column>
    <e-column
      field="outletParentStartDate"
      headerText="Outlet Parent Start Date"
      width="260"
      [type]="dateOptions"
      [format]="formatoptions"
    >
    </e-column>
    <e-column
      field="outletParentEndDate"
      headerText="Outlet Parent End Date"
      width="260"
      [type]="dateOptions"
      [format]="formatoptions"
    >
    </e-column>
    <e-column
      field="outletGrandparentId"
      headerText="Outlet Grandparent ID"
      width="230"
    ></e-column>
    <e-column
      field="outletGrandparentGpoId"
      headerText="Outlet Grandparent GPO ID"
      width="250"
    ></e-column>
    <e-column
      field="outletGrandparentName"
      headerText="Outlet Grandparent Name"
      width="250"
    ></e-column>
    <e-column
      field="outletGrandparentType"
      headerText="Outlet Grandparent Type"
      width="250"
    ></e-column>
    <e-column
      field="outletGrandparentCot"
      headerText="Outlet Grandparent COT"
      width="250"
    ></e-column>
    <e-column
      field="outletGrandparentCotSubtype"
      headerText="Outlet Grandparent COT Subtype"
      width="290"
    ></e-column>
    <e-column
      field="outletGrandparentNpi"
      headerText="Outlet Grandparent NPI"
      width="250"
    ></e-column>
    <e-column
      field="outletGrandparentHin"
      headerText="Outlet Grandparent HIN"
      width="250"
    ></e-column>
    <e-column
      field="outletGrandparentDea"
      headerText="Outlet Grandparent DEA"
      width="250"
    ></e-column>
    <e-column
      field="outletGrandparentAddress1"
      headerText="Outlet Grandparent Address 1"
      width="270"
    ></e-column>
    <e-column
      field="outletGrandparentAddress2"
      headerText="Outlet Grandparent Address 2"
      width="270"
    ></e-column>
    <e-column
      field="outletGrandparentCity"
      headerText="Outlet Grandparent City"
      width="230"
    ></e-column>
    <e-column
      field="outletGrandparentState"
      headerText="Outlet Grandparent State"
      width="250"
    ></e-column>
    <e-column
      field="outletGrandparentZip"
      headerText="Outlet Grandparent ZIP"
      width="230"
    ></e-column>
    <e-column
      field="outletGrandparentCountry"
      headerText="Outlet Grandparent Country"
      width="280"
    ></e-column>
    <e-column
      field="outletGrandparentGpoStartDate"
      headerText="Outlet Grandparent GPO Start Date"
      width="300"
      [type]="dateOptions"
      [format]="formatoptions"
    >
    </e-column>
    <e-column
      field="outletGrandparentGpoEndDate"
      headerText="Outlet Grandparent GPO End Date"
      width="300"
      [type]="dateOptions"
      [format]="formatoptions"
    >
    </e-column>
  </e-columns>
</ejs-grid>
