import { CommonModule, formatDate } from "@angular/common";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  ListBox,
  ListBoxAllModule,
  ListBoxComponent,
} from "@syncfusion/ej2-angular-dropdowns";

@Component({
  selector: "app-snapshot-delete-dialog",
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ListBoxAllModule, CommonModule],
  templateUrl: "./snapshot-delete-dialog.component.html",
  styleUrl: "./snapshot-delete-dialog.component.css",
})
export class SnapshotDeleteDialogComponent {
  @Input() data!: any;

  @Output() okClick = new EventEmitter();

  @Output() closeClick = new EventEmitter();

  onOkClick() {
    // Emit some data on OK click
    this.okClick.emit({ result: this.data });
  }

  closeDialog() {
    // Emit some data on OK click
    this.closeClick.emit({ result: "Cancel" });
  }

  isButtonDisabled(): boolean {
    // Return true to disable the button when the condition is not satisfied
    return false;
  }
  onDrop(args: any) {
    this.data = args.source.currentData;
  }

  onDragStop() {
    //  const newListOrder = this.listBox.getSortedList();
    // Process the new list order here
  }

  formatSnapshotDate(snapshotDate: string): string {
    return formatDate(snapshotDate, "MM-dd-yyyy", "en-US");
  }
}
