<ejs-dialog
  #auditHistoryDialog
  [visible]="visible"
  [header]="header"
  [width]="dialogWidth"
  [height]="dialogHeight"
  [animationSettings]="animationSettings"
  isModal="true"
  [position]="dimensionModalposition"
  [showCloseIcon]="true"
>
  <ng-template #content>
    <div style="margin-left: 10px">
      <button
        class="btn btn-secondary"
        style="margin-left: 10px"
        title="View Org"
      >
      <i class="fas fa-clipboard-check"></i></button
      ><br /><br />
      <a href="" style="margin-left: 20px">Audit</a>
    </div>

    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <div class="control-container">
          <ejs-carousel [dataSource]="productItems">
            <ng-template #itemTemplate let-data>
              <div class="slide-content"></div>
              <figure class="img-container">
                <img
                  [src]="getImage(data.imageName)"
                  alt=""
                  style="height: 50%; width: 80%"
                />
                <figcaption class="img-caption"></figcaption>
              </figure>
            </ng-template>
          </ejs-carousel>
        </div>
      </div>
    </div>
  </ng-template>
</ejs-dialog>
