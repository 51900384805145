import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { inject, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  
  router = inject(Router);

  private BASE_URL = environment.apiURL;
  redirectLogin = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.redirectLogin.subscribe((data) => {
      if (data == true) {
        this.redirctToLogin();
      }
    });
  }

  redirctToLogin() {
    this.clearToken();
    this.router.navigate(["/login"]);
  }

  getToken(): string | null {
    return localStorage.getItem("token");
  }

  isAuthenticated(): boolean {
    return !!this.getToken();
  }

  saveToken(token: string): void {
    localStorage.setItem("token", token);
  }

  clearToken(): void {
    localStorage.removeItem("token");
  }

  logout(obj: any): void {
    this.http.post<any>(this.BASE_URL + "auth/logout", obj).subscribe((res) => {
    });
    // Clear token and navigate to login page
    this.clearToken();
    this.router.navigate(["/login"]);
  }

  handleForbiddenError(): void {
    // Redirect to login page when encountering 403 Forbidden error
    this.redirectLogin.next(true);
  }

  login(credentials: { username: string; password: string }): Observable<any> {
    return this.http.post<any>(this.BASE_URL + "auth/login", credentials);
  }

  verifyOTP(username: string, otp: number) {
    return this.http.post<any>(this.BASE_URL + "auth/validate-otp", {
      username,
      otp,
    });
  }

  isMFAEnabled(username: string) {
    return this.http.get<any>(
      this.BASE_URL + "auth/is-mfa-enabled/" + username
    );
  }

  isUserRegistered(username: any) {
    return this.http.get<any>(
      this.BASE_URL + "auth/is-user-registered/" + username
    );
  }

  generateQRCode(username: any) {
    const headers = new HttpHeaders({ "Content-Type": "image/jpeg" }); // Adjust content type as needed

    return this.http.get<any>(this.BASE_URL + "auth/generate-qr/" + username, {
      responseType: "arraybuffer" as "json",
    });
  }

  checkAdminRole(){
    return this.http.get<any>(
      this.BASE_URL + 'simulations/user-role'
    );
  }

  addModuleHistory(moduleName: any){
    return this.http.get<any>(
      this.BASE_URL + 'simulations/module-history/'+moduleName
    );
  }

  getRedirectModuleUrl(){
    return this.http.get<any>(
      this.BASE_URL + 'simulations/redirect-history-url'
    );
  }
}
