import { CommonModule } from '@angular/common';
import { Component, Input, input, OnInit } from '@angular/core';
import { Form, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators,AbstractControl } from '@angular/forms';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { GpoService } from '../../../gpo.service';
import { GpoRuleconditionComponent } from '../gpo-rulecondition/gpo-rulecondition.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { ToastService } from '../../../Toast/toast.service';

interface RuleDetail {
  businessRuleName: string;
  businessRuleDesc: string;
}

@Component({
  selector: 'app-gpo-rule-details',
  standalone: true,
  imports: [ FormsModule,
    ReactiveFormsModule,
    TextBoxModule,
    DropDownListModule,
    ButtonModule, CommonModule, GpoRuleconditionComponent],
      templateUrl: './gpo-rule-details.component.html',
  styleUrl: './gpo-rule-details.component.css'
})
export class GpoRuleDetailsComponent implements OnInit {
  allDataSource : any[] =[];
  allRuleNames !: string[];
  business_rule_id = 0;

  isEdit = false;

  businessRuleForm!: FormGroup;


  constructor(private fb: FormBuilder, private gpoService: GpoService,private route: ActivatedRoute,private router :Router,private _toastService:ToastService
    ) {

      

    }
 

  ngOnInit(): void {

    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      
      if (id) {
        this.business_rule_id = Number(id);
        this.gpoService.getBusinessRule(this.business_rule_id).subscribe(data => {
          console.log('Data ',data);
          this.isEdit = true;
          this.businessRuleForm.patchValue({
            businessRuleName: data.businessRuleName,
            businessRuleDesc: data.businessRuleDesc
        });
        });
        // this.fetchSimulationById(id);
      }
    });

    this.gpoService.getAllBusinessRule().subscribe(data => {
      this.allDataSource = data;
    });
    this.allRuleNames = this.allDataSource.map(item => item.businessRuleName);

    this.businessRuleForm = this.fb.group({
    businessRuleName: ['', [Validators.required, this.nospecialCharacterValidator,this.nameExistsValidator.bind(this)]],
    businessRuleDesc: ['', [Validators.required, this.nospecialCharacterValidator]],
    });  
  
    
  }

  // Custom validator function to check if the value starts with a space
  nospecialCharacterValidator(control: AbstractControl): { [key: string]: any } | null {
    const value: string = control.value;
  if (value && value.startsWith(' ')) {
    return { 'specialCharacter': true }; // Return an error object if the value starts with a space
  }
  return null;
  }

  nameExistsValidator(control: FormControl): { [key: string]: any } | null {
    if (this.allRuleNames.length === 0) {
      this.allRuleNames = this.allDataSource.map(item => item.businessRuleName);
    }
    if (this.allRuleNames.some(name => name.toLowerCase() === control.value.trim().toLowerCase())) {
      return { 'nameExists': true }; // Validation failed
    }
    return null; // Validation passed
  }

  onSubmit() {
    // Process your form submission here
    const ruleDetail: RuleDetail = {
      businessRuleName: this.businessRuleForm.value.businessRuleName.trim(),
      businessRuleDesc: this.businessRuleForm.value.businessRuleDesc.trim() 
    }
    if(this.isEdit){
      this.gpoService.updateBusinessRule(ruleDetail,this.business_rule_id).subscribe(data => {
        this.business_rule_id = data.id;
        this._toastService.showSuccessToast('Business Rule Created');
      });
    }else {
      this.isEdit = true;
      this.gpoService.addBusinessRule(ruleDetail).subscribe(data => {
        this.business_rule_id = data.id;
        this._toastService.showSuccessToast('Business Rule Created');
      });
    }

    console.log(this.businessRuleForm.value);
      
  }

  onCancel() {
    // Handle the cancel action here
    this.router.navigate(["/home/rule"]);
    // this.businessRuleForm.reset();
  }

}
