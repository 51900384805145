<div class="row" style="margin-left: 4px; margin-right: 1px">
  <form [formGroup]="searchForm" class="form-box-d" style="padding: 20px">
    <div class="row" style="display: flex; align-items: center">
      <div class="col-md-1">
        <label>Filter Snapshot</label>
      </div>
      <div class="col-md-2">
        <!-- <label for="snapshotName">Select Snapshot Name</label> -->
        <ejs-dropdownlist
          #gpoDropdown
          cssClass="e-outline"
          class="e-outline"
          id="gpoDropdown"
          formControlName="snapshotName"
          placeholder="Select Snapshot"
          [allowFiltering]="true"
          [dataSource]="snapshotFilterData"
          [fields]="{ text: 'snapshotName', value: 'snapshotName' }"
        ></ejs-dropdownlist>
      </div>
      <div class="col-md-2">
        <!-- <label>Select GPO</label> -->
        <ejs-multiselect
          id="gpo"
          formControlName="selectedGPOs"
          cssClass="custom-outline e-outline"
          [dataSource]="gpoDetails"
          [fields]="{ text: 'gpoName', value: 'gpoId' }"
          placeholder="Select GPO"
          [mode]="mode"
          [selectAllText]="'Select All GPO`s'"
          showSelectAll="true"
        ></ejs-multiselect>
      </div>
      <div class="col-md-2">
        <!-- <label for="startDate">Select Start Date</label> -->
        <ejs-datepicker
          id="startDate"
          format="yyyy-MM-dd"
          formControlName="startDate"
          cssClass="custom-outline e-outline"
          placeholder="Select Start Date"
        >
        </ejs-datepicker>
      </div>
      <div class="col-md-2">
        <!-- <label for="endDate">Select End Date</label> -->
        <ejs-datepicker
          id="endDate"
          format="yyyy-MM-dd"
          formControlName="endDate"
          cssClass="custom-outline e-outline"
          placeholder="Select End Date"
          [min]="searchForm.get('startDate')?.value"
        >
        </ejs-datepicker>
      </div>

      <!-- Search and Clear Buttons -->
    </div>

    <div
      class="col"
      style="text-align: right; margin: 0px auto 0px auto; margin-top: 2em"
    >
      <button
        type="button"
        class="btn btn-primary search_btn"
        (click)="search()"
        style="margin-right: 10px"
      >
        Search
      </button>
      <button
        type="button"
        class="btn btn-secondary cancle_btn"
        (click)="resetForm()"
      >
        Clear
      </button>
    </div>
  </form>

  <div
    style="
      background-color: #dfeff4;
      padding: 1rem;
      margin-top: 1rem;
      border: 1px solid #27d2f8;
      border-radius: 4px;
    "
  >
    <div class="mt-2">
      <div class="row" style="margin-bottom: 0.5rem">
        <div class="col-md-12">
          <div class="row align-items-center">
            <div class="col-md-5">
              <span style="font-weight: 500; font-size: 16px"
                >Point in time</span
              >
            </div>
            <div class="col-md-7">
              <div class="d-flex justify-content-end">
                <!-- Icon container for CSV -->
                <div style="margin-right: 0.5rem">
                  <button
                    type="button"
                    class="btn btn-primary"
                    (click)="deleteSnapshot(selectedRows)"
                    title="Delete"
                    [disabled]="!selectedRows || selectedRows.length === 0"
                    style="
                      margin-right: 10px;
                      background-color: red;
                      border: none;
                      border-radius: 3px;
                    "
                  >
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </div>
                <!-- Icon container for Excel -->
                <div style="margin-right: 0.5rem">
                  <button
                    type="button"
                    class="btn btn-primary"
                    (click)="exportSnapshotPage()"
                    title="Export"
                    [disabled]="!selectedRows || selectedRows.length === 0"
                    style="margin-right: 10px; border-radius: 3px"
                  >
                    <i class="fa-solid fa-file-export"></i>
                  </button>
                </div>
                <!-- Icon container for Fullscreen -->
                <div>
                  <button
                    type="button"
                    class="btn btn-primary"
                    (click)="createSnapshotPage()"
                    title="Create"
                    style="
                      margin-right: 10px;
                      background-color: green;
                      border: none;
                      border-radius: 3px;
                    "
                  >
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="d-flex justify-content-end" style="margin-bottom: 1em">
        
        
        
      </div> -->
      <div class="grid-container" style="height: auto" *ngIf="snapshotData">
        <ejs-grid
          #grid
          class="syncfusion-grid"
          [dataSource]="snapshotData"
          [allowPaging]="true"
          [pageSettings]="{ pageSize: selectedPageSize }"
          [allowSorting]="true"
          [selectionSettings]="selectionOptions"
          [allowFiltering]="true"
          [filterSettings]="filterOptions"
          allowSelection="true"
          [selectionSettings]="{
            type: 'Multiple',
            checkboxOnly: true,
            persistSelection: true
          }"
          (rowSelected)="onRowSelected($event)"
          (rowDeselected)="onRowDeselected($event)"
          [pageSettings]="{ pageSize: pageSize }"
          allowReordering="true"
          allowResizing="true"
          autoFit="true"
          [height]="gridHeight"
        >
          <e-columns>
            <e-column
              textAlign="Center"
              headerText="Select"
              [allowEditing]="true"
              type="checkbox"
              width="60"
            ></e-column>
            <e-column
              textAlign="Center"
              headerText="Edit"
              [allowEditing]="false"
              type="edit"
              width="70"
            >
              <ng-template #template let-data>
                <ejs-tooltip content="Edit snapshot.">
                  <div
                    *ngIf="data.status === 'ACTIVE'"
                    class="icon-center-container"
                    (click)="editClick(data.id)"
                  >
                    <span
                      class="e-icons e-edit"
                      style="color: #008000; cursor: pointer; font-size: 15px"
                    >
                    </span>
                  </div>
                </ejs-tooltip>
              </ng-template>
            </e-column>
            <e-column headerText="Export" textAlign="Center" width="90">
              <ng-template #template let-data>
                <ejs-tooltip content="Export snapshot.">
                  <button
                    *ngIf="data.status !== 'INACTIVE'"
                    class="btn e-icons e-export"
                    style="
                      color: #8e8e8e;
                      cursor: pointer;
                      font-size: 17px;
                      border: none;
                    "
                    (click)="exportSingleSnapshot(data.id)"
                    [disabled]="selectedRows && selectedRows.length > 1"
                    [ngClass]="{
                      'disabled-icon': selectedRows && selectedRows.length > 1,
                      'active-icon': selectedRows && selectedRows.length <= 1
                    }"
                  ></button>
                </ejs-tooltip>
              </ng-template>
            </e-column>
            <e-column headerText="Delete" textAlign="Center" width="90">
              <ng-template #template let-data>
                <ejs-tooltip content="Delete snapshot.">
                  <button
                    *ngIf="data.status !== 'INACTIVE'"
                    class="btn e-icons e-delete"
                    style="
                      color: red;
                      cursor: pointer;
                      font-size: 17px;
                      border: none;
                    "
                    (click)="deleteSingleSnapshot(data)"
                    [disabled]="selectedRows && selectedRows.length > 1"
                    [ngClass]="{
                      'disabled-icon': selectedRows && selectedRows.length > 1,
                      'active-icon': selectedRows && selectedRows.length <= 1
                    }"
                  ></button>
                </ejs-tooltip>
              </ng-template>
            </e-column>
            <e-column
              field="id"
              headerText="Snapshot ID"
              textAlign="Center"
              width="180"
            >
              <ng-template #template let-data>
                <ejs-tooltip
                  content="Click here for more details about Snapshot."
                >
                  <a class="achor" (click)="openSnapshotDetails(data)">
                    {{ "SNAP00" + data.id }}
                  </a>
                </ejs-tooltip>
              </ng-template>
            </e-column>
            <e-column
              field="snapshotName"
              headerText="Snapshot Name"
              textAlign="Center"
              width="200"
            >
              <ng-template #template let-data>
                <ejs-tooltip
                  content="Click here for more details about Snapshot."
                >
                  <span title="{{ data.snapshotName }}">
                    <a class="achor" (click)="openSnapshotDetails(data)">
                      {{ data.snapshotName }}
                    </a>
                  </span>
                </ejs-tooltip>
              </ng-template>
            </e-column>
            <e-column
              field="status"
              headerText="Status"
              textAlign="Center"
              width="150"
            ></e-column>

            <e-column
              field="snapshotDate"
              headerText="Snapshot Date"
              textAlign="Center"
              width="180"
              type="date"
              [format]="formatoptions"
            >
            </e-column>
            <e-column
              field="gpoList"
              headerText="GPO's"
              textAlign="Center"
              width="250"
            >
              <ng-template #template let-data>
                <span style="white-space: normal">{{ data.gpoList }}</span>
              </ng-template>
            </e-column>
            <e-column
              field="createdBy"
              headerText="Created By"
              textAlign="Center"
              width="180"
            ></e-column>
            <e-column
              field="createDate"
              headerText="Create Date"
              width="180"
              type="date"
              [format]="formatoptions"
              textAlign="Center"
            >
            </e-column>
            <e-column
              field="lastRunDate"
              headerText="Last Run Date"
              width="180"
              type="date"
              [format]="formatoptions"
              textAlign="Center"
            >
            </e-column>
            <e-column
              field="lastRunStatus"
              headerText="Last Run Status"
              width="200"
              textAlign="Center"
            >
            </e-column>
          </e-columns>
        </ejs-grid>
      </div>
    </div>
  </div>
</div>

<ejs-dialog
  #deleteDialog
  [visible]="true"
  cssClass="custom-dialog"
  *ngIf="showDeleteDialog"
  [position]="position"
  [content]="deleteDialogContent"
  [showCloseIcon]="true"
  header="Confirm Snapshot Delete"
  isModal="true"
>
  <ng-template #deleteDialogContent>
    <app-snapshot-delete-dialog
      [data]="selectedRows"
      (okClick)="onConfirmation($event)"
      (closeClick)="closeDialog($event)"
    ></app-snapshot-delete-dialog>
  </ng-template>
</ejs-dialog>

<ejs-dialog
  id="detailsDialog"
  #detailsDialog
  [visible]="true"
  [showCloseIcon]="true"
  isModal="true"
  cssClass="custom-dialog"
  [visible]="false"
  width="40%"
>
  <ng-template #header>
    <div title="Audit Trail" class="e-icon-settings dlg-template">
      <span style="font-size: 22px">Snapshot Details</span>
    </div>
  </ng-template>
  <ng-template #content style="max-height: 70%">
    <div class="row divider">
      <div class="col-md-3 m-b-1">
        <label> Snapshot ID</label> : <span>{{ selectedSnapshot?.id }}</span>
      </div>
      <div class="col-md-3 m-b-1">
        <label> Snapshot Name</label> :
        <span>{{ selectedSnapshot?.snapshotName }}</span>
      </div>
      <div class="col-md-3 m-b-1">
        <label> GPO List </label> : <span>{{ selectedSnapshot?.gpoList }}</span>
      </div>
      <div class="col-md-3 m-b-1">
        <label> Snapshot Date</label> :
        <span>{{ formatSnapshotDate(selectedSnapshot?.snapshotDate) }}</span>
      </div>
      <div class="col-md-3 m-b-1">
        <label> File Name</label> :
        <span>{{ selectedSnapshot?.snapshotFileNameJson }}</span>
      </div>
      <div class="col-md-3 m-b-1">
        <label> Last Run Date</label> :
        <span *ngIf="selectedSnapshot?.lastRunDate != null">
          {{ formatSnapshotDate(selectedSnapshot?.lastRunDate) }}
        </span>
        <span *ngIf="selectedSnapshot?.lastRunDate == null">
          {{ "Analysis not yet run" }}
        </span>
      </div>
      <div class="col-md-3 m-b-1">
        <label> Last Run Status</label> :
        <span *ngIf="selectedSnapshot?.lastRunStatus != null">{{
          selectedSnapshot?.lastRunStatus
        }}</span>
        <span *ngIf="selectedSnapshot?.lastRunStatus == null">{{ "NA" }}</span>
      </div>
      <div class="col-md-3 m-b-1">
        <label> Created By</label> :
        <span>{{ selectedSnapshot?.createdBy }}</span>
      </div>
      <div class="col-md-3 m-b-1">
        <label> Created At </label> :
        <span>{{ formatSnapshotDate(selectedSnapshot?.createDate) }}</span>
      </div>
    </div>
  </ng-template>
  <ng-template #footerTemplate>
    <button
      id="close"
      (click)="closeDetailsDialog()"
      class="btn btn-secondary cancle_btn"
      data-ripple="true"
    >
      close
    </button>
  </ng-template>
</ejs-dialog>
