import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CategoryService, ChartModule, DataLabelService, TooltipService, WaterfallSeriesService } from '@syncfusion/ej2-angular-charts';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { GridAllModule, GridComponent } from '@syncfusion/ej2-angular-grids';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { ColumnFormat, ColumnFormater } from '../layout/modal/grid/column-format';





@Component({
  selector: 'app-dummy',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, GridAllModule],
  templateUrl: './dummy.component.html',
  styleUrl: './dummy.component.css',
  providers: [] // Injecting services here

})
export class DummyComponent  implements OnInit{
  

  @ViewChild('grid') public grid?: GridComponent;

  @Input() gridData!: Object[];

  @Input() columnFormater!: ColumnFormater[]

   columns: { [key: string]: any } = {};

  ngOnInit(): void {

  }

  dataBound() {

      this.columnFormater.map((item, index) => {
        var columnName = `column${index + 1}`;
        this.columns[columnName] = (this.grid as GridComponent).getColumnByField(item.columnName);
        this.columns[columnName].textAlign = 'center';
        this.columns[columnName].autoFit = true;

        if(item.columnFormat == ColumnFormat.DOLLER){

          this.columns[columnName].format = 'C2';
        }else if(item.columnFormat == ColumnFormat.PERCENTAGE){
          this.columns[columnName].format = 'P0';
        }

       });

      (this.grid as GridComponent).refreshColumns();
  }

  format() {
    var column = (this.grid as GridComponent).getColumnByField('Gross Revenue');
    column.format = 'P0';
    (this.grid as GridComponent).refreshColumns();
  }
 
  
}

 
 

  

  

  

  

  
