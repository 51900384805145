import { CommonModule } from '@angular/common';
import { Component, ViewChild, inject } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import {
  ToastComponent,
  ToastModule,
} from '@syncfusion/ej2-angular-notifications';
import { DialogModule } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ToastModule, DialogModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
})
export class LoginComponent {
  router = inject(Router);

  loginForm!: FormGroup;
  mfaForm!: FormGroup;

  isUserLoggedIn: boolean = false;
  isMfa: boolean = false;
  isUserRegistredWithGAuthy: boolean = false;
  QRCode: any = undefined;
  signInToken!: string;

  @ViewChild('toast')
  toastObj!: ToastComponent;

  constructor(private fb: FormBuilder, private authService: AuthService) {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });

    this.mfaForm = this.fb.group({
      code: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
    });
  }

  ngOnInit() {
    const token = this.authService.getToken();
    if (token) {
      this.router.navigate(['/home']);
    }
  }

  async onSubmit() {
    if (this.loginForm.valid) {
      await this.authService.login(this.loginForm.value).subscribe({
        next: (response) => {
          this.signInToken = response.token;
          this.isUserLoggedIn = true;
          this.isMFAEnabled();
        },
        error: (error) => {
          var message: string = '401: Unauthorize';

          // Check if error object has an error message
          if (error?.status === 401) {
            message =
              '401 : Bad Credentials! Please try with valid credentials.';
          } else if (error?.error?.message && error.status) {
            message = error.status + ' : ' + error.error.message + '\n';
          }

          // Display error message with proper formatting
          this.toastObj.show({
            title: 'Error!',
            content: message,
            cssClass: 'e-toast-danger',
            position: { X: 'Right', Y: 'Top' },
          });
        },
      });
    }
  }

  async isUserRegistered() {
    let result: boolean = false;
    await this.authService
      .isUserRegistered(this.loginForm.get('username')?.value)
      .subscribe({
        next: (response: boolean) => {
          if (!response) {
            this.generateQRCode();
          }
        },
        error: (error: any) => {
          // Display error message with proper formatting
          this.toastObj.show({
            title: 'Warning!',
            content:
              this.loginForm.get('username')?.value +
              'is not registred with Google Authy',
            cssClass: 'e-toast-warning',
            position: { X: 'Right', Y: 'Top' },
          });
          this.navigateTologin();
        },
      });

    return result;
  }

  async isMFAEnabled() {
    let result: boolean = false;
    await this.authService
      .isMFAEnabled(this.loginForm.get('username')?.value)
      .subscribe({
        next: (response: boolean) => {
          if (response) {
            this.isMfa = true;
            this.generateQRCode();
          } else {
            localStorage.setItem('token', this.signInToken);
            this.router.navigate(['/home']);
          }
        },
        error: (error: any) => {
          // Display error message with proper formatting
          this.toastObj.show({
            title: 'Error!',
            content: 'Internal server error!',
            cssClass: 'e-toast-danger',
            position: { X: 'Right', Y: 'Top' },
          });
          this.navigateTologin();
        },
      });
    return result;
  }

  async generateQRCode() {
    await this.authService
      .generateQRCode(this.loginForm.get('username')?.value)
      .subscribe({
        next: (response: any) => {
          if (response.byteLength != 0) {
            const byteArray = new Uint8Array(response);
            this.QRCode =
              'data:image/png;base64,' + this.arrayBufferToBase64(byteArray);
          } else {
            this.QRCode = undefined;
          }
        },
        error: (error: any) => {
          console.log(error);
          // Display error message with proper formatting
          this.toastObj.show({
            title: 'Error!',
            content: 'Error while generating QR for google authentication',
            cssClass: 'e-toast-danger',
            position: { X: 'Right', Y: 'Top' },
          });
          this.navigateTologin();
        },
      });
  }

  async verifyOTP() {
    if (this.mfaForm.valid && this.loginForm.valid) {
      await this.authService
        .verifyOTP(
          this.loginForm.get('username')?.value,
          this.mfaForm.get('code')?.value
        )
        .subscribe({
          next: (response) => {
            if (response === true) {
              localStorage.setItem('token', this.signInToken);

              // Display error message with proper formatting
              this.toastObj.show({
                title: 'Success!',
                content: 'OTP Validated!',
                cssClass: 'e-toast-success',
                position: { X: 'Right', Y: 'Top' },
              });

              setTimeout(() => {
                this.router.navigate(['/home']);
              }, 2500);
            } else {
              // Display error message with proper formatting
              this.toastObj.show({
                title: 'Error!',
                content: 'Invalid OTP! Please try with valid OTP.',
                cssClass: 'e-toast-danger',
                position: { X: 'Right', Y: 'Top' },
              });
            }
          },
          error: (error) => {
            var errorMessage = 'Invalid OTP! Please try with valid OTP.';

            if (error?.error?.message) {
              errorMessage = error.error.message;
            }
            // Display error message with proper formatting
            this.toastObj.show({
              title: 'Error!',
              content: errorMessage,
              cssClass: 'e-toast-danger',
              position: { X: 'Right', Y: 'Top' },
            });
          },
        });
    }
  }

  arrayBufferToBase64(array: Uint8Array): string {
    let binary = '';
    for (let i = 0; i < array.length; i++) {
      binary += String.fromCharCode(array[i]);
    }
    return btoa(binary);
  }

  navigateTologin() {
    this.isUserLoggedIn = false;
    this.isMfa = false;
    this.QRCode = undefined;
  }
}
