import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { Observable } from "rxjs";
import { SubSink } from "subsink";
import { GpoService } from "../../gpo.service";

import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { CustomgridComponent } from "../customgrid/customgrid.component";

@Component({
  selector: "fundae-gpo-grid",
  standalone: true,
  imports: [CustomgridComponent],
  templateUrl: "./gpo-grid.component.html",
  styleUrls: ["./gpo-grid.component.css"],
})
export class GpoGridComponent {
  public type = "grid";
}
