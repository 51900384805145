import { Routes } from "@angular/router";
import { authGuard } from "./auth.guard";
import { LayoutComponent } from "./layout/layout.component";
import { LoginComponent } from "./login/login.component";
import { DummyComponent } from "./dummy/dummy.component";
import { GpoDataComponent } from "./layout/gpo/gpo-data/gpo-data.component";
import { GpoGridComponent } from "./layout/gpo/gpo-grid/gpo-grid.component";
import { GpoChartComponent } from "./layout/gpo/gpo-chart/gpo-chart.component";
import { GpoAuditComponent } from "./layout/gpo/gpo-audit/gpo-audit.component";
import { GpoDiscoverComponent } from "./layout/gpo/gpo-discover/gpo-discover.component";
import { GpoHistoryComponent } from "./layout/gpo/gpo-history/gpo-history.component";
import { GpoMergeComponent } from "./layout/gpo/gpo-merge/gpo-merge.component";
import { GpoSummaryComponent } from "./layout/gpo/gpo-summary/gpo-summary.component";
import { GpoRuleComponent } from "./layout/gpo/gpo-rule/gpo-rule.component";
import { GpoPointInTimeComponent } from "./layout/gpo/gpo-point-in-time/gpo-point-in-time.component";
import { Component } from "@angular/core";
import { GpoRuleDetailsComponent } from "./layout/gpo/gpo-rule/gpo-rule-details/gpo-rule-details.component";
import { SnapshotDetailsComponent } from "./layout/gpo/gpo-point-in-time/snapshot-details/snapshot-details.component";
import { ExportSnapshotComponent } from "./layout/gpo/gpo-point-in-time/export-snapshot/export-snapshot.component";
import { Oauth2Component } from "./oauth2/oauth2.component";
import { GpoDiscoverDetailsComponent } from "./layout/gpo/gpo-discover/gpo-discover-details/gpo-discover-details.component";
import { GpoDiscoverAnalysisComponent } from "./layout/gpo/gpo-discover/gpo-discover-analysis/gpo-discover-analysis.component";

export const routes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "dummy", component: DummyComponent },
  { path: "oauth2/:token", component: Oauth2Component },

  {
    path: "home",
    component: LayoutComponent,
    children: [
      {
        path: "",
        component: GpoGridComponent,
      },
      {
        path: "grid",
        component: GpoGridComponent,
      },
      {
        path: "chart",
        component: GpoChartComponent,
      },
      {
        path: "batch",
        component: GpoDataComponent,
      },
      {
        path: "audit",
        component: GpoAuditComponent,
      },
      {
        path: "discover",
        component: GpoDiscoverComponent,
      },
      {
        path: "discover/discover-details",
        component: GpoDiscoverDetailsComponent,
      },
      {
        path: "discover/discover-details/:id",
        component: GpoDiscoverDetailsComponent,
      },
      {
        path: "discover/discover-analysis/:id",
        component: GpoDiscoverAnalysisComponent,
      },
      {
        path: "history",
        component: GpoHistoryComponent,
      },
      {
        path: "merge",
        component: GpoMergeComponent,
      },
      {
        path: "summary",
        component: GpoSummaryComponent,
      },
      {
        path: "rule",
        component: GpoRuleComponent,
      },
      { path: "rule/rule-details", component: GpoRuleDetailsComponent },
      { path: "rule/rule-details/:id", component: GpoRuleDetailsComponent },
      {
        path: "point-in-time",
        component: GpoPointInTimeComponent,
      },
      {
        path: "point-in-time/snapshot/:id",
        component: SnapshotDetailsComponent,
      },
      { path: "point-in-time/snapshot", component: SnapshotDetailsComponent },
      {
        path: "point-in-time/snapshot-export",
        component: ExportSnapshotComponent,
      },
      { path: "", redirectTo: "grid", pathMatch: "full" },
    ],
    canActivate: [authGuard],
  },

  { path: "", redirectTo: "login", pathMatch: "full" },
];
