import { CommonModule } from "@angular/common";
import { Component, Input, ViewChild } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { ButtonModule } from "@syncfusion/ej2-angular-buttons";
import { DialogComponent, DialogModule } from "@syncfusion/ej2-angular-popups";

@Component({
  selector: "app-confirm-dialog",
  standalone: true,
  imports: [CommonModule, DialogModule, ButtonModule, ReactiveFormsModule],
  templateUrl: "./confirm-dialog.component.html",
  styleUrl: "./confirm-dialog.component.css",
})
export class ConfirmDialogComponent {
  @Input()
  gpoData!: any[];

  @ViewChild("confirmDialog")
  confirmDialog!: DialogComponent;
  animationSettings: Object = { effect: "Zoom", duration: 200, delay: 0 };
  showCloseIcon: Boolean = true;
  visible: Boolean = true;
  dialogWidth: string = "500px";
  dimensionModalposition: object = { X: "center", Y: "30" };

  onClick() {}

  cancel() {}
}
