<div>
  <form
    [formGroup]="gpoForm"
    class="form-box"
    (ngSubmit)="uploadFile()"
    style="padding: 0.5rem !important"
  >
    <div class="row">
      <div class="col-md-4">
        <div class="row mt-3" style="margin-left: 10px">
          <div class="col-md-12">
            <label>Upload File</label>
            <div
              id="dropArea"
              (dragover)="onDragOver($event)"
              (drop)="onDrop($event)"
              style="
                height: auto;
                overflow: auto;
                border: 2px dashed #29b5e1;
                position: relative;
              "
            >
              <div
                (dragover)="onDragOver($event)"
                (drop)="onDrop($event)"
                style="
                  position: absolute;
                  top: 50%;
                  left: 60%;
                  transform: translate(-50%, -50%);
                  text-align: center;
                  color: #999;
                "
              >
                Drop file here supported format: csv,txt
              </div>
              <label
                for="fileInput"
                class="btn btn-secondary btn-sm browse_btn"
                style="margin-top: 3%; margin-left: 3%"
                >Browse</label
              >
              <div style="position: relative">
                <input
                  type="file"
                  id="fileInput"
                  style="
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    cursor: pointer;
                  "
                  (change)="handleFileInput($event)"
                />
              </div>
            </div>
            <div
              *ngIf="!selectedFile && gpoForm.get('fileUpload')?.errors?.['required'] && gpoForm.get('fileUpload')?.touched"
              class="text-danger mt-1"
            >
              File is required.
            </div>
          </div>
        </div>
        <div class="row mt-3" style="margin-left: 10px">
          <div *ngIf="selectedFile">
            <div class="selected-file" style="color: #5c4b4b">
              {{ selectedFile.name }}
              <span style="color: #fa1203">
                <i
                  class="fa fa-trash"
                  (click)="clearSelectedFile()"
                  style="float: right; padding-top: 4px"
                ></i>
              </span>
            </div>
            <br />
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="row mt-2">
          <div class="col-md-12">
            <label for="entity" style="margin-bottom: 15px"
              >Select Entity</label
            >
            <ejs-dropdownlist
              #entityDropdown
              cssClass="e-outline"
              (change)="onEntitySelectionChange($event)"
              id="entityDropdown1"
              [dataSource]="availableEntities"
              placeholder="Select Entity"
              [allowFiltering]="true"
              (filtering)="searchEntity($event.text)"
              formControlName="entity"
            >
            </ejs-dropdownlist>
            <div
              *ngIf="gpoForm.get('entity')?.errors?.['required']  && gpoForm.get('entity')?.touched"
              class="text-danger"
            >
              Entity is required.
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-6">
            <label>Data State</label>
            <div class="textboxes" floatLabelType="Auto">
              <ejs-radiobutton
                cssClass="e-outline"
                id="Baseline"
                name="dataState"
                value="Baseline"
                label="Baseline"
                formControlName="dataState"
                (change)="onDataStateChange('Baseline')"
                checked
              >
              </ejs-radiobutton>
              <ejs-radiobutton
                style="margin-left: 10px"
                cssClass="e-outline e-success"
                id="Updates"
                name="dataState"
                formControlName="dataState"
                value="Updates"
                label="Updates"
                (change)="onDataStateChange('Updates')"
              >
              </ejs-radiobutton>
            </div>
            <div
              *ngIf="gpoForm.get('dataState')?.errors?.['required'] && gpoForm.get('dataState')?.touched"
              class="text-danger"
            >
              Data State is required.
            </div>
          </div>

          <div class="col-md-6">
            <label>File Type</label>
            <div class="textboxes" floatLabelType="Auto">
              <ejs-radiobutton
                title="Feature not available"
                cssClass="e-outline"
                label=".csv"
                id="csv"
                name="fileType"
                value="csv"
                formControlName="fileType"
                (change)="onFileTypeChange('csv')"
              >
              </ejs-radiobutton>
              <ejs-radiobutton
                style="margin-left: 10px"
                cssClass="e-outline e-success"
                id="txt"
                name="fileType"
                formControlName="fileType"
                value="txt"
                (change)="onFileTypeChange('txt')"
                label=".txt"
                checked
              >
              </ejs-radiobutton>
            </div>
            <div
              *ngIf="gpoForm.get('fileType')?.errors?.['required'] && gpoForm.get('fileType')?.touched"
              class="text-danger"
            >
              File Type is required.
            </div>
          </div>
        </div>

        <div class="row mt-3" *ngIf="gpoForm.get('fileType')?.value == 'txt'">
          <div class="col-md-12">
            <label>Delimiters</label>
            <ejs-dropdownlist
              #delimeters
              id="delimeters"
              placeholder="Select Delimeters"
              [dataSource]="delimiters"
              cssClass="e-outline"
              formControlName="delimeters"
              [fields]="delimiterFields"
              (change)="onDelimiterChange($event.value)"
            >
              {{ gpoForm.get("delimiters") }}
            </ejs-dropdownlist>
            <div
              *ngIf="gpoForm.get('delimeters')?.errors?.['required'] && gpoForm.get('delimeters')?.touched"
              class="text-danger"
            >
              Delimiters is required.
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="row mt-2" style="margin-right: 10px">
          <div class="col-md-12">
            <label style="margin-bottom: 15px">Apply Business Rules</label>
            <ejs-multiselect
              #ruleDropdown
              cssClass="e-outline"
              id="ruleDropdown"
              [dataSource]="availableRules"
              placeholder="Select Rule To Apply"
              [allowFiltering]="true"
              (filtering)="searchRules($event.text)"
              formControlName="ruleId"
              [mode]="mode"
            >
            </ejs-multiselect>
          </div>
        </div>
        <!-- Removed as unwanted things -->
        <!-- <div class="row mt-3">
          <div class="col-md-12">
            <span><i class="fa fa-calendar" style="margin-right: 5px;"></i> Set Rebate Eligible Date</span>
          </div>
          <div class="col-md-12 mt-3">
            <span><i class="fas fa-redo-alt" style="margin-right: 5px;"></i> Update COT</span>
          </div>
        </div> -->
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <button
        ejs-button
        class="btn btn-secondary mr-2 cancle_btn"
        (click)="clearForm()"
      >
        Cancel
      </button>
      <button
        ejs-button
        style="margin-left: 10px; margin-right: 10px"
        class="btn btn-primary search_btn"
        type="submit"
      >
        Upload
      </button>
    </div>
  </form>
</div>

<!-- <div class="d-flex justify-content-end" style="font-size: 16px">
  <i class="fas fa-file-csv fa-lg icon-spacing" title="csv" style="cursor: pointer; color: #212529;"></i>
  <i class="fas fa-file-pdf fa-lg icon-spacing" title="pdf" style="cursor: pointer; color: #212529;"></i>
  <i class="fas fa-file-excel fa-lg icon-spacing" title="xlsx" style="cursor: pointer; color: #212529;"></i>
  <i class="fa fa-arrows-alt fa-lg icon-spacing" title="fullscreen" style="cursor: pointer; color: #212529;"
    (click)="toggleFullscreen()"></i>
</div> -->
<br />
<div
  style="
    background-color: #dfeff4;
    padding: 0.5rem;
    border: 1px solid #27d2f8;
    border-radius: 4px;
  "
>
  <div class="row" style="margin-bottom: 0.5rem">
    <div class="col-md-12">
      <div class="row align-items-center">
        <div class="col-md-5">
          <span style="font-weight: 500; font-size: 16px"
            >Batch processing history</span
          >
        </div>
        <div class="col-md-7">
          <div class="d-flex justify-content-end">
            <!-- Icon container for CSV -->
            <div style="margin-right: 1rem">
              <img
                style="height: 2rem; width: auto; cursor: pointer"
                src="../../../../assets/images/export-options/csv.png"
                (click)="cvsGenerator()"
              />
            </div>
            <!-- Icon container for Excel -->
            <div style="margin-right: 1rem">
              <img
                style="height: 2rem; width: auto; cursor: pointer"
                src="../../../../assets/images/export-options/xlsx.png"
                (click)="xlxsGenerator()"
              />
            </div>
            <!-- Icon container for Fullscreen -->
            <div>
              <img
                style="height: 2rem; width: auto; cursor: pointer"
                src="../../../../assets/images/export-options/fullscreen.png"
                (click)="toggleFullscreen()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="grid-container" style="height: auto">
    <ejs-grid
      #BatchGrid
      id="BatchGrid"
      class="syncfusion-grid"
      [dataSource]="gpoData"
      [allowPaging]="true"
      [pageSettings]="{ pageSize: pageSize }"
      [allowFiltering]="true"
      [filterSettings]="{
        type: 'Excel'
      }"
      allowReordering="true"
      allowResizing="true"
      autoFit="true"
      [height]="gridHeight"
      [allowExcelExport]="true"
      (excelExportComplete)="batchExportComplete()"
    >
      <e-columns>
        <e-column
          textAlign="Center"
          title="fileId"
          field="fileId"
          headerText="File ID"
          width="130"
        ></e-column>
        <e-column
          field="fileName"
          headerText="File Name"
          width="350"
        ></e-column>
        <e-column
          field="userName"
          headerText="User Name"
          width="180"
        ></e-column>
        <e-column
          textAlign="Center"
          field="processDate"
          headerText="Process Date"
          width="150"
          type="date"
          format="MM-dd-yyyy"
        ></e-column>
        <e-column
          textAlign="Center"
          field="entityId"
          headerText="Entity ID"
          width="130"
        ></e-column>
        <e-column
          textAlign="Center"
          field="entityName"
          headerText="Entity Name"
          width="200"
        ></e-column>
        <e-column
          textAlign="Center"
          field="fileType"
          headerText="File Type"
          width="150"
        ></e-column>
        <e-column
          textAlign="Center"
          field="status"
          headerText="Status"
          width="200"
        ></e-column>
        <e-column
          textAlign="Left"
          field="errorMessage"
          headerText="Error Description"
          width="250"
        >
          <ng-template #template let-data>
            <span style="white-space: normal">{{ data.errorMessage }}</span>
          </ng-template>
        </e-column>
        <e-column
          textAlign="Center"
          field="processedRecords"
          headerText="Number of Records Processed"
          width="280"
        ></e-column>
      </e-columns>
    </ejs-grid>
    <div id="fullscreen-close-btn" style="display: none">
      <button
        class="btn btn-secondary cancle-btn"
        style="margin-left: 10px; width: auto"
        (click)="exitFullscreen()"
      >
        Close Fullscreen
      </button>
    </div>
  </div>
</div>

<div *ngIf="isStatus">
  <ejs-dialog
    #confirmDialog
    [visible]="visible"
    [header]="headerAdd"
    [width]="dialogWidth"
    [animationSettings]="animationSettings"
    isModal="true"
    [position]="dimensionModalposition"
  >
    <ng-template #content>
      <form>
        <label>{{ message }}</label>
        <br /><br />
        <div class="button-container">
          <button
            type="button"
            ejs-button
            class="btn btn-primary btn-sm"
            (click)="onClick('APPROVED')"
            style="background-color: green"
          >
            Approve
          </button>
          <button
            ejs-button
            type="button"
            ejs-button
            class="btn btn-primary btn-sm"
            (click)="cancel('REJECTED')"
            style="margin-left: 10px; background-color: red"
          >
            Reject
          </button>
        </div>
      </form>
    </ng-template>
  </ejs-dialog>
</div>

<ejs-toast #toast></ejs-toast>
<ejs-dialog
  #confirmationDialog
  [visible]="true"
  [isModal]="true"
  cssClass="custom-dialog"
  *ngIf="showConfirmationDialog"
  [position]="position"
  [content]="confirmationDialogContent"
  [showCloseIcon]="true"
  [height]="'700px'"
  [width]="'500px'"
  [header]="'Business Rules Execution Summary'"
  (close)="showConfirmationDialog = false"
>
  <ng-template #confirmationDialogContent>
    <app-business-rule-confirmation-dialog
      [data]="impact"
      (gpoDataGridEvent)="gpoDataGrid($event)"
      (okClick)="onConfirmation($event)"
      (closeClick)="closeDialog($event)"
    ></app-business-rule-confirmation-dialog>
  </ng-template>
</ejs-dialog>
<ejs-dialog
  #ejDialog
  [visible]="true"
  [header]="'Execute Business Rule Sequences'"
  *ngIf="showReorderDialog"
  [isModal]="true"
  [position]="position"
  [content]="dialogContent"
  [showCloseIcon]="true"
  cssClass="custom-dialog"
  [height]="'2500px'"
  [width]="'1000px'"
  (close)="showReorderDialog = false"
>
  <ng-template #dialogContent>
    <app-business-rule-sequence-dialog
      [data]="selectedItems"
      (dataEvent)="receiveDataFromDialog($event)"
      (okClick)="onDialogOk($event)"
      (closeClick)="closeDialog($event)"
    ></app-business-rule-sequence-dialog>
  </ng-template>
</ejs-dialog>
<ejs-dialog
  #gpodetailsDialog
  [visible]="true"
  [header]="'Summary :'"
  *ngIf="showGpodetailsDialog"
  [isModal]="true"
  [content]="GpodetailsDialogContent"
  [showCloseIcon]="true"
  cssClass="custom-dialog"
  [position]="position"
  (close)="showGpodetailsDialog = false"
>
  <ng-template #GpodetailsDialogContent>
    <app-business-rule-detail-dialog
      [data]="gpoGridData"
      (businessRuleDetailOkClick)="onGpodetailsDialogOk()"
      (businessRuleDetailCloseClick)="onGpodetailsDialogClosed()"
    ></app-business-rule-detail-dialog>
  </ng-template>
</ejs-dialog>

<ejs-dialog
  #ejDialog
  [visible]="true"
  [header]="'Choose File for Rule Execution'"
  *ngIf="showExecutionSectionPopup"
  [isModal]="true"
  [position]="position"
  [content]="dialogContent"
  [showCloseIcon]="true"
  cssClass="custom-dialog"
  [height]="'2500px'"
  [width]="'1000px'"
  (close)="showExecutionSectionPopup = false"
>
  <ng-template #dialogContent>
    <div class="container-fluid dialog-content">
      <div class="row">
        <div
          class="col-12"
          style="border-top: 1px solid grey; padding-top: 1rem"
        >
          <p>
            Select a file from the list below. The chosen file will be used to
            execute the rule.
          </p>
        </div>
      </div>
      <div class="row" style="padding: 0.5rem">
        <ejs-dropdownlist
          #ruleExecutionDropdown
          id="ddropdown"
          [dataSource]="pendingRuleExecutionFiles"
          [fields]="fields"
          placeholder="Select a file..."
          (change)="selectFileForExecution($event)"
        ></ejs-dropdownlist>
      </div>
      <div class="row" style="padding-top: 1rem">
        <div class="col-12 d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-primary save_btn"
            style="margin-right: 0.5rem"
            (click)="onRuleExecutionClick()"
          >
            Run
          </button>
          <button
            type="button"
            class="btn btn-secondary cancle_btn"
            (click)="closeRuleExecutionDialog()"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</ejs-dialog>
