<ejs-dialog
  #openViewDialog
  [visible]="visible"
  [header]="header"
  [width]="dialogWidth"
  [height]="dialogHeight"
  [animationSettings]="animationSettings"
  isModal="true"
  [position]="dimensionModalposition"
  [showCloseIcon]="true"
>
  <ng-template #content>   
    <div class="mt-5">
      <ejs-grid
        [dataSource]="dataSource"
        [allowPaging]="true"
        [allowSorting]="true"
      >
        <e-columns>
          <e-column headerText="Edit">
            <ng-template #template let-data>
                <i class="fas fa-edit"></i>
            </ng-template>
          </e-column>
          <e-column field="viewName" headerText="View Name"></e-column>
          <e-column
            field="viewDescription"
            headerText="View Description"
          ></e-column>
          <e-column field="createdOn" headerText="Created On"></e-column>
          <e-column
            field="lastUpdatedOn"
            headerText="Last Updated On"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </div>
  </ng-template>
</ejs-dialog>
