import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localDate',
  standalone: true
})
export class LocalDatePipe implements PipeTransform {

  transform(value: Date | string, format: string = 'yyyy-MM-dd'): string {
    if (!value) return '';
    
    const date = new Date(value);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(date.getTime() - userTimezoneOffset);

    // For simplicity, using toISOString and splitting, adjust if different formatting is needed
    return adjustedDate.toISOString().split('T')[0];
  }
}
