import {
  AfterViewInit,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  inject,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  AppBarAllModule,
  MenuAllModule,
  TabComponent,
  TabModule,
} from "@syncfusion/ej2-angular-navigations";
import { ButtonAllModule } from "@syncfusion/ej2-angular-buttons";
import { DropDownButtonAllModule } from "@syncfusion/ej2-angular-splitbuttons";
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterModule,
} from "@angular/router";
import { LoginComponent } from "../login/login.component";
import { AuthService } from "../auth.service";
import { GpoDataComponent } from "./gpo/gpo-data/gpo-data.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { ToastService } from "../common/toast.service";
import { DialogComponent, DialogModule } from "@syncfusion/ej2-angular-popups";

@Component({
  selector: "app-layout",
  standalone: true,
  templateUrl: "./layout.component.html",
  styleUrl: "./layout.component.css",
  imports: [
    AppBarAllModule,
    ButtonAllModule,
    DropDownButtonAllModule,
    MenuAllModule,
    RouterModule,
    LoginComponent,
    GpoDataComponent,
    TabModule,
    CommonModule,
    FormsModule,
    RouterLink,
    RouterLinkActive,
    DialogModule,
    CommonModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LayoutComponent implements OnInit, AfterViewInit {
  authService = inject(AuthService);

  @ViewChild("element") tabInstance!: TabComponent;

  public appBarColor = {
    colorMode: "Primary",
    colorClass: "e-primary",
    isPrimary: false,
    loginClass: "e-inherit login",
  };

  dialogPosition = { X: "center", Y: "center" };
  @ViewChild("sessionExpiredDailog")
  public sessionExpiredDialog!: DialogComponent;

  @ViewChild("logoutDailog")
  public logoutDialog!: DialogComponent;

  isAdmin = false;

  tabs: any[] = [
    { text: "grid", url: "grid", iconCss: "custom-icon-grid" },
    { text: "chart", url: "chart", iconCss: "custom-icon-sitemap" },
    // { text: "audit", url: "audit", iconCss: "custom-icon-clipboard-check" },
    // { text: "summary", url: "summary", iconCss: "custom-icon-file-alt" },
    { text: "merge", url: "merge", iconCss: "custom-icon-code-branch" },
    { text: "rule", url: "rule", iconCss: "custom-icon-ruler" },
    // { text: "history", url: "history", iconCss: "custom-icon-history" },
    {
      text: "Snapshot-Analysis",
      url: "discover",
      iconCss: "custom-icon-search",
    },
    { text: "batch", url: "batch", iconCss: "custom-icon-layer-group" },
    {
      text: "point-in-time",
      url: "point-in-time",
      iconCss: "custom-icon-point-in-time",
    },
  ];

  constructor(
    private _authService: AuthService,
    public _router: Router,
    private _route: ActivatedRoute,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.checkAdminRole();
  }

  ngAfterViewInit(): void {
    this.selectTabBasedOnRoute();
  }

  navigateToHomePage(): void {
    this._router.navigate(["/home"]);
  }

  navigateToadmin(): void {
    this.authService.addModuleHistory("gpo").subscribe((data) => {
      if (data) {
        window.location.href = "http://localhost:4300/home";
      }
    });
  }

  checkAdminRole() {
    this._authService.checkAdminRole().subscribe((data) => {
      this.isAdmin = data;
    });
  }

  selectTabBasedOnRoute() {
    const currentUrl = this._router.url;
    // Extract the segment after '/home/' which is relevant for tab selection
    const pathSegment = currentUrl.split("/")[2]; // This gets the segment immediately following '/home/'

    // If there is a more complex path like '/home/point-in-time/snapshot', it still correctly focuses on 'point-in-time'
    const tabIndex = this.tabs.findIndex(
      (tab) => pathSegment && tab.url.startsWith(pathSegment)
    );
    this.initialSelected(tabIndex);
  }

  initialSelected(tabIndex: number) {
    if (tabIndex !== -1) {
      let selectedTab = this.tabs[tabIndex];
      this._router.navigate([selectedTab.url], { relativeTo: this._route });
      if (this.tabInstance) {
        setTimeout(() => (this.tabInstance.selectedItem = tabIndex), 0);
      }
    }
  }

  openLogoutDialog() {
    this.logoutDialog.show();
  }

  closeLogoutModal() {
    this.logoutDialog.hide();
  }
  logout() {
    const token = this.authService.getToken();

    const obj = {
      token: token,
    };
    this.authService.logout(obj);
  }

  tabSelected(e: any) {
    let tab = this.tabs[e.selectedIndex];
    this._router.navigate([tab.url], { relativeTo: this._route });
  }
}
