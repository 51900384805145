<div id="gridContainer">
  <button (click)="download()">Download</button>
<ejs-diagram
  #diagram
  id="diagram"
  width="100%"
  height="580px"
  [getNodeDefaults]="getNodeDefaults"
  [getConnectorDefaults]="getConnectorDefaults"
  [snapSettings]="snapSettings"
  [layout]="layout"
  [dataSourceSettings]="dataSourceSettings"
>
</ejs-diagram>
</div>
