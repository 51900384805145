import { CommonModule } from "@angular/common";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  ConnectorModel,
  DataBindingService,
  DataSourceModel,
  DecoratorModel,
  Diagram,
  DiagramAllModule,
  DiagramComponent,
  HierarchicalTreeService,
  LayoutModel,
  NodeModel,
  ShapeStyleModel,
  SnapSettingsModel,
  TextModel,
  IExportOptions,
  TreeInfo,
} from "@syncfusion/ej2-angular-diagrams";
import { DataManager, Query } from "@syncfusion/ej2-data";
import html2canvas from "html2canvas";

@Component({
  selector: "app-orgchart",
  standalone: true,
  imports: [CommonModule, DiagramAllModule],
  providers: [HierarchicalTreeService, DataBindingService],
  templateUrl: "./orgchart.component.html",
  styleUrl: "./orgchart.component.css",
})
export class OrgchartComponent implements OnInit {
  @ViewChild("diagram")
  public diagram?: DiagramComponent;
  public snapSettings?: SnapSettingsModel;
  public items?: DataManager;
  public layout?: LayoutModel;
  public dataSourceSettings?: DataSourceModel;

  @Input("data") data!: any[];

  //   //Sets the default properties for all the Nodes
  public getNodeDefaults(obj: NodeModel | any, diagram: Diagram): NodeModel {
    obj.width = 150;
    obj.height = 50;
    obj.borderColor = "white";
    obj.style.fill = "#6BA5D7";
    obj.borderWidth = 1;
    obj.annotations = [
      {
        content: obj.data["Role"],
        style: {
          color: "white",
        },
      },
    ];
    return obj;
  }

  //Sets the default properties for all the connectors
  public getConnectorDefaults(
    connector: ConnectorModel,
    diagram: Diagram
  ): ConnectorModel {
    connector.style = {
      strokeColor: "#6BA5D7",
      strokeWidth: 2,
    };
    (
      ((connector as ConnectorModel).targetDecorator as DecoratorModel)
        .style as ShapeStyleModel
    ).fill = "#6BA5D7";
    (
      ((connector as ConnectorModel).targetDecorator as DecoratorModel)
        .style as ShapeStyleModel
    ).strokeColor = "#6BA5D7";
    ((connector as ConnectorModel).targetDecorator as DecoratorModel).shape =
      "None";
    connector.type = "Orthogonal";
    return connector;
  }
  ngOnInit(): void {
    this.snapSettings = {
      constraints: 0,
    };
    this.items = new DataManager(this.data as JSON[], new Query().take(7));
    //Uses layout to auto-arrange nodes on the Diagram page
    this.layout = {
      //Sets layout type
      type: "OrganizationalChart",
      //Defines getLayoutInfo
      getLayoutInfo: (node: Node | any, options: TreeInfo) => {
        if (!options.hasSubTree) {
          options.type = "Right";
          options.orientation = "Vertical";
        }
      },
    };
    //Configures data source for Diagram
    this.dataSourceSettings = {
      id: "Id",
      parentId: "Manager",
      dataSource: this.items,
    };
  }

    download(){
      // const gridElement = document.getElementById('gridContainer');
      // html2canvas(gridElement as HTMLElement).then((canvas) => {
      //   // Create an image and download it
      //   const image = canvas.toDataURL('image/jpeg', 1.0);
      //   let link = document.createElement('a');
      //   link.href = image;
      //   link.download = 'orgchart.jpeg';
      //   link.click();
      // });
      let exportOptions: IExportOptions = {};
      exportOptions.format = 'PNG';
      exportOptions.mode = 'Download';
      exportOptions.region = 'PageSettings';
      exportOptions.fileName = 'Export';
      exportOptions.pageHeight = 800;
      exportOptions.pageWidth = 800;
      this.diagram?.exportDiagram(exportOptions);
    }
}
