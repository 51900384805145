import { CommonModule } from "@angular/common";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  GridAllModule,
  GridComponent,
  RowDataBoundEventArgs,
} from "@syncfusion/ej2-angular-grids";
import { GpoService } from "../../../gpo.service";
interface RowData {
  [key: string]: any;
}
@Component({
  selector: "app-snapshot-analysis-grid",
  standalone: true,
  imports: [GridAllModule, FormsModule, CommonModule],
  templateUrl: "./snapshot-analysis-grid.component.html",
  styleUrl: "./snapshot-analysis-grid.component.css",
})
export class SnapshotAnalysisGridComponent implements OnInit {
  @ViewChild("analysisGrid") public analysisGrid!: GridComponent;

  public pageSettings?: Object = { pageSize: 10 };
  public formatoptions?: Object = { type: "date", format: "MM-dd-yyyy" };
  public dateOptions?: string = "date";

  @Input() public dataSource: any[] = [];
  @Input() public allowGrouping!: boolean;
  @Input() public groupSettings!: Object;

  @Input() attributeChanges: { [key: string]: string[] } = {};

  ngOnInit(): void {
    console.log("Analysis grid are loaded!");
  }

  rowDataBound(args: RowDataBoundEventArgs): void {
    if (args.data && args.row && this.attributeChanges) {
      if (Object.keys(this.attributeChanges).length > 0) {
        const groupID = (args.data as RowData)["GroupID"];
        this.attributeChanges[groupID].forEach((item: any) => {
          const cellIndex = this.getColumnIndex(args.data, item);
          const cell = args.row?.querySelector(
            `[aria-colindex="${cellIndex}"]`
          );
          if (cell) {
            cell.classList.add("highlight-difference");
          }
        });
      } else {
        console.log("No Changes Detected");
      }
    }
  }
  private getColumnIndex(data: any, field: string): number {
    return Object.keys(data).indexOf(field) + 1;
  }
}
