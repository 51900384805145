import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { GridAllModule } from "@syncfusion/ej2-angular-grids";
import { GpoService } from "../../layout/gpo.service";
import { GridComponent, ToolbarItems } from "@syncfusion/ej2-angular-grids";
import { ClickEventArgs } from "@syncfusion/ej2-angular-navigations";

@Component({
  selector: "app-business-rule-detail-dialog",
  standalone: true,
  imports: [GridAllModule, CommonModule],
  templateUrl: "./business-rule-detail-dialog.component.html",
  styleUrl: "./business-rule-detail-dialog.component.css",
})
export class BusinessRuleDetailDialogComponent {
  public toolbar?: ToolbarItems[] = ["ExcelExport", "CsvExport", "Search"];
  @ViewChild("grid") public grid?: GridComponent;
  @Output() businessRuleDetailOkClick = new EventEmitter();

  @Output() businessRuleDetailCloseClick = new EventEmitter();

  @Input() data!: any[];
  constructor(private gpoService: GpoService) {}
  onOkClick() {
    // Emit some data on OK click
    this.businessRuleDetailOkClick.emit();
  }

  closeDialog() {
    // Emit some data on OK click
    this.businessRuleDetailCloseClick.emit();
  }

  scrollSettings = {
    enableTouchScroll: true,
    width: "auto", // Adjust 'auto' to ensure the grid width automatically adjusts.
    height: "400px", // Example height, adjust as needed
  };

  getColumnNames(): string[] {
    return this.data.length > 0 ? Object.keys(this.data[0]) : [];
  }

  formatHeader(key: string): string {
    // return key.replace(/([A-Z])/g, ' $1')
    //           .replace(/^./, str => str.toUpperCase()) // Capitalize the first letter
    //           .trim();
    return key
      .replace(/([A-Z])/g, " $1")
      .trim()
      .split(" ")
      .map((word) =>
        word.length <= 3 && word.toUpperCase() != "END"
          ? word.toUpperCase()
          : word.charAt(0).toUpperCase() + word.slice(1)
      )
      .join(" ");
  }
  cvsGenerator() {
    // this.gpoService.downloadRuleCsvFile(this.toggleBarChecked);
  }
  xlxsGenerator() {
    // this.gpoService.downloadRuleXlxsFile(this.toggleBarChecked);
  }
  toolbarClick(args: ClickEventArgs): void {
    if (args.item.id?.endsWith("excelexport")) {
      // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
      (this.grid as GridComponent).excelExport();
    } else if (args.item.id?.endsWith("csvexport")) {
      // 'Grid_csvexport' -> Grid component id + _ + toolbar item name
      (this.grid as GridComponent).csvExport();
    }
  }
}
