import { CommonModule, DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ButtonAllModule,
  CheckBoxAllModule,
} from "@syncfusion/ej2-angular-buttons";
import { DatePickerModule } from "@syncfusion/ej2-angular-calendars";
import {
  CheckBoxSelectionService,
  DropDownListAllModule,
  MultiSelectModule,
} from "@syncfusion/ej2-angular-dropdowns";
import { TextBoxAllModule } from "@syncfusion/ej2-angular-inputs";
import { LocalDatePipe } from "../../../../common/pipes/local-date.pipe";
import { GpoService } from "../../../gpo.service";
import { DialogComponent, DialogModule } from "@syncfusion/ej2-angular-popups";
import { SnapshotConfirmationDialogComponent } from "../../../../common/snapshot-confirmation-dialog/snapshot-confirmation-dialog.component";
import { SnapshotCancelDialogComponent } from "../../../../common/snapshot-cancel-dialog/snapshot-cancel-dialog.component";
import { SnapshotDetailsDialogComponent } from "../../../../common/snapshot-details-dialog/snapshot-details-dialog.component";
import { ToastService } from "../../../../common/toast.service";

@Component({
  selector: "app-snapshot-details",
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropDownListAllModule,
    TextBoxAllModule,
    MultiSelectModule,
    DatePickerModule,
    DialogModule,
    ButtonAllModule,
    CheckBoxAllModule,
    SnapshotConfirmationDialogComponent,
    SnapshotCancelDialogComponent,
    SnapshotDetailsDialogComponent,
  ],
  providers: [CheckBoxSelectionService, LocalDatePipe, DatePipe],

  templateUrl: "./snapshot-details.component.html",
  styleUrl: "./snapshot-details.component.css",
})
export class SnapshotDetailsComponent implements OnInit {
  snapshotForm!: FormGroup;
  isUpdateMode: boolean = false;
  mode!: string; // Ensure mode is declared
  confirmationDialogData = {
    title: "Confirm Snapshot Creation",
    content: "Confirmation Dialog Content",
  };
  public showConfirmationDialog: boolean = false;
  public showCancelDialog: boolean = false;
  public showDetailsDialog: boolean = false;

  @ViewChild("confirmationDialog") confirmationDialog!: DialogComponent;
  @ViewChild("cancelDialog") cancelDialog!: DialogComponent;
  dialogData = { title: "Dialog Title", content: "Dialog Content" };
  cancelDialogData = {
    title: "Cancel Snapshot Creation",
    content: "Cancel Dialog Content",
  };

  gpoDetails: any[] = [];
  position = { X: "center", Y: "center" };
  maxSnapshotNameLength = 250;
  public snapshot_id: number = 0;
  public selectedValues: any[] = [];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private gpoService: GpoService,
    private localDatePipe: LocalDatePipe,
    private router: Router,
    private toastService: ToastService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.fetchGpoDetails();
    this.mode = "CheckBox";

    this.snapshotForm = this.fb.group(
      {
        snapshotId: [{ value: "", disabled: true }],
        snapshotName: ["", [Validators.required, this.validateInput]],
        selectedGPO: [[], Validators.required], // Initial value
        snapshotDate: ["", Validators.required],
      },
      { validators: this.gpoDetails.length > 0 ? Validators.required : null }
    );
  }

  async patchFormValues() {
    await this.route.paramMap.subscribe((params) => {
      const id = params.get("id");
      if (id) {
        this.snapshot_id = Number(id);
        this.gpoService
          .getSnapshotById(this.snapshot_id)
          .subscribe((data: any) => {
            this.isUpdateMode = true;
            this.selectedValues = this.patchSelectedGPOs(data.gpoList);
            this.snapshotForm.patchValue({
              snapshotId: data.id,
              snapshotName: data.snapshotName,
              snapshotDate: new Date(this.formatDateInUTC(data.snapshotDate)),
              selectedGPO: this.selectedValues,
            });
          });
      }
    });
  }

  formatDateInUTC(date: any): any {
    const utcDate = new Date(date);
    return this.datePipe.transform(utcDate, "MM-dd-yyyy", "UTC");
  }

  patchSelectedGPOs(jsonString: any) {
    const selected = JSON.parse(jsonString);
    return selected.map((item: any) => item.gpoId);
  }

  validateInput(control: any) {
    const value = control.value;
    const hasSpecialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
      value
    );
    if (hasSpecialChars) {
      return { hasSpecialChars: true };
    }
    return null;
  }

  resetForm(): void {
    this.showCancelDialog = true;
  }

  goBack() {
    this.router.navigate(["/home/point-in-time"]);
  }

  onSubmit(): void {
    this.showConfirmationDialog = true;
  }

  get selectedGPOs(): any[] {
    return this.gpoDetails.filter((option) =>
      this.snapshotForm.value.selectedGPO.includes(option.gpoId)
    );
  }

  convertToServerData(): any {
    return {
      snapshotName: this.snapshotForm.get("snapshotName")?.value,
      gpoList: JSON.stringify(this.selectedGPOs), // Assuming the server expects 'gpoList' instead of 'selectedGPO'
      snapshotDate: this.localDatePipe.transform(
        this.snapshotForm?.get("snapshotDate")?.value
      ),
    };
  }

  async fetchGpoDetails() {
    await this.gpoService.getGpoDetails().subscribe({
      next: (response: string | any) => {
        this.gpoDetails = response;
        this.patchFormValues();
      },
      error: (error: any) => {
        this.toastService.showToast(
          "Error",
          "Fail to load GPO details!",
          "error"
        );
      },
    });
  }

  onConfirmation(data: any) {
    this.showConfirmationDialog = false;

    this.gpoService
      .createSnapshotDetailsData(
        this.convertToServerData(),
        this.snapshot_id,
        this.isUpdateMode
      )
      .subscribe({
        next: (response) => {
          let status = this.isUpdateMode ? "Updated" : "created";
          this.toastService.showToast(
            "Success",
            "Snapshot " + status + " successfully.",
            "success"
          );
          setTimeout(() => {
            this.router.navigate(["/home/point-in-time"]);
          }, 2000);
        },
        error: (error) => {
          this.toastService.showToast(
            "Error",
            "Fail to create snapshot.",
            "error"
          );
        },
      });
  }

  onConfirmationSnapshot(data: any) {
    this.router.navigate(["/home/point-in-time"]);
  }

  onCancel(data: any) {
    this.showCancelDialog = false;
    this.snapshotForm.reset();
  }

  closeDialog(data: any): void {
    this.showConfirmationDialog = false;
    this.showCancelDialog = false;
  }
}
