import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { Observable } from "rxjs";
import { SnapshotAnalysisModel } from "../model/gpo-discover/snapshot-analysis-model";
import { Snapshot } from "../model/gpo-discover/snapshot";
import { SnapshotDetail } from "../model/gpo-discover/snapshot-detail";

@Injectable({
  providedIn: "root",
})
export class GpoDiscoverService {
  private BASE_URL = environment.apiURL;

  constructor(private http: HttpClient) {}

  fetchSnapshotAnalysisModel(): Observable<SnapshotAnalysisModel[]> {
    const apiUrl =
      this.BASE_URL + "api/snapshot-analysis/snapshot_analysis_model";

    return this.http.get<SnapshotAnalysisModel[]>(apiUrl);
  }

  fetchSnapshots(): Observable<Snapshot[]> {
    const apiUrl = this.BASE_URL + "api/gpo/get-snapshots";

    return this.http.get<Snapshot[]>(apiUrl);
  }

  fetchSnapshotDetailsByid(id: number): Observable<SnapshotDetail> {
    const apiUrl = this.BASE_URL + "api/snapshot-analysis/" + id;

    return this.http.get<SnapshotDetail>(apiUrl);
  }

  fetchAllSnapshotDetails(): Observable<SnapshotDetail[]> {
    const apiUrl = this.BASE_URL + "api/snapshot-analysis";

    return this.http.get<SnapshotDetail[]>(apiUrl);
  }

  submitSnapshotDetail(snapshotDetail: any): Observable<any> {
    const apiUrl = this.BASE_URL + "api/snapshot-analysis";

    return this.http.post(`${apiUrl}`, snapshotDetail);
  }

  updateSnapshotDetail(snapshotDetail: any, id: number): Observable<any> {
    const apiUrl = this.BASE_URL + "api/snapshot-analysis/update/" + id;

    return this.http.post(`${apiUrl}`, snapshotDetail);
  }
  deleteSnapshotDetailsByid(id: any): Observable<any> {
    const url = this.BASE_URL + "api/snapshot-analysis/delete/" + id;
    return this.http.get(url);
  }
}
