<div class="mt-3">
  <form [formGroup]="businessRuleForm" (ngSubmit)="onSubmit()" class="row g-3 align-items-end form-box-d">
    <div class="row" style="padding-top: 15px;">
      <div class="col col-sm-3">
        <label for="businessRuleName" class="form-label">Business Rule Name</label>
        <input type="text" id="businessRuleName" class="form-control" formControlName="businessRuleName" style="border: 2px solid #29b5e1;">
      </div>
      <div class="col col-sm-3">
        <label for="businessRuleDesc" class="form-label">Business Rule Description</label>
        <input type="text" id="businessRuleDesc" class="form-control" formControlName="businessRuleDesc" style="border: 2px solid #29b5e1;">
      </div>
      <div class="col-auto justify-content-end align-items-end" style="margin-top: 2.3em;">
        <button type="submit" class="btn btn-primary e-primary save_btn"
          [disabled]="businessRuleForm.invalid">{{isEdit == true ? 'Update' : 'Create'}}</button>
        <button type="button" class="btn btn-secondary cancle_btn" (click)="onCancel()"
          style="margin-left: 10px;">Cancel</button>
      </div>
    </div>
    <div class="row">
      <div class="col col-sm-3">
        <div
          *ngIf="businessRuleForm.get('businessRuleName')?.hasError('nameExists') && businessRuleForm.get('businessRuleName')?.dirty"
          class="text-danger">
          Name already exists.
        </div>
        <div
          *ngIf="businessRuleForm.get('businessRuleName')?.hasError('specialCharacter') && businessRuleForm.get('businessRuleName')?.dirty"
          class="text-danger">
          Name should not start with whitespace.
        </div>
        <div
          *ngIf="businessRuleForm.get('businessRuleName')?.hasError('required') && businessRuleForm.get('businessRuleName')?.touched"
          class="text-danger">
          Name is required.
        </div>
      </div>
      <div class="col col-sm-3">
        <div
          *ngIf="businessRuleForm.get('businessRuleDesc')?.hasError('required') && businessRuleForm.get('businessRuleDesc')?.touched"
          class="text-danger">
          Description is required.
        </div>
        <div
        *ngIf="businessRuleForm.get('businessRuleDesc')?.hasError('specialCharacter') && businessRuleForm.get('businessRuleDesc')?.dirty"
        class="text-danger">
          Description should not start with whitespace.
        </div>
      </div>
    </div>
  </form>
  <br />
 
</div>
<app-gpo-rulecondition *ngIf="business_rule_id > 0" [business_rule_id]="business_rule_id"></app-gpo-rulecondition>