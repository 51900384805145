import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { seriesRender } from '@syncfusion/ej2-angular-charts';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PredealService {

  private BASE_URL = environment.apiURL;

  getAllSimulation(): Observable<any> {
    const apiUrl = this.BASE_URL+'simulations';

    return this.http.get<any>(apiUrl);
  }

  constructor(private http: HttpClient) {}

  saveEntity(data: any): Observable<any> {
    const url = this.BASE_URL+'simulations'; // Adjust URL
    return this.http.post(url, data);
  }

  editEntity(id: number, data: any): Observable<any> {
    const url = this.BASE_URL+`simulations/${id}`; // Adjust URL
    return this.http.post(url, data);
  }

  submitScenario(data: any): Observable<any> {
    const url = this.BASE_URL+'scenarios'; // Replace with your actual endpoint
    return this.http.post(url, data);
  }

  updateScenario(scenariId: number, data: any): Observable<any> {
    const url = this.BASE_URL+`scenarios/${scenariId}`; // Replace with your actual endpoint
    return this.http.post(url, data);
  }

  updateReferenceata(simulationId: number, data: any): Observable<any> {
    const url = this.BASE_URL+`simulations/reference-data/${simulationId}`;
    return this.http.post(url, data);
  } 

  fetchSimulationById(simulationId: number): Observable<any> {
    const url = this.BASE_URL+`simulations/${simulationId}`;
    return this.http.get(url);
}

  fetchNdcBrand(search:any): Observable<any> {
    const formData = new FormData();
    formData.append("searchText", search)
    const url = this.BASE_URL+`config/ndc-brand`;
    return this.http.post(url, formData);
}

  fetchEntity(classification: any, search:any): Observable<any> {
  const formData = new FormData();
  formData.append("classification", classification)
  formData.append("searchText", search)
  const url = this.BASE_URL+`config/entity`;
  return this.http.post(url, formData);
}

fetchChannel(search:any): Observable<any> {
  const formData = new FormData();
  formData.append("searchText", search)
  const url = this.BASE_URL+`config/channel`;
  return this.http.post(url, formData);
}

fetchPriceType(): Observable<any> {
  const url = this.BASE_URL+`application-config/price-type`;
  return this.http.get(url);
}


fetchScenarioData(simualtionId:any, scenarioId:any): Observable<any> {
  const url = this.BASE_URL+`simulations/scenario/data/${simualtionId}/${scenarioId}`;
  return this.http.get(url);
}


fetchREfVSFORData(simualtionId:any): Observable<any> {
  const url = this.BASE_URL+`simulations/analysis/${simualtionId}`;
  return this.http.get(url);
}

}
