import { CommonModule } from "@angular/common";
import { Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { ButtonAllModule } from "@syncfusion/ej2-angular-buttons";
import { GridAllModule } from "@syncfusion/ej2-angular-grids";
import { CarouselAllModule } from "@syncfusion/ej2-angular-navigations";
import {
  DialogAllModule,
  DialogComponent,
} from "@syncfusion/ej2-angular-popups";

@Component({
  selector: "app-gpo-outlet-summary",
  standalone: true,
  imports: [
    CommonModule,
    ButtonAllModule,
    CarouselAllModule,
    DialogAllModule,
    GridAllModule,
  ],
  templateUrl: "./gpo-outlet-summary.component.html",
  styleUrl: "./gpo-outlet-summary.component.css",
  encapsulation: ViewEncapsulation.None,
})
export class GpoOutletSummaryComponent {
  @ViewChild("outletSummaryDialog")
  outletSummaryDialog!: DialogComponent;
  animationSettings: Object = { effect: "Zoom", duration: 200, delay: 0 };
  visible: Boolean = true;
  dialogWidth: string = "2000px";
  dialogHeight: string = "2000px";
  dimensionModalposition: object = { X: "center", Y: "50" };
  header = "Outlet Summary";
  showCloseIcon = true;
  public size!: number;
  public displayedItems: any;
  dataSource: any;
  rebateDataSource: any;

  public productItems: Record<string, string | number>[] = [
    { ID: 1, Name: "Cardinal", imageName: "Pattern_7" },
    { ID: 2, Name: "Kingfisher", imageName: "Pattern_8" },
    { ID: 3, Name: "Keel-billed-toucan", imageName: "Pattern_9" },
    { ID: 4, Name: "Yellow-warbler", imageName: "Pattern_6" },
  ];

  public getImage(pattern: string): string {
    return `https://ej2.syncfusion.com/angular/demos/assets/diagram/patternimages/${pattern}.png`;
  }

  ngOnInit(): void {
    this.dataSource = [
      {
        gpoId: 20,
        gpoName: "ION",
        membershipStartDate: "01/01/2023",
        membershipEndDate: "12/31/9999",
        membershipStatus: "Active",
        numberOfChildOutlets: 65,
      },
      {
        gpoId: 21,
        gpoName: "Unity",
        membershipStartDate: "01/01/2015",
        membershipEndDate: "12/31/2022",
        membershipStatus: "In Active",
        numberOfChildOutlets: 122,
      },
    ];

    this.rebateDataSource = [
      {
        contractId: "00198523",
        contractName: "ION",
        contractStartDate: "01/01/2023",
        contractEndDate: "12/31/9999",
        contractStatus: "Active",
        brandId: "PRD600",
        brandName: "Rofloxisolo",
        rebateEligible: "Yes",
        rebateEligibleStartDate: "01/01/2023",
        rebateEligibleEndDate: "12/31/9999",
      },
      {
        contractId: "00185452",
        contractName: "ION",
        contractStartDate: "01/01/2015",
        contractEndDate: "12/31/2022",
        contractStatus: "In Active",
        brandId: "PRD901",
        brandName: "Pumalin",
        rebateEligible: "Yes",
        rebateEligibleStartDate: "01/01/2023",
        rebateEligibleEndDate: "12/31/2022",
      },
    ];
  }
}
