import { CommonModule } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {
  ButtonAllModule,
  CheckBoxAllModule,
  RadioButtonAllModule,
  RadioButtonModel,
} from "@syncfusion/ej2-angular-buttons";
import { DatePickerModule } from "@syncfusion/ej2-angular-calendars";
import {
  DropDownListAllModule,
  MultiSelectAllModule,
  MultiSelectComponent,
} from "@syncfusion/ej2-angular-dropdowns";
import { GridAllModule } from "@syncfusion/ej2-angular-grids";
import { TextBoxAllModule } from "@syncfusion/ej2-angular-inputs";
import { GpoService } from "../../../gpo.service";
import { saveAs } from "file-saver";
import { Router } from "@angular/router";
import {
  DialogButtonDirective,
  DialogComponent,
  DialogModule,
} from "@syncfusion/ej2-angular-popups";
import { SnapshotExportConfirmationDialogComponent } from "../../../../common/snapshot-export-confirmation-dialog/snapshot-export-confirmation-dialog.component";
import { SnapshotExportCancelDialogComponent } from "../../../../common/snapshot-export-cancel-dialog/snapshot-export-cancel-dialog.component";

@Component({
  selector: "app-export-snapshot",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DropDownListAllModule,
    ButtonAllModule,
    TextBoxAllModule,
    DatePickerModule,
    RadioButtonAllModule,
    CheckBoxAllModule,
    GridAllModule,
    DialogModule,
    SnapshotExportConfirmationDialogComponent,
    SnapshotExportCancelDialogComponent,
    MultiSelectAllModule,
  ],
  templateUrl: "./export-snapshot.component.html",
  styleUrl: "./export-snapshot.component.css",
})
export class ExportSnapshotComponent implements OnInit {
  exportForm!: FormGroup;
  snapshotData: any[] = [];
  selectedRows: any[] = [];
  selectedFileFormat: string = "csv";
  @ViewChild("snapshotMultiSelect") snapshotMultiSelect!: MultiSelectComponent;
  confirmationDialogData = {
    title: "Confirm Snapshot Creation",
    content: "Confirmation Dialog Content",
  };
  public showConfirmationDialog: boolean = false;
  public showCancelDialog: boolean = false;

  @ViewChild("confirmationDialog") confirmationDialog!: DialogComponent;
  @ViewChild("cancelDialog") cancelDialog!: DialogComponent;
  dialogData = { title: "Dialog Title", content: "Dialog Content" };
  cancelDialogData = {
    title: "Cancel Snapshot Creation",
    content: "Cancel Dialog Content",
  };
  position = { X: "center", Y: "center" };

  delimiters: any[] = [
    { value: "|", name: "Pipe (|)" },
    { value: ",", name: "Comma (,)" },
    { value: "!", name: "Exclamation (!)" },
  ]; // Populate with your data
  mode = "CheckBox"; // For MultiSelect

  public radioOptions: RadioButtonModel = {
    labelPosition: "Before",
    checked: false,
  };

  defaultFormValues = {
    selectedSnapshot: null,
    exportFormat: "csv",
    selectedDelimiter: "",
    exportMode: "download",
  };

  constructor(
    private fb: FormBuilder,
    private gpoService: GpoService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.gpoService.selectedRows$.subscribe((rows) => {
      this.selectedRows = rows;
    });

    this.exportForm = this.fb.group({
      selectedSnapshot: [
        this.defaultFormValues.selectedSnapshot,
        Validators.required,
      ],
      exportFormat: [this.defaultFormValues.exportFormat, Validators.required],
      selectedDelimiter: [this.defaultFormValues.selectedDelimiter],
      exportMode: [this.defaultFormValues.exportMode, Validators.required],
    });

    this.fetchSnapshotData();
  }

  onFileTypeChange(selectedFileType: string): void {
    this.selectedFileFormat = selectedFileType;
    this.customValidation(this.exportForm);
  }

  customValidation(formGroup: FormGroup) {
    const delimitersControl = formGroup.get("selectedDelimiter");

    if (this.selectedFileFormat !== "csv") {
      delimitersControl?.setValidators(Validators.required);
    } else {
      delimitersControl?.clearValidators();
    }

    delimitersControl?.updateValueAndValidity();
  }

  fetchSnapshotData() {
    this.gpoService.getSnapshotData().subscribe({
      next: (response: string | any) => {
        const filteredSnapshots = response.filter(
          (snapshot: any) => snapshot.status === "ACTIVE"
        ); // Filter by status
        this.snapshotData = filteredSnapshots.map((snapshot: any) => {
          const gpoList = JSON.parse(snapshot.gpoList);
          const gpoNames = gpoList.map((gpo: any) => gpo.gpoName);
          return { ...snapshot, gpoList: gpoNames };
        });

        // Set selected rows after snapshotData is populated (optional)
        this.setSelectedRows();

        console.log("Filtered Snapshot Data:", this.snapshotData);
      },
      error: (error: any) => {
        console.log("Error:" + error);
      },
    });
  }

  setSelectedRows() {
    if (this.snapshotMultiSelect && this.selectedRows.length > 0) {
      // Set the selected values in the multiselect based on selectedRows
      this.snapshotMultiSelect.value = this.selectedRows.map((row) => row.id); // Extract IDs from selectedRows
    }
  }

  goBack() {
    this.router.navigate(["/home/point-in-time"]);
  }

  onConfirmation(data: any) {
    if (this.exportForm.valid) {
      const selectedSnapshotControl = data;

      if (
        selectedSnapshotControl &&
        selectedSnapshotControl.result &&
        selectedSnapshotControl.result.selectedSnapshot
      ) {
        selectedSnapshotControl.result.selectedSnapshot.forEach((id: any) => {
          this.gpoService
            .exportSnapshot({
              exportId: id,
              exportMode: data.result.exportMode,
              exportFormat: data.result.exportFormat,
              exportDelimiter: data.result.selectedDelimiter,
            })
            .subscribe({
              next: (blob) => {
                const selectedSnapshot = this.snapshotData.find(
                  (snapshot: any) => snapshot.id === id
                );
                // debugger;

                if (selectedSnapshot && data.result.exportFormat === "csv") {
                  const trimmedFileName =
                    selectedSnapshot.snapshotFileNameJson.slice(0, -4);
                  saveAs(blob, `${trimmedFileName}csv`);
                }
                if (
                  selectedSnapshot &&
                  data.result.exportFormat === "delimiter"
                ) {
                  const trimmedFileName =
                    selectedSnapshot.snapshotFileNameJson.slice(0, -4);
                  saveAs(blob, `${trimmedFileName}.txt`);
                } else {
                  console.error(`Snapshot with id ${id} not found.`);
                }

                // Redirect back to parent screen
                setTimeout(() => {
                  this.goBack();
                }, 3000);
              },
              error: (error: any) => {
                console.log("Error:" + JSON.stringify(error));
              },
            });
        });
        this.showConfirmationDialog = false;
      }
    }
  }

  onCancel(data: any) {
    this.showCancelDialog = false;
    this.exportForm.reset(this.defaultFormValues);
    this.onFileTypeChange(this.defaultFormValues.exportFormat);
  }

  closeDialog(data: any): void {
    this.showConfirmationDialog = false;
    this.showCancelDialog = false;
  }

  onSubmit(): void {
    this.showConfirmationDialog = true;
  }

  cancel() {
    this.showCancelDialog = true;
  }
}
