<ejs-dialog
  #confirmDialog
  [visible]="visible"
  header="Record Process"
  [showCloseIcon]="showCloseIcon"
  [width]="dialogWidth"
  [animationSettings]="animationSettings"
  isModal="true"
  [position]="dimensionModalposition"
>
  <ng-template #content>
    <form>
      <label>Do you want to load validated gpo to db?</label>
      <br /><br />
      <button
        type="button"
        ejs-button
        class="btn btn-primary btn-sm"
        (click)="onClick()"
      >
        Yes</button
      >&nbsp;&nbsp;&nbsp;&nbsp;
      <button
        type="button"
        ejs-button
        class="btn btn-primary btn-sm"
        (click)="cancel()"
      >
        No
      </button>
    </form>
  </ng-template>
</ejs-dialog>
