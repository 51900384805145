<div class="container-fluid mt-3">
  <!-- Row for Switch and Dialog Button -->
  <div class="row">
    <div class="col-12" style="padding: 0 30px">
      <div *ngIf="data && data.totalRecordsUpdated > 0; else noDataTemplate">
        <ejs-grid
          [dataSource]="data.rules"
          #grid
          [allowSorting]="true"
          autoFit="true"
          allowSelection="true"
          allowReordering="true"
          allowResizing="true"
          [selectionSettings]="{
            type: 'Multiple',
            checkboxOnly: true,
            persistSelection: true
          }"
        >
          <e-columns>
            <e-column
              field="dateImpact[0].gpoId"
              headerText="Business Rule Id"
              name="Id"
              [isPrimaryKey]="true"
              width="150"
            ></e-column>
            <e-column
              field="dateImpact[0].gpoName"
              headerText="Business Rule Name"
              name="Name"
              [allowEditing]="false"
              width="250"
            ></e-column>
          </e-columns>
        </ejs-grid>
      </div>
      <ng-template #noDataTemplate>
        <div>No data available</div>
      </ng-template>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-primary execute_btn"
        style="margin-right: 0.5rem"
        (click)="onOkClick()"
      >
        Execute
      </button>
      <button
        type="button"
        class="btn btn-secondary cancle_btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
    </div>
  </div>
</div>
