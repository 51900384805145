<ejs-dialog
  #outletSummaryDialog
  [visible]="visible"
  [header]="header"
  [width]="dialogWidth"
  [height]="dialogHeight"
  [animationSettings]="animationSettings"
  isModal="true"
  [position]="dimensionModalposition"
  [showCloseIcon]="true"
>
  <ng-template #content>
    <div class="row">
      <div class="col-md-4">
        <div style="margin-left: 10px">
          <button
            class="btn btn-secondary"
            style="margin-left: 10px"
            title="View Org"
          >
            <i class="fas fa-list"></i></button
          ><br /><br />
          <a href="" style="margin-left: 5px">Summary</a>
        </div>
      </div>
      <div class="col-md-8">
        <div class="control-container">
          <ejs-carousel [dataSource]="productItems">
            <ng-template #itemTemplate let-data>
              <div class="slide-content"></div>
              <figure class="img-container">
                <img
                  [src]="getImage(data.imageName)"
                  alt=""
                  style="height: 50%; width: 50%"
                />
                <figcaption class="img-caption"></figcaption>
              </figure>
            </ng-template>
          </ejs-carousel>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div style="margin-left: 10px">
          <button
            class="btn btn-secondary"
            style="margin-left: 10px"
            title="View Summay"
          >
            <i class="fas fa-list"></i></button
          ><br /><br />
          <a href="" style="margin-left: 20px">Summary</a>
        </div>
      </div>
      <div class="col-md-8">
        <label style="color: #29b5e1; margin-left: 20px; font-weight: bold"
          >GPO Membership Details</label
        >
        <div style="margin-left: 20px; padding-right: 20px" class="mt-3">
          <ejs-grid
            [dataSource]="dataSource"
            [allowPaging]="true"
            [allowSorting]="true"
          >
            <e-columns>
              <e-column field="gpoId" headerText="GPO Id"></e-column>
              <e-column field="gpoName" headerText="GPO Name"></e-column>
              <e-column
                field="membershipStartDate"
                headerText="Membership Start Date"
              ></e-column>
              <e-column
                field="membershipEndDate"
                headerText="Membership End Date"
              ></e-column>
              <e-column
                field="membershipStatus"
                headerText="Membership Status"
              ></e-column>
              <e-column
                field="numberOfChildOutlets"
                headerText="Number Of Child Outlets"
              ></e-column>
            </e-columns>
          </ejs-grid>
        </div>
        <br />
        <label style="color: #29b5e1; margin-left: 20px; font-weight: bold"
          >Rebate Eligibility</label
        >
        <div style="margin-left: 20px; padding-right: 20px" class="mt-3">
          <ejs-grid
            [dataSource]="rebateDataSource"
            [allowPaging]="true"
            [allowSorting]="true"
          >
            <e-columns>
              <e-column field="contractId" headerText="Contract Id"></e-column>
              <e-column
                field="contractName"
                headerText="Contract Name"
              ></e-column>
              <e-column
                field="contractStartDate"
                headerText="Contract Start Date"
              ></e-column>
              <e-column
                field="contractEndDate"
                headerText="Contract End Date"
              ></e-column>
              <e-column
                field="contractStatus"
                headerText="Contract Status"
              ></e-column>
              <e-column field="brandId" headerText="Brand Id"></e-column>
              <e-column field="brandName" headerText="Brand Name"></e-column>
              <e-column
                field="rebateEligible"
                headerText="Rebate Eligible"
              ></e-column>
              <e-column
                field="rebateEligibleStartDate"
                headerText="Rebate Eligible Start Date"
              ></e-column>
              <e-column
                field="rebateEligibleEndDate"
                headerText="Rebate Eligible End Date"
              ></e-column>
            </e-columns>
          </ejs-grid>
        </div>
        <br />
        <label style="color: #29b5e1; margin-left: 20px; font-weight: bold"
          >Affiliated HCPs</label
        >
      </div>
    </div>
  </ng-template>
</ejs-dialog>
