<div class="container-fluid dialog-content">
  <div class="row">
    <div class="col-12">
      <p style="font-size: 14px; font-weight: 400">
        You have selected to export snapshot with id -
        {{ data.selectedSnapshot }} in {{ data.selectedDelimiter }} delimiter
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <p style="font-size: 14px; font-weight: 400">
        Please confirm. If you hit cancel you will be redirected back to the
        Export screen
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-primary cancle_btn"
        style="margin-right: 0.5rem"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-secondary confirm_btn"
        (click)="onOkClick()"
      >
        Confirm
      </button>
    </div>
  </div>
</div>
