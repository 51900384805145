  <div class="container-fluid dialog-content">
    <div class="row">
      <div class="col-12">
        <p>This will delete the rule permanently and you will not be able to undo this action.</p>
      </div>
    </div>
    <div class="row" style="margin-top: 10px;">
      <div class="col-12 d-flex justify-content-end">
        <button type="button" class="btn btn-secondary cancle_btn" (click)="closeDialog()" style="margin-right: 10px;">Cancel</button>
      <button type="button" class="btn btn-secondary danger_btn" (click)="onOkClick()">Confirm Delete</button>
    </div>
    </div>
  </div>