import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { DataManager, Query, WebApiAdaptor } from "@syncfusion/ej2-data";
import { FormBuilder } from "@angular/forms";
import { GpoService } from "../../gpo.service";
import { ToastService } from "../../Toast/toast.service";
import { CustomgridComponent } from "../customgrid/customgrid.component";
import { OrgchartComponent } from "../../../common/chart/orgchart/orgchart.component";

@Component({
  selector: "fundae-gpo-chart",
  standalone: true,
  imports: [CustomgridComponent, OrgchartComponent],
  templateUrl: "./gpo-chart.component.html",
  styleUrls: ["./gpo-chart.component.css"],
})
export class GpoChartComponent implements OnInit {
  public type = "chart";

  constructor(
    private gpoService: GpoService,
    private toastService: ToastService,
    private formBuilder: FormBuilder
  ) {}

  public ngOnInit(): void {}
}
