<!DOCTYPE html>
<html lang="en">
  <head>
    <style>
      .container {
        width: 500px;
        text-align: center;
        line-height: 400px;
        border: 2px solid #fff;
        margin-top: 12%;
        padding: 2em;
        background-color: #2684cc;
        border-radius: 10px;
      }

      h1 {
        color: #fff;
      }
    </style>
  </head>

  <body>
    <div class="container">
      <h1>Coming soon..!</h1>
    </div>
  </body>
</html>
