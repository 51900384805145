import { CommonModule, formatDate } from "@angular/common";
import { Component, Input, ViewChild } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { ButtonModule } from "@syncfusion/ej2-angular-buttons";
import { DropDownListModule } from "@syncfusion/ej2-angular-dropdowns";
import { TextBoxModule } from "@syncfusion/ej2-angular-inputs";
import { Toast, ToastModule } from "@syncfusion/ej2-angular-notifications";
import { DialogModule } from "@syncfusion/ej2-angular-popups";
import { BusinessRuleConditionDeletionDialogComponent } from "../../../../common/business-rule-condition-deletion-dialog/business-rule-condition-deletion-dialog.component";
import { DatePickerModule } from "@syncfusion/ej2-angular-calendars";
import { GpoService } from "../../../gpo.service";
import { ToastService } from "../../../../common/toast.service";
import { Router } from "@angular/router";

interface WhereCondition {
  whereColumn: string;
  filterValue: string[];
  logicalOperator: string;
  conditionType: string;
  operator: string;
}

interface BusinessCondition {
  column: string;
  value: string;
  ruleType: string;
  whereConditions: WhereCondition[];
}

@Component({
  selector: "app-gpo-rulecondition",
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TextBoxModule,
    DropDownListModule,
    ButtonModule,
    CommonModule,
    GpoRuleconditionComponent,
    ToastModule,
    DialogModule,
    BusinessRuleConditionDeletionDialogComponent,
    DatePickerModule,
  ],
  templateUrl: "./gpo-rulecondition.component.html",
  styleUrl: "./gpo-rulecondition.component.css",
})
export class GpoRuleconditionComponent {
  @ViewChild("toast")
  public toast!: Toast;
  @Input() business_rule_id!: number;

  conditionToRemove!: any;
  showDeleteDialog: boolean = false;

  businessConditionsForm: FormGroup;
  stringOperators: string[] = [
    "EQUAL TO",
    "NOT EQUAL TO",
    "CONTAINS",
    "BEGINS WITH",
    "LIKE",
    "NOT LIKE",
    "IN",
    "NOT IN",
  ];

  dateOperators: string[] = [
    "GREATER THAN",
    "GREATER THAN OR EQUAL",
    "LESS THAN",
    "LESS THAN OR EQUAL",
    "EQUAL",
    "NOT EQUAL",
    "BETWEEN",
  ];
  operators: string[] = [...this.stringOperators, ...this.dateOperators];

  dateColumns: string[] = [];
  dateBaseColumns: string[] = [];
  attributeColumns: string[] = [];
  attributeBaseColumns: string[] = [];
  standardDateValues: string[] = [
    "First Day of Next Month",
    "Last Day of Next Month",
    "First Day of Current Month",
    "Last Day of Current Month",
    "First Day of Current Quarter",
    "Last Day of Current Quarter",
    "First Day of Next Quarter",
    "Last Day of Next Quarter",
    "First Day of Current Year",
    "Last Day of Current Year",
    "First Day of Next Year",
    "Last Day of Next Year",
  ];

  dateConditionValues: string[] = [
    "In the first half of the current calendar quarter",
    "In the second half of the current calendar quarter",
    "In the first half of the next calendar quarter",
    "In the second half of the next calendar quarter",
    "In the first half of the current month",
    "In the second half of the current month",
    "In the first half of the next month",
    "In the second half of the next month",
    "In the first half of the current year",
    "In the second half of the current year",
  ];

  booleanDataSource: string[] = ["TRUE", "FALSE"];

  setDateValues: string[] = [...this.standardDateValues, "Custom Date"];
  dateCustomOptions: string[] = [...this.dateConditionValues, "Custom Date"];

  businessRuleDefinition: any;
  formValid: boolean = true;
  userInteraction: boolean = false;

  constructor(
    private fb: FormBuilder,
    private gpoService: GpoService,
    private toastService: ToastService,
    private router: Router
  ) {
    this.businessConditionsForm = this.fb.group({
      businessConditions: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.gpoService.fetchConfiguration().subscribe({
      next: (response: string | any) => {
        this.dateColumns = response.updateDateColumns;
        this.dateBaseColumns = response.updateDateBaseColumns;
        this.attributeColumns = response.updateAttributeColumns;
        this.attributeBaseColumns = response.updateAttributeBaseColumns;

        this.toastService.showToast(
          "Success!",
          "Business rule configurations fetched successfully!",
          "success"
        );

        let payload: BusinessCondition[] = [];
        if (this.business_rule_id > 0) {
          this.gpoService.getBusinessRule(this.business_rule_id).subscribe(
            (data) => {
              this.businessRuleDefinition = data;
              if (data.businessConditions) {
                payload = data.businessConditions;
                this.toastService.showToast(
                  "Success!",
                  "Business rule details fetched successfully!",
                  "success"
                );
              }

              this.initializeForm(payload);
            },
            (error: any) => {
              this.toastService.showToast(
                "Error!",
                "Failed to load business rule details!",
                "error"
              );
            }
          );
        }
      },
      error: (error: any) => {
        this.toastService.showToast(
          "Error!",
          "Failed to load business rule configurations!",
          "error"
        );
      },
    });

    (this.businessConditions.controls as FormGroup[]).forEach(
      (control, index) => {
        this.addConditionalValidators(control);
        (this.getWhereConditions(index).controls as FormGroup[]).forEach(
          (whereControl) => {
            this.addWhereConditionalValidators(whereControl);
          }
        );
      }
    );
  }

  onChange(event: any, index: any): void {
    if (this.userInteraction) {
      const item = this.businessConditions
        ?.at(index)
        .get("value") as FormControl;
      item.setValue("", { emitEvent: true });
    }
    this.userInteraction = false;
  }

  get businessConditions(): FormArray {
    return this.businessConditionsForm.get("businessConditions") as FormArray;
  }

  addConditionalValidators(group: FormGroup): void {
    const customDateControl = group.get("customDate");
    const valueControl = group.get("value");
    const ruleTypeControl = group.get("ruleType");

    group.get("value")?.valueChanges.subscribe((value) => {
      if (ruleTypeControl?.value === "DATE" && value === "Custom Date") {
        customDateControl?.setValidators([Validators.required]);
      } else {
        customDateControl?.setValue("");
        customDateControl?.setValidators(null);
      }
      customDateControl?.updateValueAndValidity();
    });

    group.get("ruleType")?.valueChanges.subscribe((value) => {
      valueControl?.setValue("");
      valueControl?.updateValueAndValidity();
    });
  }
  addWhereConditionalValidators(group: FormGroup): void {
    const conditionTypeControl = group.get("conditionType");

    const whereColumnControl = group.get("whereColumn");
    const filterValueControl = group.get("filterValue");
    const operatorControl = group.get("operator");
    const customDate1Control = group.get("customDate1");
    const customDate2Control = group.get("customDate2");

    group.get("filterValue")?.valueChanges.subscribe((value) => {
      if (value === "Custom Date") {
        if (conditionTypeControl?.value === "DATE") {
          customDate1Control?.setValidators([Validators.required]);
          customDate2Control?.clearValidators();
        }
      } else {
        operatorControl?.clearValidators();
        customDate1Control?.clearValidators();
      }
      operatorControl?.updateValueAndValidity();
      customDate1Control?.updateValueAndValidity();
      customDate2Control?.updateValueAndValidity();
    });

    group.get("operator")?.valueChanges.subscribe((value) => {
      if (conditionTypeControl?.value === "DATE") {
        if (value === "BETWEEN") {
          customDate1Control?.setValidators([Validators.required]);
          customDate2Control?.setValidators([Validators.required]);
        } else {
          customDate2Control?.setValue("");
          customDate2Control?.clearValidators();
        }
        customDate1Control?.updateValueAndValidity();
        customDate2Control?.updateValueAndValidity();
      }
    });

    group.get("conditionType")?.valueChanges.subscribe((value) => {
      filterValueControl?.setValue("");
      operatorControl?.setValue("");
      whereColumnControl?.setValue("");

      if (value === "ATTRIBUTE") {
        operatorControl?.setValidators([Validators.required]);
      }
      whereColumnControl?.updateValueAndValidity();
      operatorControl?.updateValueAndValidity();
      filterValueControl?.updateValueAndValidity();
    });
  }
  getWhereConditions(businessConditionIndex: number): FormArray {
    return this.businessConditions
      .at(businessConditionIndex)
      .get("whereConditions") as FormArray;
  }
  createBusinessCondition(): FormGroup {
    const group = this.fb.group({
      column: ["", Validators.required],
      value: ["", Validators.required],
      customDate: [""],
      ruleType: ["DATE", Validators.required],
      whereConditions: this.fb.array([this.createWhereCondition()]),
    });
    this.addConditionalValidators(group);
    return group;
  }
  createWhereCondition(): FormGroup {
    const group = this.fb.group({
      whereColumn: ["", Validators.required],
      filterValue: ["", Validators.required],
      customDate1: [""],
      customDate2: [""],
      logicalOperator: ["OR"],
      conditionType: ["DATE", Validators.required],
      operator: [""],
    });
    this.addWhereConditionalValidators(group);
    return group;
  }
  addBusinessCondition(): void {
    this.businessConditions.push(this.createBusinessCondition());
  }
  removeBusinessCondition(index: number): void {
    this.businessConditions.removeAt(index);
  }
  addWhereCondition(businessConditionIndex: number): void {
    const whereConditions = this.getWhereConditions(businessConditionIndex);
    const lastWhereConditionIndex = whereConditions.length - 1;
    const lastWhereCondition = whereConditions.at(
      lastWhereConditionIndex
    ) as FormGroup;

    if (lastWhereCondition.valid) {
      whereConditions.push(this.createWhereCondition());
    } else {
      this.toastService.showToast(
        "Error!",
        "Oops! Looks like you haven't filled out all the necessary fields for the previous condition. Let's complete those first before adding a new one.",
        "error"
      );
    }
  }
  removeWhereCondition(
    businessConditionIndex: number,
    whereConditionIndex: number
  ): void {
    const whereConditions = this.businessConditions
      .at(businessConditionIndex)
      .get("whereConditions") as FormArray;
    whereConditions.removeAt(whereConditionIndex);
  }
  goBack(): void {
    this.router.navigate(["/home/rule"]);
  }
  removeBusinessRule(index: number): void {
    this.conditionToRemove = index;
    this.showDeleteDialog = true;
  }
  onDeleteConfirmation(data: any) {
    this.businessConditions.removeAt(data.result);
    this.showDeleteDialog = false;
  }
  closeDialog(data: any): void {
    this.showDeleteDialog = false;
  }
  onSubmit(): void {
    if (this.businessConditionsForm.valid) {
      const payload = this.generatePayload();
      this.businessRuleDefinition.businessConditions = payload;
      this.gpoService
        .updateBusinessRuleCondition(
          this.businessRuleDefinition,
          this.business_rule_id
        )
        .subscribe(
          (data) => {
            this.business_rule_id = data.id;
            this.toastService.showToast(
              "Success!",
              "Business rule created/updated sucessfully!",
              "success"
            );
            setTimeout(() => {
              this.router.navigate(["/home/rule"]);
            }, 1500);
          },
          (error: any) => {
            this.toastService.showToast(
              "Error!",
              "Failed to create/update business rule!",
              "error"
            );
          }
        );
    } else {
      this.toastService.showToast(
        "Error!",
        "Please complete all required fields before submitting the form.",
        "error"
      );
    }
  }
  generatePayload(): any[] {
    const payload: any[] = [];
    this.businessConditions.controls.forEach((control, index) => {
      const group = control as FormGroup;
      const whereConditions = this.getWhereConditions(index).value;

      const businessCondition = {
        column: group.get("column")?.value,
        value:
          group.get("value")?.value === "Custom Date"
            ? this.formatToDateYYYYMMDD(group.get("customDate")?.value)
            : group.get("value")?.value,
        ruleType: group.get("ruleType")?.value,
        whereConditions: whereConditions.map((wc: any) => ({
          whereColumn: wc.whereColumn,
          filterValue:
            wc.filterValue === "Custom Date" && wc.operator === "BETWEEN"
              ? [
                  this.formatToDateYYYYMMDD(wc.customDate1),
                  this.formatToDateYYYYMMDD(wc.customDate2),
                ]
              : wc.filterValue === "Custom Date" && wc.operator !== "BETWEEN"
              ? [this.formatToDateYYYYMMDD(wc.customDate1)]
              : [wc.filterValue],
          logicalOperator: wc.logicalOperator,
          conditionType: wc.conditionType,
          operator:
            wc.filterValue !== "Custom Date" && wc.conditionType === "DATE"
              ? "BETWEEN"
              : wc.operator,
        })),
      };

      payload.push(businessCondition);
    });
    return payload;
  }
  private updateFormValueAndValidity(formGroup: FormGroup | FormArray): void {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
      if (control instanceof FormControl) {
        control.updateValueAndValidity();
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        this.updateFormValueAndValidity(control);
      }
    });
  }
  formatToDateYYYYMMDD(date: Date): string {
    return formatDate(date, "yyyy-MM-dd", "en-US");
  }
  formatToDateMMDDYYYY(date: Date): string {
    return formatDate(date, "MM-dd-yyyy", "en-US");
  }
  isValidDateString(dateString: string): boolean {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateString) && !isNaN(Date.parse(dateString));
  }
  initializeForm(payload: BusinessCondition[]): void {
    const businessConditionsArray = this.fb.array([] as FormGroup[]);

    this.businessConditions.clear();

    if (payload && payload.length > 0) {
      payload.forEach((condition, index) => {
        this.userInteraction = false;
        const businessConditionGroup = this.createBusinessCondition();
        let customDate = null;
        let value = condition.value;

        if (
          condition.ruleType === "DATE" &&
          this.isValidDateString(condition.value)
        ) {
          customDate = this.formatToDateMMDDYYYY(new Date(condition.value));
          condition.value = "Custom Date";
        }

        businessConditionGroup.patchValue(
          {
            column: condition.column,
            ruleType: condition.ruleType,
            value: condition.value,
            customDate: customDate,
          },
          { emitEvent: false }
        );

        const whereConditionsArray = this.fb.array([] as FormGroup[]);
        condition.whereConditions.forEach((whereCondition, whereIndex) => {
          const whereConditionGroup = this.createWhereCondition();
          let customDate1 = "";
          let customDate2 = "";
          let filterValue = whereCondition.filterValue[0];
          let operator = whereCondition.operator;

          if (
            Array.isArray(whereCondition.filterValue) &&
            whereCondition.filterValue.length === 2
          ) {
            const [value1, value2] = whereCondition.filterValue;
            if (
              this.isValidDateString(value1) &&
              this.isValidDateString(value2)
            ) {
              customDate1 = this.formatToDateMMDDYYYY(new Date(value1));
              customDate2 = this.formatToDateMMDDYYYY(new Date(value2));
              filterValue = "Custom Date";
              operator = "BETWEEN";
            }
          } else if (
            Array.isArray(whereCondition.filterValue) &&
            whereCondition.filterValue.length === 1
          ) {
            const [value1] = whereCondition.filterValue;
            if (this.isValidDateString(value1)) {
              customDate1 = this.formatToDateMMDDYYYY(new Date(value1));
              filterValue = "Custom Date";
            } else if (whereCondition.conditionType === "DATE") {
              operator = "";
            }
          }

          whereConditionGroup.patchValue(
            {
              conditionType: whereCondition.conditionType,
              whereColumn: whereCondition.whereColumn,
              filterValue: filterValue,
              operator: operator,
              customDate1: customDate1,
              customDate2: customDate2,
              logicalOperator: whereCondition.logicalOperator,
            },
            { emitEvent: false }
          );

          whereConditionsArray.push(whereConditionGroup);
        });

        businessConditionGroup.setControl(
          "whereConditions",
          whereConditionsArray
        );
        businessConditionsArray.push(businessConditionGroup);
      });
      this.updateFormValueAndValidity(this.businessConditionsForm);
    } else {
      businessConditionsArray.push(this.createBusinessCondition());
    }

    this.businessConditionsForm.setControl(
      "businessConditions",
      businessConditionsArray
    );

    console.log("Form Values", this.businessConditionsForm.value);
  }

  isValidFilterValue(filterValue: string): void {
    const regex = /^[a-zA-Z0-9]+(,[a-zA-Z0-9]+)*$/;
    if (!regex.test(filterValue)) {
      this.formValid = false;
      this.toastService.showToast(
        "Error!",
        "Invalid filter value. Please enter values separated by commas.",
        "error"
      );
    } else {
      this.formValid = true;
    }
  }
}
