import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListBox, ListBoxAllModule, ListBoxComponent } from '@syncfusion/ej2-angular-dropdowns';



@Component({
  selector: 'app-business-rule-deletion-dialog',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ListBoxAllModule, CommonModule
],
  templateUrl: './business-rule-deletion-dialog.component.html',
  styleUrl: './business-rule-deletion-dialog.component.css'
})
export class BusinessRuleDeletionDialogComponent {
  
  @ViewChild('listBox') listBox!: ListBox; // Reference to the ListBox component

  @Input() data!: any;

  @Output() okClick = new EventEmitter();

  @Output() closeClick = new EventEmitter();
 
  onOkClick() {
    // Emit some data on OK click
    this.okClick.emit({ result:  this.data });
  }

  closeDialog() {
    // Emit some data on OK click
    this.closeClick.emit({ result: 'Cancel to process execution' });
  }

   public setfield = { text: "DisplayName" };

   onDrop(args: any) {
    this.data = args.source.currentData;
  }

  onDragStop() {
  //  const newListOrder = this.listBox.getSortedList();
    // Process the new list order here
  }

}
