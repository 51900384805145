<div class="container-fluid dialog-content" style="top: 10%">
  <div class="row" *ngIf="data.length == 1">
    <div class="col-12">
      <p style="font-size: 14px; font-weight: 400">
        You have selected to delete a Snapshot -
        <span style="font-weight: 600">{{ data[0].snapshotName }}</span> for
        GPO's -
        <span style="font-weight: 600">
          <span *ngFor="let gpo of data[0].gpoList; let last = last">
            {{ gpo }}{{ !last ? "," : "" }}
          </span>
        </span>
        with Point in Time Date -
        <span style="font-weight: 600">
          {{ formatSnapshotDate(data[0].snapshotDate) }}
        </span>
        .
      </p>
    </div>
  </div>
  <div class="row" *ngIf="data.length != 0">
    <div class="col-12">
      <p style="font-size: 14px; font-weight: 400">
        Please confirm. If you hit cancel you will be redirected back to the
        Point in Time screen
      </p>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12 d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-primary delete_btn"
        (click)="onOkClick()"
        style="margin-right: 10px"
      >
        Confirm
      </button>
      <button
        type="button"
        class="btn btn-secondary cancle_btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
    </div>
  </div>
</div>
