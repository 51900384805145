import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule ,FormGroup, FormBuilder,FormControl, Validators} from '@angular/forms';
import { CheckBoxAllModule } from '@syncfusion/ej2-angular-buttons';
import { CheckBoxSelectionService, ListBox, ListBoxAllModule, ListBoxComponent, MultiSelectModule} from '@syncfusion/ej2-angular-dropdowns';
import { GpoService } from '../../layout/gpo.service';

@Component({
  selector: 'app-business-rule-order-dialog',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ListBoxAllModule, CommonModule,MultiSelectModule, CheckBoxAllModule
],
providers: [CheckBoxSelectionService],

  templateUrl: './business-rule-order-dialog.component.html',
  styleUrl: './business-rule-order-dialog.component.css'
})
export class BusinessRuleOrderDialogComponent implements OnInit{
  
  @ViewChild('listBox') listBox!: ListBox; // Reference to the ListBox component

  gpoForm!: FormGroup;
  @Input() data!: any[];
  mode = 'CheckBox'; // For MultiSelect

  newListOrder =  [];
  defaultSelectedValues :any[] =[];
  @Output() okClick = new EventEmitter();
  @Output() dataEvent = new EventEmitter<any>();
  @Output() closeClick = new EventEmitter();
  
  ngOnInit(): void {
   
  }

  constructor(private _gpoService: GpoService,private formBuilder: FormBuilder
    ) {
     
    }
    
  async onOkClick() {
    // Emit some data on OK click
    await this.okClick.emit({ result:  this.data });
    
  }

  closeDialog() {
    // Emit some data on OK click
    this.closeClick.emit({ result: 'Cancel to process execution' });
  }


   public setfield1 = { text: "DisplayName" };


   preprocessData(): void {
    this.data = this.data.map((item : any) => ({
      ...item,
      DisplayName: `${item['businessRuleId']} - ${item['businessRuleName']}`
    }));
  }

   onDrop(args: any) {
    this.data = args.source.currentData;
  }

  onDragStop() {
  //  const newListOrder = this.listBox.getSortedList();
    // Process the new list order here
  }
  
}
