<div class="container-fluid dialog-content">
    <div class="row">
      <div class="col-12">
        <p>To execute Business Rule Sequences, you have the flexibility to reorder them by dragging and dropping. The business rules will then execute sequentially from top to bottom.</p>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-12">
        <ejs-listbox id="customListBox" #listBox [dataSource]="data" [allowDragAndDrop]="true" [fields]="setfield1" (drop)="onDrop($event)" cssClass="custom-listbox"></ejs-listbox>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-content-end">
        <button type="button" class="btn" style="background-color: #29B5E1; color: #FFFFFF;" (click)="onOkClick()">Submit and Check Impact</button>
        <button type="button" class="btn" style="
        background-color: #708090;
        color: white;
        margin: 0 10px;
      " (click)="closeDialog()">Cancel</button>  
    </div>
    </div>
  </div>
  