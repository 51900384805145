  <div class="container-fluid dialog-content">
    <div class="row">
      <div class="col-12">
        <p>This will delete the condition permanently and you will not be able to undo this action.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-content-end">
        <button type="button" class="btn" style="
        background-color: #708090;
        color: white;
        margin: 0 10px;
      " (click)="closeDialog()">Cancel</button>
      <button type="button" class="btn btn-danger btn-sm" style="padding: 4px 20px; font-size: 14px" (click)="onOkClick()">Confirm Delete</button>
    </div>
    </div>
  </div>